import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { ClassOfLossEdit } from 'Components/ProjectData';
import { useLossDataFunctions } from 'Context/LossData';

function ClassOfLossEditContainer() {
  const { selectedLossClass, setSelectedLossClass }: any = useLossDataFunctions();

  const lossCategories = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
  ];

  return (
    <ClassOfLossEdit
      categories={lossCategories}
      selectedLossClass={selectedLossClass}
      setSelectedLossClass={setSelectedLossClass}
    />
  );
}

const ClassOfLossEditContainerMemo = memo(ClassOfLossEditContainer, areEqual);

export { ClassOfLossEditContainerMemo as ClassOfLossEdit };
