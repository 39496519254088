import React, { memo, ReactNode } from 'react';

import { areEqual } from 'Utils/equalityChecks';
import { ProjectDataHeader } from 'Containers/ProjectData';

import classes from './esignature.module.css';

interface Props {
  children?: ReactNode;
}

const Esignature = ({ children }: Props) => (
  <div className={classes.crewContent}>
    <div className="d-flex justify-content-start align-items-center">
      <ProjectDataHeader />
    </div>
    <div className="position-relative">{children}</div>
  </div>
);
Esignature.defaultProps = {
  children: undefined,
};

const EsignatureMemo = memo(Esignature, areEqual);

export { EsignatureMemo as Esignature };
