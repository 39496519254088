import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';
import { useMetricsFunctions } from 'Context/Metrics';
import { Metrics } from 'Components/Metrics';
import { listCompanyMetrics } from 'Containers/Metrics/actions';
import { MetricsFilters } from 'Containers/Metrics/types';

import { firstCompanyIdSelector } from 'Containers/Projects/selectors';

function MetricsContainer() {
  const dispatch = useDispatch();

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);
  const { savedEmployeeFilters, isActiveFilterSet, savedDateFilter, setIsDownloadMetricsModalOpen }: any =
    useMetricsFunctions();

  const [initialPage, setInitialPage] = useState(0);

  const getMetrics = useCallback(
    (initialPage = 0) => {
      const filters: MetricsFilters = {};
      if (savedDateFilter) {
        filters.startsBetween = savedDateFilter;
      }
      if (savedEmployeeFilters) {
        filters.employeeId = savedEmployeeFilters.toString();
      }
      if (isActiveFilterSet) {
        filters.active = isActiveFilterSet;
      }

      dispatch(listCompanyMetrics(firstCompanyId, filters, initialPage + 1));
    },
    [firstCompanyId, initialPage, savedEmployeeFilters, isActiveFilterSet, savedDateFilter]
  );

  const onDownloadButtonClick = () => {
    setIsDownloadMetricsModalOpen(true);
  };

  // initial API call
  useEffect(() => {
    if (firstCompanyId) {
      setInitialPage(0);
      getMetrics();
    }
  }, [firstCompanyId]);

  // fetch on filter change
  useEffect(() => {
    setInitialPage(0);
    getMetrics();
  }, [savedEmployeeFilters, isActiveFilterSet, savedDateFilter]);

  return <Metrics getMetrics={getMetrics} onDownloadButtonClick={onDownloadButtonClick} />;
}

const MetricsContainerMemo = memo(MetricsContainer, areEqual);

export { MetricsContainerMemo as MetricsContainer };
