import React, { memo } from 'react';

import { TextBox } from 'Components/TextBox';
import { TextArea } from 'Components/TextArea';
import TimeKeeper from 'react-timekeeper';
import { RocketCallEmployeeDropdown } from 'Containers/RocketCall';

import { areEqual } from 'Utils/equalityChecks';
import classes from './rocketCallSetupTab.module.css';

interface Props {
  companies: any;
  employees: any;
  selectedValue: number;
  selectedEmployeeName: string;
  showDropDown: boolean;
  forwardPhoneNumber: string;
  onSelectItem: (e: any) => void;
  onClickIcon: (e: any) => void;
  // time selection props
  showStartTimePicker: boolean;
  showEndTimePicker: boolean;
  startTime: string;
  endTime: string;
  setStartTime: (e: any) => void;
  setEndTime: (e: any) => void;
  onClickStartTimeBox: (e: any) => void;
  onClickEndTimeBox: (e: any) => void;
  onCloseStartTimePicker: (e: any) => void;
  onCloseEndTimePicker: (e: any) => void;
}

function RocketCallSetupTab({
  companies,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  employees,
  selectedValue,
  selectedEmployeeName,
  showStartTimePicker,
  showEndTimePicker,
  onClickStartTimeBox,
  onClickEndTimeBox,
  onSelectItem,
  onClickIcon,
  onCloseStartTimePicker,
  onCloseEndTimePicker,
  showDropDown,
  forwardPhoneNumber
}: Props) {
  return (
    <div className={classes.sectionContainer}>
      <form className={`requires-validation g-3 ${classes.formBase}`} noValidate action="#">
        <h2 className={classes.contentHeader}>General Info</h2>
        <div className={classes.generalInfoContainer}>
          <div className={classes.generalInfoColumn}>
            <div className={classes.inline}>
              <div>
                Company Name:
                {companies?.[0]?.name}
              </div>
            </div>
            <br />
            <div className={classes.inline}>
              Company Phone Number:
              <TextBox
                name="formName"
                type="text"
                className={`mb-0 pb-0 ${classes.inlineTextbox}`}
                required
                maxLength={100}
                placeholder=""
                ariaLabel=""
                autoComplete="off"
                value={forwardPhoneNumber}
              />
            </div>
          </div>
          <div className={classes.generalInfoColumn}>
            <div className={`${classes.inline} ${classes.relativePositioning}`}>
              Hours of Operations:
              <TextBox
                name="formName"
                type="text"
                className={`mb-0 pb-0 ${classes.inlineTimeBox}`}
                required
                maxLength={30}
                placeholder=""
                ariaLabel=""
                autoComplete="off"
                readonly
                value={startTime}
                onClick={onClickStartTimeBox}
              />
              to
              <TextBox
                name="formName"
                type="text"
                className={`mb-0 pb-0 ${classes.inlineTimeBox}`}
                required
                maxLength={30}
                placeholder=""
                ariaLabel=""
                autoComplete="off"
                readonly
                value={endTime}
                onClick={onClickEndTimeBox}
              />
              {showStartTimePicker && (
                <div className={classes.timePickerContainer}>
                  <TimeKeeper
                    time={startTime}
                    onChange={(data) => setStartTime(data.formatted12)}
                    switchToMinuteOnHourSelect
                    closeOnMinuteSelect
                    onDoneClick={onCloseStartTimePicker}
                  />
                </div>
              )}
              {showEndTimePicker && (
                <div className={classes.timePickerContainer2}>
                  <TimeKeeper
                    time={endTime}
                    onChange={(data) => setEndTime(data.formatted12)}
                    switchToMinuteOnHourSelect
                    closeOnMinuteSelect
                    onDoneClick={onCloseEndTimePicker}
                  />
                </div>
              )}
            </div>
            <br />
            <div className={classes.inline}>
              Employee on Call:
              <RocketCallEmployeeDropdown
                className={classes.dropDown}
                dropdownType="employee-select"
                choices={employees?.data}
                selectedOptionId={selectedValue}
                selectedOption={selectedEmployeeName}
                onSelectItem={onSelectItem}
                showDropDown={showDropDown}
                onClickIcon={onClickIcon}
                onChangeValueSelect={() => {}}
              />
            </div>
          </div>
        </div>
        <br />
        <h2 className={classes.contentHeader}>Opening</h2>
        <TextArea
          name="formName"
          className={`mb-0 pb-0 ${classes.textArea}`}
          required
          minRows={6}
          maxLength={1000}
          placeholder=""
          ariaLabel=""
          autoComplete="off"
        />
        <br />
        <h2 className={classes.contentHeader}>Fall back answer</h2>
        <TextArea
          name="formName"
          className={`mb-0 pb-0 ${classes.textArea}`}
          required
          minRows={6}
          maxLength={1000}
          placeholder=""
          ariaLabel=""
          autoComplete="off"
        />
      </form>
    </div>
  );
}

const RocketCallSetupTabMemo = memo(RocketCallSetupTab, areEqual);

export { RocketCallSetupTabMemo as RocketCallSetupTab };
