import React, { memo, useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { MetricsFilters } from 'Containers/Metrics/types';

import { MetricsList, MetricsPagination } from 'Components/Metrics';

import { listUserNotesForMetrics } from 'Containers/Metrics/actions';

import { useMetricsFunctions } from 'Context/Metrics';

interface Props {
  getMetrics: (e: any) => void;
}

function MetricsListContainer({ getMetrics }: Props) {
  const dispatch = useDispatch();

  const { companyMetrics, setIsMetricsNotesModalOpen, savedDateFilter }: any = useMetricsFunctions();

  const [pageCount, setPageCount] = useState(1);
  const [initialPage, setInitialPage] = useState(0);

  // set meta data
  useEffect(() => {
    if (companyMetrics?.data?.length > 0) {
      const { meta } = companyMetrics;
      const { total } = meta;

      setPageCount(total <= 15 ? 1 : Number(total / 15));
    }
  }, [companyMetrics]);

  const onPageChange = useCallback(({ selected: page }: any) => {
    setInitialPage(page);
    getMetrics(page);
  }, []);

  const onClickNote = useCallback(
    (userId: number) => {
      const filters: MetricsFilters = {};
      if (savedDateFilter) {
        filters.startsBetween = savedDateFilter;
      }
      dispatch(listUserNotesForMetrics(userId, filters));
      setIsMetricsNotesModalOpen(true);
    },
    [savedDateFilter]
  );

  return (
    <div>
      <MetricsList metrics={companyMetrics} onClickNote={onClickNote} />
      {companyMetrics?.meta?.total >= 15 && (
        <MetricsPagination initialPage={initialPage} pageCount={pageCount} onPageChange={onPageChange} />
      )}
    </div>
  );
}

const MetricsListContainerMemo = memo(MetricsListContainer, areEqual);

export { MetricsListContainerMemo as MetricsList };
