import React, { memo } from 'react';

import { areEqualShallow } from 'Utils/equalityChecks';
import Minus from '../../../Assets/minus.svg';

// Custom css
import classes from './minus.module.css';

interface Props {
  className?: string;
  id?: string;
  onClick?: (e: any) => void;
  onKeyUp?: (e: any) => void;
}
const MinusSvg = ({ className = '', id, onClick, onKeyUp }: Props) => (
  <Minus id={id} className={`${classes.plusBase} ${className || ''}`} onClick={onClick} onKeyUp={onKeyUp} />
);

const MinusSvgMemo = memo(MinusSvg, areEqualShallow);
export { MinusSvgMemo as MinusSvg };
