import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Table, TableBody } from 'Components/Table';
import { TableHead } from 'Containers/Table';

import { InletOutletRows } from 'Components/RocketDry';

import { InternalAtmosphericLogGroup } from 'Containers/RocketDry/types';

import classes from './internalAtmosphericTable.module.css';

interface Props {
  headers: any[];
  logs: InternalAtmosphericLogGroup[];
  roomAreaName: string;
  handleViewPicture: (e: any) => void;
}

function InternalAtmosphericTable({ headers, logs, roomAreaName, handleViewPicture }: Props) {
  return (
    <div className={classes.tableBase}>
      {roomAreaName !== 'null' && <div className={classes.roomAreaHeader}>{roomAreaName}</div>}
      <Table className="table w-100">
        <TableHead headers={headers} sortBy="date" onClickSort={() => {}} />
        {logs?.length > 0 && (
          <TableBody>
            {logs.map((logGroup: InternalAtmosphericLogGroup) => (
              <InletOutletRows key={logGroup.inlet.id} logGroup={logGroup} handleViewPicture={handleViewPicture} />
            ))}
          </TableBody>
        )}
      </Table>
      {/* No log placeholder handled by the room container */}
    </div>
  );
}

const InternalAtmosphericTableMemo = memo(InternalAtmosphericTable, areEqual);

export { InternalAtmosphericTableMemo as InternalAtmosphericTable };
