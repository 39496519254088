import { DamagedMaterialsProvider } from 'Context/RocketScan';
import { DamagedMaterials } from 'Containers/RocketScan';
import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { DamageMaterial, SelectedItem } from 'Containers/RocketScope/types';

interface Props {
  roomId: number;
  roomDamagedMaterials: DamageMaterial[];
  roomScopeOfWorkItems: SelectedItem[];
}

const DamagedMaterialsWrapper = ({ roomId, roomDamagedMaterials, roomScopeOfWorkItems }: Props) => (
  <DamagedMaterialsProvider
    roomId={roomId}
    roomDamagedMaterials={roomDamagedMaterials}
    roomScopeOfWorkItems={roomScopeOfWorkItems}
  >
    <DamagedMaterials />
  </DamagedMaterialsProvider>
);

const DamagedMaterialsWrapperMemo = memo(DamagedMaterialsWrapper, areEqual);

export { DamagedMaterialsWrapperMemo as DamagedMaterialsWrapper };
