import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { Icon } from 'Components/Icons';

import { PurpleButton } from 'Components/Button';

import classes from './noEquipmentPlaceholder.module.css';

interface Props {
  onClickPlaceholderButton: (e: any) => void;
}

function NoEquipmentPlaceholder({ onClickPlaceholderButton }: Props) {
  return (
    <div className={`d-flex align-items-center justify-content-center ${classes.placeHolderWrapper}`}>
      <div className={`d-flex flex-column align-items-center justify-content-center ${classes.placeHolderContainer}`}>
        <div className={classes.formIcon}>
          <Icon type="projects" />
        </div>
        <div className={classes.text}>Add custom equipment to use them in new projects</div>

        <PurpleButton className={classes.buttonAdd} onClick={onClickPlaceholderButton}>
          Add Equipment +
        </PurpleButton>
      </div>
    </div>
  );
}

const NoEquipmentPlaceholderMemo = memo(NoEquipmentPlaceholder, areEqual);
export { NoEquipmentPlaceholderMemo as NoEquipmentPlaceholder };
