import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { TimeTrackingWipProjects } from 'Containers/TimeTracking';
import { ProjectsProvider } from 'Context/Projects';

const TimeTrackingSelectProjectScreen = () => (
  <ProjectsProvider>
    <TimeTrackingWipProjects />
  </ProjectsProvider>
);

const TimeTrackingSelectProjectScreenMemo = memo(TimeTrackingSelectProjectScreen, areEqual);

export { TimeTrackingSelectProjectScreenMemo as TimeTrackingSelectProjectScreen };
