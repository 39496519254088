import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { ScopeSheet } from 'Containers/RocketScope/types';
import { PurpleButton } from 'Components/Button';
import { useDamagedMaterialFunctions } from 'Context/RocketScan';
import classes from './sheetNameMenu.module.css';

interface Props {
  scopeSheets: ScopeSheet[];
  roomId: number;
}

const SheetNameMenu = ({ scopeSheets, roomId }: Props) => {
  const { onSheetSelectClick }: any = useDamagedMaterialFunctions();

  return (
    <div className={`d-flex align-items-center ${classes.damageTypeList}`}>
      {scopeSheets.map((item) => (
        <PurpleButton
          className={classes.sheetNameButton}
          key={`${roomId}-${item.id}`}
          onClick={() => onSheetSelectClick(item.id)}
        >
          {item.name}
        </PurpleButton>
      ))}
    </div>
  );
};

const SheetNameMenuMemo = memo(SheetNameMenu, areEqual);

export { SheetNameMenuMemo as SheetNameMenu };
