import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';
import { CheckedMarkSvg } from 'Components/Icons/CheckedMark';

import classes from './rocketPayToast.module.css';

export interface Props {
  showToast: boolean;
  message: string;
  error: boolean;
}
function RocketPayToast({ showToast = false, message = '', error = false }: Props) {
  return (
    <div
      className={`toast fade d-flex align-items-center position-absolute border-0 bottom-0 ${
        showToast ? 'show' : 'hide'
      } ${classes.toastBase} ${error ? classes.toastError : classes.toastSuccess}`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className={`toast-body ${classes['toast-body-override']} ${classes.toastText}`}>
        {message}
        {!error && (
          <span className={`${classes.toastIcon}`}>
            <CheckedMarkSvg />
          </span>
        )}
      </div>
    </div>
  );
}

RocketPayToast.defaultProps = {};

const RocketPayToastMemo = memo(RocketPayToast, areEqual);

export { RocketPayToastMemo as RocketPayToast };
