/* eslint-disable */
import { handleApiRequest } from 'Utils/handleApiRequest';

export const SET_EQUIPMENT_INVENTORY = 'SET_EQUIPMENT_INVENTORY';
export const FETCHING_EQUIPMENT_INVENTORY = 'FETCHING_EQUIPMENT_INVENTORY';
export const EQUIPMENT_INVENTORY_CREATED = 'EQUIPMENT_INVENTORY_CREATED';
export const EQUIPMENT_INVENTORY_UPDATED = 'EQUIPMENT_INVENTORY_UPDATED';
export const EQUIPMENT_INVENTORY_DELETED = 'EQUIPMENT_INVENTORY_DELETED';
export const SELECTED_EQUIPMENT_FOR_UPDATE = 'SELECTED_EQUIPMENT_FOR_UPDATE';
export const SELECTED_EQUIPMENT_FOR_DELETE = 'SELECTED_EQUIPMENT_FOR_DELETE';

interface ActionTypes {
  SET_EQUIPMENT_INVENTORY: object;
  EQUIPMENT_INVENTORY_CREATED: boolean;
  EQUIPMENT_INVENTORY_UPDATED: boolean;
  EQUIPMENT_INVENTORY_DELETED: boolean;
  FETCHING_EQUIPMENT_INVENTORY: boolean;
  SELECTED_EQUIPMENT_FOR_UPDATE: object;
  SELECTED_EQUIPMENT_FOR_DELETE: object;
}

interface MessageAction {
  type: keyof ActionTypes;
  payload: any;
}

export type equipmentInventoryActionTypes = MessageAction;

/*
 * NON-API THUNKS
 * */

export const setFetchingEquipmentInventory = (value: boolean) => (dispatch) => {
  dispatch({
    type: FETCHING_EQUIPMENT_INVENTORY,
    payload: value,
  });
};

export const setEquipmentCreated = (payload: boolean) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_INVENTORY_CREATED,
    payload,
  });
};

export const setEquipmentUpdated = (payload: boolean) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_INVENTORY_UPDATED,
    payload,
  });
};

export const setEquipmentDeleted = (payload: boolean) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_INVENTORY_DELETED,
    payload,
  });
};

export const setEquipmentForUpdate = (payload: any) => (dispatch) => {
  dispatch({
    type: SELECTED_EQUIPMENT_FOR_UPDATE,
    payload,
  });
};

export const setEquipmentForDelete = (payload: any) => (dispatch) => {
  dispatch({
    type: SELECTED_EQUIPMENT_FOR_DELETE,
    payload,
  });
};

/*
 * API THUNKS
 * */

export const listEquipmentInventory =
  (companyId: string, page = 1) =>
  async (dispatch: any, _getState = null, utils: any) => {
    setFetchingEquipmentInventory(true);

    const response = await handleApiRequest(
      dispatch,
      utils.Api.get(`/companies/${companyId}/equipment-inventories`, {
        params: { page },
      })
    );

    if (response?.data) {
      dispatch({
        type: SET_EQUIPMENT_INVENTORY,
        payload: response,
      });
    } else {
      // we'll disable the spinner if something goes wrong with the API
      setFetchingEquipmentInventory(false);
    }
  };

export const createEquipmentInInventory =
  (companyId: string, name: string) =>
  async (dispatch: any, _getState = null, utils: any) => {
    setEquipmentCreated(false);

    const response = await handleApiRequest(
      dispatch,
      utils.Api.post(`/companies/${companyId}/equipment-inventories`, {
        name,
      })
    );

    if (response?.data) {
      dispatch(setEquipmentCreated(true));
    }
  };

export const updateEquipmentInInventory =
  (equipmentId: string, name: string) =>
  async (dispatch: any, _getState = null, utils: any) => {
    setEquipmentUpdated(false);

    const response = await handleApiRequest(
      dispatch,
      utils.Api.put(`/equipment-inventories/${equipmentId}`, {
        name,
      })
    );

    if (response?.data) {
      dispatch(setEquipmentUpdated(true));
    }
  };

export const deleteEquipmentInInventory =
  (equipmentId: string) =>
  async (dispatch: any, _getState = null, utils: any) => {
    setEquipmentDeleted(false);

    const response = await handleApiRequest(dispatch, utils.Api.delete(`/equipment-inventories/${equipmentId}`));

    if (typeof response === 'string') {
      dispatch(setEquipmentDeleted(true));
    }
  };
