import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Modal } from 'Components/Modal';
import { PurpleButton } from 'Components/Button';

import classes from './deleteEquipmentModal.module.css';

interface Props {
  // modal variables
  isOpen: boolean;
  // equipment variables
  equipmentName: any;
  // modal functions
  modalCloseClick: (e: any) => void;
  onDeleteButtonClick: (e: any) => void;
}

function DeleteEquipmentModal({ isOpen, equipmentName, modalCloseClick, onDeleteButtonClick }: Props) {
  return (
    <Modal
      id="delete-equipment-inventory-modal"
      classes={classes}
      title="Delete Equipment from Inventory"
      isOpen={isOpen}
      modalHeader
      dataBsBackdrop="static"
      dataBsKeyboard="false"
      modalCloseClick={modalCloseClick}
    >
      <div className={classes.deleteModalCopy}>
        <p>
          Are you sure you want to delete
          {` ${equipmentName}`}?
        </p>
        <p>You will not be able to undo this action.</p>
      </div>

      <div className={`modal-footer ${classes.modalFooter}`}>
        <PurpleButton onClick={modalCloseClick} className={classes.cancelButton}>
          Cancel
        </PurpleButton>
        <PurpleButton onClick={onDeleteButtonClick} className={classes.deleteButton}>
          Delete
        </PurpleButton>
      </div>
    </Modal>
  );
}

const DeleteEquipmentModalMemo = memo(DeleteEquipmentModal, areEqual);

export { DeleteEquipmentModalMemo as DeleteEquipmentModal };
