import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import DatePicker from 'react-datepicker';

import { Label } from 'Components/Label';

import formClasses from 'Themes/form/form.module.css';
import classes from './dateTimeEdit.module.css';
import 'react-datepicker/dist/react-datepicker-min.module.css';

interface Props {
  dateTime: Date;
  onDateTimeChange: (date: any) => void;
  labelText: string;
}

function DateTimeEdit({ dateTime, onDateTimeChange, labelText }: Props) {
  return (
    <div className={classes.container}>
      <Label ariaLabel={labelText} className={classes.label}>
        {labelText}
      </Label>
      <DatePicker
        selected={dateTime}
        onChange={onDateTimeChange}
        isClearable
        showTimeSelect
        dateFormat="MM/dd/yyyy h:mm aa"
        maxDate={new Date()}
        className={`form-control ${formClasses.validateField} ${formClasses.validField}`}
      />
    </div>
  );
}

const DateTimeEditMemo = memo(DateTimeEdit, areEqual);

export { DateTimeEditMemo as DateTimeEdit };
