import { ScopeData } from 'Containers/RocketScope/types';
import readXlsxFile, { Row } from 'read-excel-file';
import React from 'react';

const CATEGORY_COLUMN_NAME = 'category';
const CODE_ONE_COLUMN_NAME = 'xactimate code (part 1)';
const CODE_TWO_COLUMN_NAME = 'xactimate code (part 2)';
const DESCRIPTION_COLUMN_NAME = 'desc';
const UNIT_COLUMN_NAME = 'unit';
const RATE_COLUMN_NAME = 'rate';
const NOTES_COLUMN_NAME = 'note';

// filter any rows that don't have a category and a description and a unit.
export function filterScopeData(scopeData: ScopeData[][]): ScopeData[][] {
  const filteredData = scopeData.map((sheet) => sheet.filter((row) => row.category && row.description && row.unit));

  return filteredData.filter((sheet) => sheet.length > 0);
}

// Filter out any rows that don't have a category or description, filter out any sheets that are empty,
export function filterChangeData(
  changeData: { [key: number]: number[] },
  scopeData: ScopeData[][]
): { [key: number]: ScopeData[] } {
  const newChangeData: { [key: number]: ScopeData[] } = {};

  Object.keys(changeData).forEach((sheetId) => {
    const newSheetData = changeData[sheetId]
      .map((id) => scopeData[sheetId].find((row: ScopeData) => row.id === id))
      .filter((row: ScopeData) => row.category && row.description);

    if (newSheetData.length > 0) {
      newChangeData[sheetId] = newSheetData;
    }
  });

  Object.keys(newChangeData).forEach((sheetId) => {
    const sheet = newChangeData[sheetId];
    const result = sheet.filter((row: ScopeData) => row.category && row.description);
    if (result.length > 0) {
      newChangeData[sheetId] = result;
    } else {
      delete newChangeData[sheetId];
    }
  });

  return newChangeData;
}

function allRowsValid(checkScopeData: ScopeData[][]): boolean {
  return checkScopeData.every((sheet) => sheet.some((row) => row.category && row.description && row.unit));
}

export async function parseExcelData(
  arrayBuffer: ArrayBuffer,
  sheetNames: string[],
  selectedFileName: string,
  setFileSelectError: React.Dispatch<React.SetStateAction<string>>,
  setSheetNames: React.Dispatch<React.SetStateAction<string[]>>,
  setCurrentSheet: React.Dispatch<React.SetStateAction<number>>,
  setScopeData: React.Dispatch<React.SetStateAction<ScopeData[][]>>,
  hasFileUpload: (value: boolean) => void
): Promise<void> {
  const importScopeData: ScopeData[][] = [];
  const importSheetNames: string[] = [];

  const promises: Promise<Row[]>[] = [];

  // Doing old school for loop to avoid async issues
  for (let i = 0; i < sheetNames.length; i += 1) {
    promises.push(readXlsxFile(arrayBuffer, { sheet: sheetNames[i] }));
  }

  const results = await Promise.all(promises);

  results.forEach((data, i) => {
    if (data.length > 1) {
      const headerRow = data[0];
      if (headerRow.length > 6) {
        if (
          headerRow[0].toString().toLowerCase().startsWith(CATEGORY_COLUMN_NAME) &&
          headerRow[1].toString().toLowerCase().startsWith(CODE_ONE_COLUMN_NAME) &&
          headerRow[2].toString().toLowerCase().startsWith(CODE_TWO_COLUMN_NAME) &&
          headerRow[3].toString().toLowerCase().startsWith(DESCRIPTION_COLUMN_NAME) &&
          headerRow[4].toString().toLowerCase().startsWith(UNIT_COLUMN_NAME) &&
          headerRow[5].toString().toLowerCase().startsWith(RATE_COLUMN_NAME) &&
          headerRow[6].toString().toLowerCase().startsWith(NOTES_COLUMN_NAME)
        ) {
          importScopeData.push(
            data.slice(1).map((row) => ({
              category: row[0]?.toString() || '',
              codePart1: row[1]?.toString() || '',
              codePart2: row[2]?.toString() || '',
              description: row[3]?.toString() || '',
              unit: row[4]?.toString() || '',
              rate: row[5]?.toString() || '',
              notes: row[6]?.toString() || '',
            }))
          );
          importSheetNames.push(sheetNames[i]);
        }
      }
    }
  });

  if (importSheetNames.length === 0) {
    setFileSelectError(
      `No valid sheets found in the file ${selectedFileName}.
      Please upload a valid Excel file with sheets based on the "RocketScope Template.xslx".`
    );
  } else {
    if (!allRowsValid(importScopeData)) {
      setFileSelectError(
        `Invalid data found in the file. All items must contain at least a category, code Part 1 and a unit.
        Those rows will be skipped on import.`
      );
    } else {
      setFileSelectError('');
      hasFileUpload(true);
    }
    setCurrentSheet(0);
    setScopeData(importScopeData);
    setSheetNames(importSheetNames);
  }
}
