import { handleApiRequest } from 'Utils/handleApiRequest';
import { FORM_ERRORS } from 'Containers/Core/actions';
import { processData } from './dataProcessing';

export const SET_UNITS_PHOTOS_DATA = 'SET_UNITS_PHOTOS_DATA';
export const SET_CUSTOMS_REPORT_PROJECT_DATA = 'SET_CUSTOMS_REPORT_PROJECT_DATA';
export const SET_CUSTOMS_REPORT_CLAIMS_DATA = 'SET_CUSTOMS_REPORT_CLAIMS_DATA';
export const RESET_CLAIMS = 'RESET_CLAIMS';
interface ActionTypes {
  SET_UNITS_PHOTOS_DATA: any;
  SET_CUSTOMS_REPORT_PROJECT_DATA: any;
  SET_CUSTOMS_REPORT_CLAIMS_DATA: any;
  RESET_CLAIMS: any;
}

interface MessageAction {
  type: keyof ActionTypes;
  payload: any;
}
interface ProjectDataType {
  id: any;
  uid: any;
  address: any;
  projectStatus: any;
  createdAt: any;
  projectType: any;
  company: any;
  propertyId: any;
  alias: any;
  hasNewDamageTypes: any;
}

export type setCustomReportActionTypes = MessageAction;
/* eslint-disable */
export const populateCustomReportData =
  (projectId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const project = await handleApiRequest(
      dispatch,
      utils.Api.get(`/projects/${projectId}`, {
        params: {
          include: 'projectStatus,projectType,address,properties',
        },
      }),
      FORM_ERRORS
      // FETCHING_PROJECT
    );

    let projectData: ProjectDataType = {
      id: undefined,
      uid: undefined,
      address: undefined,
      projectStatus: undefined,
      createdAt: undefined,
      projectType: undefined,
      company: undefined,
      propertyId: undefined,
      alias: undefined,
      hasNewDamageTypes: undefined,
    };
    if (project?.data) {
      const {
        id,
        properties: [property],
        address,
        project_status: projectStatus,
        uid,
        alias,
        created_at: createdAt,
        has_new_damage_types: hasNewDamageTypes,
        company,
        project_type: projectType,
      } = project?.data;
      projectData = {
        id,
        uid,
        address,
        projectStatus,
        createdAt,
        projectType,
        company,
        propertyId: property?.id,
        alias,
        hasNewDamageTypes,
      };

      dispatch({
        type: SET_CUSTOMS_REPORT_PROJECT_DATA,
        payload: projectData,
      });
      dispatch({ type: RESET_CLAIMS });
      dispatch(populateProjectClaimsData(projectId));
      dispatch(populateLocationsData(property?.id));
    }
  };

export const populateLocationsData =
  (propertyId: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    let locationsId = [];
    const locations = await handleApiRequest(dispatch, utils.Api.get(`/properties/${propertyId}/locations`));
    if (locations?.data?.length) {
      const { data } = locations;
      for (let i = 0; i < data.length; i += 1) {
        locationsId.push(data[i].id);
      }
    }
    if (locationsId.length) {
      dispatch(populateLocationClaimsData(locationsId));
    }
  };

export const populateLocationClaimsData =
  (locationsId: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    let claimsData = [];
    for (let i = 0; i < locationsId.length; i++) {
      const locationId = locationsId[i];
      const locationClaims = await handleApiRequest(dispatch, utils.Api.get(`/locations/${locationId}/claims`));
      if (locationClaims?.data?.length) {
        const { data } = locationClaims;
        for (let i = 0; i < data.length; i++) {
          claimsData.push(data[i]);
        }
      }
    }
    dispatch({
      type: SET_CUSTOMS_REPORT_CLAIMS_DATA,
      payload: claimsData,
    });
  };

export const populateProjectClaimsData =
  (projectId: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const claims = await handleApiRequest(dispatch, utils.Api.get(`/projects/${projectId}/claims`));
    if (claims?.data) {
      const { data } = claims;
      dispatch({
        type: SET_CUSTOMS_REPORT_CLAIMS_DATA,
        payload: data,
      });
    }
  };

export const populateUnitsPhotosData =
  (projectId: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    let more = true;
    let rawData = [];
    let page = 1;
    while (more) {
      const unitsRoomsAlbumsWithPhotos = await handleApiRequest(
        dispatch,
        utils.Api['get'](`/projects/${projectId}/location-photos`, {
          params: {
            include: 'photoable.location,photo,albums,photoable.roomType,photoable.level',
            page,
          },
        })
      );
      unitsRoomsAlbumsWithPhotos?.meta?.current_page === unitsRoomsAlbumsWithPhotos?.meta?.last_page
        ? (more = false)
        : (more = true);
      page += 1;
      if (unitsRoomsAlbumsWithPhotos?.data) {
        rawData = rawData.concat(unitsRoomsAlbumsWithPhotos?.data);
      }
    }
    let unitsRoomsAlbumsWithPhotosData = [];

    unitsRoomsAlbumsWithPhotosData = rawData.length ? processData(rawData) : rawData;
    dispatch({
      type: SET_UNITS_PHOTOS_DATA,
      payload: unitsRoomsAlbumsWithPhotosData,
    });
  };
