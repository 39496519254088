import React, { memo, ReactNode } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { SingleProjectProvider } from 'Context/Project';
import { ProjectTabsLayout } from 'Containers/Layouts/ProjectTabsLayout';
import { TabContent } from 'Components/Tabs';

import classes from './esignatureWrapper.module.css';

interface Props {
  children: ReactNode;
}

const EsignatureWrapper = ({ children }: Props) => (
  <SingleProjectProvider tab="documents">
    <ProjectTabsLayout tab="documents">
      <TabContent id="documents" className={`active show position-relative ${classes.tabContent}`}>
        {children}
      </TabContent>
    </ProjectTabsLayout>
  </SingleProjectProvider>
);

const EsignatureWrapperMemo = memo(EsignatureWrapper, areEqual);

export { EsignatureWrapperMemo as EsignatureWrapper };
