import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { Button } from 'Components/Button';
import { Icon } from 'Components/Icons';
import { EquipmentCount, NoEquipmentPlaceholder } from 'Components/RocketDry';

import { trimAndToLowerCase } from 'Utils/helpers';

import classes from './locationEquipment.module.css';

interface Props {
  equipmentList: any;
  onInfoIconClick?: (e: any) => void;
}

function LocationEquipment({ equipmentList, onInfoIconClick }: Props) {
  function convertDate(date: String): String {
    const splitDate = date.split('-');
    splitDate[2] = splitDate[2].split('T')[0];
    splitDate.reverse();
    return splitDate.join('-');
  }

  return equipmentList?.length > 0 ? (
    <div className={classes.container}>
      <Button className={classes.infoPopupButton} onClick={onInfoIconClick}>
        <Icon type="info" className={classes.infoIcon} />
      </Button>
      {equipmentList.map((equipment) => (
        <div key={equipment.id} className={classes.itemsRow}>
          <div className={classes.leftItems}>
            <EquipmentCount
              name={equipment.display_name}
              icon={<Icon type={trimAndToLowerCase(equipment.name)} />}
              count={equipment.quantity}
            />
            <div className={classes.innerContainer}>X</div>
          </div>
          <div className={`${classes.innerContainer} ${classes.durationContainer}`}>
            {equipment.date_in && (
              <span>
                Day IN:
                {` ${convertDate(equipment.date_in)}`}
              </span>
            )}
            {equipment.date_out && (
              <span>
                Day OUT:
                {` ${convertDate(equipment.date_out)}`}
              </span>
            )}
            <span>{equipment.duration} Days</span>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <NoEquipmentPlaceholder />
  );
}

LocationEquipment.defaultProps = {
  onInfoIconClick: undefined,
};

const LocationEquipmentMemo = memo(LocationEquipment, areEqual);

export { LocationEquipmentMemo as LocationEquipment };
