import React, { memo, useCallback, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { RocketPayCompanyModel } from 'Containers/User/Models/RocketPayModel/RocketPayCompanyModel';
import { RocketPayPayments, RocketPayStatus, RocketPayPayouts, RequestPaymentModal } from 'Containers/RocketPay';
import { RocketPayTabs } from 'Components/RocketPay';
import { PurpleButton } from 'Components/Button';

import { RocketPayPending } from 'Containers/RocketPay/RocketPayPending';
import classes from './rocketPayDashboard.module.css';

type PaymentDateRange = {
  startDate: string;
  endDate: string;
};

export type PaymentFilters = {
  startsBetween?: PaymentDateRange;
  email?: string;
  payment_status?: string;
};

interface Props {
  companyInfo: RocketPayCompanyModel;
}

function RocketPayDashboardContainer({ companyInfo }: Props) {
  const [isRequestingPayment, setIsRequestingPayment] = useState(false);

  const requestPaymentClick = useCallback(() => {
    setIsRequestingPayment(true);
  }, [setIsRequestingPayment]);

  return (
    <>
      <RocketPayTabs id="rocketpay-tabs">
        <RocketPayPayments companyInfo={companyInfo} />
        <RocketPayPayouts companyInfo={companyInfo} />
        <RocketPayPending />
        <RocketPayStatus companyInfo={companyInfo} />
      </RocketPayTabs>
      <PurpleButton className={classes.upperLeftCornerButton} onClick={requestPaymentClick}>
        Request Payment
      </PurpleButton>
      {isRequestingPayment && <RequestPaymentModal isOpen={isRequestingPayment} setIsOpen={setIsRequestingPayment} />}
    </>
  );
}

const RocketPayDashboardContainerMemo = memo(RocketPayDashboardContainer, areEqual);
export { RocketPayDashboardContainerMemo as RocketPayDashboard };
