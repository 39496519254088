import React, { memo, useCallback, useEffect, useState, useRef } from 'react';

import { areEqual } from 'Utils/equalityChecks';
import { useDispatch, useSelector } from 'react-redux';
import { EquipmentInventory } from 'Components/EquipmentInventory';
import { CreateEquipmentModal, EditEquipmentModal, DeleteEquipmentModal } from 'Containers/EquipmentInventory';

import { DashboardPagination } from 'Components/Pagination';

import { userFeatureFlagsSelector } from 'Containers/User/selector';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import {
  equipmentInventorySelector,
  fetchingEquipmentInventorySelector,
  equipmentCreatedSelector,
  equipmentUpdatedSelector,
  equipmentDeletedSelector,
} from './selectors';
import {
  listEquipmentInventory,
  setEquipmentCreated,
  setEquipmentUpdated,
  setEquipmentDeleted,
  setEquipmentForUpdate,
  setEquipmentForDelete,
} from './actions';

// refer to contractTab.tsx
function EquipmentInventoryContainer() {
  const dispatch = useDispatch();

  const mounted = useRef(true);

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);
  const equipmentInventory = useSelector(equipmentInventorySelector, areEqual);
  const fetching = useSelector(fetchingEquipmentInventorySelector, areEqual);
  const equipmentCreated = useSelector(equipmentCreatedSelector, areEqual);
  const equipmentUpdated = useSelector(equipmentUpdatedSelector, areEqual);
  const equipmentDeleted = useSelector(equipmentDeletedSelector, areEqual);

  // feature flag selector
  const { hideDeleteButton } = useSelector(userFeatureFlagsSelector, areEqual);

  const [pageCount, setPageCount] = useState(1);
  const [initialPage, setInitialPage] = useState(0);
  const [isOpenCreateEquipment, setIsOpenCreateEquipment] = useState(false);
  const [isOpenEditEquipment, setIsOpenEditEquipment] = useState(false);
  const [isOpenDeleteEquipment, setIsOpenDeleteEquipment] = useState(false);

  const getEquipmentInventory = useCallback(
    async (initialPage = 0) => {
      dispatch(listEquipmentInventory(firstCompanyId, initialPage + 1));
    },
    [firstCompanyId]
  );

  // fetch inventory
  useEffect(() => {
    mounted.current = true;
    (async function fetchData() {
      await getEquipmentInventory();
    })();
    return () => {
      if (mounted) {
        mounted.current = false;
      }
    };
  }, [firstCompanyId]);

  // setup pagination
  useEffect(() => {
    if (equipmentInventory?.data?.length > 0) {
      const { meta } = equipmentInventory;
      const { total } = meta;

      setPageCount(total <= 50 ? 1 : Number(total / 50));
    }
  }, [equipmentInventory]);

  const onPageChange = useCallback(({ selected: page }: any) => {
    setInitialPage(page);
    getEquipmentInventory(page);
  }, []);

  // fetch on create
  useEffect(() => {
    if (equipmentCreated) {
      getEquipmentInventory();
      setIsOpenCreateEquipment(false);
      dispatch(setEquipmentCreated(false));
    }

    return () => {
      if (equipmentCreated) {
        dispatch(setEquipmentCreated(false));
      }
    };
  }, [equipmentCreated]);

  // fetch on edit
  useEffect(() => {
    if (equipmentUpdated) {
      getEquipmentInventory();
      setIsOpenEditEquipment(false);
      dispatch(setEquipmentForUpdate(undefined));
      dispatch(setEquipmentUpdated(false));
    }

    return () => {
      if (equipmentUpdated) {
        dispatch(setEquipmentUpdated(false));
        dispatch(setEquipmentForUpdate(undefined));
      }
    };
  }, [equipmentUpdated]);

  // fetch on delete
  useEffect(() => {
    if (equipmentDeleted) {
      getEquipmentInventory();
      setIsOpenDeleteEquipment(false);
      dispatch(setEquipmentForDelete(undefined));
      dispatch(setEquipmentDeleted(false));
    }

    return () => {
      if (equipmentDeleted) {
        dispatch(setEquipmentDeleted(false));
        dispatch(setEquipmentForDelete(undefined));
      }
    };
  }, [equipmentDeleted]);

  // Open Modals
  const onClickOpenCreateEquipment = useCallback(() => {
    setIsOpenCreateEquipment(true);
  }, []);

  const onClickOpenEditEquipment = useCallback((equipment: any) => {
    dispatch(setEquipmentForUpdate(equipment));
    setIsOpenEditEquipment(true);
  }, []);

  const onClickOpenDeleteEquipment = useCallback((equipment: any) => {
    dispatch(setEquipmentForDelete(equipment));
    setIsOpenDeleteEquipment(true);
  }, []);

  const onClickCloseCreateEquipment = useCallback(() => {
    setIsOpenCreateEquipment(false);
  }, []);

  const onClickCloseEditEquipment = useCallback(() => {
    setIsOpenEditEquipment(false);
  }, []);

  const onClickCloseDeleteEquipment = useCallback(() => {
    setIsOpenDeleteEquipment(false);
  }, []);

  return (
    <div className="container-fluid px-0 position-relative">
      <EquipmentInventory
        equipmentInventoryData={equipmentInventory}
        canDelete={!hideDeleteButton}
        fetching={fetching}
        onAddButtonClick={onClickOpenCreateEquipment}
        onEditButtonClick={onClickOpenEditEquipment}
        onDeleteButtonClick={onClickOpenDeleteEquipment}
      />
      {equipmentInventory?.meta?.total > 50 && (
        <DashboardPagination initialPage={initialPage} pageCount={pageCount} onPageChange={onPageChange} />
      )}
      <CreateEquipmentModal isOpen={isOpenCreateEquipment} onCloseClick={onClickCloseCreateEquipment} />
      <EditEquipmentModal isOpen={isOpenEditEquipment} onCloseClick={onClickCloseEditEquipment} />
      {!hideDeleteButton && (
        <DeleteEquipmentModal isOpen={isOpenDeleteEquipment} onCloseClick={onClickCloseDeleteEquipment} />
      )}
    </div>
  );
}

const EquipmentInventoryContainerMemo = memo(EquipmentInventoryContainer, areEqual);

export { EquipmentInventoryContainerMemo as EquipmentInventoryContainer };
