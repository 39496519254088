import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { Table, TableBody, TableColumn, TableHeader, TableRow, Th } from 'Components/Table';
import { formatDate } from 'Utils/helpers';

import classes from './contractFormList.module.css';
import { NoContractFormTable } from '../NoContractFormTable';
import { DeleteContractForm } from '../DeleteContractForm';

interface Props {
  contractforms: any;
  canDelete: boolean;
  onClickRow?: (e: any) => void;
  onDeleteClick?: (e: any) => void;
}

function ContractFormList({ contractforms, canDelete, onClickRow, onDeleteClick }: Props) {
  return contractforms?.length > 0 ? (
    <Table className={`table ${classes.formListWrapper}`}>
      <TableHeader>
        <TableRow>
          <Th>Template Name</Th>
          <Th>Date Created</Th>
          {canDelete && <Th />}
        </TableRow>
      </TableHeader>
      <TableBody>
        {contractforms.map(({ id, name, created_at: createdAt }: any) => (
          <TableRow key={id}>
            <TableColumn dataId={id} tdOnClick={onClickRow}>
              <p className={classes.formName}>{name}</p>
            </TableColumn>
            <TableColumn dataId={id} tdOnClick={onClickRow} className={classes.columnContent}>
              <p className={classes.numberAndDate}>{formatDate(createdAt, 'PP')}</p>
            </TableColumn>
            {canDelete && (
              <TableColumn>
                <DeleteContractForm contractId={id} onDeleteClick={onDeleteClick} />
              </TableColumn>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <NoContractFormTable />
  );
}

ContractFormList.defaultProps = {
  onClickRow: null,
  onDeleteClick: null,
};

const ContractFormListMemo = memo(ContractFormList, areEqual);

export { ContractFormListMemo as ContractFormList };
