import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { Table, TableBody, TableColumn, TableHeader, TableRow, Th } from 'Components/Table';

import { Icon } from 'Components/Icons';

import classes from './metricsList.module.css';

interface Props {
  metrics?: any;
  onClickNote?: (id: number) => void;
}

function MetricsList({ metrics, onClickNote }: Props) {
  return metrics?.data?.length > 0 ? (
    <Table className={`table ${classes.metricsListWrapper}`}>
      <TableHeader>
        <TableRow>
          <Th>
            <span className={classes.thSpan}>Employee Name</span>
          </Th>
          <Th>
            <span className={classes.thSpan}>Active</span>
          </Th>
          <Th>
            <span className={classes.thSpan}>Projects Created</span>
          </Th>
          <Th>
            <span className={classes.thSpan}>Photos</span>
          </Th>
          <Th>
            <span className={classes.thSpan}>Notes</span>
          </Th>
          <Th>
            <span className={classes.thSpan}>Moisture Logs</span>
          </Th>
          <Th>
            <span className={classes.thSpan}>Documents</span>
          </Th>
        </TableRow>
      </TableHeader>
      <TableBody>
        {metrics.data.map((userMetrics) => (
          <TableRow key={userMetrics.id}>
            <TableColumn>
              <div>{userMetrics.full_name}</div>
            </TableColumn>

            <TableColumn className={classes.columnContent}>
              <div>{userMetrics.active ? 'Yes' : 'No'}</div>
            </TableColumn>

            <TableColumn className={classes.columnContent}>{userMetrics.projects_created}</TableColumn>
            <TableColumn className={classes.columnContent}>{userMetrics.photos_uploaded}</TableColumn>
            {userMetrics.notes_created > 0 ? (
              <TableColumn className={classes.columnContent} tdOnClick={() => onClickNote(userMetrics.id)}>
                {userMetrics.notes_created}
              </TableColumn>
            ) : (
              <TableColumn className={classes.columnContent}>0</TableColumn>
            )}
            <TableColumn className={classes.columnContent}>{userMetrics.moisture_logs_created}</TableColumn>
            <TableColumn className={classes.columnContent}>{userMetrics.documents_created}</TableColumn>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <>
      <Table className={`table ${classes.metricsListWrapper}`}>
        <TableHeader>
          <TableRow>
            <Th>
              <span className={classes.thSpan}>Employee Name</span>
            </Th>
            <Th>
              <span className={classes.thSpan}>Active</span>
            </Th>
            <Th>
              <span className={classes.thSpan}>Projects Created</span>
            </Th>
            <Th>
              <span className={classes.thSpan}>Photos</span>
            </Th>
            <Th>
              <span className={classes.thSpan}>Notes</span>
            </Th>
            <Th>
              <span className={classes.thSpan}>Moisture Logs</span>
            </Th>
            <Th>
              <span className={classes.thSpan}>Documents</span>
            </Th>
          </TableRow>
        </TableHeader>
      </Table>
      <div className={`d-flex justify-content-center align-items-center flex-column w-100 ${classes.noMetricsContent}`}>
        <p className={classes.noMetricsText}>No metrics available</p>
        <Icon type="rocketemblemtwo" />
      </div>
    </>
  );
}

MetricsList.defaultProps = {
  metrics: [],
  onClickNote: null,
};

const MetricsListMemo = memo(MetricsList, areEqual);

export { MetricsListMemo as MetricsList };
