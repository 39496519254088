import React, { memo } from 'react';

import { areEqualShallow } from 'Utils/equalityChecks';
import EntireUnit from '../../../Assets/entire-unit.svg';

// Custom css
import classes from './entireUnit.module.css';

interface Props {
  className?: string;
  id?: string;
  onClick?: (e: any) => void;
  onKeyUp?: (e: any) => void;
}
function EntireUnitSvg({ className = '', id, onClick, onKeyUp }: Props) {
  return (
    <EntireUnit
      id={id}
      className={`${classes.entireUnitBase} ${className || ''}`}
      onClick={onClick}
      onKeyUp={onKeyUp}
    />
  );
}

EntireUnitSvg.defaultProps = {
  className: undefined,
  id: undefined,
  onClick: undefined,
  onKeyUp: undefined,
};

const EntireUnitSvgMemo = memo(EntireUnitSvg, areEqualShallow);
export { EntireUnitSvgMemo as EntireUnitSvg };
