import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Modal } from 'Components/Modal';
import { Label } from 'Components/Label';
import { TextBox } from 'Components/TextBox';
import { PurpleButton } from 'Components/Button';

import { ValidateBackGround } from 'Components/Validation';

import formClasses from 'Themes/form/form.module.css';
import classes from './createEquipmentModal.module.css';

interface Props {
  // modal variables
  isOpen: boolean;
  canCreate: boolean;
  formErrors: any;
  // equipment variables
  equipmentName: string;
  // modal functions
  modalCloseClick: (e: any) => void;
  onCreateButtonClick: (e: any) => void;
  // equipment functions
  onChangeEquipmentName: (e: any) => void;
}

function CreateEquipmentModal({
  isOpen,
  canCreate,
  formErrors,
  equipmentName,
  modalCloseClick,
  onCreateButtonClick,
  onChangeEquipmentName,
}: Props) {
  return (
    <Modal
      id="add-equipment-inventory-modal"
      classes={classes}
      title="Add Equipment to Inventory"
      isOpen={isOpen}
      modalHeader
      dataBsBackdrop="static"
      dataBsKeyboard="false"
      modalCloseClick={modalCloseClick}
    >
      <form
        className={`requires-validation g-3 ${formClasses.formBase} ${classes.container}`}
        noValidate
        action="#"
        onSubmit={onCreateButtonClick}
      >
        <ValidateBackGround isValid={!formErrors?.name?.length}>
          <Label ariaLabel="Equipment Name" className={formClasses.label} htmlFor="equipmentName">
            Equipment Name
          </Label>
          <TextBox
            value={equipmentName}
            name="equipmentName"
            type="text"
            className={`mb-0 pb-0 ${formClasses.validateField} ${
              formErrors?.name?.length ? formClasses.invalidField : formClasses.validField
            } ${formErrors?.name?.length ? 'is-invalid' : ''}`}
            required
            placeholder=""
            ariaLabel="Please enter the equipment name"
            onChange={onChangeEquipmentName}
            autoComplete="off"
          />
          <div
            className={`${formClasses.invalidFieldFeedback} invalid-feedback ${
              formErrors?.name?.length ? 'd-block' : ''
            }`}
          >
            {formErrors?.name?.[0]}
          </div>
        </ValidateBackGround>
        <br />
        <div className={`modal-footer ${classes.modalFooter}`}>
          <PurpleButton onClick={modalCloseClick} className={classes.cancelButton}>
            Cancel
          </PurpleButton>
          <PurpleButton onClick={onCreateButtonClick} disabled={!canCreate} className={classes.createButton}>
            Create
          </PurpleButton>
        </div>
      </form>
    </Modal>
  );
}

const CreateEquipmentModalMemo = memo(CreateEquipmentModal, areEqual);

export { CreateEquipmentModalMemo as CreateEquipmentModal };
