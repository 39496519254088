import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import {
  RocketPayAgreementScreen,
  RocketPayBankInfoForm,
  RocketPayDashboard,
  RocketPayBusinessForm,
} from 'Containers/RocketPay';

import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { RocketPayCompanyModel } from 'Containers/User/Models/RocketPayModel/RocketPayCompanyModel';
import { getRocketPayStatus, acceptJustiFiTerms } from './actions';

enum RocketPayShow {
  Initial,
  AgreementScreen,
  BusinessForm,
  BankInfoForm,
  Dashboard,
}

function RocketPayRouter() {
  const dispatch = useDispatch();

  const [screen, setScreen] = useState(RocketPayShow.Initial);
  const [rocketPayCompanyInfo, setRocketPayCompanyInfo] = useState<RocketPayCompanyModel>(null);

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

  const fetchRocketPayStatus = useCallback(async () => {
    const response: any = await dispatch(getRocketPayStatus(firstCompanyId));
    if (response.data) {
      const companyInfo: RocketPayCompanyModel = response.data;
      setRocketPayCompanyInfo(companyInfo);

      // onboarding order is AgreementScreen -> BusinessForm -> BankInfoForm -> Dashboard
      if (
        companyInfo.business_id &&
        companyInfo.terms_accepted_id &&
        companyInfo.platform_account_id &&
        companyInfo.bank_account_id
      ) {
        setScreen(RocketPayShow.Dashboard);
      } else if (!companyInfo.business_id) {
        // put user at the first part of onboarding without the required id
        setScreen(RocketPayShow.AgreementScreen);
      } else if (!companyInfo.platform_account_id || !companyInfo.terms_accepted_id) {
        // We're accepting terms at the business form screen as it takes some time for JustiFi to
        // create the business account outside of test mode. The intent is that they'll create the
        // entry by the time the user fills out the form so we can accept the terms.
        setScreen(RocketPayShow.BusinessForm);
      } else if (!companyInfo.bank_account_id) {
        setScreen(RocketPayShow.BankInfoForm);
      } else {
        setScreen(RocketPayShow.AgreementScreen);
      }
    }
  }, [firstCompanyId]);

  const businessFormSuccessCallback = useCallback(() => {
    dispatch(acceptJustiFiTerms(firstCompanyId));
    setScreen(RocketPayShow.BankInfoForm);
  }, [firstCompanyId]);

  useEffect(() => {
    (async function fetchData() {
      await fetchRocketPayStatus();
    })();
  }, [firstCompanyId]);

  switch (screen) {
    // RocketPay dashboard proper
    case RocketPayShow.Dashboard:
      return <RocketPayDashboard companyInfo={rocketPayCompanyInfo} />;
    // handle onboarding process
    case RocketPayShow.AgreementScreen:
      return <RocketPayAgreementScreen recheckStatus={fetchRocketPayStatus} />;
    case RocketPayShow.BankInfoForm:
      return <RocketPayBankInfoForm recheckStatus={fetchRocketPayStatus} />;
    case RocketPayShow.BusinessForm:
      return (
        <RocketPayBusinessForm
          businessId={rocketPayCompanyInfo.business_id}
          successCallback={businessFormSuccessCallback}
        />
      );
    default:
      return null;
  }
}

const RocketPayRouterMemo = memo(RocketPayRouter, areEqual);
export { RocketPayRouterMemo as RocketPayRouter };
