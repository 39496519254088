// types
import { handleApiRequest } from 'Utils/handleApiRequest';

import { downloadLocal } from 'Utils/helpers';
import { MetricsFilters } from './types';

export const SET_COMPANY_METRICS = 'SET_COMPANY_METRICS';
export const SET_USER_METRICS_NOTES = 'SET_USER_METRICS_NOTES';

interface ActionTypes {
  SET_COMPANY_METRICS: any[];
  SET_USER_METRICS_NOTES: any[];
}

interface MessageAction {
  type: keyof ActionTypes;
  payload: any;
}

export type MetricsTypes = MessageAction;

/*
 * NON-API THUNKS
 * */
export const resetUserMetricsNotes = () => async (dispatch: any) => {
  dispatch({
    type: SET_USER_METRICS_NOTES,
    payload: [],
  });
};

/*
 * API THUNKS
 * */
/* eslint-disable */
export const listCompanyMetrics =
  (companyId: number, filters: MetricsFilters, page = 1) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const params = {
      page,
      'filter[user_id]': filters.employeeId,
      'filter[is_active]': filters.active,
    };

    if (filters.startsBetween) {
      params['filter[created_between]'] = `${filters.startsBetween.startDate},${filters.startsBetween.endDate}`;
    }

    const response = await handleApiRequest(
      dispatch,
      utils.Api.get(`companies/${companyId}/metrics`, {
        params,
      })
    );

    if (response?.data) {
      dispatch({
        type: SET_COMPANY_METRICS,
        payload: {
          data: response.data,
          meta: response.meta ?? {},
          links: response.links ?? {},
        },
      });
    }
  };

export const exportCompanyMetricsCsv =
  (companyId: number, filters: MetricsFilters) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const params = {
      limit: 120,
      'filter[user_id]': filters.employeeId,
      'filter[is_active]': filters.active,
    };

    if (filters.startsBetween) {
      params['filter[created_between]'] = `${filters.startsBetween.startDate},${filters.startsBetween.endDate}`;
    }

    const response = await handleApiRequest(
      dispatch,
      utils.Api.csvGet(`companies/${companyId}/metrics/export`, {
        params,
      })
    );

    if (response) {
      const name = 'metrics.csv';
      downloadLocal(response, name);
    }
  };

export const listUserNotesForMetrics =
  (userId: number, filters: MetricsFilters, page = 1) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const params = {
      page,
    };

    if (filters.startsBetween) {
      params['filter[created_between]'] = `${filters.startsBetween.startDate},${filters.startsBetween.endDate}`;
    }

    const response = await handleApiRequest(
      dispatch,
      utils.Api.get(`users/${userId}/notes`, {
        params,
      })
    );

    if (response?.data) {
      dispatch({
        type: SET_USER_METRICS_NOTES,
        payload: {
          data: response.data,
          meta: response.meta ?? {},
          links: response.links ?? {},
        },
      });
    }
  };
