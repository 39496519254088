import React, { memo, useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';
import { debounce } from 'Utils/debounce';

import { MetricsFilters } from 'Components/Metrics';
import { useMetricsFunctions } from 'Context/Metrics';

import { listEmployeesForTimesheets } from 'Containers/TimeTracking/actions';

import { firstCompanyIdSelector } from 'Containers/Projects/selectors';

function MetricsFiltersContainer() {
  const {
    // filter open status
    isEmployeeFilterOpen,
    isActiveFilterOpen,
    isDateRangeFilterOpen,
    setIsEmployeeFilterOpen,
    setIsActiveFilterOpen,
    setIsDateRangeFilterOpen,
    // list of filters to show
    employeeFilters,
    // selected (but not saved) filters
    selectedEmployeeFilters,
    dateFilterStart,
    dateFilterEnd,
    // saved filters
    savedEmployeeFilters,
    isActiveFilterSet,
    isDateFilterApplied,
    // filter functions
    onEmployeeFilterListItemClick,
    onActiveFilterOptionClick,
    applyEmployeeFilters,
    onChangeDateFilter,
    applyDateFilters,
    clearEmployeeFilters,
    clearDateFilter,
  }: any = useMetricsFunctions();

  const dispatch = useDispatch();

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

  // search box refs
  const employeeSearchRef = useRef(undefined);

  const [employeeSearchValue, setEmployeeSearchValue] = useState('');

  const onEmployeeFilterClick = useCallback(() => {
    setIsEmployeeFilterOpen((prevState) => !prevState);
  }, []);

  const onActiveFilterClick = useCallback(() => {
    setIsActiveFilterOpen((prevState) => !prevState);
  }, []);

  const onDateRangeFilterClick = useCallback(() => {
    setIsDateRangeFilterOpen((prevState) => !prevState);
  }, []);

  const onApplyFilterButtonClicked = useCallback(
    (e: any) => {
      e.preventDefault();
      const { id } = e.target;

      if (id === 'employee') {
        applyEmployeeFilters();
        setIsEmployeeFilterOpen(false);
      } else if (id === 'date') {
        applyDateFilters();
        setIsDateRangeFilterOpen(false);
      }
    },
    [applyEmployeeFilters, applyDateFilters]
  );

  const onClearFilterButtonClicked = useCallback((e: any) => {
    e.preventDefault();
    const { id } = e.target;

    if (id === 'employee') {
      clearEmployeeFilters();
      setIsEmployeeFilterOpen(false);
    } else if (id === 'date') {
      clearDateFilter();
      setIsDateRangeFilterOpen(false);
    }
  }, []);

  // api call to list filters
  const getEmployees = useCallback(
    (search = '') => {
      dispatch(listEmployeesForTimesheets(firstCompanyId, search));
    },
    [firstCompanyId]
  );

  // get employee filters
  useEffect(() => {
    if (firstCompanyId) {
      getEmployees();
    }
  }, [firstCompanyId]);

  // handle search box value change
  const handleEmployeeSearchValueChange = ({ target: { value } }: any) => {
    if (value.length <= 36) {
      setEmployeeSearchValue(value);
      if (value.length >= 2) {
        getEmployees(value);
      }
      if (value.length === 0) {
        getEmployees();
      }
    }
  };

  // debounce function on search value change
  const onChangeEmployeeSearchValue = useMemo(() => debounce(handleEmployeeSearchValueChange, 300), [firstCompanyId]);

  const onClickEmployeeSearchClearButton = useCallback(() => {
    getEmployees();
    setEmployeeSearchValue('');
    employeeSearchRef.current.value = '';
    employeeSearchRef.current.focus();
  }, [firstCompanyId, employeeSearchRef]);

  return (
    <MetricsFilters
      startDate={dateFilterStart}
      endDate={dateFilterEnd}
      isEmployeeFilterOpen={isEmployeeFilterOpen}
      isActiveFilterOpen={isActiveFilterOpen}
      isDateRangeFilterOpen={isDateRangeFilterOpen}
      // employee filters
      employeeFilters={employeeFilters}
      selectedEmployeeFilters={selectedEmployeeFilters}
      employeeSearchRef={employeeSearchRef}
      employeeSearchValue={employeeSearchValue}
      savedEmployeeFiltersCount={savedEmployeeFilters.length}
      onChangeEmployeeSearchValue={onChangeEmployeeSearchValue}
      // active filter
      activeFilterSet={isActiveFilterSet}
      // date filter
      dateFilterApplied={isDateFilterApplied}
      // functions
      onEmployeeFilterClick={onEmployeeFilterClick}
      onEmployeeFilterListItemClick={onEmployeeFilterListItemClick}
      onClickEmployeeSearchClearButton={onClickEmployeeSearchClearButton}
      onActiveFilterClick={onActiveFilterClick}
      onActiveFilterOptionClick={onActiveFilterOptionClick}
      onDateRangeFilterClick={onDateRangeFilterClick}
      onChangeDate={onChangeDateFilter}
      onApplyFilterButtonClick={onApplyFilterButtonClicked}
      onClearFilterButtonClick={onClearFilterButtonClicked}
      onClearDateFilterButtonClick={onClearFilterButtonClicked}
    />
  );
}

const MetricsFiltersContainerMemo = memo(MetricsFiltersContainer, areEqual);

export { MetricsFiltersContainerMemo as MetricsFilters };
