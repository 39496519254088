import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { DamagedMaterials } from 'Components/RocketScan';
import { useDamagedMaterialFunctions } from 'Context/RocketScan';

const DamagedMaterialsContainer = () => {
  // global functions
  const { roomId, damageTypes, scopeSheets, editMode, damagesCount, onClickEditButton, onClickAddButton }: any =
    useDamagedMaterialFunctions();

  return (
    <DamagedMaterials
      roomId={roomId}
      damagesCount={damagesCount}
      editMode={editMode}
      scopeSheets={scopeSheets}
      onClickEditButton={onClickEditButton}
      onClickAddButton={onClickAddButton}
      damageTypes={damageTypes}
    />
  );
};

const DamagedMaterialsContainerMemo = memo(DamagedMaterialsContainer, areEqual);

export { DamagedMaterialsContainerMemo as DamagedMaterials };
