import React from 'react';
import { Span } from 'Components/Span';

import classes from './EsignatureItem.module.css';

interface Props {
  formId: number;
  title: string;
  checkList: number[];
  selectContractForm: (formId: number) => void;
}

export const EsignatureItem = ({ formId, title, checkList, selectContractForm }: Props) => (
  <Span className={classes.typeContainer}>
    <Span className={classes.typeTitle}>{title}</Span>
    <span
      role="button"
      aria-label="checkbox"
      tabIndex={0}
      onClick={() => selectContractForm(formId)}
      className={checkList.indexOf(formId) === -1 ? classes.unchecked : classes.checked}
      onKeyDown={() => {}}
    >
      {checkList.indexOf(formId) > -1 && (
        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.6464 0.646447C16.8417 0.451184 17.1583 0.451184 17.3536 0.646447C17.5271 0.820013 17.5464 1.08944 17.4114 1.28431L17.3536 1.35355L6.35355 12.3536C6.17999 12.5271 5.91056 12.5464 5.71569 12.4114L5.64645 12.3536L0.646447 7.35355C0.451184 7.15829 0.451184 6.84171 0.646447 6.64645C0.820013 6.47288 1.08944 6.4536 1.28431 6.58859L1.35355 6.64645L6 11.293L16.6464 0.646447Z"
            fill="#5B476B"
          />
        </svg>
      )}
    </span>
  </Span>
);
