import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';
import { useTimeTrackingFunctions } from 'Context/TimeTracking';
import { Timesheets } from 'Components/TimeTracking';
import { listCompanyTimeCards } from 'Containers/TimeTracking/actions';
import { TimeCardFilters } from 'Containers/TimeTracking/types';

import { refreshCompanyTimeCardsSelector } from 'Containers/TimeTracking/selectors';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';

const TimesheetsContainer = () => {
  const dispatch = useDispatch();

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);
  const refreshCompanyTimeCards = useSelector(refreshCompanyTimeCardsSelector, areEqual);
  const {
    savedEmployeeFilters,
    savedAddressFilters,
    savedProjectNumFilters,
    savedDateFilter,
    setIsCreateTimeCardModalOpen,
    setIsDownloadTimesheetsModalOpen,
  }: any = useTimeTrackingFunctions();

  const [initialPage, setInitialPage] = useState(0);

  const getTimeCards = useCallback(
    (initialPage = 0) => {
      const filters: TimeCardFilters = {};
      if (savedDateFilter) {
        filters.startsBetween = savedDateFilter;
      }
      if (savedEmployeeFilters) {
        filters.employeeId = savedEmployeeFilters.toString();
      }
      if (savedAddressFilters) {
        filters.addressId = savedAddressFilters.toString();
      }
      if (savedProjectNumFilters) {
        filters.projectId = savedProjectNumFilters.toString();
      }

      dispatch(listCompanyTimeCards(firstCompanyId, filters, initialPage + 1));
    },
    [firstCompanyId, initialPage, savedEmployeeFilters, savedAddressFilters, savedProjectNumFilters, savedDateFilter]
  );

  // initial API call
  useEffect(() => {
    if (firstCompanyId) {
      setInitialPage(0);
      getTimeCards();
    }
  }, [firstCompanyId]);

  // fetch on filter change
  useEffect(() => {
    setInitialPage(0);
    getTimeCards();
  }, [savedEmployeeFilters, savedAddressFilters, savedProjectNumFilters, savedDateFilter]);

  // fetch
  useEffect(() => {
    if (refreshCompanyTimeCards) {
      getTimeCards();
    }
  }, [refreshCompanyTimeCards]);

  const onCreateTimeCardButtonClick = () => {
    setIsCreateTimeCardModalOpen(true);
  };

  const onDownloadButtonClick = () => {
    setIsDownloadTimesheetsModalOpen(true);
  };

  return (
    <Timesheets
      getTimeCards={getTimeCards}
      onCreateTimeCardButtonClick={onCreateTimeCardButtonClick}
      onDownloadButtonClick={onDownloadButtonClick}
    />
  );
};

const TimesheetsContainerMemo = memo(TimesheetsContainer, areEqual);

export { TimesheetsContainerMemo as TimesheetsContainer };
