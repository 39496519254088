import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { OptionToolBar } from 'Containers/OptionToolBar';

import classes from './classOfLossEdit.module.css';

interface Props {
  categories: any[];
  selectedLossClass?: any;
  setSelectedLossClass: (e: any) => void;
}

function ClassOfLossEdit({ categories, selectedLossClass, setSelectedLossClass }: Props) {
  return (
    <div className={classes.container} role="toolbar" aria-label="Toolbar with button groups">
      <OptionToolBar
        idForLabel="class-of-loss-options"
        label="Class Of Loss"
        optionNames={categories}
        classificationId={selectedLossClass}
        setClassificationId={setSelectedLossClass}
      />
    </div>
  );
}

ClassOfLossEdit.defaultProps = {
  selectedLossClass: undefined,
};

const ClassOfLossEditMemo = memo(ClassOfLossEdit, areEqual);

export { ClassOfLossEditMemo as ClassOfLossEdit };
