import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Modal } from 'Components/Modal';
import { Label } from 'Components/Label';
import { PurpleButton } from 'Components/Button';

import { ValidateBackGround } from 'Components/Validation';
import { TextBox } from 'Components/TextBox';
import DatePicker from 'react-datepicker';

import formClasses from 'Themes/form/form.module.css';
import classes from './editTimeCardModal.module.css';

import 'react-datepicker/dist/react-datepicker-min.module.css';

interface Props {
  // modal variables
  isOpen: boolean;
  canEdit: boolean;
  formErrors: any;
  // timecard
  name: string;
  address: string;
  // date variables
  startDate: any;
  endDate: any;
  // modal functions
  modalCloseClick: (e: any) => void;
  onEditButtonClick: (e: any) => void;
  // date functions
  onChangeStartDate: (e: any) => void;
  onChangeEndDate: (e: any) => void;
}

const EditTimeCardModal = ({
  isOpen,
  canEdit,
  name,
  address,
  formErrors,
  startDate,
  endDate,
  modalCloseClick,
  onEditButtonClick,
  onChangeStartDate,
  onChangeEndDate,
}: Props) => (
  <Modal
    id="add-timecard-modal"
    classes={classes}
    title="Add Timecard"
    isOpen={isOpen}
    modalHeader
    dataBsBackdrop="static"
    dataBsKeyboard="false"
    modalCloseClick={modalCloseClick}
  >
    <form className={`requires-validation g-3 ${formClasses.formBase}`} noValidate action="#">
      <Label ariaLabel="Employee" className={formClasses.label} htmlFor="employee">
        Employee
      </Label>
      <TextBox
        className={formClasses.validateField}
        type="text"
        name="employee"
        ariaLabel="Employee"
        value={name}
        disabled
      />
      <br />
      <Label ariaLabel="Project" className={formClasses.label} htmlFor="project">
        Project
      </Label>
      <TextBox
        className={formClasses.validateField}
        type="text"
        name="project"
        ariaLabel="Project"
        value={address}
        disabled
      />
      <br />
      <ValidateBackGround isValid={!formErrors?.time_in?.length}>
        <Label ariaLabel="Clock In Time" className={formClasses.label} htmlFor="clockOutTime">
          Clock In Time
        </Label>
        <DatePicker
          todayButton="Today"
          selected={startDate}
          onChange={onChangeStartDate}
          isClearable
          maxDate={new Date()}
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy h:mm aa"
          showTimeInput
          className={`form-control ${formClasses.validateField} ${formClasses.validField} ${classes.dateEntry}`}
        />
        <div
          className={`${formClasses.invalidFieldFeedback} invalid-feedback ${
            formErrors?.time_in?.length ? 'd-block' : ''
          }`}
        >
          {formErrors?.time_in?.[0]}
        </div>
      </ValidateBackGround>
      <br />
      <ValidateBackGround isValid={!formErrors?.time_out?.length}>
        <Label ariaLabel="Clock Out Time" className={formClasses.label} htmlFor="clockOutTime">
          Clock Out Time
        </Label>
        <DatePicker
          todayButton="Today"
          selected={endDate}
          onChange={onChangeEndDate}
          isClearable
          maxDate={new Date()}
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy h:mm aa"
          showTimeInput
          className={`form-control ${formClasses.validateField} ${formClasses.validField} ${classes.dateEntry}`}
        />
        <div
          className={`${formClasses.invalidFieldFeedback} invalid-feedback ${
            formErrors?.time_out?.length ? 'd-block' : ''
          }`}
        >
          {formErrors?.time_out?.[0]}
        </div>
      </ValidateBackGround>
      <br />
      <div className={`modal-footer ${classes.modalFooter}`}>
        <PurpleButton onClick={modalCloseClick} className={classes.cancelButton}>
          Cancel
        </PurpleButton>
        <PurpleButton onClick={onEditButtonClick} disabled={!canEdit} className={classes.editButton}>
          Save
        </PurpleButton>
      </div>
    </form>
  </Modal>
);

const EditTimeCardModalMemo = memo(EditTimeCardModal, areEqual);

export { EditTimeCardModalMemo as EditTimeCardModal };
