import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import classes from './claimsDataView.module.css';

interface Props {
  policyHolder: string;
  representative: string;
  ownershipStatus: string;
  policyHolderPhone: string;
  policyHolderEmail: string;
  provider: string;
  insuranceDeductible: string;
  policyNumber: string;
  claimNumber: string;
  adjuster: string;
  adjusterPhone: string;
  adjusterEmail: string;
}

const ClaimsDataView = ({ policyHolder, representative, ownershipStatus,
      policyHolderPhone, policyHolderEmail, provider, insuranceDeductible,
      policyNumber, claimNumber, adjuster, adjusterPhone, adjusterEmail}: Props) => (
  <div className="d-flex flex-column w-100">
    <div className={classes.description}>
      Policy Holder:
      {policyHolder ? (
        <span className={classes.details}>{policyHolder}</span>
      ) : (
        <span className={classes.noDetails}>No Policy Holder</span>
      )}
    </div>
    <div className={classes.description}>
      Representative:
      {representative ? (
        <span className={classes.details}>{representative}</span>
      ) : (
        <span className={classes.noDetails}>No Representative</span>
      )}
    </div>
    <div className={classes.description}>
      Ownership Status:
      {ownershipStatus ? (
        <span className={classes.details}>{ownershipStatus}</span>
      ) : (
        <span className={classes.noDetails}>Unknown</span>
      )}
    </div>
    <div className={classes.description}>
      Policy Holder Phone:
      {policyHolderPhone ? (
        <span className={classes.details}>{policyHolderPhone}</span>
      ) : (
        <span className={classes.noDetails}>No Policy Holder Phone</span>
      )}
    </div>
    <div className={classes.description}>
      Policy Holder Email:
      {policyHolderEmail ? (
        <span className={classes.details}>{policyHolderEmail}</span>
      ) : (
        <span className={classes.noDetails}>No Policy Holder Email</span>
      )}
    </div>
    <div className={classes.description}>
      Provider:
      {provider ? (
        <span className={classes.details}>{provider}</span>
      ) : (
        <span className={classes.noDetails}>No Provider</span>
      )}
    </div>
    <div className={classes.description}>
      Insurance Deductible:
      {insuranceDeductible ? (
        <span className={classes.details}>{insuranceDeductible}</span>
      ) : (
        <span className={classes.noDetails}>No Deductible</span>
      )}
    </div>
    <div className={classes.description}>
      Policy number:
      {policyNumber ? (
        <span className={classes.details}>{policyNumber}</span>
      ) : (
        <span className={classes.noDetails}>No Policy Number</span>
      )}
    </div>
    <div className={classes.description}>
      Claim Number:
      {claimNumber ? (
        <span className={classes.details}>{claimNumber}</span>
      ) : (
        <span className={classes.noDetails}>No Claim Number</span>
      )}
    </div>
    <div className={classes.description}>
      Adjuster:
      {adjuster ? (
        <span className={classes.details}>{adjuster}</span>
      ) : (
        <span className={classes.noDetails}>No Adjuster</span>
      )}
    </div>
    <div className={classes.description}>
      Adjuster Phone Number:
      {adjusterPhone ? (
        <span className={classes.details}>{adjusterPhone}</span>
      ) : (
        <span className={classes.noDetails}>No Adjuster Phone Number</span>
      )}
    </div>
    <div className={classes.description}>
      Adjuster Email:
      {adjusterEmail ? (
        <span className={classes.details}>{adjusterEmail}</span>
      ) : (
        <span className={classes.noDetails}>No Adjuster Email</span>
      )}
    </div>
  </div>
);

const ClaimsDataViewMemo = memo(ClaimsDataView, areEqual);

export { ClaimsDataViewMemo as ClaimsDataView };
