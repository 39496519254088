export const allowedTimeZones = [
  {
    id: 1,
    name: 'Pacific Time',
    default: 'America/Los_Angeles',
  },
  {
    id: 2,
    name: 'Mountain Time',
    default: 'America/Denver',
  },
  {
    id: 3,
    name: 'Central Time',
    default: 'America/Chicago',
  },
  {
    id: 4,
    name: 'Eastern Time',
    default: 'America/New_York',
  },
  {
    id: 5,
    name: 'Alaska Time',
    default: 'America/Anchorage',
  },
  {
    id: 6,
    name: 'Hawaii-Aleutian Time',
    default: 'Pacific/Honolulu',
  },
  {
    id: 7,
    name: 'Australian Eastern Standard Time',
    default: 'Australia/Brisbane',
  },
  {
    id: 8,
    name: 'Australian Central Standard Time',
    default: 'Australia/Adelaide',
  },
  {
    id: 9,
    name: 'Australian Western Standard Time',
    default: 'Australia/Perth',
  },
  {
    id: 10,
    name: 'New Zealand Standard Time',
    default: 'Pacific/Auckland',
  },
  {
    id: 11,
    name: 'Greenwich Mean Time',
    default: 'Europe/London',
  },
  {
    id: 12,
    name: 'Gulf Time',
    default: 'Asia/Dubai',
  },
];
