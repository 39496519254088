import React, { memo, useCallback } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useDispatch, useSelector } from 'react-redux';

import { DeleteEquipmentModal } from 'Components/EquipmentInventory';
import { deleteEquipmentInInventory } from '../actions';
import { selectedEquipmentForDeleteSelector } from '../selectors';

interface Props {
  isOpen: boolean;
  onCloseClick: (e: any) => void;
}

function DeleteEquipmentModalContainer({ isOpen, onCloseClick }: Props) {
  const dispatch = useDispatch();

  const selectedEquipment = useSelector(selectedEquipmentForDeleteSelector, areEqual);

  const onDeleteButtonClick = useCallback(() => {
    if (selectedEquipment?.id) {
      dispatch(deleteEquipmentInInventory(selectedEquipment.id));
    }
  }, [selectedEquipment]);

  return (
    <DeleteEquipmentModal
      isOpen={isOpen}
      equipmentName={selectedEquipment?.name}
      modalCloseClick={onCloseClick}
      onDeleteButtonClick={onDeleteButtonClick}
    />
  );
}

const DeleteEquipmentModalContainerMemo = memo(DeleteEquipmentModalContainer, areEqual);

export { DeleteEquipmentModalContainerMemo as DeleteEquipmentModal };
