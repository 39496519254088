import React, { memo } from 'react';
import { RoomButton as SketchButton } from 'Containers/RocketScan';
import { areEqual } from 'Utils/equalityChecks';
import classes from './sketchButton.module.css';

interface Props {
  locationId: number;
  iconType: string;
  selectedId: number;
  onClick?: (e: any) => void;
}

function SketchButtonContainer({ locationId, iconType, onClick, selectedId }: Props) {
  return (
    <div className={classes.sketchWrapper}>
      <SketchButton
        id="0"
        locationId={locationId}
        iconType={iconType}
        isActive={Number(selectedId) === Number(0)}
        onClick={onClick}
      >
        Sketch
      </SketchButton>
    </div>
  );
}

SketchButtonContainer.defaultProps = {
  onClick: undefined,
};

const SketchButtonContainerMemo = memo(SketchButtonContainer, areEqual);
export { SketchButtonContainerMemo as SketchButtonContainer };
