import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { DateTimeEdit } from 'Components/ProjectData';

import { useLossDataFunctions } from 'Context/LossData';

function ArrivedOnSiteEditContainer() {
  const { selectedArrivedOnSite, setSelectedArrivedOnSite }: any = useLossDataFunctions();

  return (
    <DateTimeEdit
      dateTime={selectedArrivedOnSite}
      onDateTimeChange={setSelectedArrivedOnSite}
      labelText="Arrived On Site (optional)"
    />
  );
}

const ArrivedOnSiteEditContainerMemo = memo(ArrivedOnSiteEditContainer, areEqual);

export { ArrivedOnSiteEditContainerMemo as ArrivedOnSiteEdit };
