import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { RocketPlanHeader } from 'Components/RocketPlanHeader';
import { ProgressBar } from 'Components/ProgressBar';
import { CompanyInviteBanner } from 'Components/SignIn';
import classes from './signIn.wrapper.module.css';

interface Props {
  title: string;
  progress?: number;
  companyName?: any;
  companyLogo?: any;
  children: any;
}

function SignInWrapper({ title, progress, companyName, companyLogo, children }: Props) {
  return (
    <>
      {progress && <ProgressBar max={progress} />}

      {companyName && (
        <div className="row">
          <div className="col">
            <CompanyInviteBanner companyName={companyName} companyLogo={companyLogo} />
          </div>
        </div>
      )}

      <div className={`container-fluid ${companyName ? classes.signInWrapperBaseShort : classes.signInWrapperBase}`}>
        <div className="row">
          <div className="col">
            <div className={classes.svgRocketContainer}>
              <RocketPlanHeader iconClassName={classes.svgRocket} iconType="logoMobile" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="w-100 text-center d-block">
              <h2 className={classes.title}>{title}</h2>
            </div>
          </div>
        </div>
        {children}
      </div>
    </>
  );
}

SignInWrapper.defaultProps = {
  progress: undefined,
  companyName: undefined,
  companyLogo: undefined,
};

/*
  The error message will come in.  Need to handle empty password and incorrect credentials
*/
// This allows for default props if they exist
const SignInWrapperMemo = memo(SignInWrapper, areEqual);

export { SignInWrapperMemo as SignInWrapper };
