export const activeProjectSelector = ({ dashboard: { activeProject: value = false } }: any) => value;

export const dashboardProjectListSelector = ({ projects: { dashboardProjectList: value = {} } }: any) => value;

export const fetchingDashboardProjectListSelector = ({
  projects: { fetchingDashboardProjectList: value = false },
}: any) => value;

export const mobileWarningModalShownSelector = ({ dashboard: { mobileWarningModalShown: value = false } }: any) =>
  value;
