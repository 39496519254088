import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { RocketCallTabs } from 'Components/RocketCall';
import { RocketCallRecordingsTabContainer, RocketCallSetupTabContainer } from 'Containers/RocketCall';
import { RocketCallModel } from 'Containers/User/Models/RocketCallModel';

interface Props {
    rocketCall: RocketCallModel;
}
function RocketCallTabsContainer({rocketCall}: Props) {
  return (
    <RocketCallTabs id="rocketcall-tabs">
      <RocketCallSetupTabContainer rocketCall={rocketCall} />
      <RocketCallRecordingsTabContainer />
    </RocketCallTabs>
  );
}

const RocketCallTabsContainerMemo = memo(RocketCallTabsContainer, areEqual);

export { RocketCallTabsContainerMemo as RocketCallTabsContainer };
