import React, { memo, useCallback, useEffect, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import classes from './CustomReportEditor.module.css';
import { PhotoCarouselModal } from './PhotoCarouselModal';

interface Props {
  album: any;
  onPhotoClick: (e: any) => void;
}

const Albums = ({ album, onPhotoClick }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentPhotos, setCurrentPhotos] = useState([]);
  const [currentAlbumName, setCurrentAlbumName] = useState('');

  const onOpenCarousel = useCallback((index) => {
    setActiveIndex(index);
    setIsModalOpen(true);
  }, []);

  const onNextPhoto = useCallback(() => {
    if (activeIndex === currentPhotos.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex((prev) => prev + 1);
    }
  }, [activeIndex, currentPhotos]);

  const onPrevPhoto = useCallback(() => {
    if (activeIndex === 0) {
      setActiveIndex(() => currentPhotos.length - 1);
    } else {
      setActiveIndex((prev) => prev - 1);
    }
  }, [activeIndex, currentPhotos]);

  const onChangePhoto = useCallback(
    (index) => {
      setActiveIndex(() => index);
    },
    [activeIndex]
  );

  const onModalCloseClick = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  useEffect(() => {
    setCurrentPhotos(() => [...album.photos]);
    setCurrentAlbumName(album.name);
  }, []);

  useEffect(() => {}, [currentPhotos, currentAlbumName, activeIndex]);

  return (
    <>
      <div className="">
        <ul className={classes.marginAuto}>
          <li className={`${classes.albumHead}`}>{album.name}</li>
        </ul>
        <ul className={`${classes.marginAuto}`}>
          {album?.photos?.length
            ? album?.photos?.map((_photo: any, index: number) => (
                <li key={`${index + 1}`} className={`${classes.albumPhotos}`}>
                  <button
                    className={classes.projectInfoListPhotoButton}
                    onClick={() => onOpenCarousel(index)}
                    onKeyDown={() => onOpenCarousel(index)}
                  >
                    Photo {index + 1}
                  </button>
                </li>
              ))
            : null}
        </ul>
      </div>
      <PhotoCarouselModal
        isOpen={isModalOpen}
        activeIndex={activeIndex}
        currentAlbumName={currentAlbumName}
        photos={currentPhotos}
        modalCloseClick={onModalCloseClick}
        onPhotoClick={onPhotoClick}
        onNextPhoto={onNextPhoto}
        onPrevPhoto={onPrevPhoto}
        onChangePhoto={onChangePhoto}
      />
    </>
  );
};

const AlbumsMemo = memo(Albums, areEqual);

export { AlbumsMemo as Albums };
