import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { ContractFormTabs } from 'Components/Tabs';
import { ContractTab } from './ContractTab';

const ContractFormTabsContainer = () => (
  <ContractFormTabs id="contract-tabs">
    <ContractTab />
  </ContractFormTabs>
);

ContractFormTabsContainer.defaultProps = {};

const ContractFormTabsContainerMemo = memo(ContractFormTabsContainer, areEqual);

export { ContractFormTabsContainerMemo as ContractFormTabs };
