import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import {
  CategoryOfDamageEdit,
  ClassOfLossEdit,
  DateOfLossEdit,
  CauseOfDamageEdit,
  CallReceivedEdit,
  CrewDispatchedEdit,
  ArrivedOnSiteEdit,
} from 'Containers/ProjectData';
import { CancelButton, PurpleButton } from 'Components/Button';

import classes from './damageInfoEdit.module.css';

interface Props {
  showCategorySection: boolean;
  onEditButtonClick: (e: any) => void;
  onSaveButtonClick: (e: any) => void;
}

function DamageInfoEdit({ showCategorySection, onEditButtonClick, onSaveButtonClick }: Props) {
  return (
    <div className={`damage-info-edit-container ${classes.container}`}>
      <CauseOfDamageEdit />
      {showCategorySection && <CategoryOfDamageEdit />}
      {showCategorySection && <ClassOfLossEdit />}
      <DateOfLossEdit />
      <CallReceivedEdit />
      <CrewDispatchedEdit />
      <ArrivedOnSiteEdit />
      <div className={`d-flex justify-content-end align-items-center ${classes.formButtons}`}>
        <CancelButton className={classes.formFooterButton} onClick={onEditButtonClick}>
          Cancel
        </CancelButton>
        <PurpleButton type="submit" className={`${classes.formFooterButton}`} onClick={onSaveButtonClick}>
          Save
        </PurpleButton>
      </div>
    </div>
  );
}

const DamageInfoEditMemo = memo(DamageInfoEdit, areEqual);

export { DamageInfoEditMemo as DamageInfoEdit };
