import React, { memo } from 'react';

import { areEqualShallow } from 'Utils/equalityChecks';

import RocketPay from '../../../../Assets/NavItems/rocketpay.svg';

// Custom css
import classes from './rocketPay.module.css';

interface Props {
  className?: string;
  id?: string;
  onClick?: (e: any) => void;
  onKeyUp?: (e: any) => void;
}
function RocketPaySvg({ className = '', id, onClick, onKeyUp }: Props) {
  return <RocketPay id={id} className={`${classes.iconBase} ${className || ''}`} onClick={onClick} onKeyUp={onKeyUp} />;
}

RocketPaySvg.defaultProps = {
  className: undefined,
  id: undefined,
  onClick: undefined,
  onKeyUp: undefined,
};

const RocketPaySvgMemo = memo(RocketPaySvg, areEqualShallow);
export { RocketPaySvgMemo as RocketPaySvg };
