/*eslint-disable */
import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';
import classes from './thumbOverlay.module.css';

interface Props {
  text?: string;
  className?: string;
  type?: string;
}

const ThumbOverlay = ({ text, type }: Props) => {
  switch (type) {
    case 'small':
      return (
        <div className={classes.thumbOverlaySmall}>
          <span className={classes.overlayCopySmall}>{text}</span>
        </div>
      );
    default:
      return (
        <div className={classes.thumbOverlay}>
          <span className={classes.overlayCopy}>{text}</span>
        </div>
      );
  }
};
ThumbOverlay.defaultProps = {
  text: undefined,
  className: undefined,
  type: 'large',
};
const ThumbOverlayMemo = memo(ThumbOverlay, areEqual);
export { ThumbOverlayMemo as ThumbOverlay };
