import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { TimeCardTab } from 'Components/TimeTracking';

import { useTimeTrackingFunctions } from 'Context/TimeTracking';

import { projectAddressSelector } from 'Containers/RocketScan/selectors';

import { clockIn, clockOut } from 'Containers/TimeTracking/actions';
import { calculateTotalClockDurationClamped } from 'Containers/TimeTracking/helpers';
import { formatDate, addDurations } from 'Utils/helpers';
import { timeCardTypes } from 'Containers/TimeTracking/timeCardTypes';
import { TimeTrackingCard } from 'Containers/TimeTracking/types';

interface Props {
  projectId: number;
  changeProject: (e: any) => void;
}

const TimeCardTabContainer = ({ projectId, changeProject }: Props) => {
  const dispatch = useDispatch();

  const {
    selectedAddress,
    clockedInProjectId,
    setClockedInProjectId,
    todayAllCards,
    todayTotalTime,
    userClockedInTimeCards,
    clockInSuccess,
    clockOutSuccess,
  }: any = useTimeTrackingFunctions();

  const projectAddress = useSelector(projectAddressSelector, areEqual);

  const [projectClockedIn, setProjectClockedIn] = useState(false);
  const [projectClockedInCard, setProjectClockedInCard] = useState(undefined);
  const [clockButtonDisabled, setClockButtonDisabled] = useState(false);
  const [projectTodayTime, setProjectTodayTime] = useState({});
  const [dayTotalTimeDisplay, setDayTotalTimeDisplay] = useState({});

  // notes states
  const [notesOpen, setNotesOpen] = useState(false);

  const currentTime = formatDate(new Date().toString(), 'p');

  const onClockInButtonClick = useCallback(() => {
    // console.log(userClockedInTimeCards);
    if (userClockedInTimeCards.length > 0) {
      // console.log('clock out first!');
      setClockButtonDisabled(true);

      // clock out
      const clockOutTime = new Date().toISOString();
      userClockedInTimeCards.forEach((timeCard: TimeTrackingCard) => {
        dispatch(clockOut(timeCard, clockOutTime, true));
      });

      // now clock in
      const clockInTime = new Date().toISOString();
      // console.log('clock in time: ', clockInTime);
      dispatch(clockIn(projectId, clockInTime, timeCardTypes.standard, true));

      // used to redirect user to project after closing modal
      setClockedInProjectId(projectId);
    } else {
      // can clock in, disable button first to prevent multi-sending
      setClockButtonDisabled(true);

      // clock in
      const clockInTime = new Date().toISOString();
      // console.log('clock in time: ', clockInTime);
      dispatch(clockIn(projectId, clockInTime, timeCardTypes.standard));

      // used to redirect user to project after closing modal
      setClockedInProjectId(projectId);
    }
  }, [projectId, clockedInProjectId, userClockedInTimeCards]);

  const onClockOutButtonClick = useCallback(() => {
    if (projectClockedIn && projectClockedInCard) {
      // disable button first to prevent multi-sending
      setClockButtonDisabled(true);

      // clock out
      const clockOutTime = new Date().toISOString();
      // console.log('clock out time: ', clockOutTime);
      dispatch(clockOut(projectClockedInCard, clockOutTime));

      // stop redirecting user after closing modal
      setClockedInProjectId(undefined);
    }
  }, [projectId, projectClockedIn, projectClockedInCard]);

  // useEffects

  // update project clock status after fetching user's clocked in time cards
  useEffect(() => {
    if (projectId && userClockedInTimeCards) {
      const projectTimeCard = userClockedInTimeCards.find(
        (timeCard: TimeTrackingCard) => timeCard.projectId.toString() === projectId.toString()
      );
      if (projectTimeCard) {
        setProjectClockedIn(true);
        setProjectClockedInCard(projectTimeCard);
      } else {
        setProjectClockedIn(false);
        setProjectClockedInCard(undefined);
      }
    } else {
      // console.log('project is not clocked in');
    }
  }, [projectId, userClockedInTimeCards]);

  useEffect(() => {
    if (projectId && todayAllCards) {
      // console.log(userTimeCards);
      const userProjectTimeCards: TimeTrackingCard[] = todayAllCards.filter(
        (timeCard) => timeCard.projectId === projectId
      );
      setProjectTodayTime(calculateTotalClockDurationClamped(userProjectTimeCards, new Date(), new Date()));
    }
  }, [projectId, todayAllCards]);

  // re-enable clock in/out button after success
  useEffect(() => {
    if (clockInSuccess) {
      setClockButtonDisabled(false);
    }
  }, [clockInSuccess]);

  useEffect(() => {
    if (clockOutSuccess) {
      setClockButtonDisabled(false);

      // close notes section
      setNotesOpen(false);
    }
  }, [clockOutSuccess]);

  // update clock times while clocked in to project
  const ongoingProjectClockUpdate = useCallback(() => {
    setProjectTodayTime((prevDuration) => addDurations(prevDuration, { minutes: 1 }));
  }, []);

  useEffect(() => {
    if (projectClockedIn) {
      const timerId = setInterval(ongoingProjectClockUpdate, 1000 * 60);
      return () => clearInterval(timerId);
    }
    return () => {};
  }, [projectClockedIn]);

  const ongoingDayTotalClockUpdate = useCallback(() => {
    setDayTotalTimeDisplay((prevDuration) => addDurations(prevDuration, { minutes: 1 }));
  }, []);

  useEffect(() => {
    if (userClockedInTimeCards?.length > 0) {
      const timerId = setInterval(ongoingDayTotalClockUpdate, 1000 * 60);
      return () => clearInterval(timerId);
    }
    return () => {};
  }, [userClockedInTimeCards]);

  useEffect(() => {
    setDayTotalTimeDisplay(todayTotalTime);
  }, [todayTotalTime]);

  return (
    <>
      <TimeCardTab
        address={projectAddress || selectedAddress}
        currentTime={currentTime}
        onProjectSectionClick={changeProject}
        onClockInButtonClick={onClockInButtonClick}
        onClockOutButtonClick={onClockOutButtonClick}
        clockButtonDisabled={clockButtonDisabled}
        projectClockedIn={projectClockedIn}
        dayTotalTime={dayTotalTimeDisplay}
        dayProjectTime={projectTodayTime}
        // notes variables
        notesOpen={notesOpen}
        projectClockedInCardId={projectClockedInCard?.id}
        // notes functions
        setNotesOpen={setNotesOpen}
      />
      {/* <div>project id: {projectId}</div>
      <div>clocked in project id: {clockedInProjectId}</div> */}
    </>
  );
};

const TimeCardTabContainerMemo = memo(TimeCardTabContainer, areEqual);

export { TimeCardTabContainerMemo as TimeCardTab };
