import {
  setCustomReportActionTypes,
  SET_CUSTOMS_REPORT_CLAIMS_DATA,
  SET_CUSTOMS_REPORT_PROJECT_DATA,
  SET_UNITS_PHOTOS_DATA,
  RESET_CLAIMS,
} from './actions';

const initialState = {
  unitsRoomsAlbumsWithPhotos: [],
  claims: [],
  project: {},
};

export const customReportReducer = (state = initialState, action: setCustomReportActionTypes) => {
  switch (action.type) {
    case SET_CUSTOMS_REPORT_PROJECT_DATA:
      return {
        ...state,
        project: action.payload,
      };
    case SET_CUSTOMS_REPORT_CLAIMS_DATA:
      return {
        ...state,
        claims: state.claims.concat(action.payload),
      };
    case RESET_CLAIMS:
      return {
        ...state,
        claims: [],
      };
    case SET_UNITS_PHOTOS_DATA:
      return {
        ...state,
        unitsRoomsAlbumsWithPhotos: action.payload,
      };
    default:
      return state;
  }
};
