import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { ScopeOfWorkAddModal } from 'Components/RocketScan';
import { useDamagedMaterialFunctions } from 'Context/RocketScan';

const ScopeOfWorkAddModalContainer = () => {
  const { isScopeAddModalOpen, onClickAddScopeSaveButton, onCLickAddScopeCancelButton }: any =
    useDamagedMaterialFunctions();

  return (
    <ScopeOfWorkAddModal
      isOpen={isScopeAddModalOpen}
      onSaveClick={onClickAddScopeSaveButton}
      onCancelClick={onCLickAddScopeCancelButton}
    />
  );
};

const ScopeOfWorkAddModalContainerMemo = memo(ScopeOfWorkAddModalContainer, areEqual);

export { ScopeOfWorkAddModalContainerMemo as ScopeOfWorkAddModal };
