import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { DeleteTimeCardModal } from 'Components/TimeTracking';

import { deleteTimeCard } from 'Containers/TimeTracking/actions';

import { useTimeTrackingFunctions } from 'Context/TimeTracking';

const DeleteTimeCardModalContainer = () => {
  const dispatch = useDispatch();

  const {
    selectedTimeCardId,
    setSelectedTimeCardId,
    isDeleteTimeCardModalOpen,
    setIsDeleteTimeCardModalOpen,
    selectedTimeCard,
    setSelectedTimeCard,
  }: any = useTimeTrackingFunctions();

  // console.log(errors);

  const closeModal = () => {
    setIsDeleteTimeCardModalOpen(false);
    setSelectedTimeCardId(undefined);
    setSelectedTimeCard(undefined);
  };

  const onDeleteButtonClick = useCallback(() => {
    if (selectedTimeCardId) {
      dispatch(deleteTimeCard(selectedTimeCardId, closeModal));
    }
  }, [selectedTimeCardId]);

  return (
    <DeleteTimeCardModal
      id={selectedTimeCardId}
      card={selectedTimeCard}
      isOpen={isDeleteTimeCardModalOpen}
      onDeleteButtonClick={onDeleteButtonClick}
      modalCloseClick={closeModal}
    />
  );
};

const DeleteTimeCardModalContainerMemo = memo(DeleteTimeCardModalContainer, areEqual);

export { DeleteTimeCardModalContainerMemo as DeleteTimeCardModal };
