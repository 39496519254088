import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { Label } from 'Components/Label';
import { TextBox } from 'Components/TextBox';
import { CancelButton, PurpleButton } from 'Components/Button';

import formClasses from 'Themes/form/form.module.css';
import classes from './claimsDataEdit.module.css';
import { DropDown } from 'Components/DropDown';
import { Icon } from 'Components/Icons';

interface Props {
  policyHolder: string;
  ownershipStatus: string;
  ownershipStatusId: number;
  ownershipTypes: {id:number, name:string}[];
  showOwnershipStatusDropDown: boolean;
  policyHolderPhone: string;
  policyHolderEmail: string;
  representative: string;
  provider: string;
  insuranceDeductible: string;
  policyNumber: string;
  claimNumber: string;
  adjuster: string;
  adjusterPhone: string;
  adjusterEmail: string;
  onChangePolicyHolder: (e: any) => void;
  onChangeOwnershipStatus: (e: any) => void;
  toggleOwnershipStatusDropdown: (e: any) => void;
  onChangePolicyHolderPhone: (e: any) => void;
  onChangePolicyHolderEmail: (e: any) => void;
  onChangeRepresentative: (e: any) => void;
  onChangeProvider: (e: any) => void;
  onChangeInsuranceDeductible: (e: any) => void;
  onChangePolicyNumber: (e: any) => void;
  onChangeClaimNumber: (e: any) => void;
  onChangeAdjuster: (e: any) => void;
  onChangeAdjusterPhone: (e: any) => void;
  onChangeAdjusterEmail: (e: any) => void;
  onCancelButtonClick: (e: any) => void;
  onSaveButtonClick: (e: any) => void;
}

const ClaimsDataEdit = ({
  policyHolder,
  ownershipStatusId,
  ownershipTypes,
  ownershipStatus,
  showOwnershipStatusDropDown,
  toggleOwnershipStatusDropdown,
  policyHolderPhone,
  policyHolderEmail,
  representative,
  provider,
  insuranceDeductible,
  policyNumber,
  claimNumber,
  adjuster,
  adjusterEmail,
  adjusterPhone,
  onChangePolicyHolder,
  onChangeOwnershipStatus,
  onChangePolicyHolderPhone,
  onChangePolicyHolderEmail,
  onChangeRepresentative,
  onChangeProvider,
  onChangeInsuranceDeductible,
  onChangePolicyNumber,
  onChangeClaimNumber,
  onChangeAdjuster,
  onChangeAdjusterPhone,
  onChangeAdjusterEmail,
  onCancelButtonClick,
  onSaveButtonClick,
}: Props) => (
  <form className={`d-flex flex-column w-100 ${classes.editBase}`}>
    <div className={classes.inputFields}>
      <div className={classes.claimsDataInputWrapper}>
        <Label ariaLabel="Policy Holder" className={formClasses.label}>
          Policy Holder
        </Label>
        <TextBox
          value={policyHolder}
          type="text"
          name="policy holder"
          className={`${formClasses.validateField} ${formClasses.validField}`}
          placeholder="Type Policy Holder Name"
          ariaLabel="Type Policy Holder Name"
          onChange={onChangePolicyHolder}
          autoComplete="off"
        />
      </div>
      <div className={classes.claimsDataInputWrapper}>
        <Label ariaLabel="Ownership Status" className={formClasses.label}>
          Ownership Status
        </Label>
        <div className={`dropdown ${classes.dropdownSelectBase}`}>
          <TextBox
            value={ownershipStatus}
            name="ownershipStatus"
            type="text"
            className={`mb-0 pb-0 ${classes.textBox}`}
            onChange={() => {}}
            onClick={toggleOwnershipStatusDropdown}
            required
            placeholder=""
            ariaLabel="Please enter the ownership status"
            autoComplete="off"
          />
          <Icon
            className={classes.icon}
            type={showOwnershipStatusDropDown ? 'caretup' : 'caretdown'}
            onClick={toggleOwnershipStatusDropdown}
          />
          <DropDown
            className={classes.dropDown}
            id="ownershipStatus"
            items={ownershipTypes}
            selected={ownershipStatusId}
            onSelectItem={onChangeOwnershipStatus}
            showDropDown={showOwnershipStatusDropDown}
          />
        </div>
      </div>
      <div className={classes.claimsDataInputWrapper}>
        <Label ariaLabel="Policy Holder Phone" className={formClasses.label}>
          Policy Holder Phone
        </Label>
        <TextBox
          value={policyHolderPhone}
          type="text"
          name="policy holder phone"
          className={`${formClasses.validateField} ${formClasses.validField}`}
          placeholder="Type Policy Holder Phone"
          ariaLabel="Type Policy Holder Phone"
          onChange={onChangePolicyHolderPhone}
          autoComplete="off"
        />
      </div>
      <div className={classes.claimsDataInputWrapper}>
        <Label ariaLabel="Policy Holder Email" className={formClasses.label}>
          Policy Holder Email
        </Label>
        <TextBox
          value={policyHolderEmail}
          type="text"
          name="policy holder email"
          className={`${formClasses.validateField} ${formClasses.validField}`}
          placeholder="Type Policy Holder Email"
          ariaLabel="Type Policy Holder Email"
          onChange={onChangePolicyHolderEmail}
          autoComplete="off"
        />
      </div>
      <div className={classes.claimsDataInputWrapper}>
        <Label ariaLabel="Representative" className={formClasses.label}>
          Representative
        </Label>
        <TextBox
          value={representative}
          type="text"
          name="representative"
          className={`${formClasses.validateField} ${formClasses.validField}`}
          placeholder="Type Representative Name"
          ariaLabel="Type Representative Name"
          onChange={onChangeRepresentative}
          autoComplete="off"
        />
      </div>
      <div className={classes.claimsDataInputWrapper}>
        <Label ariaLabel="Provider" className={formClasses.label}>
          Provider
        </Label>
        <TextBox
          value={provider}
          type="text"
          name="provider"
          className={`${formClasses.validateField} ${formClasses.validField}`}
          placeholder="Type Provider Name"
          ariaLabel="Type Provider Name"
          onChange={onChangeProvider}
          autoComplete="off"
        />
      </div>
      <div className={classes.claimsDataInputWrapper}>
        <Label ariaLabel="Insurance Deductible" className={formClasses.label}>
          Insurance Deductible
        </Label>
        <TextBox
          value={insuranceDeductible}
          type="text"
          name="insurance deductible"
          className={`${formClasses.validateField} ${formClasses.validField}`}
          placeholder="Type Insurance Deductible"
          ariaLabel="Type Insurance Deductible"
          onChange={onChangeInsuranceDeductible}
          autoComplete="off"
        />
      </div>
      <div className={classes.claimsDataInputWrapper}>
        <Label ariaLabel="Policy Number" className={formClasses.label}>
          Policy Number
        </Label>
        <TextBox
          value={policyNumber}
          type="text"
          name="policy number"
          className={`${formClasses.validateField} ${formClasses.validField}`}
          placeholder="Type Policy Number"
          ariaLabel="Type Policy Number"
          onChange={onChangePolicyNumber}
          autoComplete="off"
        />
      </div>
      <div className={classes.claimsDataInputWrapper}>
        <Label ariaLabel="Claim Number" className={formClasses.label}>
          Claim Number
        </Label>
        <TextBox
          value={claimNumber}
          type="text"
          name="claim number"
          className={`${formClasses.validateField} ${formClasses.validField}`}
          placeholder="Type Claim Number"
          ariaLabel="Type Claim Number"
          onChange={onChangeClaimNumber}
          autoComplete="off"
        />
      </div>
      <div className={classes.claimsDataInputWrapper}>
        <Label ariaLabel="Adjuster" className={formClasses.label}>
          Adjuster
        </Label>
        <TextBox
          value={adjuster}
          type="text"
          name="adjuster"
          className={`${formClasses.validateField} ${formClasses.validField}`}
          placeholder="Type Adjuster Name"
          ariaLabel="Type Adjuster Name"
          onChange={onChangeAdjuster}
          autoComplete="off"
        />
      </div>
      <div className={classes.claimsDataInputWrapper}>
        <Label ariaLabel="Adjuster Phone" className={formClasses.label}>
          Adjuster Phone
        </Label>
        <TextBox
          value={adjusterPhone}
          type="text"
          name="adjuster phone"
          className={`${formClasses.validateField} ${formClasses.validField}`}
          placeholder="Type Adjuster Phone"
          ariaLabel="Type Adjuster Phone"
          onChange={onChangeAdjusterPhone}
          autoComplete="off"
        />
      </div>
      <div className={classes.claimsDataInputWrapper}>
        <Label ariaLabel="Adjuster Email" className={formClasses.label}>
          Adjuster Email
        </Label>
        <TextBox
          value={adjusterEmail}
          type="text"
          name="adjuster email"
          className={`${formClasses.validateField} ${formClasses.validField}`}
          placeholder="Type Adjuster Email"
          ariaLabel="Type Adjuster Email"
          onChange={onChangeAdjusterEmail}
          autoComplete="off"
        />
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <CancelButton className={classes.formFooterButton} onClick={onCancelButtonClick}>
          Cancel
        </CancelButton>
        <PurpleButton type="submit" className={`${classes.formFooterButton}`} onClick={onSaveButtonClick}>
          Save
        </PurpleButton>
      </div>
    </div>
  </form>
);

const ClaimsDataEditMemo = memo(ClaimsDataEdit, areEqual);

export { ClaimsDataEditMemo as ClaimsDataEdit };
