import React, { memo, useEffect, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useDamagedMaterialFunctions } from 'Context/RocketScan';
import { SelectedItem } from 'Containers/RocketScope/types';

import classes from './workScopeView.module.css';

const WorkScopeView = () => {
  const { selectedItems } = useDamagedMaterialFunctions() as { selectedItems: SelectedItem[] };

  const [scopeCategories, setScopeCategories] = useState<{ [category: string]: SelectedItem[] }>({});
  const [scopeTotal, setScopeTotal] = useState(0.0);

  const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  useEffect(() => {
    const newScopeCategories: { [category: string]: SelectedItem[] } = {};
    let total = 0.0;
    selectedItems.forEach((selectedItem) => {
      if (selectedItem.rate) {
        total += selectedItem.quantity * parseFloat(selectedItem.rate);
      }
      if (newScopeCategories[selectedItem.category] === undefined) {
        newScopeCategories[selectedItem.category] = [];
      }
      newScopeCategories[selectedItem.category].push(selectedItem);
    });
    setScopeTotal(total);
    setScopeCategories(newScopeCategories);
  }, [selectedItems]);

  return (
    <div className="w-100 pt-3">
      {Object.keys(scopeCategories).map((category) => (
        <div key={category}>
          <div className={classes.categoryHeader}>{category}</div>
          {scopeCategories[category].map((selectedItem) => (
            <div key={selectedItem.id} className={classes.workScopeRow}>
              <div className={classes.field}>
                {selectedItem.codePart1}
                {selectedItem.codePart2}
              </div>
              <div className={classes.field}>{selectedItem.description}</div>
              <div className={classes.fieldRight}>
                {selectedItem.quantity} x{selectedItem.unit}
                {selectedItem.rate && <span> @{currencyFormat.format(parseFloat(selectedItem.rate))} </span>}
              </div>
              <div className={classes.fieldRight}>
                {selectedItem.rate && (
                  <span>{currencyFormat.format(selectedItem.quantity * parseFloat(selectedItem.rate))}</span>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
      <hr className="w-100" />
      <div className={classes.workScopeRow}>
        <div className={classes.workScopeRow}>Total</div>
        <div className={classes.workScopeRow} />
        <div className={classes.workScopeRow} />
        <div className={classes.fieldRight}>{currencyFormat.format(scopeTotal)}</div>
      </div>
    </div>
  );
};

const WorkScopeViewMemo = memo(WorkScopeView, areEqual);

export { WorkScopeViewMemo as WorkScopeView };
