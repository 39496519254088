import React, { memo, useCallback, useState, useEffect } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setSelectedProjectId } from 'Containers/Projects/actions';

import { TimesheetsList, TimesheetsPagination } from 'Components/TimeTracking';
import { TimeTrackingCardTimesheet } from 'Containers/TimeTracking/types';

import { listTimeCardNotes } from 'Containers/TimeTracking/actions';

import { useTimeTrackingFunctions } from 'Context/TimeTracking';

interface Props {
  getTimeCards: (e: any) => void;
}

const TimesheetsListContainer = ({ getTimeCards }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    companyTimeCards,
    setSelectedTimeCardId,
    setIsEditTimeCardModalOpen,
    setIsDeleteTimeCardModalOpen,
    setIsTimeCardNotesModalOpen,
    setSelectedTimeCard,
  }: any = useTimeTrackingFunctions();

  const [sortBy, setSortBy] = useState('-time_in');
  const [pageCount, setPageCount] = useState(1);
  const [initialPage, setInitialPage] = useState(0);

  // sort by: -field === DESC, field === ASC
  // prefix with '-' for DESC
  const onClickSort = useCallback(
    (sort: string) => {
      if (sortBy.includes('-') && sortBy === sort) {
        setSortBy(sort.replace('-', ''));
      } else {
        setSortBy(sort);
      }
    },
    [sortBy]
  );

  // set meta data
  useEffect(() => {
    if (companyTimeCards?.data?.length > 0) {
      const { meta } = companyTimeCards;
      const { total } = meta;

      setPageCount(total <= 15 ? 1 : Number(total / 15));
    }
  }, [companyTimeCards]);

  const onPageChange = useCallback(({ selected: page }: any) => {
    setInitialPage(page);
    getTimeCards(page);
  }, []);

  const onClickProject = useCallback((e: any) => {
    const {
      dataset: { id },
    } = e.currentTarget;

    dispatch(setSelectedProjectId(id));
    history.push(`/projects/${id}/rocketscan`);
  }, []);

  const onClickEditIcon = useCallback(
    (e: any) => {
      if (companyTimeCards?.data?.length > 0) {
        const {
          dataset: { id },
        } = e.currentTarget;

        setSelectedTimeCardId(id);
        const card = companyTimeCards.data.find((timecard: TimeTrackingCardTimesheet) => timecard.id === id);
        setSelectedTimeCard(card);
        setIsEditTimeCardModalOpen(true);
      }
    },
    [companyTimeCards]
  );

  const onClickNotesIcon = useCallback(
    (e: any) => {
      if (companyTimeCards?.data?.length > 0) {
        const {
          dataset: { id },
        } = e.currentTarget;

        setSelectedTimeCardId(id);
        dispatch(listTimeCardNotes(id));
        setIsTimeCardNotesModalOpen(true);
      }
    },
    [companyTimeCards]
  );

  const onClickDeleteIcon = useCallback(
    (e: any) => {
      if (companyTimeCards?.data?.length > 0) {
        const {
          dataset: { id },
        } = e.currentTarget;

        setSelectedTimeCardId(id);
        const card = companyTimeCards.data.find((timecard: TimeTrackingCardTimesheet) => timecard.id === id);
        setSelectedTimeCard(card);
        setIsDeleteTimeCardModalOpen(true);
      }
    },
    [companyTimeCards]
  );

  return (
    <div>
      <TimesheetsList
        timeCards={companyTimeCards}
        onClickSort={onClickSort}
        onClickProject={onClickProject}
        onClickEdit={onClickEditIcon}
        onClickNotes={onClickNotesIcon}
        onClickDelete={onClickDeleteIcon}
      />
      {companyTimeCards?.meta?.total >= 15 && (
        <TimesheetsPagination initialPage={initialPage} pageCount={pageCount} onPageChange={onPageChange} />
      )}
    </div>
  );
};

const TimesheetsListContainerMemo = memo(TimesheetsListContainer, areEqual);

export { TimesheetsListContainerMemo as TimesheetsList };
