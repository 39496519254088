import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { ValidateBackGround } from 'Components/Validation';
import { TextBox } from 'Components/TextBox';
import { Label } from 'Components/Label';

import { OptionsDropDown } from 'Containers/ProjectData';
import { OptionToolBar } from 'Containers/OptionToolBar';

import formClasses from 'Themes/form/form.module.css';
import { CancelButton, PurpleButton } from 'Components/Button';

import classes from './propertyDataEdit.module.css';

interface Props {
  invalid?: boolean;
  formErrors: any;
  projectTypeOptions: any[];
  selectedProjectTypeName: string;
  classificationOptions: any[];
  classificationId: number;
  asbestosStatusOptions: any[];
  asbestosStatusInput: number;
  buildingNameInput: string;
  yearBuiltInput: string;
  referredByNameInput: string;
  referredByPhoneInput: string;
  isPlatinumInput: boolean;
  setSelectedProjectTypeInput: (e: any) => void;
  setClassificationId: (e: any) => void;
  onChangeBuildingName: (e: any) => void;
  onChangeYearBuilt: (e: any) => void;
  onChangeReferredByName: (e: any) => void;
  onChangeReferredByPhone: (e: any) => void;
  onChangeIsPlatinum: (e: any) => void;
  setAsbestosStatusInput: (e: any) => void;
  onEditButtonClick: (e: any) => void;
  onSaveButtonClick: (e: any) => void;
}

function PropertyDataEdit({
  invalid,
  formErrors,
  projectTypeOptions,
  selectedProjectTypeName,
  classificationId,
  classificationOptions,
  asbestosStatusOptions,
  asbestosStatusInput,
  buildingNameInput,
  yearBuiltInput,
  referredByNameInput,
  referredByPhoneInput,
  isPlatinumInput,
  setClassificationId,
  onChangeBuildingName,
  onChangeYearBuilt,
  onChangeReferredByName,
  onChangeReferredByPhone,
  onChangeIsPlatinum,
  setSelectedProjectTypeInput,
  setAsbestosStatusInput,
  onEditButtonClick,
  onSaveButtonClick,
}: Props) {
  return (
    <form className={classes.propertyEditForm}>
      <OptionsDropDown
        className="project-type-selector"
        label="Project Type"
        placeHolder="a Project Type"
        items={projectTypeOptions}
        optionSelectedName={selectedProjectTypeName}
        setStatusSelected={setSelectedProjectTypeInput}
      />
      <div className={classes.propertyDataInputWrapper}>
        <ValidateBackGround isValid={!formErrors?.referredByName.length} className={formClasses.inputContainer}>
          <Label ariaLabel="Referred By" htmlFor="referred-by-name">
            Referral Name (optional)
          </Label>
          <TextBox
            name="referred-by-name"
            type="text"
            ariaLabel="Referred By"
            className={`${formClasses.validateField} ${invalid ? formClasses.invalidField : formClasses.validField}`}
            defaultValue={referredByNameInput}
            onChange={onChangeReferredByName}
            autoComplete="off"
            placeholder="Type Referral Name"
          />
          <div
            className={`${formClasses.invalidFieldFeedback} invalid-feedback ${
              formErrors?.referredByName.length ? 'd-block' : ''
            }`}
          >
            {formErrors?.referredByName?.[0]}
          </div>
        </ValidateBackGround>
      </div>
      <div className={classes.propertyDataInputWrapper}>
        <ValidateBackGround isValid={!formErrors?.referredByPhone.length} className={formClasses.inputContainer}>
          <Label ariaLabel="Referred Phone" htmlFor="referred-by-phone">
            Phone (optional)
          </Label>
          <TextBox
            name="referred-by-phone"
            type="text"
            ariaLabel="Referred By Phone"
            className={`${formClasses.validateField} ${invalid ? formClasses.invalidField : formClasses.validField}`}
            defaultValue={referredByPhoneInput}
            onChange={onChangeReferredByPhone}
            autoComplete="off"
            placeholder="Type Referral Phone Number"
          />
          <div
            className={`${formClasses.invalidFieldFeedback} invalid-feedback ${
              formErrors?.referredByName.length ? 'd-block' : ''
            }`}
          >
            {formErrors?.referredByName?.[0]}
          </div>
        </ValidateBackGround>
      </div>
      <OptionsDropDown
        className="is-platinum-selector"
        label="Is Platinum"
        placeHolder="Is Platinum"
        items={[
          { name: 'No', id: 1 },
          { name: 'Yes', id: 2 },
        ]}
        optionSelectedId={isPlatinumInput ? 2 : 1}
        setStatusSelected={onChangeIsPlatinum}
      />
      <div className={classes.toolBarWrapper}>
        <OptionToolBar
          idForLabel="classification-options"
          label="Project Classification"
          optionNames={classificationOptions}
          classificationId={classificationId}
          setClassificationId={setClassificationId}
        />
      </div>
      <OptionsDropDown
        className="asbestos-status-selector"
        label="Asbestos Status"
        placeHolder="Asbestos Status"
        items={asbestosStatusOptions}
        optionSelectedId={asbestosStatusInput}
        setStatusSelected={setAsbestosStatusInput}
      />
      <div className={classes.propertyDataInputWrapper}>
        <ValidateBackGround isValid={!formErrors?.yearBuilt.length} className={formClasses.inputContainer}>
          <Label ariaLabel="Select a Year" htmlFor="year-built">
            Year Built (optional)
          </Label>
          <TextBox
            name="year-built"
            type="text"
            ariaLabel="Year Built"
            className={`${formClasses.validateField} ${invalid ? formClasses.invalidField : formClasses.validField}`}
            defaultValue={yearBuiltInput}
            onChange={onChangeYearBuilt}
            autoComplete="off"
            placeholder="Type a Year"
          />
          <div
            className={`${formClasses.invalidFieldFeedback} invalid-feedback ${
              formErrors?.yearBuilt.length ? 'd-block' : ''
            }`}
          >
            {formErrors?.yearBuilt?.[0]}
          </div>
        </ValidateBackGround>
      </div>
      <div className={classes.propertyDataInputWrapper}>
        <ValidateBackGround isValid={!formErrors?.buildingName.length} className={formClasses.inputContainer}>
          <Label ariaLabel="Building name" htmlFor="building-name">
            Building Name (optional)
          </Label>
          <TextBox
            name="building-name"
            type="text"
            ariaLabel="Name of Building"
            className={`${formClasses.validateField} ${invalid ? formClasses.invalidField : formClasses.validField}`}
            defaultValue={buildingNameInput}
            onChange={onChangeBuildingName}
            autoComplete="off"
            placeholder="Type Building Name"
          />
          <div
            className={`${formClasses.invalidFieldFeedback} invalid-feedback ${
              formErrors?.buildingName.length ? 'd-block' : ''
            }`}
          >
            {formErrors?.buildingName?.[0]}
          </div>
        </ValidateBackGround>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <CancelButton className={classes.formFooterButton} onClick={onEditButtonClick}>
          Cancel
        </CancelButton>
        <PurpleButton type="submit" className={`${classes.formFooterButton}`} onClick={onSaveButtonClick}>
          Save
        </PurpleButton>
      </div>
    </form>
  );
}
PropertyDataEdit.defaultProps = {
  invalid: undefined,
};
const PropertyDataEditMemo = memo(PropertyDataEdit, areEqual);

export { PropertyDataEditMemo as PropertyDataEdit };
