/* eslint-disable */
import React, { memo, useCallback, useEffect } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { PhotoSizeErrorToast } from 'Components/PhotoSizeErrorToast';

export interface Props {
  id?: string;
  className?: string;
  show: boolean;
  timeout: number;
  setShowError: any;
  fileName: string;
}

const PhotoSizeErrorToastContainer = ({ id, className, show, timeout, setShowError, fileName }: Props) => {
  const closeToast = useCallback(() => {
    if (show) {
      setShowError(false);
    }
  }, [show]);

  useEffect(() => {
    if (show)
      //Close it if it's showing.
      setTimeout(() => {
        closeToast();
      }, timeout);
  }, [show]);

  return (
    <PhotoSizeErrorToast id={id} className={className} isDisplayed={show} closeToast={closeToast} fileName={fileName} />
  );
};

const PhotoSizeErrorToastContainerMemo = memo(PhotoSizeErrorToastContainer, areEqual);
export { PhotoSizeErrorToastContainerMemo as PhotoSizeErrorToast };
