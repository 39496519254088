import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import classes from './damageInfoView.module.css';

interface Props {
  damageCauseName: string;
  showCategorySection: boolean;
  selectedCategory: any;
  classOfLoss?: number;
  lossDate: string;
  callReceived: string;
  crewDispatched: string;
  arrivedOnSite: string;
}

function DamageInfoView({
  damageCauseName,
  showCategorySection,
  selectedCategory,
  classOfLoss,
  lossDate,
  callReceived,
  crewDispatched,
  arrivedOnSite,
}: Props) {
  return (
    <ul className="list-group">
      <li className={`list-group-item ${classes.lossDataViewItem}`}>
        Cause of Damage:
        {damageCauseName ? (
          <span className={classes.label}>{damageCauseName}</span>
        ) : (
          <span className={classes.noDataText}>No Selected Cause of Damage</span>
        )}
      </li>
      {showCategorySection && (
        <>
          <li className={`list-group-item ${classes.lossDataViewItem}`}>
            Category of Damage:
            {selectedCategory ? (
              <span className={classes.label}>{selectedCategory}</span>
            ) : (
              <span className={classes.noDataText}>No Selected Category of Damage</span>
            )}
          </li>
          <li className={`list-group-item ${classes.lossDataViewItem}`}>
            Class of Loss:
            {classOfLoss ? (
              <span className={classes.label}>{classOfLoss}</span>
            ) : (
              <span className={classes.noDataText}>No Selected Class Of Loss</span>
            )}
          </li>
        </>
      )}
      <li className={`list-group-item ${classes.lossDataViewItem}`}>
        Date of Loss:
        {lossDate ? (
          <span className={classes.label}>{lossDate}</span>
        ) : (
          <span className={classes.noDataText}>No Selected Date of Loss</span>
        )}
      </li>
      <li className={`list-group-item ${classes.lossDataViewItem}`}>
        Call Received:
        {callReceived ? (
          <span className={classes.label}>{callReceived}</span>
        ) : (
          <span className={classes.noDataText}>No Selected Call Received</span>
        )}
      </li>
      <li className={`list-group-item ${classes.lossDataViewItem}`}>
        Crew Dispatched:
        {crewDispatched ? (
          <span className={classes.label}>{crewDispatched}</span>
        ) : (
          <span className={classes.noDataText}>No Selected Crew Dispatched</span>
        )}
      </li>
      <li className={`list-group-item ${classes.lossDataViewItem}`}>
        Arrived on Site:
        {arrivedOnSite ? (
          <span className={classes.label}>{arrivedOnSite}</span>
        ) : (
          <span className={classes.noDataText}>No Selected Arrived on Site</span>
        )}
      </li>
    </ul>
  );
}

DamageInfoView.defaultProps = {
  classOfLoss: undefined,
};

const DamageInfoViewMemo = memo(DamageInfoView, areEqual);

export { DamageInfoViewMemo as DamageInfoView };
