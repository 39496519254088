import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { RocketPayCompanyModel } from 'Containers/User/Models/RocketPayModel/RocketPayCompanyModel';
import { RocketPayPayments } from 'Components/RocketPay';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';

import { webComponentTokenSelector } from 'Containers/RocketPay/selectors';
import { fetchWebComponentToken } from '../actions';

interface Props {
  companyInfo: RocketPayCompanyModel;
}

function RocketPayPaymentsContainer({ companyInfo }: Props) {
  const dispatch = useDispatch();

  const webToken = useSelector(webComponentTokenSelector, areEqual);

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

  useEffect(() => {
    dispatch(fetchWebComponentToken(firstCompanyId));
  }, [dispatch, firstCompanyId]);

  return <RocketPayPayments companyInfo={companyInfo} authToken={webToken} />;
}

const RocketPayPaymentsContainerMemo = memo(RocketPayPaymentsContainer, areEqual);

export { RocketPayPaymentsContainerMemo as RocketPayPayments };
