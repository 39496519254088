import React, { memo } from 'react';

import { areEqualShallow } from 'Utils/equalityChecks';
import LogoIcon from '../../../Assets/logo-icon.svg';
import LogoIconMedium from '../../../Assets/logo-icon-medium.svg';

// Custom css
import classes from './logoIcon.module.css';

interface Props {
  className?: string;
  id?: string;
  iconType?: string;
  onClick?: (e: any) => void;
  onKeyUp?: (e: any) => void;
}
function LogoIconSvg({ className = '', id, iconType, onClick, onKeyUp }: Props) {
  switch (iconType) {
    case 'medium':
      return (
        <LogoIconMedium
          id={id}
          className={`${classes.logoIconBase} ${className || ''}`}
          onClick={onClick}
          onKeyUp={onKeyUp}
        />
      );
    default:
      return (
        <LogoIcon
          id={id}
          className={`${classes.logoIconBase} ${className || ''}`}
          onClick={onClick}
          onKeyUp={onKeyUp}
        />
      );
  }
}

LogoIconSvg.defaultProps = {
  className: undefined,
  id: undefined,
  iconType: 'default',
  onClick: undefined,
  onKeyUp: undefined,
};

const LogoIconSvgMemo = memo(LogoIconSvg, areEqualShallow);
export { LogoIconSvgMemo as LogoIconSvg };
