import React, { memo, useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';
import { UploadsWrapper, UploadsTable, UploadsHeader } from 'Components/Uploads';

import { projectIdSelector } from 'Containers/RocketScan/selectors';
import { projectPDFsSelector } from 'Containers/Uploads/selectors';
import { listProjectPDFs } from 'Containers/Uploads/actions';
import { UploadFileModal, DeleteFileModal } from 'Containers/Uploads';
import { DashboardPagination } from 'Components/Pagination';
import { download } from 'Utils/helpers';

function UploadsContainer() {
  const dispatch = useDispatch();

  const mounted = useRef(true);

  const projectId = useSelector(projectIdSelector, areEqual);
  const pdfs = useSelector(projectPDFsSelector, areEqual);

  const [sortBy, setSortBy] = useState('-created_at');
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedPDF, setSelectedPDF] = useState(undefined);
  const [pageCount, setPageCount] = useState(1);
  const [initialPage, setInitialPage] = useState(0);

  const onClickSort = useCallback((sort: string) => {
    setSortBy((prevSortBy) => (prevSortBy.includes('-') ? prevSortBy.replace('-', '') : sort));
  }, []);

  const onUploadButtonClick = useCallback(() => {
    setIsUploadModalOpen(true);
  }, []);

  const onDownloadIconClick = useCallback(async (pdf: any) => {
    const { name, pdf_url: url } = pdf;
    await download(url, name);
  }, []);

  const onDeleteIconClick = useCallback(async (pdf: any) => {
    setSelectedPDF(pdf);
    setIsDeleteModalOpen(true);
  }, []);

  const getProjectPDFs = useCallback(async () => {
    if (projectId) {
      dispatch(listProjectPDFs(projectId, sortBy, initialPage + 1));
    }
  }, [projectId, sortBy, initialPage]);

  const onDeleteSuccess = useCallback(() => {
    setIsDeleteModalOpen(false);
    setSelectedPDF(undefined);
    getProjectPDFs();
  }, [getProjectPDFs]);

  // setup pagination
  useEffect(() => {
    if (pdfs?.data?.length > 0) {
      const { meta } = pdfs;
      const { total } = meta;

      setPageCount(total <= 15 ? 1 : Number(total / 15));
    }
  }, [pdfs]);

  const onPageChange = useCallback(({ selected: page }: any) => {
    setInitialPage(page);
  }, []);

  useEffect(() => {
    mounted.current = true;
    (async function fetchData() {
      await getProjectPDFs();
    })();
    return () => {
      if (mounted) {
        mounted.current = false;
      }
    };
  }, [projectId, sortBy, initialPage]);

  return (
    <UploadsWrapper>
      <UploadsHeader onClickUploadPDF={onUploadButtonClick} />
      <div>
        <UploadsTable
          uploads={pdfs}
          sortBy={sortBy}
          onClickSort={onClickSort}
          onDownloadIconClick={onDownloadIconClick}
          onDeleteIconClick={onDeleteIconClick}
        />
        {pdfs?.meta?.total > 15 && (
          <DashboardPagination initialPage={initialPage} pageCount={pageCount} onPageChange={onPageChange} />
        )}

        <UploadFileModal isOpen={isUploadModalOpen} setIsOpen={setIsUploadModalOpen} />
        <DeleteFileModal
          isOpen={isDeleteModalOpen}
          selectedPDF={selectedPDF}
          setIsOpen={setIsDeleteModalOpen}
          onDeleteSuccess={onDeleteSuccess}
        />
      </div>
    </UploadsWrapper>
  );
}

const UploadsContainerMemo = memo(UploadsContainer, areEqual);

export { UploadsContainerMemo as UploadsContainer };
