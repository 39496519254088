import React, { memo, useCallback, useState } from 'react';

import { areEqual } from 'Utils/equalityChecks';
import { Dropzone } from 'Components/RocketScan';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import { showToast } from 'Containers/PhotoErrorToast/actions';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { useUser } from 'Context/User';
import { UserModel } from 'Containers/User/Models/UserModel';
import { chunkArray, getPhotosChunkSize, generateUUID } from 'Utils/helpers';
// import { generateUUID } from 'Utils/helpers';
import { photoUploadingRoomAndAlbumSelector } from 'Containers/RocketScan/RoomsView/RoomContent/Gallery/selectors';
import {
  albumTypesSelector,
  projectAddressSelector,
  projectAliasSelector,
  projectSelector,
  propertySelector,
} from 'Containers/RocketScan/selectors';
import { setUploadingAlbums } from 'Containers/RocketScan/RoomsView/Rooms/actions';
import { useRoomFunctions } from 'Context/RocketScan';
import { sendPhotosST } from 'Utils/serviceTitanSyncAssets';
import { roomsSelector } from 'Containers/RocketScan/RoomsView/Rooms/selectors';
import { locationSelector } from 'Containers/RocketScan/MultiUnit/Locations/selectors';

interface Props {
  roomId: number;
  albumId?: number;
  hasImages: boolean;
  editable: boolean;
}

function DropzoneContainer({ roomId, albumId, hasImages, editable }: Props) {
  const dispatch = useDispatch();
  const createWorker = createWorkerFactory(() => import('../worker'));
  const dropzoneWorker = useWorker(createWorker);
  const photoUploadingRoomAndAlbum = useSelector(photoUploadingRoomAndAlbumSelector, areEqual);
  const project = useSelector(projectSelector, areEqual);
  const alias = useSelector(projectAliasSelector, areEqual);
  const projectAddress = useSelector(projectAddressSelector, areEqual);
  const property = useSelector(propertySelector, areEqual);
  const rooms = useSelector(roomsSelector, areEqual);
  const albums = useSelector(albumTypesSelector, areEqual);
  const location = useSelector(locationSelector, areEqual);
  const [showError, setShowError] = useState(false);
  const [errorFileName, setErrorFileName] = useState('');

  const user: UserModel = useUser();

  const { setShowLoadingSpinner }: any = useRoomFunctions();

  // on files drop or upload
  const onDrop = useCallback(
    async (acceptedFiles: any[], rejectedFiles: any[]) => {
      // show error toast
      if (rejectedFiles.length > 0) {
        dispatch(showToast());
      }

      if (acceptedFiles.length > 0) {
        // set uploading rooms and albums
        dispatch(
          setUploadingAlbums({
            albumId,
            roomId,
          })
        );
        setShowLoadingSpinner(true);

        const clonedFiles = acceptedFiles.map((file) => new File([file], file.name, { type: file.type }));

        const uuid = generateUUID();
        // Transloadit is having issues with assemblies, disabling simultaneous uploads until it's fixed
        const chunkedArr = chunkArray(acceptedFiles, getPhotosChunkSize(acceptedFiles));

        // Should check if the project itself is a serviceTitan project
        if (user.st_technician_id) {
          const smallBatchArr = chunkArray(clonedFiles, 3);

          const finalBatch = [];
          const indexToBeRemoved = [];
          smallBatchArr.forEach((arr) => {
            const total = arr.reduce((acc, picture, idx) => {
              if (picture.size > 4000000) {
                setErrorFileName(picture.name);
                setShowError(true);
                indexToBeRemoved.push(idx);
                return acc;
              }
              return acc + picture.size;
            }, 0);
            const filterArr = arr.filter((_, idx) => !indexToBeRemoved.includes(idx));

            if (total > 4000000) {
              const brokenDownChunk = filterArr.map((file) => [file]);
              finalBatch.push(...brokenDownChunk);
            } else {
              finalBatch.push(filterArr);
            }
            indexToBeRemoved.length = 0;
          });

          const photoNameInfo = {
            address: projectAddress.replaceAll(' ', '_'),
            unit: property.name,
            room: rooms.find((room) => room.id === roomId || ''),
            album: albums.find((album) => album.id === albumId || ''),
            name: property.name === 'multiunit' ? location.name : '',
          };

          sendPhotosST(finalBatch, alias, photoNameInfo, user);
        }
        await Promise.all(
          chunkedArr.map(
            async (filesArray) =>
              await dropzoneWorker.handleUpload(user.id, roomId, albumId, filesArray, filesArray.length, uuid, project)
          )
        );

        // await dropzoneWorker.handleUpload(userId, roomId, albumId, acceptedFiles, acceptedFiles.length, uuid, project);
      }

      return true;
    },
    [photoUploadingRoomAndAlbum, project]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['image/jpeg', 'image/png'],
  });

  return (
    editable && (
      <Dropzone
        hasImages={hasImages}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        showError={showError}
        setShowError={setShowError}
        fileName={errorFileName}
      />
    )
  );
}

DropzoneContainer.defaultProps = {
  albumId: undefined,
};

const DropzoneContainerMemo = memo(DropzoneContainer, areEqual);

export { DropzoneContainerMemo as Dropzone };
