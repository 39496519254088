import React, { memo } from 'react';

import { areEqualShallow } from 'Utils/equalityChecks';
import Wind from '../../../../Assets/wind.svg';

// Custom css
import classes from './wind.module.css';

interface Props {
  className?: string;
  id?: string;
  onClick?: (e: any) => void;
  onKeyUp?: (e: any) => void;
}
function WindSvg({ className = '', id, onClick, onKeyUp }: Props) {
  return <Wind id={id} className={`${classes.windBase} ${className || ''}`} onClick={onClick} onKeyUp={onKeyUp} />;
}

WindSvg.defaultProps = {
  className: undefined,
  id: undefined,
  onClick: undefined,
  onKeyUp: undefined,
};

const WindSvgMemo = memo(WindSvg, areEqualShallow);
export { WindSvgMemo as WindSvg };
