import React, { memo, ReactNode } from 'react';

import { areEqual } from 'Utils/equalityChecks';
import { MetricsContext, MetricsFunctions } from '.';

interface Props {
  children: ReactNode;
}

function MetricsProvider({ children }: Props) {
  const Metrics = MetricsFunctions();

  return <MetricsContext.Provider value={{ ...Metrics }}>{children}</MetricsContext.Provider>;
}

const MetricsProviderMemo = memo(MetricsProvider, areEqual);

export { MetricsProviderMemo as MetricsProvider };
