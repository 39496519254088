import React, { memo, useEffect, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useDamagedMaterialFunctions } from 'Context/RocketScan';
import { ScopeOfWorkCategory } from 'Components/RocketScan';
import { ScopeData, SelectedItem } from 'Containers/RocketScope/types';

interface Props {
  scopes: ScopeData[];
  category: string;
  isSelected: boolean;
  onSelected: () => void;
}

const ScopeOfWorkCategoryItem = ({ scopes, category, isSelected, onSelected }: Props) => {
  const { addedSelectedItems } = useDamagedMaterialFunctions() as {
    addedSelectedItems: SelectedItem[];
    selectedScopeSheet: number;
  };
  const [selectedCategoryItems, setSelectedCategoryItems] = useState<SelectedItem[]>([]);

  useEffect(() => {
    setSelectedCategoryItems(
      addedSelectedItems.filter((selectedItem) => scopes.some((scopeItem) => scopeItem.id === selectedItem.id))
    );
  }, [addedSelectedItems]);

  return (
    <ScopeOfWorkCategory
      name={category}
      onSelectedItemClick={onSelected}
      isSelected={isSelected}
      selectedItems={selectedCategoryItems}
      scopeItems={scopes}
    />
  );
};

const ScopeOfWorkCategoryItemMemo = memo(ScopeOfWorkCategoryItem, areEqual);

export { ScopeOfWorkCategoryItemMemo as ScopeOfWorkCategoryItem };
