import React, { memo, useCallback, useState } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { allowedTimeZones } from 'Utils/timeZones';

import { TimeZoneAutocomplete } from 'Components/Address';

interface Props {
  timezone: string;
  setSelectedTimeZone: (e: any) => void;
  setTimeZoneForBackEnd: (e: any) => void;
  invalid?: boolean;
  showCaretIcon?: boolean;
}

const TimeZoneAutocompleteContainer = ({
  timezone,
  setSelectedTimeZone,
  setTimeZoneForBackEnd,
  invalid,
  showCaretIcon,
}: Props) => {
  const [timeZoneLocal, setTimeZoneLocal] = useState(timezone); // full
  const [showDropDown, setShowDropDown] = useState(false);

  const onChangeTimeZone = useCallback((e: any) => {
    e.preventDefault();

    const { name = '' } = e.target;

    setSelectedTimeZone(name);
    setTimeZoneLocal(name);

    const zone = allowedTimeZones.find((timeZone) => timeZone.name === name);
    setTimeZoneForBackEnd(zone.default);
  }, []);

  const onSelectItem = useCallback((e: any) => {
    e.preventDefault();

    const { name = '' } = e.target.dataset;
    setTimeZoneLocal(name);
    setSelectedTimeZone(name);

    const zone = allowedTimeZones.find((timeZone) => timeZone.name === name);
    setTimeZoneForBackEnd(zone.default);

    setShowDropDown(false);

    // set this to false, so the if condition in the first useEffect will fail
  }, []);

  const onClickCaretIcon = useCallback(() => {
    setShowDropDown((prevState) => !prevState);
  }, []);

  // we'll close the dropdown onBlur event outside the dropdown
  // const onBlur = useCallback((e: any) => {
  //   e.preventDefault();
  //   setShowDropDown(false);
  // }, []);

  return (
    <TimeZoneAutocomplete
      timezone={timeZoneLocal}
      onChangeTimeZone={onChangeTimeZone}
      allowedTimeZones={allowedTimeZones}
      onSelectItem={onSelectItem}
      onClickCaretIcon={onClickCaretIcon}
      showCaretIcon={showCaretIcon}
      showDropDown={showDropDown}
      invalid={invalid}
    />
  );
};

TimeZoneAutocompleteContainer.defaultProps = {
  invalid: false,
  showCaretIcon: false,
};

const TimeZoneAutocompleteContainerMemo = memo(TimeZoneAutocompleteContainer, areEqual);

export { TimeZoneAutocompleteContainerMemo as TimeZoneAutocomplete };
