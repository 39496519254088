import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { Table, TableHeader, TableRow, Th } from 'Components/Table';

import classes from './noContractFormTable.module.css';

interface Props {
  searchValue?: string;
}

const NoContractFormTable = ({ searchValue }: Props) => (
  <div>
    <Table className={`table ${classes.projectListWrapper}`}>
      <TableHeader>
        <TableRow>
          <Th>Template Name</Th>
          <Th>Date Created</Th>
          <Th />
        </TableRow>
      </TableHeader>
    </Table>
    <div className={`d-flex justify-content-center align-items-center flex-column w-100 ${classes.noProjectsContent}`}>
      <p className={classes.noProjectsText}>
        {searchValue?.length >= 2
          ? `No contract found for "${searchValue}".`
          : 'No contracts yet. Create a new contract.'}
      </p>
    </div>
  </div>
);

NoContractFormTable.defaultProps = {
  searchValue: '',
};

const NoContractFormTableMemo = memo(NoContractFormTable, areEqual);

export { NoContractFormTableMemo as NoContractFormTable };
