import React, { memo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { Icon } from 'Components/Icons';
import { Button } from 'Components/Button';

import { EditAddressModal, DeleteProjectModal } from 'Containers/Project';

import { projectIdSelector, projectSelector } from 'Containers/RocketScan/selectors';
import { userFeatureFlagsSelector } from 'Containers/User/selector';

import { ProjectStatus } from 'Utils/constants';
import { updateProjectStatus } from '../actions';

import classes from './projectOptions.module.css';

function ProjectOptions() {
  const dispatch = useDispatch();

  const projectId = useSelector(projectIdSelector);
  const project = useSelector(projectSelector, areEqual);
  const { hideDeleteButton } = useSelector(userFeatureFlagsSelector, areEqual);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditAddressOpen, setIsEditAddressOpen] = useState(false);
  const [isDeleteProjectOpen, setIsDeleteProjectOpen] = useState(false);

  const onOptionButtonClick = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const markProjectAsWip = useCallback(() => {
    dispatch(updateProjectStatus(projectId, ProjectStatus.WIP.toString()));
    setIsMenuOpen(false);
  }, [projectId]);

  const markProjectAsComplete = useCallback(() => {
    dispatch(updateProjectStatus(projectId, ProjectStatus.COMPLETE.toString()));
    setIsMenuOpen(false);
  }, [projectId]);

  const markProjectAsRecon = useCallback(() => {
    dispatch(updateProjectStatus(projectId, ProjectStatus.RECON.toString()));
    setIsMenuOpen(false);
  }, [projectId]);

  const markProjectAsLead = useCallback(() => {
    dispatch(updateProjectStatus(projectId, ProjectStatus.LEAD.toString()));
    setIsMenuOpen(false);
  }, [projectId]);

  const markProjectAsContract = useCallback(() => {
    dispatch(updateProjectStatus(projectId, ProjectStatus.CONTRACT.toString()));
    setIsMenuOpen(false);
  }, [projectId]);

  const markProjectAsEstimate = useCallback(() => {
    dispatch(updateProjectStatus(projectId, ProjectStatus.ESTIMATE.toString()));
    setIsMenuOpen(false);
  }, [projectId]);

  const markProjectAsInvoiced = useCallback(() => {
    dispatch(updateProjectStatus(projectId, ProjectStatus.INVOICED.toString()));
    setIsMenuOpen(false);
  }, [projectId]);

  const markProjectAsPaid = useCallback(() => {
    dispatch(updateProjectStatus(projectId, ProjectStatus.PAID.toString()));
    setIsMenuOpen(false);
  }, [projectId]);

  const openEditAddress = useCallback(() => {
    setIsEditAddressOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const openDeleteProject = useCallback(() => {
    setIsDeleteProjectOpen(true);
  }, []);

  return (
    <>
      <Button onClick={onOptionButtonClick} className={classes.projectOptionsButton}>
        <Icon className={classes.icon} type="threedots" />
      </Button>
      {isMenuOpen && (
        <div className={`accordion-body ${classes.projectOptionsWrapper}`}>
          {project.projectStatus?.id !== ProjectStatus.COMPLETE && (
            <Button className={classes.projectOption} id="mark-project-complete" onClick={markProjectAsComplete}>
              Mark Project as Complete
            </Button>
          )}
          {project.projectStatus?.id !== ProjectStatus.WIP && (
            <Button className={classes.projectOption} id="mark-project-wip" onClick={markProjectAsWip}>
              Mark Project as WIP
            </Button>
          )}
          {project.projectStatus?.id !== ProjectStatus.RECON && (
            <Button className={classes.projectOption} id="mark-project-recon" onClick={markProjectAsRecon}>
              Mark Project as Recon
            </Button>
          )}
          {project.projectStatus?.id !== ProjectStatus.LEAD && (
            <Button className={classes.projectOption} id="mark-project-lead" onClick={markProjectAsLead}>
              Mark Project as Lead Stage
            </Button>
          )}
          {project.projectStatus?.id !== ProjectStatus.CONTRACT && (
            <Button className={classes.projectOption} id="mark-project-contract" onClick={markProjectAsContract}>
              Mark Project as Under Contract
            </Button>
          )}
          {project.projectStatus?.id !== ProjectStatus.ESTIMATE && (
            <Button className={classes.projectOption} id="mark-project-estimate" onClick={markProjectAsEstimate}>
              Mark Project as Estimate
            </Button>
          )}
          {project.projectStatus?.id !== ProjectStatus.INVOICED && (
            <Button className={classes.projectOption} id="mark-project-invoiced" onClick={markProjectAsInvoiced}>
              Mark Project as Invoiced
            </Button>
          )}
          {project.projectStatus?.id !== ProjectStatus.PAID && (
            <Button className={classes.projectOption} id="mark-project-paid" onClick={markProjectAsPaid}>
              Mark Project as Paid
            </Button>
          )}
          <Button className={classes.projectOption} id="2" onClick={openEditAddress}>
            Edit Project Address
          </Button>
          {!hideDeleteButton && (
            <Button className={classes.projectOption} id="3" onClick={openDeleteProject}>
              Delete Project
            </Button>
          )}
        </div>
      )}
      <EditAddressModal isOpen={isEditAddressOpen} setIsOpen={setIsEditAddressOpen} onAddressUpdated={closeMenu} />
      {!hideDeleteButton && (
        <DeleteProjectModal isOpen={isDeleteProjectOpen} setIsOpen={setIsDeleteProjectOpen} closeMenu={closeMenu} />
      )}
    </>
  );
}

const ProjectOptionsMemo = memo(ProjectOptions, areEqual);

export { ProjectOptionsMemo as ProjectOptions };
