import React, { memo, useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';
import { Reports, ChooseReportTypeModal, ChooseReportTypeModalAllDry } from 'Components/ReportsAndDocuments';
import { useHistory } from 'react-router-dom';
import { projectIdSelector } from 'Containers/RocketScan/selectors';
import { userSelector } from 'Containers/User/selector';
import { setSelectedReportEdit } from '../actions';

function ReportsContainer() {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    companies: [company],
  } = useSelector(userSelector, areEqual);

  const { uuid } = company;
  const [useCustomButtons, setUseCustomButtons] = useState(false);
  const [showAllDryTemplate, setShowAllDryTemplate] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const projectId = useSelector(projectIdSelector, areEqual);

  const onPhotoReportClick = useCallback(
    () => history.push(`/projects/${projectId}/reports/generate/photo`),
    [projectId]
  );

  const onDryingReportClick = useCallback(
    () => history.push(`/projects/${projectId}/reports/generate/drying`),
    [projectId]
  );

  const onCustomReportClick = useCallback(() => {
    dispatch(setSelectedReportEdit(undefined));
    history.push(`/projects/${projectId}/reports/generate/custom`);
  }, [projectId]);

  const onCustomReportClickAllDry = useCallback(() => {
    dispatch(setSelectedReportEdit(undefined));
    history.push(`/projects/${projectId}/reports/generate/custom/alldry`);
  }, [projectId]);

  const onClickGenerateReport = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onModalCloseClick = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  // show custom report templates based on company id
  useEffect(() => {
    if (uuid === '87c9b173-d93f-48e1-8d3f-1a4681f5d36e') {
      setUseCustomButtons(true);
    } else {
      setUseCustomButtons(false);
    }
    if (uuid === '63fc4d66-5b0b-4e0e-8980-fb4441a731a6') {
      setShowAllDryTemplate(true);
    } else {
      setShowAllDryTemplate(false);
    }
  }, [uuid]);

  return (
    <>
      <Reports onClickGenerateReport={onClickGenerateReport} />
      {showAllDryTemplate ? (
        <ChooseReportTypeModalAllDry
          isOpen={isModalOpen}
          onPhotoReportClick={onPhotoReportClick}
          onDryingReportClick={onDryingReportClick}
          onCustomReportClick={onCustomReportClick}
          onCustomReportClickAllDry={onCustomReportClickAllDry}
          modalCloseClick={onModalCloseClick}
        />
      ) : (
        <ChooseReportTypeModal
          isOpen={isModalOpen}
          useCustomButtons={useCustomButtons}
          onPhotoReportClick={onPhotoReportClick}
          onDryingReportClick={onDryingReportClick}
          onCustomReportClick={onCustomReportClick}
          modalCloseClick={onModalCloseClick}
        />
      )}
    </>
  );
}

const ReportsContainerMemo = memo(ReportsContainer, areEqual);

export { ReportsContainerMemo as ReportsContainer };
