import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { PillBadge } from 'Components/PillBadge';

import { projectSelector } from 'Containers/RocketScan/selectors';

function ProjectStatusBadge() {
  const project = useSelector(projectSelector, areEqual);

  if (project.projectStatus?.name === 'wip') {
    return <PillBadge text="WIP" />;
  }
  if (project.projectStatus?.name === 'completed') {
    return <PillBadge text="Completed" />;
  }
  if (project.projectStatus?.name === 'estimate') {
    return <PillBadge text="Estimate" />;
  }
  if (project.projectStatus?.name === 'under contract') {
    return <PillBadge text="Contract" />;
  }
  if (project.projectStatus?.name === 'lead stage') {
    return <PillBadge text="Lead" />;
  }
  if (project.projectStatus?.name === 'invoiced') {
    return <PillBadge text="Invoiced" />;
  }
  if (project.projectStatus?.name === 'paid') {
    return <PillBadge text="Paid" />;
  }
  if (project.projectStatus?.name === 'recon') {
    return <PillBadge text="Recon" />;
  }
  return <span />;
}

const ProjectStatusBadgeMemo = memo(ProjectStatusBadge, areEqual);

export { ProjectStatusBadgeMemo as ProjectStatusBadge };
