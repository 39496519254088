export const equipmentInventorySelector = ({ equipmentInventory: { equipmentInventory: value = [] } }: any) => value;
export const fetchingEquipmentInventorySelector = ({
  equipmentInventory: { fetchingEquipmentInventorySelector: value },
}: any) => value;
export const equipmentCreatedSelector = ({ equipmentInventory: { equipmentCreated: value } }: any) => value;
export const equipmentUpdatedSelector = ({ equipmentInventory: { equipmentUpdated: value } }: any) => value;
export const equipmentDeletedSelector = ({ equipmentInventory: { equipmentDeleted: value } }: any) => value;
export const selectedEquipmentForUpdateSelector = ({
  equipmentInventory: { selectedEquipmentForUpdate: value },
}: any) => value;
export const selectedEquipmentForDeleteSelector = ({
  equipmentInventory: { selectedEquipmentForDelete: value },
}: any) => value;
