import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Modal } from 'Components/Modal';
import { PurpleButton } from 'Components/Button';

import classes from './downloadMetricsModal.module.css';

interface Props {
  loading: boolean;
  isOpen: boolean;
  onDownloadButtonClick: (e: any) => void;
  modalCloseClick: (e: any) => void;
}

function DownloadMetricsModal({ loading, isOpen, onDownloadButtonClick, modalCloseClick }: Props) {
  return (
    <Modal title="Download Metrics?" isOpen={isOpen} classes={classes} modalHeader modalCloseClick={modalCloseClick}>
      <p>Download a copy in .csv format to your local files</p>
      <PurpleButton onClick={onDownloadButtonClick} className={classes.downloadButton} disabled={loading}>
        Download
      </PurpleButton>
    </Modal>
  );
}

const DownloadMetricsModalMemo = memo(DownloadMetricsModal, areEqual);

export { DownloadMetricsModalMemo as DownloadMetricsModal };
