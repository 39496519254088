import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { Table, TableBody, TableColumn, TableRow } from 'Components/Table';
import { TableHead } from 'Containers/Table';

import { Icon } from 'Components/Icons';
import { ActionButtonWrapper } from 'Containers/ActionButtonWrapper';

import { uploadsTableHeaders } from 'Utils/table';

import { formatDate } from 'Utils/helpers';

import { Anchor } from 'Components/Anchor';
import classes from './uploadsTable.module.css';

interface Props {
  uploads?: any;
  sortBy?: string;
  onClickSort?: (sort: string) => void;
  onDownloadIconClick: (e: any) => void;
  onDeleteIconClick: (e: any) => void;
}

function UploadsTable({ uploads, sortBy, onClickSort, onDownloadIconClick, onDeleteIconClick }: Props) {
  const hasUploads = uploads?.data?.length > 0;
  return (
    <div className={classes.tableContainer}>
      <Table className={`table ${classes.tableWrapper}`}>
        <TableHead headers={uploadsTableHeaders} sortBy={sortBy} onClickSort={onClickSort} />
        {hasUploads && (
          <TableBody>
            {uploads.data.map((upload) => (
              <TableRow key={upload.id}>
                <TableColumn>
                  <div>{upload.name}</div>
                </TableColumn>
                <TableColumn>{upload?.creator?.full_name || '[deleted user]'}</TableColumn>
                <TableColumn>
                  <div>{formatDate(upload.created_at, 'PP')}</div>
                </TableColumn>
                <TableColumn className={classes.iconColumn}>
                  <Anchor href={upload.pdf_url} openInNewTab>
                    <Icon type="eye" />
                  </Anchor>
                </TableColumn>
                <TableColumn className={classes.iconColumn}>
                  <ActionButtonWrapper
                    onClick={onDownloadIconClick}
                    item={upload}
                    disabled={upload?.status?.toLocaleLowerCase() !== 'completed'}
                  >
                    <Icon type="download" />
                  </ActionButtonWrapper>
                </TableColumn>
                <TableColumn className={classes.iconColumn}>
                  <ActionButtonWrapper
                    onClick={onDeleteIconClick}
                    item={upload}
                    disabled={upload?.status?.toLocaleLowerCase() !== 'completed'}
                  >
                    <Icon type="trash" />
                  </ActionButtonWrapper>
                </TableColumn>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </div>
  );
}

UploadsTable.defaultProps = {
  uploads: [],
  sortBy: null,
  onClickSort: null,
};

const UploadsMemo = memo(UploadsTable, areEqual);
export { UploadsMemo as UploadsTable };
