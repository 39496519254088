import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useDispatch, useSelector } from 'react-redux';
import { ContractForm } from 'Components/ContractForm/ContractForm/ContractForm';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { editCompanyContract, getCompanyContract, setIdToEdit, setContractEdited } from '../actions';
import { contractEditedSelector, contractEditIdSelector, contractInitialsSelector } from '../selectors';
// import { firstCompanyIdSelector } from '../../Projects/selectors';

interface Props {
  closeEditor: () => void;
}

function EditContractContainer({ closeEditor }: Props) {
  const dispatch = useDispatch();
  const mounted = useRef(true);
  const editorRef = useRef(null);
  // const textareaRef = useRef(null);
  const [formName, setFormName] = useState('');
  const [template, setTemplate] = useState('');
  const [replacementTags, setReplacementTags] = useState(['company', 'company_logo', 'address']);
  const [status, setStatus] = useState('inactive');
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);
  const contractEditId = useSelector(contractEditIdSelector, areEqual);
  const [hasSignature, setHasSignature] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasSignatureChangeCount, setHasSignatureChangeCount] = useState(0);
  const contractInitials = useSelector(contractInitialsSelector, areEqual);
  const contractEdited = useSelector(contractEditedSelector, areEqual);

  const onChangeFormName = useCallback((e: any) => {
    const { value } = e.target;
    setStatus('active');
    setFormName(value);
    setIsButtonEnabled(value.length !== 0 || template.length !== 0);
  }, []);

  const onHasSignatureClick = useCallback(
    ({ target: { checked } }) => {
      if (hasSignatureChangeCount === 0) {
        setHasSignature(checked);
        setIsButtonEnabled(true);
        setHasSignatureChangeCount(1);
      } else {
        setHasSignature(checked);
      }
    },
    [hasSignature, hasSignatureChangeCount]
  );

  // const onChangeTemplate = useCallback((e: any) => {
  //   const { value } = e.target;

  //   setTemplate(value);
  //   setIsButtonEnabled(value.length === 0);
  //   e.target.focus();
  // }, []);

  const updateEditor = useCallback((value: any) => {
    editorRef.current.execCommand('mceInsertContent', false, `${value} `);
    setTemplate(editorRef.current.getContent());
  }, []);

  const onChangeReplacementTags = useCallback(
    (value: string) => {
      if (/~~~/.test(value)) {
        const replacementTagStripped = value.replace(/~~~/g, '');
        // setIsButtonEnabled(formName.length === 0);
        setIsButtonEnabled(formName.length !== 0 || template.length !== 0);
        if (replacementTags.indexOf(replacementTagStripped) < 0) {
          const replacementTagsNew = replacementTags;
          replacementTagsNew.push(replacementTagStripped);
          setReplacementTags(replacementTagsNew);
        }
      }
      updateEditor(value);
    },
    [formName, template]
  );

  const onFormButtonClick = useCallback(
    (e: any) => {
      e.preventDefault();
      const template = editorRef.current.getContent();
      setLoading(true);
      dispatch(
        editCompanyContract(
          firstCompanyId,
          formName,
          replacementTags.join(','),
          status,
          template,
          hasSignature,
          contractEditId
        )
      );
      dispatch(setIdToEdit(null));
    },
    [formName, replacementTags, status, hasSignature, hasSignatureChangeCount]
  );

  const onCloseClick = useCallback((e: any) => {
    e.preventDefault();
    closeEditor();
  }, []);

  const getContract = useCallback(() => {
    dispatch(getCompanyContract(contractEditId));
  }, [contractEditId]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      if (mounted) {
        mounted.current = false;
      }
    };
  }, [firstCompanyId]);

  useEffect(() => {
    if (contractEditId) {
      getContract();
    }
  }, [contractEditId]);

  useEffect(() => {
    if (contractInitials?.id) {
      if (/<p>/.test(contractInitials?.template)) {
        setTemplate(contractInitials?.template);
      } else {
        const rawTemplate = contractInitials?.template.replace(/\t/g, '').split('\n');
        const processedTemplate = rawTemplate
          .filter(Boolean)
          .map((line: any) => `<p> ${line} </p>`)
          .join(' ');
        setTemplate(processedTemplate);
      }
      setFormName(contractInitials?.name);
      setHasSignature(contractInitials?.has_signature);
    }
  }, [contractInitials]);

  useEffect(() => {
    if (contractEdited) {
      setLoading(false);
      closeEditor();
      setFormName('');
      // setTemplate('~~~company~~~\n~~~company_logo~~~\n~~~company_address~~~');
      setTemplate(
        `<p> ~~~company~~~ </p>
        <p> ~~~company_logo~~~ </p>
        <p> ~~~company_address~~~ </p>
        `
      );
      dispatch(setContractEdited(false));
    }
  }, [contractEdited]);

  return (
    <ContractForm
      formName={formName}
      template={template}
      hasSignature={hasSignature}
      loading={loading}
      editorRef={editorRef}
      // textareaRef={textareaRef}
      isButtonEnabled={isButtonEnabled}
      submitButtonText="Edit Contract"
      onChangeFormName={onChangeFormName}
      // onChangeTemplate={onChangeTemplate}
      onChangeReplacementTags={onChangeReplacementTags}
      onFormButtonClick={onFormButtonClick}
      onHasSignatureClick={onHasSignatureClick}
      onCloseClick={onCloseClick}
    />
  );
}
const EditContractContainerMemo = memo(EditContractContainer, areEqual);

export { EditContractContainerMemo as EditContract };
