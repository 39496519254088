// types
import {
  SET_USER_TIME_CARDS,
  SET_USER_CLOCKED_IN_TIME_CARDS,
  CLOCK_IN_SUCCESS,
  CLOCK_OUT_SUCCESS,
  TIME_CARD_NOTE_CREATED,
  REFRESH_TIME_CARDS,
  REFRESH_COMPANY_TIME_CARDS,
  SET_COMPANY_TIME_CARDS,
  SET_EMPLOYEES_FILTERS,
  SET_PROJECT_NUMBERS_FILTERS,
  SET_ADDRESSES_FILTERS,
  SET_EMPLOYEES_DROPDOWN,
  SET_PROJECTS_DROPDOWN,
  CREATE_TIME_CARD_ERRORS,
  UPDATE_TIME_CARD_ERRORS,
  CREATE_TIME_CARD_NOTE_ERRORS,
  SET_TIME_CARD_NOTES,
  TimeTrackingTypes,
} from 'Containers/TimeTracking/actions';

// state
const initialState = {
  userTimeCards: [],
  userClockedInTimeCards: [],
  companyTimeCards: { data: [] },
  clockInSuccess: false,
  clockOutSuccess: false,
  timeCardNoteCreated: false,
  refreshTimeCards: false,
  refreshCompanyTimeCards: false,
  employeeFilters: [],
  projectNumFilters: [],
  addressFilters: [],
  employeeDropdownItems: [],
  projectDropdownItems: [],
  createTimeCardErrors: false,
  updateTimeCardErrors: false,
  createTimeCardNoteErrors: {},
  timeCardNotes: [],
};

export const timeTrackingReducer = (state = initialState, action: TimeTrackingTypes) => {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_TIME_CARDS:
      return {
        ...state,
        userTimeCards: payload,
      };
    case SET_USER_CLOCKED_IN_TIME_CARDS:
      return {
        ...state,
        userClockedInTimeCards: payload,
      };
    case SET_COMPANY_TIME_CARDS:
      return {
        ...state,
        companyTimeCards: payload,
      };
    case CLOCK_IN_SUCCESS:
      return {
        ...state,
        clockInSuccess: payload,
      };
    case CLOCK_OUT_SUCCESS:
      return {
        ...state,
        clockOutSuccess: payload,
      };
    case TIME_CARD_NOTE_CREATED:
      return {
        ...state,
        timeCardNoteCreated: payload,
      };
    case REFRESH_TIME_CARDS:
      return {
        ...state,
        refreshTimeCards: payload,
      };
    case REFRESH_COMPANY_TIME_CARDS:
      return {
        ...state,
        refreshCompanyTimeCards: payload,
      };
    case SET_EMPLOYEES_FILTERS:
      return {
        ...state,
        employeeFilters: payload,
      };
    case SET_PROJECT_NUMBERS_FILTERS:
      return {
        ...state,
        projectNumFilters: payload,
      };
    case SET_ADDRESSES_FILTERS:
      return {
        ...state,
        addressFilters: payload,
      };
    case SET_EMPLOYEES_DROPDOWN:
      return {
        ...state,
        employeeDropdownItems: payload,
      };
    case SET_PROJECTS_DROPDOWN:
      return {
        ...state,
        projectDropdownItems: payload,
      };
    case CREATE_TIME_CARD_ERRORS:
      return {
        ...state,
        createTimeCardErrors: payload,
      };
    case UPDATE_TIME_CARD_ERRORS:
      return {
        ...state,
        updateTimeCardErrors: payload,
      };
    case CREATE_TIME_CARD_NOTE_ERRORS:
      return {
        ...state,
        createTimeCardNoteErrors: payload,
      };
    case SET_TIME_CARD_NOTES:
      return {
        ...state,
        timeCardNotes: payload,
      };
    default:
      return state;
  }
};
