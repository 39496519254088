import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { Table, TableBody, TableColumn, TableHeader, TableRow, Th } from 'Components/Table';

import { Icon } from 'Components/Icons';
import { TimeTrackingCardTimesheet } from 'Containers/TimeTracking/types';
import { formatDate } from 'Utils/helpers';
import { parseNumber } from 'Utils/numbers';

import { formatDurationForTimeTracking } from 'Containers/TimeTracking/helpers';

import classes from './timesheetsList.module.css';

interface Props {
  sortBy?: string;
  searchValue?: string;
  timeCards?: any;
  onClickProject?: (e: any) => void;
  onClickNotes?: (e: any) => void;
  onClickEdit?: (e: any) => void;
  onClickDelete?: (e: any) => void;
  onClickSort?: (sort: string) => void;
}

const TimesheetsList = ({
  sortBy,
  searchValue,
  timeCards,
  onClickProject,
  onClickNotes,
  onClickEdit,
  onClickDelete,
  onClickSort,
}: Props) =>
  timeCards?.data?.length > 0 ? (
    <Table className={`table ${classes.projectListWrapper}`}>
      <TableHeader>
        <TableRow>
          <Th>
            <span
              className={classes.thSpan}
              role="button"
              tabIndex={-1}
              onClick={() => onClickSort('-user')}
              onKeyUp={() => onClickSort('-user')}
            >
              Employee Name
              {sortBy === '-user' ? (
                <Icon className={classes.sortIcon} type="caretup" />
              ) : (
                <Icon className={classes.sortIcon} type="caretdown" />
              )}
            </span>
          </Th>
          <Th>
            <span
              className={classes.thSpan}
              role="button"
              tabIndex={-1}
              onClick={() => onClickSort('-address')}
              onKeyUp={() => onClickSort('-address')}
            >
              Project Address
              {sortBy === '-address' ? (
                <Icon className={classes.sortIcon} type="caretup" />
              ) : (
                <Icon className={classes.sortIcon} type="caretdown" />
              )}
            </span>
          </Th>
          <Th>
            <span
              className={classes.thSpan}
              role="button"
              tabIndex={-1}
              onClick={() => onClickSort('-project_number')}
              onKeyUp={() => onClickSort('-project_number')}
            >
              Project Number
              {sortBy === '-project_number' ? (
                <Icon className={classes.sortIcon} type="caretup" />
              ) : (
                <Icon className={classes.sortIcon} type="caretdown" />
              )}
            </span>
          </Th>
          <Th>
            <span
              className={classes.thSpan}
              role="button"
              tabIndex={-1}
              onClick={() => onClickSort('-time_in')}
              onKeyUp={() => onClickSort('-time_in')}
            >
              Date In
              {sortBy === '-time_in' ? (
                <Icon className={classes.sortIcon} type="caretup" />
              ) : (
                <Icon className={classes.sortIcon} type="caretdown" />
              )}
            </span>
          </Th>
          <Th>
            <span
              className={classes.thSpan}
              role="button"
              tabIndex={-1}
              onClick={() => onClickSort('-duration')}
              onKeyUp={() => onClickSort('-duration')}
            >
              Hours
              {sortBy === '-duration' ? (
                <Icon className={classes.sortIcon} type="caretup" />
              ) : (
                <Icon className={classes.sortIcon} type="caretdown" />
              )}
            </span>
          </Th>
          <Th>Notes</Th>
          <Th>Actions</Th>
        </TableRow>
      </TableHeader>
      <TableBody>
        {timeCards.data.map((card: TimeTrackingCardTimesheet) => (
          <TableRow key={`${card.id}${card.cardDay ? `-${card.cardDay}` : ''}`}>
            <TableColumn>
              <div>{card.user}</div>
            </TableColumn>

            {card.projectId ? (
              <TableColumn
                dataId={parseNumber(card.projectId)}
                className={classes.columnContent}
                tdOnClick={onClickProject}
              >
                <div className={classes.address}>
                  <p className={classes.street}>{card.address}</p>
                  <p className={classes.region}>{card.address2}</p>
                </div>
              </TableColumn>
            ) : (
              <TableColumn className={classes.columnContent}>
                <div className={classes.address}>
                  <p className={classes.street}>[deleted project]</p>
                </div>
              </TableColumn>
            )}

            <TableColumn className={classes.columnContent}>{card.projectNumber}</TableColumn>
            <TableColumn className={classes.columnContent}>{formatDate(card.timeIn, 'PP')}</TableColumn>
            <TableColumn>{formatDurationForTimeTracking(card.duration)}</TableColumn>
            <TableColumn>
              {card.notesCount > 0 && (
                <span role="button" data-id={card.id} onClick={onClickNotes} onKeyUp={onClickNotes} tabIndex={0}>
                  <Icon type="notesbutton" />
                </span>
              )}
            </TableColumn>
            <TableColumn>
              <span role="button" data-id={card.id} onClick={onClickEdit} onKeyUp={onClickEdit} tabIndex={0}>
                <Icon type="editbutton" />
              </span>
              <span role="button" data-id={card.id} onClick={onClickDelete} onKeyUp={onClickDelete} tabIndex={0}>
                <Icon type="deletebutton" />
              </span>
            </TableColumn>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <>
      <Table className={`table ${classes.projectListWrapper}`}>
        <TableHeader>
          <TableRow>
            <Th>
              <span
                className={classes.thSpan}
                role="button"
                tabIndex={-1}
                onClick={() => onClickSort('-uid')}
                onKeyUp={() => onClickSort('-uid')}
              >
                Employee Name
                {sortBy === '-uid' ? (
                  <Icon className={classes.sortIcon} type="caretup" />
                ) : (
                  <Icon className={classes.sortIcon} type="caretdown" />
                )}
              </span>
            </Th>
            <Th>
              <span
                className={classes.thSpan}
                role="button"
                tabIndex={-1}
                onClick={() => onClickSort('-uid')}
                onKeyUp={() => onClickSort('-uid')}
              >
                Project Address
                {sortBy === '-uid' ? (
                  <Icon className={classes.sortIcon} type="caretup" />
                ) : (
                  <Icon className={classes.sortIcon} type="caretdown" />
                )}
              </span>
            </Th>
            <Th>
              <span
                className={classes.thSpan}
                role="button"
                tabIndex={-1}
                onClick={() => onClickSort('-uid')}
                onKeyUp={() => onClickSort('-uid')}
              >
                Project Number
                {sortBy === '-uid' ? (
                  <Icon className={classes.sortIcon} type="caretup" />
                ) : (
                  <Icon className={classes.sortIcon} type="caretdown" />
                )}
              </span>
            </Th>
            <Th>
              <span
                className={classes.thSpan}
                role="button"
                tabIndex={-1}
                onClick={() => onClickSort('-uid')}
                onKeyUp={() => onClickSort('-uid')}
              >
                Date In
                {sortBy === '-uid' ? (
                  <Icon className={classes.sortIcon} type="caretup" />
                ) : (
                  <Icon className={classes.sortIcon} type="caretdown" />
                )}
              </span>
            </Th>
            <Th>
              <span
                className={classes.thSpan}
                role="button"
                tabIndex={-1}
                onClick={() => onClickSort('-uid')}
                onKeyUp={() => onClickSort('-uid')}
              >
                Hours
                {sortBy === '-uid' ? (
                  <Icon className={classes.sortIcon} type="caretup" />
                ) : (
                  <Icon className={classes.sortIcon} type="caretdown" />
                )}
              </span>
            </Th>
            <Th>Notes</Th>
            <Th>Actions</Th>
          </TableRow>
        </TableHeader>
      </Table>
      <div
        className={`d-flex justify-content-center align-items-center flex-column w-100 ${classes.noProjectsContent}`}
      >
        <p className={classes.noProjectsText}>
          {searchValue?.length >= 2 ? `No timecards found for "${searchValue}".` : 'No timecards found.'}
        </p>
        <Icon type="rocketemblemtwo" />
      </div>
    </>
  );

TimesheetsList.defaultProps = {
  searchValue: null,
  sortBy: null,
  timeCards: [],
  onClickProject: null,
  onClickNotes: null,
  onClickEdit: null,
  onClickDelete: null,
  onClickSort: null,
};

const TimesheetsListMemo = memo(TimesheetsList, areEqual);

export { TimesheetsListMemo as TimesheetsList };
