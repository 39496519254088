/* eslint-disable */
export const contractsInitialsSelector = ({ contractForm: { contractsInitials: value = [] } }: any) => value;
export const contractInitialsSelector = ({ contractForm: { contractInitials: value = {} } }: any) => value;
export const totalContractsSelector = ({ contractForm: { totalContracts: value = [] } }: any) => value;
export const fetchingContractsSelector = ({ contractForm: { fetchingContracts: value = true } }: any) => value;
export const contractsDeletedSelector = ({ contractForm: { contractFormDeleted: value = '' } }: any) => value;
export const contractCreatedSelector = ({ contractForm: { contractCreated: value = true } }: any) => value;
export const contractEditedSelector = ({ contractForm: { contractEdited: value = true } }: any) => value;
export const contractDeleteIdSelector = ({ contractForm: { contractDeleteId: value = true } }: any) => value;
export const contractEditIdSelector = ({ contractForm: { contractEditId: value = true } }: any) => value;
