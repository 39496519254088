/* eslint-disable */
//The linter does not like how the curly brace on line 44 is not on it's own.
import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import classes from './photoSizeErrorToast.module.css';
import { Button } from 'Components/Button';

export interface Props {
  id?: string;
  className?: string;
  isDisplayed: boolean;
  closeToast: (e: any) => void;
  fileName: string;
}

// redux based customizable Toast component
const PhotoSizeErrorToast = ({ id, className, isDisplayed = false, fileName }: Props) => {
  return (
    <div
      id={id}
      className={`toast fade d-flex align-items-center border-0 bottom-0 ${isDisplayed ? 'show' : 'hide'} ${
        className || ''
      } ${classes.toastBase} ${classes.toastWarning}`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className={`toast-body ${classes['toast-body-override']} ${classes.toastText}`}>
        Could not upload {fileName} to ServiceTitan. Max photo size is 5MB.
      </div>
    </div>
  );
};

PhotoSizeErrorToast.defaultProps = {
  id: undefined,
  className: undefined,
};

const PhotoSizeErrorToastMemo = memo(PhotoSizeErrorToast, areEqual);

export { PhotoSizeErrorToastMemo as PhotoSizeErrorToast };
