import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useDamagedMaterialFunctions } from 'Context/RocketScan';

import { SelectedItem } from 'Containers/RocketScope/types';
import classes from 'Containers/RocketScan/RoomsView/DamagedMaterials/ScopeOfWorkCategoryList/scopeOfWorkCategoryList.module.css';
import { Button } from 'Components/Button';
import { Icon } from 'Components/Icons';
import { ScopeOfWorkAdjust } from 'Containers/RocketScan/RoomsView';

const ScopeOfWorkEditList = () => {
  const { selectedItems, removeSelectedItem, setSelectedItemQuantity } = useDamagedMaterialFunctions() as {
    selectedItems: SelectedItem[];
    removeSelectedItem: (item: SelectedItem) => void;
    setSelectedItemQuantity: (item: SelectedItem, quantity: number) => void;
  };

  return (
    <div>
      {selectedItems.map((scopeItem) => (
        <div key={scopeItem.id}>
          <div className={classes.containerRow}>
            <div className={classes.scopeItem}>
              <div className={classes.scopeCode}>
                {scopeItem.codePart1}
                {scopeItem.codePart2}
              </div>
              <div className={classes.scopeDescription}>{scopeItem.description}</div>
            </div>
            <div>
              <Button
                type="button"
                onClick={() => removeSelectedItem(scopeItem)}
                className={`${classes.plusMinusButton}`}
              >
                <Icon type="minus" />
              </Button>
            </div>
          </div>
          <ScopeOfWorkAdjust selectedScopeItem={scopeItem} setItemQuantity={setSelectedItemQuantity} />
        </div>
      ))}
    </div>
  );
};
const ScopeOfWorkEditListMemo = memo(ScopeOfWorkEditList, areEqual);

export { ScopeOfWorkEditListMemo as ScopeOfWorkEditList };
