import React, { useCallback } from 'react';
import { PurpleButton } from 'Components/Button';
import { useHistory } from 'react-router-dom';
import classes from './EmailVerificationConfirmation.module.css';

export function EmailVerificationConfirmation() {
  const history = useHistory();

  const backToDash = useCallback((e: any) => {
    e.preventDefault();
    history.push('/dashboard');
  }, []);

  return (
    <div className={classes.container}>
      <h1>Email Verified</h1>
      <p>
        Your email address has been successfully verified!
        <br />
        You can now create and edit projects.
      </p>
      <PurpleButton className={classes.btn} onClick={backToDash}>
        Go to Dashboard
      </PurpleButton>
    </div>
  );
}
