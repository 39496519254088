import React, { memo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { CreateTimeCardModal } from 'Components/TimeTracking';

import { createTimeCard } from 'Containers/TimeTracking/actions';

import { useTimeTrackingFunctions } from 'Context/TimeTracking';
import { timeCardTypes } from 'Containers/TimeTracking/timeCardTypes';
import { createTimeCardErrorSelector } from 'Containers/TimeTracking/selectors';

const CreateTimeCardModalContainer = () => {
  const dispatch = useDispatch();

  const [selectedEmployeeId, setSelectedEmployeeId] = useState(undefined);
  const [selectedProjectId, setSelectedProjectId] = useState(undefined);
  const [timeIn, setTimeIn] = useState(undefined);
  const [timeOut, setTimeOut] = useState(undefined);

  const { isCreateTimeCardModalOpen, employeeFilters, setIsCreateTimeCardModalOpen, projectDropdownItems }: any =
    useTimeTrackingFunctions();

  const errors = useSelector(createTimeCardErrorSelector, areEqual);
  // console.log(errors);

  const resetInputs = () => {
    setSelectedEmployeeId(undefined);
    setSelectedProjectId(undefined);
    setTimeIn(undefined);
    setTimeOut(undefined);
  };

  const closeModal = () => {
    setIsCreateTimeCardModalOpen(false);
    resetInputs();
  };

  const onCreateButtonClick = useCallback(() => {
    dispatch(
      createTimeCard(selectedProjectId, timeIn, timeCardTypes.standard, timeOut, selectedEmployeeId, closeModal)
    );
  }, [selectedProjectId, selectedEmployeeId, timeIn, timeOut]);

  return (
    <CreateTimeCardModal
      // modal variables
      isOpen={isCreateTimeCardModalOpen}
      formErrors={errors}
      canCreate={selectedEmployeeId && selectedProjectId && timeIn && timeOut}
      // employee variables
      employees={employeeFilters}
      selectedEmployeeId={selectedEmployeeId}
      // project variables
      projects={projectDropdownItems}
      selectedProjectId={selectedProjectId}
      // date variables
      startDate={timeIn}
      endDate={timeOut}
      // modal functions
      modalCloseClick={closeModal}
      onCreateButtonClick={onCreateButtonClick}
      // employee functions
      setSelectedEmployee={setSelectedEmployeeId}
      // project functions
      setSelectedProject={setSelectedProjectId}
      // date functions
      onChangeStartDate={setTimeIn}
      onChangeEndDate={setTimeOut}
    />
  );
};

const CreateTimeCardModalContainerMemo = memo(CreateTimeCardModalContainer, areEqual);

export { CreateTimeCardModalContainerMemo as CreateTimeCardModal };
