export const scopeSheetsSelector = ({ rocketScope: { scopeSheets } }: any) => scopeSheets;
export const currentSheetSelector = ({ rocketScope: { currentSheet } }: any) => currentSheet;
export const hasChangesSelector = ({ rocketScope: { hasChanges } }: any) => hasChanges;
export const isEditingSelector = ({ rocketScope: { isEditing } }: any) => isEditing;
export const loadingSelector = ({ rocketScope: { loading } }: any) => loading;
export const selectedRowSelector = ({ rocketScope: { selectedRow } }: any) => selectedRow;
export const changesSelector = ({ rocketScope: { changes } }: any) => changes;
export const removedSelector = ({ rocketScope: { removed } }: any) => removed;
export const newCategorySelector = ({ rocketScope: { newCategory } }: any) => newCategory;
export const isNewCategoryOpenSelector = ({ rocketScope: { isNewCategoryOpen } }: any) => isNewCategoryOpen;
export const newCategoryFormErrorsSelector = ({ rocketScope: { newCategoryFormErrors } }: any) => newCategoryFormErrors;
export const saveErrorSelector = ({ rocketScope: { saveError } }: any) => saveError;
export const hasFileUploadSelector = ({ rocketScope: { hasFileUpload } }: any) => hasFileUpload;
