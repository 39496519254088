import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { areEqual } from 'Utils/equalityChecks';
import { Modal } from 'Components/Modal';
import { Anchor } from 'Components/Anchor';
import { PurpleButton } from 'Components/Button';
import { Icon } from 'Components/Icons';
import { handleApiRequest } from 'Utils/handleApiRequest';
import { Api } from 'Utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { RocketCallPackageModel } from 'Containers/User/Models/RocketCallModel';
import classes from './rocketCallBlocker.module.css';

// Not memoized because it has useEffect.
export function RocketCallBlocker() {
    let allPackages:RocketCallPackageModel[] = [];

    const [showMonthly, setShowMonthly] = useState(true);
    const [rocketCallPackages, setRocketCallPackages] = useState<RocketCallPackageModel[]>([]);

    const history = useHistory();
    const dispatch = useDispatch();

    const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

    const onClickMonthly = useCallback(() => {
        setRocketCallPackages(allPackages.filter((p) => p.billing_period === 'M'));
        setShowMonthly(true);
    }, []);

    const onClickAnnual = useCallback(() => {
        setRocketCallPackages(allPackages.filter((p) => p.billing_period === 'Y'));
        setShowMonthly(false);
    }, []);

    const onClickCloseButton = useCallback(() => {
        history.push('/dashboard');
    }, []);

    const onClickPurchase = useCallback(async (rocketCallPackage: RocketCallPackageModel) => {
        const response = await handleApiRequest(
            dispatch,
            Api.post(`companies/${firstCompanyId}/rocket-call/subscribe`, {
                price_id: rocketCallPackage.price_id
            })
        );
        if (response.success) {
            window.location.href = response.message;
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const response = await handleApiRequest(dispatch, Api.get('/rocket-call-packages'));
            if (response.data) {
                allPackages = response.data;
                allPackages.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
                allPackages.forEach((p) => {
                   p.price = `$${parseFloat(p.price).toFixed(0)}`;
                });
                const period = showMonthly ? 'M' : 'Y';
                setRocketCallPackages(allPackages.filter((p) => p.billing_period === period));
            } else {
                console.log('Error getting packages');
            }
        };
        if (allPackages.length === 0) {
            fetchData();
        }
    }, [setRocketCallPackages, showMonthly]);

    return (
        <Modal
            id="rocketcall-modal"
            classes={classes}
            title="Activate RocketCall"
            isOpen
            modalHeader
            closeButtonText="Cancel"
            dataBsBackdrop="static"
            dataBsKeyboard="false"
            modalCloseClick={onClickCloseButton}
        >
            <div className={classes.sectionContainer}>
                <div className={classes.description}>
                    <div className={classes.row}>
                        <PurpleButton
                            onClick={onClickMonthly}
                            className={`${classes.planPaymentPickerBase}
                ${showMonthly ? classes.planPaymentPickerActive : classes.planPaymentPickerInactive}`}
                        >
                            <div>Monthly</div>
                        </PurpleButton>
                        <PurpleButton
                            onClick={onClickAnnual}
                            className={`${classes.planPaymentPickerBase}
                ${!showMonthly ? classes.planPaymentPickerActive : classes.planPaymentPickerInactive}`}
                        >
                            <div>Annual - Save 50%</div>
                        </PurpleButton>
                    </div>
                    <br />
                    <div className={classes.row}>
                        {rocketCallPackages.map((rocketCallPackage) => (
                            <div key={rocketCallPackage.id} className={classes.planContainer}>
                                <Icon type="logoiconmedium" className={classes.logo} />
                                <div className={classes.planName}>
                                    RocketCall&nbsp;
                                    <span className={classes.planNamePurple}>{ rocketCallPackage.display_name }</span>
                                </div>
                                <div className={classes.planDescription}>{ rocketCallPackage.display_description }</div>
                                <div className={classes.planPrice}>
{ rocketCallPackage.price }
/
{ rocketCallPackage.billing_period === 'M' ? 'month' : 'year' }
                                </div>
                                <Anchor onClick={() => onClickPurchase(rocketCallPackage)}>
                                    <PurpleButton>Buy Now</PurpleButton>
                                </Anchor>
                            </div>
                        ))}
                    </div>
                </div>
                <br />
            </div>
        </Modal>
    );
}
