import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { DateTimeEdit } from 'Components/ProjectData';

import { useLossDataFunctions } from 'Context/LossData';

function CallReceivedEditContainer() {
  const { selectedCallReceived, setSelectedCallReceived }: any = useLossDataFunctions();

  return (
    <DateTimeEdit
      dateTime={selectedCallReceived}
      onDateTimeChange={setSelectedCallReceived}
      labelText="Call Received (optional)"
    />
  );
}

const CallReceivedEditContainerMemo = memo(CallReceivedEditContainer, areEqual);

export { CallReceivedEditContainerMemo as CallReceivedEdit };
