import React, { memo } from 'react';

import { areEqualShallow } from 'Utils/equalityChecks';

import Scope from '../../../../Assets/NavItems/scope.svg';

// Custom css
import classes from './scope.module.css';

interface Props {
  className?: string;
  id?: string;
  onClick?: (e: any) => void;
  onKeyUp?: (e: any) => void;
}
function ScopeSvg({ className = '', id, onClick, onKeyUp }: Props) {
  return <Scope id={id} className={`${classes.iconBase} ${className || ''}`} onClick={onClick} onKeyUp={onKeyUp} />;
}

const ScopeSvgMemo = memo(ScopeSvg, areEqualShallow);
export { ScopeSvgMemo as ScopeSvg };
