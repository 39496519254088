import React, { memo } from 'react';

import { areEqualShallow } from 'Utils/equalityChecks';
import School from '../../../Assets/school.svg';

// Custom css
import classes from './school.module.css';

interface Props {
  className?: string;
  id?: string;
  onClick?: (e: any) => void;
  onKeyUp?: (e: any) => void;
}

function SchoolSvg({ className = '', id, onClick, onKeyUp }: Props) {
  return <School id={id} className={`${classes.closeBase} ${className || ''}`} onClick={onClick} onKeyUp={onKeyUp} />;
}

SchoolSvg.defaultProps = {
  className: undefined,
  id: undefined,
  onClick: undefined,
  onKeyUp: undefined,
};

const SchoolSvgMemo = memo(SchoolSvg, areEqualShallow);
export { SchoolSvgMemo as SchoolSvg };
