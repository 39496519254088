import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { fetchWebComponentToken } from 'Containers/RocketPay/actions';

import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { RocketPayBusinessForm } from 'Components/RocketPay';
import { webComponentTokenSelector } from 'Containers/RocketPay/selectors';

interface Props {
  businessId: string;
  successCallback: () => void;
}

function RocketPayBusinessFormContainer({ businessId, successCallback }: Props) {
  const dispatch = useDispatch();

  const webToken = useSelector(webComponentTokenSelector, areEqual);

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

  useEffect(() => {
    dispatch(fetchWebComponentToken(firstCompanyId));
  }, [dispatch, firstCompanyId]);

  return <RocketPayBusinessForm businessId={businessId} authToken={webToken} successCallback={successCallback} />;
}

const RocketPayBusinessFormContainerMemo = memo(RocketPayBusinessFormContainer, areEqual);
export { RocketPayBusinessFormContainerMemo as RocketPayBusinessForm };
