import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { RocketScopeTabsContainer } from 'Containers/RocketScope';

function RocketScopeContainer() {
  return <RocketScopeTabsContainer />;
}

const RocketScopeContainerMemo = memo(RocketScopeContainer, areEqual);

export { RocketScopeContainerMemo as ScopeContainer };
