import React, { memo, useState, useEffect } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { ProjectsTabMenu } from 'Components/Tabs';
import {
  fetchingStProjects,
  reconProjectsSelector,
  leadProjectsSelector,
  contractProjectsSelector,
  estimateProjectsSelector,
  invoicedProjectsSelector,
  paidProjectsSelector,
  completedProjectsSelector,
  fetchingReconProjectsSelector,
  fetchingLeadProjectsSelector,
  fetchingContractProjectsSelector,
  fetchingEstimateProjectsSelector,
  fetchingInvoicedProjectsSelector,
  fetchingPaidProjectsSelector,
  fetchingCompletedProjectsSelector,
} from 'Containers/Projects/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { MyProjects, WipProjects, ProjectsBase, PhotoDownloadModal } from 'Containers/Projects';
import {
  listCompanyReconProjects,
  listCompanyLeadStageProjects,
  listCompanyContractProjects,
  listCompanyEstimateProjects,
  listCompanyInvoicedProjects,
  listCompanyPaidProjects,
  listCompanyCompletedProjects,
} from 'Containers/Projects/actions';
import getSTJobs from 'Utils/serviceTitanJobs';
import { useUser } from 'Context/User';
import { UserModel } from 'Containers/User/Models/UserModel';
import { userFeatureFlagsSelector } from 'Containers/User/selector';
import classes from './projectTabs.module.css';

interface Props {
  onClickRow?: (e: any) => void;
}

function ProjectsTabsContainer({ onClickRow }: Props) {
  const { onlyShowMyProjects } = useSelector(userFeatureFlagsSelector, areEqual);

  // We want to set the initial active tab to the first tab in the incoming tabList
  const [activeTab, setActiveTab] = useState('my-projects-tab');
  const user: UserModel = useUser();
  const dispatch = useDispatch();
  const StJobsLoading = useSelector(fetchingStProjects);

  useEffect(() => {
    if (user.st_technician_id && user.st_tenant_id && !StJobsLoading) getSTJobs(user, dispatch);
  }, [activeTab, user.st_technician_id, user.st_tenant_id]);

  return (
    <>
      <ProjectsTabMenu
        id="projects-tabs"
        activeTab={activeTab}
        onlyShowMyProjects={onlyShowMyProjects}
        setActiveTab={setActiveTab}
      >
        {StJobsLoading && <div className={classes.loadingStJobs}>Loading ServiceTitan Jobs</div>}
        <MyProjects onClickRow={onClickRow} />
        <WipProjects onClickRow={onClickRow} />
        <ProjectsBase
          projectCategory="recon-projects"
          projectsSelector={reconProjectsSelector}
          fetchingSelector={fetchingReconProjectsSelector}
          listProjectsFunction={listCompanyReconProjects}
          onClickRow={onClickRow}
          active={activeTab === 'recon-projects-tab'}
        />
        <ProjectsBase
          projectCategory="lead-projects"
          projectsSelector={leadProjectsSelector}
          fetchingSelector={fetchingLeadProjectsSelector}
          listProjectsFunction={listCompanyLeadStageProjects}
          onClickRow={onClickRow}
          active={activeTab === 'lead-projects-tab'}
        />
        <ProjectsBase
          projectCategory="contract-projects"
          projectsSelector={contractProjectsSelector}
          fetchingSelector={fetchingContractProjectsSelector}
          listProjectsFunction={listCompanyContractProjects}
          onClickRow={onClickRow}
          active={activeTab === 'contract-projects-tab'}
        />
        <ProjectsBase
          projectCategory="estimate-projects"
          projectsSelector={estimateProjectsSelector}
          fetchingSelector={fetchingEstimateProjectsSelector}
          listProjectsFunction={listCompanyEstimateProjects}
          onClickRow={onClickRow}
          active={activeTab === 'estimate-projects-tab'}
        />
        <ProjectsBase
          projectCategory="invoiced-projects"
          projectsSelector={invoicedProjectsSelector}
          fetchingSelector={fetchingInvoicedProjectsSelector}
          listProjectsFunction={listCompanyInvoicedProjects}
          onClickRow={onClickRow}
          active={activeTab === 'invoiced-projects-tab'}
        />
        <ProjectsBase
          projectCategory="paid-projects"
          projectsSelector={paidProjectsSelector}
          fetchingSelector={fetchingPaidProjectsSelector}
          listProjectsFunction={listCompanyPaidProjects}
          onClickRow={onClickRow}
          active={activeTab === 'paid-projects-tab'}
        />
        <ProjectsBase
          projectCategory="completed-projects"
          projectsSelector={completedProjectsSelector}
          fetchingSelector={fetchingCompletedProjectsSelector}
          listProjectsFunction={listCompanyCompletedProjects}
          onClickRow={onClickRow}
          active={activeTab === 'completed-projects-tab'}
        />
      </ProjectsTabMenu>
      <PhotoDownloadModal />
    </>
  );
}

ProjectsTabsContainer.defaultProps = {
  onClickRow: null,
};

const ProjectsTabsContainerMemo = memo(ProjectsTabsContainer, areEqual);

export { ProjectsTabsContainerMemo as ProjectsTabs };
