import React, { memo, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { areEqual } from 'Utils/equalityChecks';
import { RocketPayBlocker } from 'Components/GenericBlocker';
import { userFeatureFlagsSelector, userFeatureFlagsFetchedSelector } from 'Containers/User/selector';
import { PaymentContext, PaymentFunctions } from './index';

interface Props {
  children: ReactNode;
}

function PaymentProviderProvider({ children }: Props) {
  const payment = PaymentFunctions();

  const { rocketPay } = useSelector(userFeatureFlagsSelector, areEqual);
  const featureFlagsFetched = useSelector(userFeatureFlagsFetchedSelector, areEqual);

  if (!featureFlagsFetched) {
    return <div />;
  }

  if (!rocketPay) {
    return <RocketPayBlocker />;
  }
  return <PaymentContext.Provider value={{ ...payment }}>{children}</PaymentContext.Provider>;
}

const PaymentProviderMemo = memo(PaymentProviderProvider, areEqual);

export { PaymentProviderMemo as PaymentProvider };
