import { Pagination } from 'Components/Pagination';
import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import classes from './timesheetsPagination.module.css';

interface Props {
  initialPage: number;
  pageCount: number;
  onPageChange: (e: any) => void;
}

const TimesheetsPagination = ({ initialPage, pageCount, onPageChange }: Props) => (
  <div className={classes.timesheetsPaginationBase}>
    <Pagination className="project" initialPage={initialPage} pageCount={pageCount} onPageChange={onPageChange} />
  </div>
);

const TimesheetsPaginationMemo = memo(TimesheetsPagination, areEqual);

export { TimesheetsPaginationMemo as TimesheetsPagination };
