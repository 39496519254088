import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { areEqual } from 'Utils/equalityChecks';

import { NotesFormContext, NotesFormFunctions } from 'Context/Notes';
import { DeleteNoteModal } from 'Components/Notes';
import { NotesSearchFunctions } from 'Context/Notes/NotesContext';
import { userFeatureFlagsSelector } from 'Containers/User/selector';

// notes form provider
function NotesProvider({ children }: any) {
  const NotesForm = NotesFormFunctions();
  const NotesSearch = NotesSearchFunctions();

  const { noteId, isOpenDeleteNoteModal, closeDeleteNoteModalClick, onDeleteButtonClick } = NotesForm;

  const { hideDeleteButton } = useSelector(userFeatureFlagsSelector, areEqual);

  const shouldHideDeleteButton = useMemo(() => hideDeleteButton, [hideDeleteButton]);

  return (
    <NotesFormContext.Provider value={{ ...NotesForm, ...NotesSearch }}>
      {children}
      {!shouldHideDeleteButton && (
        <DeleteNoteModal
          id={noteId}
          isOpen={isOpenDeleteNoteModal}
          noteType="Photo"
          closeDeleteNoteModalClick={closeDeleteNoteModalClick}
          onDeleteButtonClick={onDeleteButtonClick}
        />
      )}
    </NotesFormContext.Provider>
  );
}

const NotesProviderMemo = memo(NotesProvider, areEqual);

export { NotesProviderMemo as NotesProvider };
