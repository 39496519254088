import React, { memo, useCallback, useEffect } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Modal } from 'Components/Modal';

import { useDispatch, useSelector } from 'react-redux';
import { deleteCompanyContracts } from 'Containers/ContractForm/actions';
import { DarkPurpleButton, DeleteButton } from 'Components/Button';

import { toastSelector } from 'Containers/Core/selectors';
import { contractsDeletedSelector } from 'Containers/ContractForm/selectors';
import classes from './DeleteContractModal.module.css';

interface Props {
  isOpenDeleteContract: boolean;
  contractId: number;
  onClickCloseDeleteContract: (e?: any) => void;
}

const DeleteContractModal = ({ isOpenDeleteContract, onClickCloseDeleteContract, contractId }: Props) => {
  const dispatch = useDispatch();

  // Selector
  const { show } = useSelector(toastSelector, areEqual);
  const deletedContract = useSelector(contractsDeletedSelector, areEqual);

  const onDeleteContractClick = useCallback((contractId: any) => {
    dispatch(deleteCompanyContracts(contractId));
  }, []);

  useEffect(() => {
    if (show) {
      onClickCloseDeleteContract();
    }
  }, [show]);

  useEffect(() => {
    if (deletedContract) {
      onClickCloseDeleteContract();
    }
  }, [deletedContract]);

  return (
    <Modal
      id="delete-contract-modal"
      // classes={classes}
      title="Delete Contract Form"
      isOpen={isOpenDeleteContract}
      modalHeader
      leftHeaderIcon="projects"
      dataBsBackdrop="static"
      dataBsKeyboard="false"
      modalCloseClick={onClickCloseDeleteContract}
    >
      <div className={classes.text}>Are You Sure You Want to Delete this Form?</div>
      <div className={classes.buttonWrapper}>
        <DeleteButton className={classes.formTypeButton} onClick={() => onDeleteContractClick(contractId)}>
          Delete
        </DeleteButton>
        <DarkPurpleButton className={classes.formTypeButton} onClick={onClickCloseDeleteContract}>
          Cancel
        </DarkPurpleButton>
      </div>
    </Modal>
  );
};

DeleteContractModal.defaultProps = {};

const DeleteContractModalMemo = memo(DeleteContractModal, areEqual);

export { DeleteContractModalMemo as DeleteContractModal };
