import React, { ChangeEvent, memo, useCallback, useState } from 'react';

import { areEqual } from 'Utils/equalityChecks';
import { TabContent } from 'Components/Tabs';
import { PurpleButton } from 'Components/Button';
import { readSheetNames } from 'read-excel-file';
import { width } from 'Utils/screen';
import { ErrorMessage, RocketScopeDataGrid } from 'Components/RocketScope';
import { SpinnerBlock } from 'Components/SpinnerBlock';
import { fetchScopeData, replaceScope, setHasFileUpload } from 'Containers/RocketScope/actions';
import { useDispatch, useSelector } from 'react-redux';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { ScopeData } from 'Containers/RocketScope/types';
import { filterScopeData, parseExcelData } from 'Containers/RocketScope/helpers';

import classes from './rocketScopeUploadTab.module.css';

function RocketScopeUploadTabContainer() {
  const dispatch = useDispatch();

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const [selectedFileName, setSelectedFileName] = useState('');
  const [fileSelectError, setFileSelectError] = useState('');
  const [scopeData, setScopeData] = useState<ScopeData[][]>([]);
  const [sheetNames, setSheetNames] = useState<string[]>([]);
  const [currentSheet, setCurrentSheet] = useState(0);
  const [loading, setLoading] = useState(false);

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

  const completeUpload = () => {
    setLoading(false);
    hasFileUpload(false);
    setSheetNames([]);
    setScopeData([]);
    setSelectedFileName('');
    dispatch(fetchScopeData(firstCompanyId));
    hiddenFileInput.current.value = '';
  };

  const uploadScope = useCallback((e: any) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  }, []);

  const replaceScopeClick = useCallback(
    (e: any) => {
      e.preventDefault();
      setLoading(true);
      new Promise<void>((resolve, reject) => {
        dispatch(replaceScope(firstCompanyId, filterScopeData(scopeData), sheetNames, resolve, reject));
        setFileSelectError('');
        setHasFileUpload(false);
      })
        .then(() => {
          completeUpload();
        })
        .catch((error) => {
          setLoading(false);
          setFileSelectError(error);
          setSelectedFileName('');
          hiddenFileInput.current.value = '';
        });
    },
    [sheetNames, scopeData, firstCompanyId]
  );

  const hasFileUpload = useCallback((value: boolean): void => {
    dispatch(setHasFileUpload(value));
  }, []);

  const onUploadChange = useCallback(
    (e: ChangeEvent) => {
      const acceptedFiles = (e.target as HTMLInputElement).files;
      const file = acceptedFiles[0];

      if (file) {
        const fr = new FileReader();

        setSelectedFileName(file.name);

        fr.onload = async (e) => {
          const arrayBuffer = e.target.result as ArrayBuffer;
          let importSheetNames: string[];
          try {
            importSheetNames = await readSheetNames(arrayBuffer);
          } catch (error) {
            importSheetNames = [];
          }
          if (importSheetNames.length === 0) {
            setFileSelectError(
              `Invalid Excel file: ${file.name}. Please upload a valid Excel file with sheets based on the "RocketScope Template.xslx".`
            );
          } else {
            parseExcelData(
              arrayBuffer,
              importSheetNames,
              selectedFileName,
              setFileSelectError,
              setSheetNames,
              setCurrentSheet,
              setScopeData,
              hasFileUpload
            );
          }
        };
        fr.readAsArrayBuffer(file);
      }
    },
    [selectedFileName]
  );

  return (
    <TabContent key="tab-content-rocketscope-upload" id="rocketscopeupload" className="position-relative">
      <div className={classes.sectionContainer}>
        <div className={classes.uploadButtonContainer}>
          <PurpleButton className={classes.uploadButton} onClick={uploadScope}>
            Choose File
          </PurpleButton>
          <input
            className={classes.upload}
            ref={hiddenFileInput}
            onChange={onUploadChange}
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
          />
          <span className={classes.fileName}>{selectedFileName || 'No file chosen'}</span>
          <PurpleButton className={classes.uploadButton} onClick={replaceScopeClick} disabled={sheetNames.length === 0}>
            Upload Scopes
          </PurpleButton>
        </div>
        <ErrorMessage message={fileSelectError} />
        {sheetNames.length > 0 ? (
          <RocketScopeDataGrid
            sheetNames={sheetNames}
            scopeData={scopeData}
            currentSheet={currentSheet}
            setCurrentSheet={setCurrentSheet}
            selectedRow={null}
            width={width}
          />
        ) : null}
      </div>
      <div className={`${classes.spinnerContainer} ${loading ? '' : classes.hidden} `}>
        <SpinnerBlock fetching={loading} />
      </div>
    </TabContent>
  );
}

const RocketScopeUploadTabContainerMemo = memo(RocketScopeUploadTabContainer, areEqual);

export { RocketScopeUploadTabContainerMemo as RocketScopeUploadTabContainer };
