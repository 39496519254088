import React, { memo, useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { useTimeTrackingFunctions } from 'Context/TimeTracking';
import { setUserTimeCardsForTimeRange } from 'Containers/TimeTracking/actions';
import { WeekClockInTotals } from 'Containers/TimeTracking/types';

import { OverviewTab } from 'Components/TimeTracking';
import { Duration, startOfWeek, format, eachDayOfInterval } from 'date-fns';

import { useUser } from 'Context/User';

import { filterCardsByDateRange, calculateTotalClockDurationClamped } from 'Containers/TimeTracking/helpers';
import { addDurations } from 'Utils/helpers';

const OverviewTabContainer = () => {
  const dispatch = useDispatch();

  const mounted = useRef(true);

  const { todayAllCards, todayTotalTime }: any = useTimeTrackingFunctions();
  const { id: userId } = useUser();

  const [thisWeekTotalTime, setThisWeekTotalTime] = useState({});
  const [thisWeekTimesByDay, setThisWeekTimesByDay] = useState([]);
  const [initialFetch, setInitialFetch] = useState(true);

  const getWeeklyTimeCardInfo = useCallback(async () => {
    const weekStart = startOfWeek(Date.now());
    const rangeStart = format(weekStart, 'y-L-d');
    const rangeEnd = format(Date.now(), 'y-L-d');
    const weeklyTimeCards: any = await dispatch(setUserTimeCardsForTimeRange(userId, rangeStart, rangeEnd));

    const eachDayOfWeek = eachDayOfInterval({
      start: weekStart,
      end: Date.now(),
    });
    // console.log(eachDayOfWeek);
    const thisWeekClockedInTimes: WeekClockInTotals[] = [];
    eachDayOfWeek.forEach((day: Date) => {
      // console.log('day', day);
      const cardsForDay = filterCardsByDateRange(weeklyTimeCards, day, day);
      // console.log('cardsForDay', cardsForDay);
      const durationForDay = calculateTotalClockDurationClamped(cardsForDay, day, day);
      // console.log('duration', durationForDay);

      thisWeekClockedInTimes.push({ day, duration: durationForDay });
    });
    setThisWeekTimesByDay(thisWeekClockedInTimes);

    // calculate total weekly duration by adding up the daily durations
    const weekTotalTime = thisWeekClockedInTimes.reduce(
      (accumulatedDuration: Duration, day) => addDurations(accumulatedDuration, day.duration),
      { hours: 0, minutes: 0, seconds: 0 }
    );

    setThisWeekTotalTime(weekTotalTime);
  }, [mounted]);

  // get weekly timecard info on load
  useEffect(() => {
    if (initialFetch) {
      mounted.current = true;
      (async function fetchData() {
        await getWeeklyTimeCardInfo();
        setInitialFetch(false);
      })();
    }

    return () => {
      if (mounted && initialFetch) {
        mounted.current = false;
      }
    };
  }, [initialFetch]);

  return (
    <OverviewTab
      dayTimeCards={todayAllCards}
      dayTotalTime={todayTotalTime}
      weekTotalTime={thisWeekTotalTime}
      weekDailyTimes={thisWeekTimesByDay}
    />
  );
};

const OverviewTabContainerMemo = memo(OverviewTabContainer, areEqual);

export { OverviewTabContainerMemo as OverviewTab };
