import axios from 'axios';
import { UserModel } from 'Containers/User/Models/UserModel';
import { addressCreateWithAlias } from 'Containers/Address/actions';
import { FETCHING_ST_PROJECTS } from 'Containers/Projects/actions';

import { Api } from './api';

type projectMetaModel = {
  current_page: number;
  from: number;
  last_page: number;
  links: any[];
  path: string;
  per_page: number;
  to: number;
  total: number;
};

type projectLinkModel = {
  first: string;
  last: string;
  next: string | null;
  prev: string | null;
};

type projectResponseModel = {
  data: any[];
  links: projectLinkModel;
  meta: projectMetaModel;
};

async function getAllProjects(next) {
  const urlSplit = next.split('api/');
  let allProjects;
  const projectResponse = await Api.get(urlSplit[urlSplit.length - 1]);
  if (projectResponse.data.links.next) {
    const nextPage = await getAllProjects(projectResponse.data.links.next);
    allProjects = [...projectResponse.data.data, ...nextPage];
  } else {
    allProjects = [...projectResponse.data.data];
  }
  return allProjects;
}

export default async function getSTJobs(user: UserModel, dispatch) {
  try {
    dispatch({
      type: FETCHING_ST_PROJECTS,
      payload: true,
    });

    // Get all RocketPlan projects
    const myProjectsResponse = await Api.get(`users/${user.id}/projects`);
    // Assuming the user will only belong to a single company
    const companyProjectsResponse = await Api.get(`companies/${user.companies[0].id}/projects`);

    let myProjects: projectResponseModel;
    let companyProjects: projectResponseModel;

    if (myProjectsResponse?.data?.data) myProjects = myProjectsResponse.data;
    if (companyProjectsResponse?.data?.data) companyProjects = companyProjectsResponse.data;

    const allMyProjects = [];
    const allCompanyProjects = [];

    if (myProjects.links.next) {
      const projects = await getAllProjects(myProjects.links.next);
      allMyProjects.push(...projects);
    }

    if (companyProjects.links.next) {
      const projects = await getAllProjects(companyProjects.links.next);
      allCompanyProjects.push(...projects);
    }

    allMyProjects.push(...myProjects.data);
    allCompanyProjects.push(...companyProjects.data);

    const joinedProjects = [...allMyProjects, ...allCompanyProjects];

    // Get all ST projects for the technician
    const date = user.st_technician_date ? user.st_technician_date : '2023-09-20';
    const stJobs = await axios.get(
      `${import.meta.env.VITE_APP_ST_API}jobs?tenantId=${user.st_tenant_id}&technicianId=${user.st_technician_id}&date=${date}`,
      {
        headers: {
          token: localStorage.getItem('st-token'),
          'access-key': import.meta.env.VITE_APP_LAMBDA_ACCESS_KEY,
        },
      }
    );

    if (stJobs.status !== 200) throw new Error('Something went wrong');

    localStorage.setItem('st-token', stJobs.data.token);

    // create array of jobs not already created based off alias (ST Job ID)
    const filteredJobs = stJobs.data.data.data.filter((job) => {
      // check to see if there are any jobs from ST that are not part of the current RP projects
      const foundProject = joinedProjects.some((project) => project.alias === job.id?.toString());
      return !foundProject;
    });

    convertJobsToProjects(filteredJobs, user, dispatch);
  } catch (err) {
    // handle error
  }
}

async function convertJobsToProjects(jobs: any[], user: UserModel, dispatch) {
  try {
    // Using Array as a queue for job ids to be reassigned to the project as an alias
    const jobQueue = [];

    // Getting all the locations of the current ST jobs
    const locationIds = jobs.map((job) => {
      jobQueue.push({ id: job.jobNumber, locationId: job.locationId, summary: job.summary });
      return job.locationId;
    });

    /*
      *** EXAMPLE OF jobQueue ***
      jobQueue = [
        {id: 1, locationId: 1}
        {id: 2, locationId: 1}
        {id: 3, locationId: 2}
      ]
    */

    if (locationIds.length) {
      const locationsData = await axios.get(
        `${import.meta.env.VITE_APP_ST_API}locations?tenantId=${user.st_tenant_id}&locationId=${locationIds.join()}`,
        {
          headers: {
            token: localStorage.getItem('st-token'),
            'access-key': import.meta.env.VITE_APP_LAMBDA_ACCESS_KEY,
          },
        }
      );
      const locations = locationsData.data.data.data;
      /*
        *** EXAMPLE OF locations ***
        locations = [
          {id: 1, ...location details}
          {id: 2, ...location details}
        ]
      */

      // Create an address / project with an alias
      jobQueue.forEach((job, idx) => {
        const foundLocationIndex = locations.findIndex((location) => job.locationId === location.id);
        if (foundLocationIndex !== -1) {
          dispatch(
            addressCreateWithAlias(
              {
                google_places_id: null,
                country: locations[foundLocationIndex].address.country,
                state: locations[foundLocationIndex].address.state,
                city: locations[foundLocationIndex].address.city,
                zip: locations[foundLocationIndex].address.zip,
                address: locations[foundLocationIndex].address.street,
                address_2: locations[foundLocationIndex].address.unit,
                latitude: locations[foundLocationIndex].address.latitude.toString(),
                longitude: locations[foundLocationIndex].address.longitude.toString(),
              },
              user.companies[0]?.id,
              '1',
              job.id,
              user,
              locations.length - 1,
              idx,
              job.summary
            )
          );
        }
      });
    }

    if (!locationIds.length) dispatch({ type: FETCHING_ST_PROJECTS, payload: false });
  } catch (err) {
    dispatch({ type: FETCHING_ST_PROJECTS, payload: false });
    // handle err
  }
}
