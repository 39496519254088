import React, { memo } from "react";
import { areEqual } from "Utils/equalityChecks";

import { Icon } from "Components/Icons";
import { PurpleButton } from "Components/Button";

interface Props {
  className?: string;
  onClick: (e: any) => void;
}

const CreateProjectButton = ({ className, onClick }: Props) => (
  <PurpleButton className={className || ""} onClick={onClick}>
    Create Project <Icon type="plus" />
  </PurpleButton>
);

CreateProjectButton.defaultProps = {
  className: null,
};

const CreateProjectButtonButtonMemo = memo(CreateProjectButton, areEqual);

export { CreateProjectButtonButtonMemo as CreateProjectButton };
