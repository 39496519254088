import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';
import { useUser } from 'Context/User';
import { UserModel } from 'Containers/User/Models/UserModel';
import { useOnScreen } from 'Hooks/useOnScreen';
import { convertPhoneNumber, formatPhoneInternationalWithCountryCode } from 'Utils/helpers';
import { listCompanyContacts } from 'Containers/People/PeopleTabs/ContactsTab/actions';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { contactsMetadataSelector } from 'Containers/People/PeopleTabs/ContactsTab/selectors';

import { ContactCard } from 'Components/People/PeopleTabs/ContactsTab/ContactCard/ContactCard';

interface Props {
  contact: any;
  recentlyAdded: string;
  isLastContact: boolean;
  onClickContactCard: (e: any) => void;
}

function ContactCardContainer({ contact, recentlyAdded, isLastContact, onClickContactCard }: Props) {
  const dispatch = useDispatch();

  const user: UserModel = useUser();
  const ref = useRef(null);

  const isOnScreen = useOnScreen(ref);

  const [phoneNumber, setPhoneNumber] = useState('');

  const { id, companyName, isCompany, fullName, email, phone, extension } = contact;

  const contactsMetadata = useSelector(contactsMetadataSelector, areEqual);
  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

  // lazy load next page of contacts, passed from the parent
  useEffect(() => {
    if (isOnScreen && isLastContact) {
      if (contactsMetadata.currentPage < contactsMetadata.lastPage) {
        dispatch(listCompanyContacts(firstCompanyId, contactsMetadata.currentPage + 1));
      }
    }
  }, [isOnScreen, contactsMetadata, firstCompanyId]);

  // format phone number
  useEffect(() => {
    if (user?.id) {
      const { companies } = user;
      if (companies.length > 0) {
        const [company] = companies;
        const { country_code: countryCode } = company;
        const phoneFormatted = convertPhoneNumber(countryCode, phone);
        setPhoneNumber(formatPhoneInternationalWithCountryCode(countryCode, phoneFormatted.toString()));
      }
    }
  }, [user, phone]);

  const onClickCard = useCallback(() => {
    if (onClickContactCard) onClickContactCard(contact);
  }, [contact]);

  return (
    <ContactCard
      ref={ref}
      id={id.toString()}
      recentlyAdded={recentlyAdded}
      companyName={companyName}
      isCompany={isCompany}
      fullName={fullName}
      email={email}
      phone={phoneNumber}
      extension={extension}
      selectCardClick={onClickCard}
    />
  );
}

const ContactCardContainerMemo = memo(ContactCardContainer, areEqual);

export { ContactCardContainerMemo as ContactCard };
