import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useSelector } from 'react-redux';
import { userFeatureFlagsSelector } from 'Containers/User/selector';

import { Icon } from 'Components/Icons';

import classes from './damagedMaterialsPlaceholder.module.css';

const DamagedMaterialsPlaceholder = () => {
  const { rocketScope } = useSelector(userFeatureFlagsSelector, areEqual);
  return (
    <div className={classes.damagedMaterialsPlaceholder}>
      <p>{rocketScope ? 'Choose an activity or action to be performed' : 'Select a damage type to get started'}</p>
      <Icon type="rocketemblemtwo" className={classes.icon} />
    </div>
  );
};

const DamagedMaterialsPlaceholderMemo = memo(DamagedMaterialsPlaceholder, areEqual);

export { DamagedMaterialsPlaceholderMemo as DamagedMaterialsPlaceholder };
