import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import classes from './dayTotalEntry.module.css';

interface Props {
  address: string;
  duration: string;
  range: string;
}

const DayTotalEntry = ({ address, duration, range }: Props) => (
  <div className={classes.container}>
    <div className={classes.address}>{address ?? '[deleted project]'}</div>
    <div className={classes.rightContainer}>
      <div className={classes.duration}>{duration}</div>
      <div className={classes.range}>{range}</div>
    </div>
  </div>
);

const DayTotalEntryMemo = memo(DayTotalEntry, areEqual);

export { DayTotalEntryMemo as DayTotalEntry };
