import React, { memo, useCallback, useEffect, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useDamagedMaterialFunctions } from 'Context/RocketScan';

import { ScopeOfWorkCategoryItem } from 'Containers/RocketScan';
import { ScopeData } from 'Containers/RocketScope/types';

interface IScopeCategory {
  id: number;
  name: string;
  scopes: ScopeData[];
}

let selectedIndex = 1;

const ScopeOfWorkListContainer = () => {
  const { scopeSheets, selectedScopeSheet }: any = useDamagedMaterialFunctions();

  const [scopeCategories, setScopeCategories] = useState<IScopeCategory[]>([]);
  const [selectedKey, setSelectedKey] = useState<number | null>(null);

  useEffect(() => {
    if (selectedScopeSheet) {
      const scopeItems = scopeSheets.find((sheet: any) => sheet.id === selectedScopeSheet)?.data || [];
      const categoryAndScopes = {};
      scopeItems.forEach((scopeItem: ScopeData) => {
        if (!categoryAndScopes[scopeItem.category]) {
          categoryAndScopes[scopeItem.category] = [];
        }
        categoryAndScopes[scopeItem.category].push(scopeItem);
      });
      setScopeCategories([]);
      Object.keys(categoryAndScopes).forEach((category: string) => {
        setScopeCategories((prevScopeCategories) => {
          // noinspection UnnecessaryLocalVariableJS - eslint compains about no-return-inline if we don't assing to newScopeCategories.
          const newScopeCategories = [
            ...prevScopeCategories,
            { id: (selectedIndex += 1), name: category, scopes: categoryAndScopes[category] },
          ];
          return newScopeCategories;
        });
      });
    }
  }, [selectedScopeSheet, scopeSheets]);

  const selectedItemClicked = useCallback((key: number) => {
    setSelectedKey((previousSelectedKey) => (previousSelectedKey !== key ? key : null));
  }, []);

  return (
    <div>
      {scopeCategories.length > 0 &&
        scopeCategories.map((scopeCategory: IScopeCategory) => (
          <ScopeOfWorkCategoryItem
            key={scopeCategory.id}
            category={scopeCategory.name}
            scopes={scopeCategory.scopes}
            isSelected={selectedKey === scopeCategory.id}
            onSelected={() => selectedItemClicked(scopeCategory.id)}
          />
        ))}
    </div>
  );
};
const ScopeOfWorkListMemo = memo(ScopeOfWorkListContainer, areEqual);

export { ScopeOfWorkListMemo as ScopeOfWorkList };
