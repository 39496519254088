import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { ValidateBackGround } from 'Components/Validation';
import { Label } from 'Components/Label';
import { TextBox } from 'Components/TextBox';
import { UploadAvatar } from 'Containers/User';
import { UploadAvatarType } from 'Containers/User/types';

import formClasses from 'Themes/form/form.module.css';
import classes from './editSecondaryCompanyInfo.module.css';

interface Props {
  secondaryCompanyName: string;
  formErrors: any;
  onChangeSecondaryCompanyName: (e: any) => void;
}

const EditSecondaryCompanyInfo = ({ secondaryCompanyName, formErrors, onChangeSecondaryCompanyName }: Props) => (
  <div className={classes.container}>
    <p className={classes.companyLogo}>Company Logo</p>
    <UploadAvatar profile={UploadAvatarType.SecondaryCompany} />
    <div className={classes.inputFields}>
      <ValidateBackGround isValid={!formErrors?.companyName.length} className={formClasses.inputContainer}>
        <Label ariaLabel="Company Name" className={formClasses.label}>
          Company Name
        </Label>
        <TextBox
          value={secondaryCompanyName}
          type="text"
          name="company name"
          className={`mb-0 pb-0 ${formClasses.validateField} ${
            formErrors?.companyName.length ? formClasses.invalidField : formClasses.validField
          } ${formErrors?.companyName.length ? 'is-invalid' : ''}`}
          placeholder="Company Name"
          ariaLabel="Please enter your company name"
          onChange={onChangeSecondaryCompanyName}
          autoComplete="off"
        />
        <div
          className={`${formClasses.invalidFieldFeedback} invalid-feedback ${
            formErrors?.companyName.length ? 'd-block' : ''
          }`}
        >
          {formErrors?.companyName?.[0]}
        </div>
      </ValidateBackGround>
    </div>
  </div>
);

const EditSecondaryCompanyInfoMemo = memo(EditSecondaryCompanyInfo, areEqual);

export { EditSecondaryCompanyInfoMemo as EditSecondaryCompanyInfo };
