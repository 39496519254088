import { Pagination } from 'Components/Pagination';
import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import classes from './dashboardPagination.module.css';

interface Props {
  initialPage: number;
  pageCount: number;
  onPageChange: (e: any) => void;
}

// for pagination on main dashboard pages
// should not be used in modals
function DashboardPagination({ initialPage, pageCount, onPageChange }: Props) {
  return (
    <div className={classes.dashboardPaginationBase}>
      <Pagination className="project" initialPage={initialPage} pageCount={pageCount} onPageChange={onPageChange} />
    </div>
  );
}

const DashboardPaginationMemo = memo(DashboardPagination, areEqual);

export { DashboardPaginationMemo as DashboardPagination };
