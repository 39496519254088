import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Icon } from 'Components/Icons';
import { PillButton } from 'Components/PillButton';
import { PillBadge } from 'Components/PillBadge';
import { TimesheetFilterList } from 'Components/TimeTracking';
import { SearchBox } from 'Components/SearchBox';
import { PurpleButton } from 'Components/Button';

import DatePicker from 'react-datepicker';

import { formatDate } from 'Utils/helpers';

import classes from './timesheetFilters.module.css';

import 'react-datepicker/dist/react-datepicker-min.module.css';

interface Props {
  startDate: any;
  endDate: any;
  isEmployeeFilterOpen: boolean;
  isAddressFilterOpen: boolean;
  isProjectNumFilterOpen: boolean;
  isDateRangeFilterOpen: boolean;
  // employee search
  employeeFilters: any[];
  selectedEmployeeFilters: any[];
  employeeSearchRef: any;
  employeeSearchValue: string;
  savedEmployeeFiltersCount: number;
  // address search
  addressFilters: any[];
  selectedAddressFilters: any[];
  addressSearchRef: any;
  addressSearchValue: string;
  savedAddressFiltersCount: number;
  // project number search
  projectNumberFilters: any[];
  selectedProjectNumFilters: any[];
  projectNumSearchRef: any;
  projectNumSearchValue: string;
  savedProjectNumbersCount: number;
  // date filter
  dateFilterApplied: boolean;
  // employee filter functions
  onEmployeeFilterClick: (e: any) => void;
  onEmployeeFilterListItemClick: (e: any) => void;
  onChangeEmployeeSearchValue: (e: any) => void;
  onClickEmployeeSearchClearButton: (e: any) => void;
  // address filter functions
  onAddressFilterClick: (e: any) => void;
  onAddressFilterListItemClick: (e: any) => void;
  onChangeAddressSearchValue: (e: any) => void;
  onClickAddressSearchClearButton: (e: any) => void;
  // project number filter functions
  onProjectNumFilterClick: (e: any) => void;
  onProjectNumFilterListItemClick: (e: any) => void;
  onChangeProjectNumSearchValue: (e: any) => void;
  onClickProjectNumClearButton: (e: any) => void;
  // date filter functions
  onDateRangeFilterClick: (e: any) => void;
  onChangeDate: (e: any) => void;
  onClearDateFilterButtonClick: (e: any) => void;
  // apply filter function
  onApplyFilterButtonClick: (e: any) => void;
  onClearFilterButtonClick: (e: any) => void;
}

function TimesheetFilters({
  startDate,
  endDate,
  isEmployeeFilterOpen,
  isAddressFilterOpen,
  isProjectNumFilterOpen,
  isDateRangeFilterOpen,
  // employee search
  employeeFilters,
  selectedEmployeeFilters,
  employeeSearchRef,
  employeeSearchValue,
  savedEmployeeFiltersCount,
  // address search
  addressFilters,
  selectedAddressFilters,
  addressSearchRef,
  addressSearchValue,
  savedAddressFiltersCount,
  // project number search
  projectNumberFilters,
  selectedProjectNumFilters,
  projectNumSearchRef,
  projectNumSearchValue,
  savedProjectNumbersCount,
  // date filter
  dateFilterApplied,
  // employee search functions
  onEmployeeFilterClick,
  onEmployeeFilterListItemClick,
  onChangeEmployeeSearchValue,
  onClickEmployeeSearchClearButton,
  // address search functions
  onAddressFilterClick,
  onAddressFilterListItemClick,
  onChangeAddressSearchValue,
  onClickAddressSearchClearButton,
  // project number search functions
  onProjectNumFilterClick,
  onProjectNumFilterListItemClick,
  onChangeProjectNumSearchValue,
  onClickProjectNumClearButton,
  // date filter functions
  onDateRangeFilterClick,
  onChangeDate,
  onClearDateFilterButtonClick,
  // apply filter function
  onApplyFilterButtonClick,
  onClearFilterButtonClick,
}: Props) {
  return (
    <div className={classes.container}>
      Filters
      <div className={classes.filterRow}>
        <PillButton className={classes.filterButton} onClick={onEmployeeFilterClick}>
          Employee Name
          {savedEmployeeFiltersCount > 0 && (
            <PillBadge text={savedEmployeeFiltersCount.toString()} className={classes.badge} />
          )}
          <Icon className={classes.sortIcon} type={isEmployeeFilterOpen ? 'caretUp' : 'caretdown'} />
        </PillButton>
        <PillButton className={classes.filterButton} onClick={onAddressFilterClick}>
          Project Address
          {savedAddressFiltersCount > 0 && (
            <PillBadge text={savedAddressFiltersCount.toString()} className={classes.badge} />
          )}
          <Icon className={classes.sortIcon} type={isAddressFilterOpen ? 'caretUp' : 'caretdown'} />
        </PillButton>
        <PillButton className={classes.filterButton} onClick={onProjectNumFilterClick}>
          Project Number
          {savedProjectNumbersCount > 0 && (
            <PillBadge text={savedProjectNumbersCount.toString()} className={classes.badge} />
          )}
          <Icon className={classes.sortIcon} type={isProjectNumFilterOpen ? 'caretUp' : 'caretdown'} />
        </PillButton>
        <PillButton className={classes.filterButton} onClick={onDateRangeFilterClick}>
          Date Range
          {dateFilterApplied && <PillBadge text="✓" className={classes.badge} />}
          <Icon className={classes.sortIcon} type={isDateRangeFilterOpen ? 'caretUp' : 'caretdown'} />
        </PillButton>
      </div>
      {isEmployeeFilterOpen && (
        <div className={classes.filterOptionContainer}>
          <SearchBox
            ref={employeeSearchRef}
            id="employee-search"
            name="search"
            ariaLabel="Search a employee"
            value={employeeSearchValue}
            onChangeValue={onChangeEmployeeSearchValue}
            onClickClearButton={onClickEmployeeSearchClearButton}
          />
          <TimesheetFilterList
            items={employeeFilters}
            type="employee"
            onClickItem={onEmployeeFilterListItemClick}
            selectedItems={selectedEmployeeFilters}
            onApplyFilterButtonClicked={onApplyFilterButtonClick}
            onClearFilterButtonClicked={onClearFilterButtonClick}
          />
        </div>
      )}
      {isAddressFilterOpen && (
        <div className={`${classes.filterOptionContainer} ${classes.secondFilterOption}`}>
          <SearchBox
            ref={addressSearchRef}
            id="address-search"
            name="search"
            ariaLabel="Search for an address"
            value={addressSearchValue}
            onChangeValue={onChangeAddressSearchValue}
            onClickClearButton={onClickAddressSearchClearButton}
          />
          <TimesheetFilterList
            items={addressFilters}
            type="address"
            onClickItem={onAddressFilterListItemClick}
            selectedItems={selectedAddressFilters}
            onApplyFilterButtonClicked={onApplyFilterButtonClick}
            onClearFilterButtonClicked={onClearFilterButtonClick}
          />
        </div>
      )}
      {isProjectNumFilterOpen && (
        <div className={`${classes.filterOptionContainer} ${classes.thirdFilterOption}`}>
          <SearchBox
            ref={projectNumSearchRef}
            id="projectnum-search"
            name="search"
            ariaLabel="Search for a project number"
            value={projectNumSearchValue}
            onChangeValue={onChangeProjectNumSearchValue}
            onClickClearButton={onClickProjectNumClearButton}
          />
          <TimesheetFilterList
            items={projectNumberFilters}
            type="projectNumber"
            onClickItem={onProjectNumFilterListItemClick}
            selectedItems={selectedProjectNumFilters}
            onApplyFilterButtonClicked={onApplyFilterButtonClick}
            onClearFilterButtonClicked={onClearFilterButtonClick}
          />
        </div>
      )}
      {isDateRangeFilterOpen && (
        <div className={`${classes.dateFilterOptionContainer} ${classes.fourthFilterOption}`}>
          <div className={classes.dateFilterSelectionContainer}>
            <div>
              Start:
              {startDate ? formatDate(startDate, 'PP') : ''}
            </div>
            <br />
            <div>
              End:
              {endDate ? formatDate(endDate, 'PP') : ''}
            </div>
          </div>

          <div className={classes.datePickerContainer}>
            <DatePicker
              todayButton="Today"
              selected={startDate}
              onChange={onChangeDate}
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              selectsRange
              inline
              isClearable
            />
          </div>

          <div className={classes.dateFilterButtonContainer}>
            <PurpleButton onClick={onApplyFilterButtonClick} id="date">
              Apply Filter
            </PurpleButton>
            <br />
            <PurpleButton onClick={onClearDateFilterButtonClick} id="date">
              Clear
            </PurpleButton>
          </div>
        </div>
      )}
    </div>
  );
}

const TimesheetFiltersMemo = memo(TimesheetFilters, areEqual);

export { TimesheetFiltersMemo as TimesheetFilters };
