import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { DateTimeEdit } from 'Components/ProjectData';

import { useLossDataFunctions } from 'Context/LossData';

function CrewDispatchedEditContainer() {
  const { selectedCrewDispatched, setSelectedCrewDispatched }: any = useLossDataFunctions();

  return (
    <DateTimeEdit
      dateTime={selectedCrewDispatched}
      onDateTimeChange={setSelectedCrewDispatched}
      labelText="Crew Dispatched (optional)"
    />
  );
}

const CrewDispatchedEditContainerMemo = memo(CrewDispatchedEditContainer, areEqual);

export { CrewDispatchedEditContainerMemo as CrewDispatchedEdit };
