import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';
import { PaymentList, PaymentFilters } from 'Containers/RocketPay';

import { TabContent } from 'Components/Tabs';
import classes from './rocketPayPending.module.css';

interface Props {
  payments: any;
  sortBy?: string;
  onClickSort?: (sort: string) => void;
  initialPage?: number;
  pageCount?: number;
  onPageChange?: (e: any) => void;
  isLoading?: boolean;
}

function RocketPayPending({ payments, sortBy, onClickSort, initialPage, pageCount, isLoading, onPageChange }: Props) {
  return (
    <TabContent key="tab-content-rocketpay-pending" id="rocketpaypending" className="position-relative">
      <div className={classes.rocketPayContent}>
        <div className={`d-flex justify-content-between align-items-center ${classes.contentHeader}`}>
          <h3>Pending Payments</h3>
        </div>
        <PaymentFilters />
        <PaymentList
          payments={payments}
          onClickSort={onClickSort}
          sortBy={sortBy}
          initialPage={initialPage}
          pageCount={pageCount}
          onPageChange={onPageChange}
          isLoading={isLoading}
        />
      </div>
    </TabContent>
  );
}

const RocketPayPendingMemo = memo(RocketPayPending, areEqual);

export { RocketPayPendingMemo as RocketPayPending };
