import React, { memo, useCallback, useEffect, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { DryingReportForm, GenerateReportWrapper } from 'Components/ReportsAndDocuments';
import { useDispatch, useSelector } from 'react-redux';
import { createReport, setReportCreated } from 'Containers/ReportsAndDocuments/Reports/actions';
import { projectSelector, projectAliasSelector } from 'Containers/RocketScan/selectors';
import {
  reportCreatedSelector,
  reportCreatingSelector,
  selectedReportLocationsSelector,
  selectedReportRoomsSelector,
  titleErrorSelector,
} from 'Containers/ReportsAndDocuments/Reports/selectors';
import { useHistory } from 'react-router-dom';

import { useUser } from 'Context/User';
import { UserModel } from 'Containers/User/Models/UserModel';
import { userFeatureFlagsSelector } from 'Containers/User/selector';

function DryingReportContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user: UserModel = useUser();

  const project = useSelector(projectSelector, areEqual);
  const fetching = useSelector(reportCreatingSelector, areEqual);
  const reportCreated = useSelector(reportCreatedSelector, areEqual);
  const selectedLocations = useSelector(selectedReportLocationsSelector, areEqual);
  const selectedRooms = useSelector(selectedReportRoomsSelector, areEqual);
  const alias = useSelector(projectAliasSelector, areEqual);

  const { companies }: any = user;
  const [company] = companies;
  const {
    name: companyName,
    logo_url: logoUrl,
    secondary_name: secondaryCompanyName,
    secondary_logo_url: secondaryLogoUrl,
  } = company;

  const { secondaryCompany: showSecondaryCompanyOption } = useSelector(userFeatureFlagsSelector, areEqual);

  const [name, setName] = useState('');
  const [useSecondaryCompany, setUseSecondaryCompany] = useState('no');
  const [showExternal, setShowExternal] = useState('yes');
  const [showInternal, setShowInternal] = useState('yes');
  const [showMaterialMoisture, setShowMaterialMoisture] = useState('yes');
  const [showEquipment, setShowEquipment] = useState('yes');
  const [showPhotos, setShowPhotos] = useState('yes');
  const [unitType, setUnitType] = useState('full_project');

  const errors = {
    title: useSelector(titleErrorSelector, areEqual),
  };

  const onChangeTitle = useCallback(({ target: { value } }: any) => {
    setName(value);
  }, []);

  const onCompanyClick = useCallback(({ currentTarget: { value } }: any) => {
    setUseSecondaryCompany(value);
  }, []);

  const onExternalClick = useCallback(({ currentTarget: { value } }: any) => {
    setShowExternal(value);
  }, []);

  const onInternalClick = useCallback(({ currentTarget: { value } }: any) => {
    setShowInternal(value);
  }, []);

  const onMaterialMoistureClick = useCallback(({ currentTarget: { value } }: any) => {
    setShowMaterialMoisture(value);
  }, []);

  const onEquipmentClick = useCallback(({ currentTarget: { value } }: any) => {
    setShowEquipment(value);
  }, []);

  const onPhotosClick = useCallback(({ currentTarget: { value } }: any) => {
    setShowPhotos(value);
  }, []);

  const onUnitTypeClick = useCallback(({ currentTarget: { value } }: any) => {
    setUnitType(value);
  }, []);

  const onFormSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      if (project?.id) {
        const { id: projectId } = project;

        const settings = JSON.stringify({
          format: 'rocketdry',
          atmospheric_external: showExternal,
          atmospheric_internal: showInternal,
          moisture_logs: showMaterialMoisture,
          equipment_list: showEquipment,
          photos: showPhotos,
          use_secondary_company: useSecondaryCompany,
          locations: unitType === 'full_project' ? [] : selectedLocations,
          rooms: unitType === 'full_project' ? [] : selectedRooms,
        });

        dispatch(createReport(projectId, { name, settings }, alias, user));
      }
    },
    [
      name,
      project,
      unitType,
      selectedLocations,
      selectedRooms,
      showExternal,
      showInternal,
      showMaterialMoisture,
      showEquipment,
      showPhotos,
      useSecondaryCompany,
    ]
  );

  useEffect(() => {
    if (reportCreated) {
      const { id: projectId } = project;

      history.push(`/projects/${projectId}/rocketreports`);
    }

    return () => {
      if (reportCreated) {
        dispatch(setReportCreated(false));
      }
    };
  }, [reportCreated, project]);

  return (
    <GenerateReportWrapper>
      <DryingReportForm
        title={name}
        useSecondaryCompany={useSecondaryCompany}
        companyName={companyName}
        companyLogo={logoUrl}
        secondaryCompanyName={secondaryCompanyName}
        secondaryCompanyLogo={secondaryLogoUrl}
        showExternal={showExternal}
        showInternal={showInternal}
        showMaterialMoisture={showMaterialMoisture}
        showEquipment={showEquipment}
        showPhotos={showPhotos}
        unitType={unitType}
        formErrors={errors}
        fetching={fetching}
        showSecondaryCompanyOption={showSecondaryCompanyOption}
        onChangeTitle={onChangeTitle}
        onCompanyClick={onCompanyClick}
        onExternalClick={onExternalClick}
        onInternalClick={onInternalClick}
        onMaterialMoistureClick={onMaterialMoistureClick}
        onEquipmentClick={onEquipmentClick}
        onPhotosClick={onPhotosClick}
        onUnitTypeClick={onUnitTypeClick}
        onFormSubmit={onFormSubmit}
      />
    </GenerateReportWrapper>
  );
}

const DryingReportContainerMemo = memo(DryingReportContainer, areEqual);

export { DryingReportContainerMemo as DryingReport };
