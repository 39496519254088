import { handleApiRequest } from 'Utils/handleApiRequest';

export const SET_PROJECT_PDFS = 'SET_PROJECT_PDFS';

interface ActionTypes {
  SET_PROJECT_PDFS: any[];
}

interface MessageAction {
  type: keyof ActionTypes;
  payload: any;
}

export type UploadsTypes = MessageAction;

/*
 * NON-API THUNKS
 * */
export const resetProjectPDFs = () => async (dispatch: any) => {
  dispatch({
    type: SET_PROJECT_PDFS,
    payload: [],
  });
};

/*
 * API THUNKS
 * */
/* eslint-disable */
export const listProjectPDFs =
  (projectId: number, sort = '-created_at', page = 1) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.get(`projects/${projectId}/uploads`, {
        params: {
          include: 'creator',
          sort,
          page,
        },
      })
    );

    if (response?.data) {
      dispatch({
        type: SET_PROJECT_PDFS,
        payload: {
          data: response.data,
          meta: response.meta ?? {},
          links: response.links ?? {},
        },
      });
    }
  };

export const uploadPDF =
  (projectId: number, uploadInfo: any, onUploadComplete?: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(dispatch, utils.Api.post(`projects/${projectId}/uploads`, uploadInfo));

    if (onUploadComplete) {
      onUploadComplete();
    }

    if (response?.data) {
      dispatch(listProjectPDFs(projectId));
    }
  };

export const deletePDF =
  (uploadId: number, onDelete?: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(dispatch, utils.Api.delete(`uploads/${uploadId}`));

    if (typeof response === 'string') {
      if (onDelete) {
        onDelete();
      }
    }
  };
