import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { ScopeOfWorkEditModal } from 'Components/RocketScan';
import { useDamagedMaterialFunctions } from 'Context/RocketScan';

const ScopeOfWorkEditModalContainer = () => {
  const { isScopeOfWorkEditModal, onClickScopeEditSaveButton, onClickScopeEditCancelButton }: any =
    useDamagedMaterialFunctions();

  return (
    <ScopeOfWorkEditModal
      isOpen={isScopeOfWorkEditModal}
      onSaveClick={onClickScopeEditSaveButton}
      onCancelClick={onClickScopeEditCancelButton}
    />
  );
};

const ScopeOfWorkEditModalMemo = memo(ScopeOfWorkEditModalContainer, areEqual);

export { ScopeOfWorkEditModalMemo as ScopeOfWorkEditModal };
