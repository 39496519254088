import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { areEqual } from 'Utils/equalityChecks';
import { useUser } from 'Context/User';

import { UserModel } from 'Containers/User/Models/UserModel';
import { userFeatureFlagsSelector } from 'Containers/User/selector';
import { convertPhoneNumber, formatPhoneInternationalWithCountryCode } from 'Utils/helpers';
import { CompanyProfile } from 'Components/Profiles/Account/CompanyProfile';
import {
  updateCompanyDetails,
  createCompanyPhone,
  createCompanyAddress,
  updateCompanyAddress,
  updateCompanyPhone,
  setCompanyUpdated,
  setCompanyAddressUpdated,
  setCompanyAddressCreated,
  setCompanyPhoneUpdated,
  setCompanyPhoneCreated,
} from 'Containers/Company/actions';
import {
  companyPhonesSelector,
  companyUpdatedSelector,
  companyAddressCreatedSelector,
  companyPhoneCreatedSelector,
  companyAddressUpdatedSelector,
  companyPhoneUpdatedSelector,
  companyNameErrorSelector,
  companyPhoneErrorSelector,
  companyWebsiteErrorSelector,
  companyAddressErrorSelector,
  companyAddressSecondErrorSelector,
  companyCountryErrorSelector,
  companyStateErrorSelector,
  companyCityErrorSelector,
  companyCodeErrorSelector,
} from 'Containers/Company/selectors';
import { userDetails } from 'Containers/User/actions';
import { countries } from 'Utils/data';
import { isCompanyAdmin } from 'Utils/roles';

import { getTimeZones } from '@vvo/tzdb';

const isCompanyAddressChanged = (
  savedCompanyAddress,
  enteredAddressPrimary,
  enteredAddressSecond,
  enteredCountry,
  enteredState,
  enteredCity,
  enteredZip
): boolean => {
  if (!savedCompanyAddress) {
    // if any part of the address form is filled out, the address has been changed
    // even if it wouldn't work due to it being partially filled out
    return !!(
      enteredAddressPrimary ||
      enteredAddressSecond ||
      enteredCountry ||
      enteredState ||
      enteredCity ||
      enteredZip
    );
  }

  const {
    address: savedAddress,
    address_2: savedAddress2,
    zip: savedZip,
    city: savedCity,
    state: savedState,
    country: savedCountry,
  }: any = savedCompanyAddress;

  return (
    savedAddress !== enteredAddressPrimary ||
    // second line of address can be null, so need to take care of that case
    (savedAddress2 ? savedAddress2 !== enteredAddressSecond : !!enteredAddressSecond) ||
    savedZip !== enteredZip ||
    savedCity !== enteredCity ||
    savedState !== enteredState ||
    savedCountry !== enteredCountry
  );
};

const isCompanyPhoneChanged = (savedPhones, enteredCountryCode, enteredPhone): boolean => {
  if (savedPhones.length > 0) {
    const [phone] = savedPhones;
    const { value: savedPhone, country_code: savedCountryCode } = phone;

    return (
      savedCountryCode !== enteredCountryCode ||
      formatPhoneInternationalWithCountryCode(savedCountryCode, savedPhone) !== enteredPhone
    );
  }
  return !!(enteredCountryCode || enteredPhone);
};

const isCompanyTimeZoneChanged = (savedTimeZone, selectedTimeZone): boolean => savedTimeZone !== selectedTimeZone;

const CompanyProfileContainer = () => {
  const dispatch = useDispatch();

  const user: UserModel = useUser();
  const { secondaryCompany: showSecondaryCompanyFields } = useSelector(userFeatureFlagsSelector, areEqual);

  const companyPhoneNumbers = useSelector(companyPhonesSelector, areEqual);

  const companyPhoneCreated = useSelector(companyPhoneCreatedSelector, areEqual);
  const companyAddressCreated = useSelector(companyAddressCreatedSelector, areEqual);
  const companyUpdated = useSelector(companyUpdatedSelector, areEqual);
  const companyPhoneUpdated = useSelector(companyPhoneUpdatedSelector, areEqual);
  const companyAddressUpdated = useSelector(companyAddressUpdatedSelector, areEqual);

  const [companyAvatar, setCompanyAvatar] = useState(null);
  const [secondaryCompanyAvatar, setSecondaryCompanyAvatar] = useState(null);
  const [companyIsEditable, setCompanyIsEditable] = useState(false);
  const [secondaryCompanyIsEditable, setSecondaryCompanyIsEditable] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [secondaryCompanyName, setSecondaryCompanyName] = useState('');

  const [companyPhoneId, setCompanyPhoneId] = useState(null);
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');

  const [companyAddressId, setCompanyAddressId] = useState(null);
  const [companyAddress, setCompanyAddress] = useState(undefined);
  const [companyAddressPrimary, setCompanyAddressPrimary] = useState('');
  const [companyAddressSecond, setCompanyAddressSecond] = useState('');
  const [companyCity, setCompanyCity] = useState('');
  const [companyZip, setCompanyZip] = useState('');
  const [companyId, setCompanyId] = useState(null);
  const [companyTimeZone, setCompanyTimeZone] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [countryId, setCountryId] = useState(1);
  const [countryCode, setCountryCode] = useState('');
  const [companyCountryCode, setCompanyCountryCode] = useState(1);
  const [countryFlag, setCountryFlag] = useState('usa');
  const [companyAdmin, setCompanyAdmin] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState('');
  const [timeZoneForBackEnd, setTimeZoneForBackEnd] = useState('');

  // used for checking if
  const [companyPhoneChanged, setCompanyPhoneChanged] = useState(false);
  const [companyAddressChanged, setCompanyAddressChanged] = useState(false);

  const errors = {
    companyName: useSelector(companyNameErrorSelector, areEqual),
    companyPhone: useSelector(companyPhoneErrorSelector, areEqual),
    companyWebsite: useSelector(companyWebsiteErrorSelector, areEqual),
    companyAddress: useSelector(companyAddressErrorSelector, areEqual),
    companyAddressSecond: useSelector(companyAddressSecondErrorSelector, areEqual),
    companyCountry: useSelector(companyCountryErrorSelector, areEqual),
    companyState: useSelector(companyStateErrorSelector, areEqual),
    companyCity: useSelector(companyCityErrorSelector, areEqual),
    companyZip: useSelector(companyCodeErrorSelector, areEqual),
  };

  const timeZonesDatabase = getTimeZones();

  // Set Company Details Values
  useEffect(() => {
    if (user?.id) {
      const { companies } = user;

      if (companies.length > 0) {
        const [company] = companies;
        const {
          logo_url: companyLogo,
          id,
          name,
          website,
          address,
          country_alpha_2: countryAlphaTwo,
          country_code: code,
          timezone,
          secondary_name: secondaryName,
          secondary_logo_url: secondaryCompanyLogo,
        } = company;
        setCompanyId(id);
        setCompanyName(name);
        setCompanyWebsite(website || '');
        setCountryCode(code);
        if (address) {
          setCompanyAddress(address);
        }
        setCompanyAvatar(companyLogo);
        if (secondaryCompanyLogo) {
          setSecondaryCompanyAvatar(secondaryCompanyLogo);
        }
        if (secondaryName) {
          setSecondaryCompanyName(secondaryName);
        }

        const countryAlpha = countryAlphaTwo;
        const companyCountry = countries.find((country) => country.alpha_2 === countryAlpha);

        if (companyCountry?.id) {
          const { id, flag, code } = companyCountry;

          setCountryId(id);
          setCompanyCountryCode(code);
          setCountryFlag(flag);
        }

        // set time zone
        const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (timezone === 'UTC') {
          const zone = timeZonesDatabase.find((timeZone) => timeZone.group.includes(browserTimeZone));
          if (zone) {
            setCompanyTimeZone(zone.alternativeName);
            setSelectedTimeZone(zone.alternativeName);
            setTimeZoneForBackEnd(zone.name);
          } else {
            setSelectedTimeZone('');
          }
        } else {
          const zone = timeZonesDatabase.find((timeZone) => timeZone.group.includes(timezone));
          if (zone) {
            setCompanyTimeZone(zone.alternativeName);
            setSelectedTimeZone(zone.alternativeName);
            setTimeZoneForBackEnd(zone.name);
          } else {
            setSelectedTimeZone('');
          }
        }
      }
      setCompanyAdmin(isCompanyAdmin(user?.roles));
    }
  }, [user]);

  // Set Company Phone Values
  useEffect(() => {
    if (companyPhoneNumbers.length > 0) {
      const [phone] = companyPhoneNumbers;
      const { id, value, country_code: code } = phone;
      setCompanyPhoneId(id);
      setCompanyPhone(formatPhoneInternationalWithCountryCode(code, value));
    }
  }, [companyPhoneNumbers]);

  // Set Company Address Values
  useEffect(() => {
    if (companyAddress?.id) {
      const { id, address, address_2: addressSecond, zip, city, state, country }: any = companyAddress;
      setCompanyAddressId(id);
      setCompanyAddressPrimary(address);
      if (addressSecond) {
        setCompanyAddressSecond(addressSecond);
      }
      setCompanyZip(zip);
      setCompanyCity(city);
      setSelectedState(state);
      setSelectedCountry(country);
    }
  }, [companyAddress]);

  // Go back to Company Profile info and created/updated reset status
  useEffect(() => {
    if (
      companyUpdated &&
      // since user can change phone and address separately, need to do this to know when all changes have been made
      (!companyPhoneChanged || (companyPhoneChanged && (companyPhoneUpdated || companyPhoneCreated))) &&
      (!companyAddressChanged || (companyAddressChanged && (companyAddressUpdated || companyAddressCreated)))
    ) {
      dispatch(userDetails());
      setCompanyIsEditable(false);
    }
    return () => {
      if (
        companyUpdated &&
        (!companyPhoneChanged || (companyPhoneChanged && (companyPhoneUpdated || companyPhoneCreated))) &&
        (!companyAddressChanged || (companyAddressChanged && (companyAddressUpdated || companyAddressCreated)))
      ) {
        dispatch(setCompanyUpdated(false));
        dispatch(setCompanyPhoneCreated(false));
        dispatch(setCompanyPhoneUpdated(false));
        dispatch(setCompanyAddressCreated(false));
        dispatch(setCompanyAddressUpdated(false));
        setCompanyPhoneChanged(false);
        setCompanyAddressChanged(false);
      }
    };
  }, [
    companyIsEditable,
    companyUpdated,
    companyPhoneChanged,
    companyPhoneUpdated,
    companyPhoneCreated,
    companyAddressChanged,
    companyAddressCreated,
    companyAddressUpdated,
  ]);

  // reset states when exiting edit mode
  useEffect(() => {
    if (!companyIsEditable) {
      setCompanyPhoneChanged(false);
      setCompanyAddressChanged(false);
    }
  }, [companyIsEditable]);

  const onEditCompanyClick = useCallback(
    (e: any) => {
      e.preventDefault();
      setCompanyIsEditable(true);
    },
    [companyIsEditable]
  );

  const onEditSecondaryCompanyClick = useCallback(
    (e: any) => {
      e.preventDefault();
      setSecondaryCompanyIsEditable(true);
    },
    [secondaryCompanyIsEditable]
  );

  // Set Editable false and reset input values
  const onCancelSecondaryCompanyEdit = useCallback(
    (e: any) => {
      e.preventDefault();
      setSecondaryCompanyIsEditable(false);
    },
    [secondaryCompanyIsEditable]
  );

  const onCancelClick = useCallback(
    (e: any) => {
      e.preventDefault();
      setCompanyIsEditable(false);
    },
    [companyIsEditable]
  );

  const onChangeCompanyName = useCallback(({ target: { value } }) => {
    setCompanyName(value);
  }, []);

  const onChangeCompanyPhone = useCallback((e: any) => {
    const { value } = e.target;
    setCompanyPhone(value);
  }, []);

  const onChangeCompanyWebsite = useCallback((e: any) => {
    const { value } = e.target;
    setCompanyWebsite(value);
  }, []);

  const onChangeCompanyAddress = useCallback((e: any) => {
    const { value } = e.target;
    setCompanyAddressPrimary(value);
  }, []);

  const onChangeCompanyAddressSecond = useCallback((e: any) => {
    const { value } = e.target;
    setCompanyAddressSecond(value);
  }, []);

  const onChangeCompanyCity = useCallback((e: any) => {
    const { value } = e.target;
    setCompanyCity(value);
  }, []);

  const onChangeCompanyZip = useCallback((e: any) => {
    const { value } = e.target;
    setCompanyZip(value);
  }, []);

  const onChangeSecondaryCompanyName = useCallback((e: any) => {
    const { value } = e.target;
    setSecondaryCompanyName(value);
  }, []);

  const onFormSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      if (isCompanyTimeZoneChanged(companyTimeZone, selectedTimeZone)) {
        dispatch(
          updateCompanyDetails(companyId, {
            name: companyName,
            website: companyWebsite,
            timezone: timeZoneForBackEnd,
          })
        );
      } else {
        dispatch(
          updateCompanyDetails(companyId, {
            name: companyName,
            website: companyWebsite,
          })
        );
      }

      if (isCompanyPhoneChanged(companyPhoneNumbers, countryCode, companyPhone)) {
        setCompanyPhoneChanged(true);
        if (companyPhoneNumbers.length === 0) {
          dispatch(
            createCompanyPhone(companyId, {
              value: convertPhoneNumber(countryCode, companyPhone),
              extension: '',
              is_primary: true,
              type: 'landline',
            })
          );
        }
        if (companyPhoneNumbers.length > 0) {
          dispatch(
            updateCompanyPhone(companyPhoneId, {
              value: convertPhoneNumber(countryCode, companyPhone),
              extension: '',
              is_primary: true,
              type: 'landline',
            })
          );
        }
      }

      if (
        isCompanyAddressChanged(
          companyAddress,
          companyAddressPrimary,
          companyAddressSecond,
          selectedCountry,
          selectedState,
          companyCity,
          companyZip
        )
      ) {
        setCompanyAddressChanged(true);
        if (!companyAddress) {
          dispatch(
            createCompanyAddress(companyId, {
              address: companyAddressPrimary,
              address_2: companyAddressSecond,
              country: selectedCountry,
              state: selectedState,
              city: companyCity,
              zip: companyZip,
            })
          );
        }
        if (companyAddress) {
          dispatch(
            updateCompanyAddress(companyAddressId, {
              address: companyAddressPrimary,
              address_2: companyAddressSecond,
              country: selectedCountry,
              state: selectedState,
              city: companyCity,
              zip: companyZip,
            })
          );
        }
      }
    },
    [
      companyName,
      companyWebsite,
      companyAddress,
      companyPhoneNumbers,
      companyPhoneId,
      companyPhone,
      companyId,
      selectedCountry,
      companyAddressId,
      companyAddressPrimary,
      companyAddressSecond,
      selectedState,
      companyCity,
      companyZip,
      companyPhoneChanged,
      companyAddressChanged,
      companyTimeZone,
      selectedTimeZone,
      timeZoneForBackEnd,
      secondaryCompanyName,
    ]
  );

  const onSecondaryFormSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      dispatch(
        updateCompanyDetails(companyId, {
          name: companyName,
          secondary_name: secondaryCompanyName,
        })
      );
      setSecondaryCompanyIsEditable(false);
    },
    [companyName, secondaryCompanyName]
  );

  return (
    <CompanyProfile
      companyAvatar={companyAvatar}
      companyName={companyName}
      companyPhone={companyPhone}
      companyWebsite={companyWebsite}
      companyAddress={companyAddressPrimary}
      companyAddressSecond={companyAddressSecond}
      companyCountry={selectedCountry}
      companyState={selectedState}
      companyCity={companyCity}
      companyZip={companyZip}
      timeZone={selectedTimeZone}
      companyEditable={companyIsEditable}
      secondaryCompanyAvatar={secondaryCompanyAvatar}
      secondaryCompanyName={secondaryCompanyName}
      formErrors={errors}
      isCompanyAdmin={companyAdmin}
      showSecondaryCompanyFields={showSecondaryCompanyFields}
      secondaryCompanyEditable={secondaryCompanyIsEditable}
      onEditCompanyClick={onEditCompanyClick}
      onFormSubmit={onFormSubmit}
      onCancelClick={onCancelClick}
      onChangeCompanyName={onChangeCompanyName}
      onChangeCompanyPhone={onChangeCompanyPhone}
      onChangeCompanyWebsite={onChangeCompanyWebsite}
      onChangeCompanyAddress={onChangeCompanyAddress}
      onChangeCompanyAddressSecond={onChangeCompanyAddressSecond}
      setSelectedCountry={setSelectedCountry}
      setSelectedProvince={setSelectedState}
      onChangeCompanyCity={onChangeCompanyCity}
      onChangeCompanyZip={onChangeCompanyZip}
      setSelectedTimeZone={setSelectedTimeZone}
      setTimeZoneForBackEnd={setTimeZoneForBackEnd}
      onEditSecondaryCompanyClick={onEditSecondaryCompanyClick}
      onChangeSecondaryCompanyName={onChangeSecondaryCompanyName}
      onSubmitSecondaryCompanyForm={onSecondaryFormSubmit}
      onCancelSecondaryCompanyEdit={onCancelSecondaryCompanyEdit}
      countryCode={companyCountryCode}
      countryId={countryId}
      countryFlag={countryFlag}
    />
  );
};

const CompanyProfileContainerMemo = memo(CompanyProfileContainer, areEqual);

export { CompanyProfileContainerMemo as CompanyProfileContainer };
