import React, { memo, useCallback, useState, useEffect } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useHistory, useParams } from 'react-router-dom';

import { ClockInModal } from 'Components/TimeTracking';
import { TimeTrackingSelectProjectScreen, ClockInOutScreen } from 'Containers/TimeTracking';

import { useTimeTrackingFunctions } from 'Context/TimeTracking';

const ClockInModalContainer = () => {
  const {
    isTimeTrackingModalOpen,
    setIsTimeTrackingModalOpen,
    selectedProject,
    setSelectedProject,
    setSelectedAddress,
    clockedInProjectId,
    showProjectList,
  }: any = useTimeTrackingFunctions();

  const { projectId: projectIdParam } = useParams<{ projectId: string }>();

  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');

  const history = useHistory();

  useEffect(() => {
    if (showProjectList) {
      setContent('projectlist');
      setTitle('Select In-Progress Project');
    } else if (selectedProject) {
      setContent('project');
      setTitle('Clock In/Clock Out');
    } else if (projectIdParam) {
      setContent('project');
      setTitle('Clock In/Clock Out');
    } else {
      setContent('projectlist');
      setTitle('Select In-Progress Project');
    }
  }, [projectIdParam, selectedProject]);

  const onCloseClick = useCallback(() => {
    // redirect user to clocked in project.
    if (clockedInProjectId) {
      history.push(`/projects/${clockedInProjectId}/rocketscan`);
    }

    // handle closing regardless of clocked in status
    setIsTimeTrackingModalOpen(false);
    setSelectedProject(undefined);
    setSelectedAddress('');
  }, [clockedInProjectId]);

  const returnToClock = () => {
    setContent('projectlist');
  };

  return (
    <ClockInModal isOpen={isTimeTrackingModalOpen} title={title} onCloseClick={onCloseClick}>
      {content === 'projectlist' && <TimeTrackingSelectProjectScreen />}
      {content === 'project' && (
        <ClockInOutScreen projectId={selectedProject ?? projectIdParam} onClickReturn={returnToClock} />
      )}
    </ClockInModal>
  );
};

const ClockInModalContainerMemo = memo(ClockInModalContainer, areEqual);

export { ClockInModalContainerMemo as ClockInModal };
