import React, { memo, useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';
import { debounce } from 'Utils/debounce';

import { TimesheetFilters } from 'Components/TimeTracking';
import { useTimeTrackingFunctions } from 'Context/TimeTracking';

import { listEmployeesForTimesheets, listProjectsAndAddressesForTimesheets } from 'Containers/TimeTracking/actions';

import { firstCompanyIdSelector } from 'Containers/Projects/selectors';

const TimesheetFiltersContainer = () => {
  const {
    // filter open status
    isEmployeeFilterOpen,
    isAddressFilterOpen,
    isProjectNumFilterOpen,
    isDateRangeFilterOpen,
    setIsEmployeeFilterOpen,
    setIsAddressFilterOpen,
    setIsProjectNumFilterOpen,
    setIsDateRangeFilterOpen,
    // list of filters to show
    employeeFilters,
    projectNumberFilters,
    addressFilters,
    // selected (but not saved) filters
    selectedAddressFilters,
    selectedEmployeeFilters,
    selectedProjectNumFilters,
    dateFilterStart,
    dateFilterEnd,
    // saved filters
    savedEmployeeFilters,
    savedAddressFilters,
    savedProjectNumFilters,
    // filter functions
    onAddressFilterListItemClick,
    onProjectNumFilterListItemClick,
    onEmployeeFilterListItemClick,
    applyEmployeeFilters,
    applyAddressFilters,
    applyProjectNumFilters,
    onChangeDateFilter,
    applyDateFilters,
    clearAddressFilters,
    clearProjectNumFilters,
    clearEmployeeFilters,
    clearDateFilter,
    isDateFilterApplied,
  }: any = useTimeTrackingFunctions();

  const dispatch = useDispatch();

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

  // search box refs
  const employeeSearchRef = useRef(undefined);
  const addressSearchRef = useRef(undefined);
  const projectNumSearchRef = useRef(undefined);

  const [employeeSearchValue, setEmployeeSearchValue] = useState('');
  const [addressSearchValue, setAddressSearchValue] = useState('');
  const [projectNumSearchValue, setProjectNumSearchValue] = useState('');

  const onEmployeeFilterClick = useCallback(() => {
    setIsEmployeeFilterOpen((prevState) => !prevState);
    setIsAddressFilterOpen(false);
    setIsProjectNumFilterOpen(false);
    setIsDateRangeFilterOpen(false);
  }, []);

  const onAddressFilterClick = useCallback(() => {
    setIsAddressFilterOpen((prevState) => !prevState);
    setIsProjectNumFilterOpen(false);
    setIsDateRangeFilterOpen(false);
    setIsEmployeeFilterOpen(false);
  }, []);

  const onProjectNumFilterClick = useCallback(() => {
    setIsProjectNumFilterOpen((prevState) => !prevState);
    setIsAddressFilterOpen(false);
    setIsDateRangeFilterOpen(false);
    setIsEmployeeFilterOpen(false);
  }, []);

  const onDateRangeFilterClick = useCallback(() => {
    setIsDateRangeFilterOpen((prevState) => !prevState);
    setIsAddressFilterOpen(false);
    setIsEmployeeFilterOpen(false);
    setIsProjectNumFilterOpen(false);
  }, []);

  const onApplyFilterButtonClicked = useCallback(
    (e: any) => {
      e.preventDefault();
      const { id } = e.target;

      if (id === 'employee') {
        applyEmployeeFilters();
        setIsEmployeeFilterOpen(false);
      } else if (id === 'address') {
        applyAddressFilters();
        setIsAddressFilterOpen(false);
      } else if (id === 'projectNumber') {
        applyProjectNumFilters();
        setIsProjectNumFilterOpen(false);
      } else if (id === 'date') {
        applyDateFilters();
        setIsDateRangeFilterOpen(false);
      }
    },
    [applyEmployeeFilters, applyAddressFilters, applyProjectNumFilters, applyDateFilters]
  );

  const onClearFilterButtonClicked = useCallback((e: any) => {
    e.preventDefault();
    const { id } = e.target;

    if (id === 'employee') {
      clearEmployeeFilters();
      setIsEmployeeFilterOpen(false);
    } else if (id === 'address') {
      clearAddressFilters();
      setIsAddressFilterOpen(false);
    } else if (id === 'projectNumber') {
      clearProjectNumFilters();
      setIsProjectNumFilterOpen(false);
    } else if (id === 'date') {
      clearDateFilter();
      setIsDateRangeFilterOpen(false);
    }
  }, []);

  // api call to list filters
  const getEmployees = useCallback(
    (search = '') => {
      dispatch(listEmployeesForTimesheets(firstCompanyId, search));
    },
    [firstCompanyId]
  );

  const getProjectsAndAddresses = useCallback(
    (search = '') => {
      dispatch(listProjectsAndAddressesForTimesheets(firstCompanyId, search));
    },
    [firstCompanyId]
  );

  // get employee and project filters
  useEffect(() => {
    if (firstCompanyId) {
      getEmployees();
      getProjectsAndAddresses();
    }
  }, [firstCompanyId]);

  // handle search box value change
  const handleEmployeeSearchValueChange = ({ target: { value } }: any) => {
    if (value.length <= 36) {
      setEmployeeSearchValue(value);
      if (value.length >= 2) {
        getEmployees(value);
      }
      if (value.length === 0) {
        getEmployees();
      }
    }
  };
  const handleAddressSearchValueChange = ({ target: { value } }: any) => {
    if (value.length <= 36) {
      setEmployeeSearchValue(value);
      if (value.length >= 2) {
        getProjectsAndAddresses(value);
      }
      if (value.length === 0) {
        getProjectsAndAddresses();
      }
    }
  };
  const handleProjectNumberSearchValueChange = ({ target: { value } }: any) => {
    if (value.length <= 36) {
      setEmployeeSearchValue(value);
      if (value.length >= 2) {
        getProjectsAndAddresses(value);
      }
      if (value.length === 0) {
        getProjectsAndAddresses();
      }
    }
  };

  // debounce function on search value change
  const onChangeEmployeeSearchValue = useMemo(() => debounce(handleEmployeeSearchValueChange, 300), [firstCompanyId]);
  const onChangeAddressSearchValue = useMemo(() => debounce(handleAddressSearchValueChange, 300), [firstCompanyId]);
  const onChangeProjectNumSearchValue = useMemo(
    () => debounce(handleProjectNumberSearchValueChange, 300),
    [firstCompanyId]
  );

  const onClickEmployeeSearchClearButton = useCallback(() => {
    getEmployees();
    setEmployeeSearchValue('');
    employeeSearchRef.current.value = '';
    employeeSearchRef.current.focus();
  }, [firstCompanyId, employeeSearchRef]);

  const onClickAddressSearchClearButton = useCallback(() => {
    getProjectsAndAddresses();
    setAddressSearchValue('');
    addressSearchRef.current.value = '';
    addressSearchRef.current.focus();
  }, [firstCompanyId, addressSearchRef]);

  const onClickProjectNumClearButton = useCallback(() => {
    getProjectsAndAddresses();
    setProjectNumSearchValue('');
    projectNumSearchRef.current.value = '';
    projectNumSearchRef.current.focus();
  }, [firstCompanyId, projectNumSearchRef]);

  return (
    <TimesheetFilters
      startDate={dateFilterStart}
      endDate={dateFilterEnd}
      isEmployeeFilterOpen={isEmployeeFilterOpen}
      isAddressFilterOpen={isAddressFilterOpen}
      isProjectNumFilterOpen={isProjectNumFilterOpen}
      isDateRangeFilterOpen={isDateRangeFilterOpen}
      // employee filters
      employeeFilters={employeeFilters}
      selectedEmployeeFilters={selectedEmployeeFilters}
      employeeSearchRef={employeeSearchRef}
      employeeSearchValue={employeeSearchValue}
      savedEmployeeFiltersCount={savedEmployeeFilters.length}
      onChangeEmployeeSearchValue={onChangeEmployeeSearchValue}
      // address filters
      addressFilters={addressFilters}
      selectedAddressFilters={selectedAddressFilters}
      addressSearchRef={addressSearchRef}
      addressSearchValue={addressSearchValue}
      savedAddressFiltersCount={savedAddressFilters.length}
      onChangeAddressSearchValue={onChangeAddressSearchValue}
      // project number filters
      projectNumberFilters={projectNumberFilters}
      selectedProjectNumFilters={selectedProjectNumFilters}
      projectNumSearchRef={projectNumSearchRef}
      projectNumSearchValue={projectNumSearchValue}
      savedProjectNumbersCount={savedProjectNumFilters.length}
      onChangeProjectNumSearchValue={onChangeProjectNumSearchValue}
      // functions
      onEmployeeFilterClick={onEmployeeFilterClick}
      onEmployeeFilterListItemClick={onEmployeeFilterListItemClick}
      onClickEmployeeSearchClearButton={onClickEmployeeSearchClearButton}
      onAddressFilterClick={onAddressFilterClick}
      onAddressFilterListItemClick={onAddressFilterListItemClick}
      onClickAddressSearchClearButton={onClickAddressSearchClearButton}
      onProjectNumFilterClick={onProjectNumFilterClick}
      onProjectNumFilterListItemClick={onProjectNumFilterListItemClick}
      onClickProjectNumClearButton={onClickProjectNumClearButton}
      onDateRangeFilterClick={onDateRangeFilterClick}
      onChangeDate={onChangeDateFilter}
      onApplyFilterButtonClick={onApplyFilterButtonClicked}
      onClearFilterButtonClick={onClearFilterButtonClicked}
      onClearDateFilterButtonClick={onClearFilterButtonClicked}
      dateFilterApplied={isDateFilterApplied}
    />
  );
};

const TimesheetFiltersContainerMemo = memo(TimesheetFiltersContainer, areEqual);

export { TimesheetFiltersContainerMemo as TimesheetFilters };
