import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Modal } from 'Components/Modal';

import { formatDate } from 'Utils/helpers';

import classes from './timeCardNotesModal.module.css';

import 'react-datepicker/dist/react-datepicker-min.module.css';

interface Props {
  isOpen: boolean;
  notes: any[];
  modalCloseClick: (e: any) => void;
}

function TimeCardNotesModal({ isOpen, notes, modalCloseClick }: Props) {
  return (
    <Modal
      id="timecard-notes-modal"
      classes={classes}
      title="Notes"
      isOpen={isOpen}
      modalHeader
      dataBsBackdrop="static"
      dataBsKeyboard="false"
      modalCloseClick={modalCloseClick}
    >
      {notes?.length > 0 &&
        notes.map((note: any) => (
          <div key={note.id}>
            <div>
              <div className={classes.name}>{note.author?.full_name ?? '[deleted user]'}</div>
              <div className={classes.datePosted}>
                {formatDate(note.created_at, 'MMM d')} at
                {formatDate(note.created_at, 'h:mmaaa')}
              </div>
            </div>
            <div className={`${classes.noteContent}`}>{note.body}</div>
            <br />
          </div>
        ))}
      {notes?.length === 0 && <div className={classes.noNotes}>There are no notes for this timecard.</div>}
    </Modal>
  );
}

const TimeCardNotesModalMemo = memo(TimeCardNotesModal, areEqual);

export { TimeCardNotesModalMemo as TimeCardNotesModal };
