import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { PurpleButton } from 'Components/Button';

import { Modal } from 'Components/Modal';
import { Spinner } from 'Components/Spinner';

import classes from './uploadFileModal.module.css';

interface Props {
  isOpen: boolean;
  fetching: boolean;
  inputKey: any;
  modalCloseClick: (e: any) => void;
  uploadButtonClick: (e: any) => void;
  onUploadChange: (e: any) => void;
}

function UploadFileModal({ isOpen, fetching, inputKey, modalCloseClick, uploadButtonClick, onUploadChange }: Props) {
  return (
    <Modal
      id="pdf-upload-modal"
      classes={classes}
      title="Upload PDF"
      isOpen={isOpen}
      modalHeader
      dataBsBackdrop="static"
      dataBsKeyboard="false"
      modalCloseClick={modalCloseClick}
    >
      <div className={classes.buttonWrapper}>
        {fetching && (
          <div className={classes.loading}>
            <div className={classes.fileNameBox}>
              <span className={classes.fileName}>File is uploading...</span>
            </div>
            <div className={classes.spinnerBox}>
              <span className={classes.spinner}>
                <Spinner loading />
              </span>
            </div>
          </div>
        )}
        <input
          key={inputKey}
          className={classes.upload}
          onChange={onUploadChange}
          type="file"
          accept="application/pdf"
        />
      </div>
      <div className={classes.buttonWrapper}>
        <PurpleButton onClick={uploadButtonClick} disabled={fetching}>
          Upload
        </PurpleButton>
      </div>
    </Modal>
  );
}

const UploadFileModalMemo = memo(UploadFileModal, areEqual);

export { UploadFileModalMemo as UploadFileModal };
