import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { PurpleButton } from 'Components/Button';

import { PaymentFilterListItem } from 'Containers/RocketPay';

import classes from './paymentFilterList.module.css';

interface Props {
  items: any[];
  selectedItems: any[];
  type: string;
  onClickItem: (e: any) => void;
  onApplyFilterButtonClicked: (e: any) => void;
  onClearFilterButtonClicked: (e: any) => void;
}

const isSelected = (selectedList: any[], id: any): boolean =>
  // find current item id if it appears in the list of selected ids.
  // !! to turn the result into a boolean
  !!selectedList.find((item: any) => item === id);

const PaymentFilterList = ({
  items,
  selectedItems,
  type,
  onClickItem,
  onApplyFilterButtonClicked,
  onClearFilterButtonClicked,
}: Props) => (
  <div className={classes.container}>
    <div className={classes.listContainer}>
      {items?.length > 0 ? (
        items.map((item: any) => (
          <PaymentFilterListItem
            key={item.id}
            item={item}
            isSelected={isSelected(selectedItems, item.id)}
            type={type}
            onSelectItem={onClickItem}
          />
        ))
      ) : (
        <div className={classes.listLoading}>Loading</div>
      )}
    </div>
    <div className={classes.buttonContainer}>
      <PurpleButton onClick={onApplyFilterButtonClicked} id={type}>
        Apply Filter
      </PurpleButton>
      <br />
      <PurpleButton onClick={onClearFilterButtonClicked} id={type}>
        Clear Filter
      </PurpleButton>
    </div>
  </div>
);

const PaymentFilterListMemo = memo(PaymentFilterList, areEqual);

export { PaymentFilterListMemo as PaymentFilterList };
