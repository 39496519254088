import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { MetricsList, MetricsFilters, MetricsNotesModal, DownloadMetricsModal } from 'Containers/Metrics';
import { PurpleButton } from 'Components/Button';

import classes from './metrics.module.css';

interface Props {
  getMetrics: (e: any) => void;
  onDownloadButtonClick: (e: any) => void;
}

function Metrics({ getMetrics, onDownloadButtonClick }: Props) {
  return (
    <div className={classes.container}>
      <div className={`d-flex justify-content-start align-items-center ${classes.contentHeader}`}>
        <h2>Metrics</h2>
        <PurpleButton className={classes.downloadButton} onClick={onDownloadButtonClick}>
          Download
        </PurpleButton>
      </div>
      <MetricsFilters />
      <MetricsList getMetrics={getMetrics} />
      <MetricsNotesModal />
      <DownloadMetricsModal />
    </div>
  );
}

const MetricsMemo = memo(Metrics, areEqual);

export { MetricsMemo as Metrics };
