import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { ProjectsList } from 'Components/Projects';
import { ProjectsListAlternateColumns } from 'Components/Projects/ProjectsTabs/ProjectsList';
import { CompanyProjectsDataModal } from 'Containers/Projects/Modals/CompanyProjectsModal';

import { userFeatureFlagsSelector } from 'Containers/User/selector';

interface Props {
  iconType?: string;
  sortBy?: string;
  projects: CompanyProjectsDataModal;
  onClickRow?: (e: any) => void;
  onClickSort?: (sort: string) => void;
}

function ProjectsListContainer({ iconType, sortBy, projects, onClickRow, onClickSort }: Props) {
  const { changeProjectColumns } = useSelector(userFeatureFlagsSelector, areEqual);

  return changeProjectColumns ? (
    <ProjectsListAlternateColumns
      iconType={iconType}
      projects={projects}
      sortBy={sortBy}
      onClickRow={onClickRow}
      onClickSort={onClickSort}
    />
  ) : (
    <ProjectsList
      iconType={iconType}
      projects={projects}
      sortBy={sortBy}
      onClickRow={onClickRow}
      onClickSort={onClickSort}
    />
  );
}

ProjectsListContainer.defaultProps = {
  iconType: 'my',
  sortBy: null,
  onClickRow: null,
  onClickSort: null,
};

const ProjectsListContainerMemo = memo(ProjectsListContainer, areEqual);

export { ProjectsListContainerMemo as ProjectsList };
