import React, { memo } from 'react';

import { Spinner } from 'Components/Spinner';
import { PurpleButton } from 'Components/Button/PurpleButton';
import { areEqual } from 'Utils/equalityChecks';

import classes from './equipmentInventory.module.css';
import { NoEquipmentPlaceholder } from './NoEquipmentPlaceholder';
import { EquipmentInventoryList } from './EquipmentInventoryList';

interface Props {
  equipmentInventoryData: any;
  canDelete: boolean;
  fetching: boolean;
  onAddButtonClick: (e: any) => void;
  onEditButtonClick: (e: any) => void;
  onDeleteButtonClick: (e: any) => void;
}

function EquipmentInventory({
  equipmentInventoryData,
  canDelete,
  fetching,
  onAddButtonClick,
  onEditButtonClick,
  onDeleteButtonClick,
}: Props) {
  return (
    <div className={classes.inventoryContent}>
      <div className={`d-flex justify-content-start align-items-center ${classes.contentHeader}`}>
        <h2>Equipment Inventory</h2>
        <PurpleButton className={classes.addButton} onClick={onAddButtonClick}>
          Add +
        </PurpleButton>
      </div>
      {fetching && <Spinner loading />}
      {equipmentInventoryData?.meta?.total === 0 && !fetching && (
        <NoEquipmentPlaceholder onClickPlaceholderButton={onAddButtonClick} />
      )}
      {equipmentInventoryData?.meta?.total > 0 && (
        <EquipmentInventoryList
          equipmentList={equipmentInventoryData?.data}
          canDelete={canDelete}
          onEditIconClick={onEditButtonClick}
          onDeleteIconClick={onDeleteButtonClick}
        />
      )}
    </div>
  );
}

const EquipmentInventoryMemo = memo(EquipmentInventory, areEqual);

export { EquipmentInventoryMemo as EquipmentInventory };
