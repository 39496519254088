export const reportTableHeaders = [
  {
    id: 1,
    displayName: 'Name',
    column: 'name',
    canSort: false,
    action: false,
  },
  {
    id: 2,
    displayName: 'Author',
    column: 'creator',
    canSort: false,
    action: false,
  },
  {
    id: 3,
    displayName: 'Date Created',
    column: 'id',
    canSort: true,
    action: false,
  },
  {
    id: 4,
    displayName: 'Style',
    column: 'style',
    canSort: false,
    action: false,
  },
  {
    id: 5,
    displayName: 'Status',
    column: 'status',
    canSort: false,
    action: false,
  },
  {
    id: 6,
    displayName: 'Download',
    column: 'download',
    canSort: false,
    action: true,
  },
  {
    id: 7,
    displayName: 'Share',
    column: 'share',
    canSort: false,
    action: true,
  },
  {
    id: 8,
    displayName: 'Edit',
    column: 'edit',
    canSort: false,
    action: true,
  },
  {
    id: 9,
    displayName: 'Delete',
    column: 'delete',
    canSort: false,
    action: true,
  },
];

export const externalAtmosphericTableHeaders = [
  {
    id: 1,
    displayName: 'Date',
    column: 'date',
    canSort: false,
    action: false,
  },
  {
    id: 2,
    displayName: 'Relative Humidity',
    column: 'rhumidity',
    canSort: false,
    action: false,
  },
  {
    id: 3,
    displayName: 'Temp',
    column: 'temp',
    canSort: false,
    action: false,
  },
  {
    id: 4,
    displayName: 'Pressure',
    column: 'pressure',
    canSort: false,
    action: false,
  },
  {
    id: 5,
    displayName: 'Wind Speed',
    column: 'windspeed',
    canSort: false,
    action: false,
  },
  {
    id: 6,
    displayName: 'View Log',
    column: 'viewlog',
    canSort: false,
    action: true,
  },
];

export const internalAtmosphericTableHeaders = [
  {
    id: 1,
    displayName: 'Date',
    column: 'date',
    canSort: false,
    action: false,
  },
  {
    id: 2,
    displayName: 'In/Out',
    column: 'inout',
    canSort: false,
    action: false,
  },
  {
    id: 3,
    displayName: 'R. Humidity',
    column: 'rhumidity',
    canSort: false,
    action: false,
  },
  {
    id: 4,
    displayName: 'Temp',
    column: 'temp',
    canSort: false,
    action: false,
  },
  {
    id: 5,
    displayName: 'Dew',
    column: 'dewpoint',
    canSort: false,
    action: false,
  },
  {
    id: 6,
    displayName: 'GPP',
    column: 'gpp',
    canSort: false,
    action: false,
  },
  {
    id: 7,
    displayName: 'View Log',
    column: 'viewlog',
    canSort: false,
    action: true,
  },
];

export const moistureLogTableHeaders = [
  {
    id: 1,
    displayName: 'Material',
    column: 'material',
    canSort: false,
    action: false,
  },
  {
    id: 2,
    displayName: 'Date',
    column: 'date',
    canSort: false,
    action: false,
  },
  {
    id: 3,
    displayName: 'Goal Average',
    column: 'goalaverage',
    canSort: false,
    action: false,
  },
  {
    id: 4,
    displayName: 'Average',
    column: 'average',
    canSort: false,
    action: false,
  },
  {
    id: 5,
    displayName: 'View Log',
    column: 'viewlog',
    canSort: false,
    action: true,
  },
];

export const esignatureTableHeaders = [
  {
    id: 1,
    displayName: 'Form Name',
    column: 'contract_form.name',
    canSort: false,
    action: false,
  },
  {
    id: 2,
    displayName: 'Date Created',
    column: 'datecreated',
    canSort: false,
    action: false,
  },
  {
    id: 3,
    displayName: 'Status',
    column: 'status',
    canSort: false,
    action: false,
  },
  {
    id: 4,
    displayName: 'Edit',
    column: 'action',
    canSort: false,
    action: false,
  },
  {
    id: 5,
    displayName: 'Download',
    column: 'action',
    canSort: false,
    action: false,
  },
  {
    id: 6,
    displayName: 'Delete',
    column: 'action',
    canSort: false,
    action: false,
  },
];

export const uploadsTableHeaders = [
  {
    id: 1,
    displayName: 'Name',
    column: 'name',
    canSort: false,
    action: false,
  },
  {
    id: 2,
    displayName: 'Author',
    column: 'creator',
    canSort: false,
    action: false,
  },
  {
    id: 3,
    displayName: 'Date Created',
    column: 'id',
    canSort: true,
    action: false,
  },
  {
    id: 4,
    displayName: 'View',
    column: 'view',
    canSort: false,
    action: true,
  },
  {
    id: 5,
    displayName: 'Download',
    column: 'download',
    canSort: false,
    action: true,
  },
  {
    id: 6,
    displayName: 'Delete',
    column: 'delete',
    canSort: false,
    action: true,
  },
];
