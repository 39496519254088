// import axios from 'axios';
import { SET_AUTH_TOKEN } from 'Containers/Auth/actions';
import { setToaster } from 'Containers/Core';
import { userDetails } from 'Containers/User';
import { Api } from 'Utils/api';
import { handleApiRequest } from 'Utils/handleApiRequest';

export const GET_ESIGNATURE_DATA = 'GET_ESIGNATURE_DATA';
export const SELECTED_ESIGN_LOCATIONS = 'SELECTED_ESIGN_LOCATIONS';
export const ESIGN_CREATING = 'ESIGN_CREATING';
export const ESIGN_SHARING = 'ESIGN_SHARING';
export const ESIGN_CREATED = 'ESIGN_CREATED';
export const ESIGN_DELETED = 'ESIGN_DELETED';
export const ESIGN_PROPERTY = 'ESIGN_PROPERTY';
export const ESIGN_SHARED = 'ESIGN_SHARED';
export const PROJECT_ESIGNS = 'PROJECT_ESIGNS';
export const ESIGN_ERRORS = 'ESIGN_ERRORS';

interface ActionTypes {
  GET_ESIGNATURE_DATA: any;
  SELECTED_ESIGN_LOCATIONS: any;
  ESIGN_CREATING: any;
  ESIGN_SHARING: any;
  ESIGN_CREATED: any;
  ESIGN_DELETED: any;
  ESIGN_SHARED: any;
  PROJECT_ESIGNS: any;
  ESIGN_ERRORS: any;
  ESIGN_PROPERTY: any;
}

interface MessageAction {
  type: keyof ActionTypes;
  payload: any;
}

export type EsignatureActionTypes = MessageAction;

/*
 * NON-API THUNKS
 * */

export const resetSharedEsignature = () => ({
  type: ESIGN_SHARED,
  payload: undefined,
});

export const setEsignatureShared = (payload: boolean) => (dispatch) => {
  dispatch({
    type: ESIGN_SHARED,
    payload,
  });
};

/*
 * API THUNKS
 * */
/* eslint-disable */

export const listEsignatureApi =
  (company_id: number, project_id: number, pageNumber = 1) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response: any = await handleApiRequest(
      dispatch,
      utils.Api.get(`/companies/${company_id}/e-signatures`, {
        params: {
          include: 'roomType,level',
          page: pageNumber,
          project_id,
        },
      })
    );
    if (response?.data) {
      dispatch({
        type: GET_ESIGNATURE_DATA,
        payload: response.data,
      });
    }
  };

export const getEsignatureApi =
  (id: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response: any = await handleApiRequest(
      dispatch,
      utils.Api.get(`/e-signatures/${id}`, {
        params: {},
      })
    );
    if (response?.data) {
      dispatch({
        type: ESIGN_SHARED,
        payload: response.data,
      });
    }
  };

export const setWebviewToken =
  (token: string) =>
  async (dispatch: any, _getState = null, utils: any) => {
    // const data = await handleApiRequest(dispatch, utils.Api[type](url, requestData));
    if (token) {
      // update the axios headers
      utils.Api.setAuthorizationToken(token);

      // we'll fetch user details on a successful sign in and set redux variable for route changes
      dispatch(userDetails());

      // set the redux state with the token --sanctum auth bearer token
      dispatch({
        type: SET_AUTH_TOKEN,
        payload: token,
      });
    }
  };

export const getEsignatureApiWithToken = (id: number) => async (dispatch: any) => {
  // const url = `${baseURL}/e-signatures/${id}`;
  // const config = {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Accept: 'application/json',
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // const response: any = await handleApiRequest(dispatch, axios.get(url, config));
  const response: any = await handleApiRequest(dispatch, Api.get(`/e-signatures/${id}`));
  if (response?.data) {
    dispatch({
      type: ESIGN_SHARED,
      payload: response.data,
    });
  }
};

export const signCompanyContractWithToken =
  (signID: string, signature: string, content: string) => async (dispatch: any) => {
    // const url = `${baseURL}/sign-contracts/${signID}`;
    // const config = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json',
    //     Authorization: `Bearer ${token}`,
    //   },
    // };

    // const response: any = await handleApiRequest(dispatch, axios.put(url, { signature, content }, config));
    const response: any = await handleApiRequest(
      dispatch,
      Api.put(`/sign-contracts/${signID}`, { signature, content })
    );
    if (response?.data) {
      dispatch(setToaster('Contract Documented Signed. Click Close Button', true));
    }
  };

export const createEsignature =
  (
    companyId: string,
    project_id: number,
    contract_form_id: string,
    name: string,
    replacementTags: string,
    status: string,
    // template: string,
    clientName?: string,
    clientPhone?: string,
    clientEmail?: string,
    url?: string
  ) =>
  async (dispatch: any, _getState = null, utils: any) => {
    dispatch({
      type: ESIGN_CREATED,
      payload: false,
    });
    const content = await getEsignatureContent(companyId, project_id, contract_form_id, dispatch);
    const response = await handleApiRequest(
      dispatch,
      utils.Api.post(`/e-signatures`, {
        company_id: companyId,
        project_id,
        contract_form_id,
        client_name: clientName,
        client_phone: clientPhone,
        client_email: clientEmail,
        name,
        replacement_tags: replacementTags,
        status,
        content,
        url,
      })
    );

    if (response?.data) {
      dispatch({
        type: ESIGN_CREATED,
        payload: true,
      });
    }
  };

export const getEsignatureContent = async (
  company_id: string,
  project_id: number,
  contract_form_id: string,
  dispatch: any
) => {
  const response = await handleApiRequest(
    dispatch,
    Api.get(`/signature-contents`, {
      params: {
        company_id,
        project_id,
        contract_form_id,
      },
    })
  );

  if (response?.content) {
    return response?.content;
  }
};

export const signCompanyContract =
  (signID: string, signature: string, content: string, onFinish?: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.put(`/sign-contracts/${signID}`, {
        signature,
        content,
      })
    );

    if (response?.data) {
      if (onFinish) {
        onFinish(response.data);
      } else {
        dispatch(setToaster('Contract Documented Signed. Click Close Button', true));
      }
    }
  };

export const deleteEsignature =
  (signId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    dispatch(resetDeleteEsignature());
    const response = await handleApiRequest(dispatch, utils.Api.delete(`/e-signatures/${signId}`, {}));

    if (typeof response === 'string') {
      dispatch({
        type: ESIGN_DELETED,
        payload: true,
      });
    }
  };

export const resetDeleteEsignature =
  () =>
  async (dispatch: any, _getState = null) => {
    dispatch({
      type: ESIGN_DELETED,
      payload: false,
    });
  };

export const getPropertyForEsignature =
  (projectId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response: any = await handleApiRequest(
      dispatch,
      utils.Api.get(`/projects/${projectId}/properties`, {
        params: {
          include: 'levels',
        },
      })
    );
    if (response?.data) {
      dispatch({
        type: ESIGN_PROPERTY,
        payload: response.data,
      });
    }
  };

export const shareEsignature =
  (esigId: number, requestData: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.post(`e-signatures/${esigId}/shares`, requestData),
      ESIGN_ERRORS,
      ESIGN_SHARING
    );

    if (typeof response === 'string') {
      dispatch(setEsignatureShared(true));
    }
  };
