import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { DashboardGoogleMap } from 'Components/Dashboard/DashboardGoogleMap/DashboardGoogleMap';
import { ProjectSelection } from 'Components/Dashboard/ProjectSelection';

interface Props {
  handleChangeProject: any;
  center: any;
  zoom: any;
  activeProject: any;
  myProjects: any;
  fetching: any;
  activeTab: number;
  handleViewAll: any;
  onlyShowMyProjects: boolean;
  setTabWIP: (e: any) => void;
  setTabMyProjects: (e: any) => void;
}

function Dashboard({
  center,
  zoom,
  activeProject,
  handleChangeProject,
  myProjects,
  fetching,
  activeTab,
  handleViewAll,
  onlyShowMyProjects,
  setTabWIP,
  setTabMyProjects,
}: Props) {
  return (
    <div id="Dashboard" className="container-fluid">
      <div className="row">
        <div className="col-md-7 col-xl-8 col-xxl-9">
          <DashboardGoogleMap
            center={center}
            zoom={zoom}
            activeProject={activeProject}
            handleChangeProject={handleChangeProject}
            myProjects={myProjects}
            fetching={fetching}
          />
        </div>
        <div className="col-md-5 col-xl-4 col-xxl-3">
          <ProjectSelection
            handleViewAll={handleViewAll}
            activeTab={activeTab}
            setTabWIP={setTabWIP}
            setTabMyProjects={setTabMyProjects}
            activeProject={activeProject}
            handleChangeProject={handleChangeProject}
            myProjects={myProjects}
            onlyShowMyProjects={onlyShowMyProjects}
            fetching={fetching}
          />
        </div>
      </div>
    </div>
  );
}

const DashboardMemo = memo(Dashboard, areEqual);

export { DashboardMemo as Dashboard };
