import { Spinner } from 'Components/Spinner';
import { ProjectsPagination } from 'Components/Projects';
import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { TimeTrackingProjectsList } from 'Containers/TimeTracking';
import { ProjectsSearch } from 'Containers/Projects';

import classes from './timeTrackingWipProjects.module.css';

interface Props {
  sortBy?: string;
  projects: any;
  fetching: boolean;
  initialPage: number;
  pageCount: number;
  onPageChange: (e: any) => void;
  onClickRow?: (e: any) => void;
  onClickSort?: (e: any) => void;
}

function TimeTrackingWipProjects({
  projects,
  sortBy,
  initialPage,
  pageCount,
  onPageChange,
  onClickRow,
  onClickSort,
  fetching,
}: Props) {
  return (
    <>
      <div className={classes.projectContent}>
        {fetching && <Spinner loading />}
        {!fetching && (
          <>
            <div className={classes.searchContainer}>
              <ProjectsSearch />
            </div>
            <TimeTrackingProjectsList
              projects={projects}
              sortBy={sortBy}
              onClickRow={onClickRow}
              onClickSort={onClickSort}
            />
          </>
        )}
      </div>
      {projects?.meta?.total >= projects?.meta?.per_page && (
        <ProjectsPagination initialPage={initialPage} pageCount={pageCount} onPageChange={onPageChange} />
      )}
    </>
  );
}

TimeTrackingWipProjects.defaultProps = {
  sortBy: null,
  onClickRow: null,
  onClickSort: null,
};

const TimeTrackingWipProjectsMemo = memo(TimeTrackingWipProjects, areEqual);

export { TimeTrackingWipProjectsMemo as TimeTrackingWipProjects };
