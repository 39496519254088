import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { PaymentList } from 'Components/RocketPay';
import { DashboardPagination } from 'Components/Pagination';
import { Spinner } from 'Components/Spinner';

interface Props {
  payments: any;
  sortBy?: string;
  onClickSort?: (sort: string) => void;
  initialPage?: number;
  pageCount?: number;
  onPageChange?: (e: any) => void;
  isLoading?: boolean;
}

function PaymentListContainer({
  payments,
  initialPage,
  pageCount,
  onPageChange,
  sortBy,
  isLoading,
  onClickSort,
}: Props) {
  return (
    <div>
      <PaymentList payments={payments} sortBy={sortBy} onClickSort={onClickSort} />
      {payments?.meta?.total >= 15 && (
        <DashboardPagination initialPage={initialPage} pageCount={pageCount} onPageChange={onPageChange} />
      )}
      <Spinner loading={isLoading} />
    </div>
  );
}

const PaymentListContainerMemo = memo(PaymentListContainer, areEqual);

export { PaymentListContainerMemo as PaymentList };
