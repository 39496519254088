import React, { memo, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { RocketPayAgreementScreen } from 'Components/RocketPay';

import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { createJustiFiBusinessForCompany } from 'Containers/RocketPay/actions';

interface Props {
  recheckStatus: (e?: any) => void;
}

function RocketPayAgreementScreenContainer({ recheckStatus }: Props) {
  const dispatch = useDispatch();
  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

  const [checked, setChecked] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastError, setToastError] = useState(false);

  const onCheckboxClick = useCallback(() => {
    setChecked((prevState) => !prevState);
  }, []);

  const showError = useCallback(() => {
    setToastMessage('Error connecting to JustiFi. Please wait before trying again.');
    setToastError(true);
    setShowToast(true);
  }, [setToastError]);

  const onActivateButtonClick = useCallback(async () => {
    if (firstCompanyId) {
      setLoading(true);
      const createBusinessResponse: any = await dispatch(createJustiFiBusinessForCompany(firstCompanyId));
      if (createBusinessResponse?.data?.business_id) {
        recheckStatus();
      } else {
        showError();
        setLoading(false);
      }
    }
  }, [firstCompanyId]);

  return (
    <RocketPayAgreementScreen
      acceptCheckboxTicked={checked}
      onCheckboxClick={onCheckboxClick}
      onActivateButtonClick={onActivateButtonClick}
      loading={loading}
      showToast={showToast}
      message={toastMessage}
      error={toastError}
    />
  );
}

const RocketPayAgreementScreenContainerMemo = memo(RocketPayAgreementScreenContainer, areEqual);
export { RocketPayAgreementScreenContainerMemo as RocketPayAgreementScreen };
