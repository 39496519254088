import { createContext, useContext, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { employeeFiltersSelector } from 'Containers/TimeTracking/selectors';

import { areEqual } from 'Utils/equalityChecks';
import { useUser } from 'Context/User';
import { addOrRemoveFromArray, formatDate } from 'Utils/helpers';
import { companyMetricsSelector } from 'Containers/Metrics/selectors';

export const MetricsContext = createContext({});

export const MetricsFunctions = () => {
  const { id: userId } = useUser();

  // states for metrics filters
  // selected filters: filters that are currently selected, but are not applied yet
  // saved filters: filters that will be sent through the API once clicking the apply filters button
  const [isEmployeeFilterOpen, setIsEmployeeFilterOpen] = useState(false);
  const [selectedEmployeeFilters, setSelectedEmployeeFilters] = useState([]);
  const [savedEmployeeFilters, setSavedEmployeeFilters] = useState([]);
  const [isActiveFilterOpen, setIsActiveFilterOpen] = useState(false);
  const [isActiveFilterSet, setIsActiveFilterSet] = useState(false);
  const [isDateRangeFilterOpen, setIsDateRangeFilterOpen] = useState(false);
  const [dateFilterStart, setDateFilterStart] = useState(null);
  const [dateFilterEnd, setDateFilterEnd] = useState(null);
  const [savedDateFilter, setSavedDateFilter] = useState(null);
  const [isDateFilterApplied, setIsDateFilterApplied] = useState(false);
  const [isMetricsNotesModalOpen, setIsMetricsNotesModalOpen] = useState(false);
  const [isDownloadMetricsModalOpen, setIsDownloadMetricsModalOpen] = useState(false);

  const employeeFilters = useSelector(employeeFiltersSelector, areEqual);
  const companyMetrics = useSelector(companyMetricsSelector, areEqual);

  // handle changing filters
  const onEmployeeFilterListItemClick = useCallback(
    (employeeId: any) => {
      setSelectedEmployeeFilters(addOrRemoveFromArray(selectedEmployeeFilters, employeeId));
    },
    [selectedEmployeeFilters]
  );

  const onActiveFilterOptionClick = useCallback(() => {
    setIsActiveFilterSet((prevState) => !prevState);
    setIsActiveFilterOpen((prevState) => !prevState);
  }, []);

  const onChangeDateFilter = (dates) => {
    const [start, end] = dates;
    setDateFilterStart(start);
    setDateFilterEnd(end);
  };

  // handle applying filters
  const applyEmployeeFilters = useCallback(() => {
    setSavedEmployeeFilters(selectedEmployeeFilters);
  }, [selectedEmployeeFilters]);

  // active status filter is handled on click

  const applyDateFilters = useCallback(() => {
    if (dateFilterStart && dateFilterEnd) {
      setSavedDateFilter({
        startDate: formatDate(dateFilterStart, 'yyyy-MM-dd'),
        endDate: formatDate(dateFilterEnd, 'yyyy-MM-dd'),
      });
      setIsDateFilterApplied(true);
    }
  }, [dateFilterStart, dateFilterEnd]);

  // handle clearing filters
  const clearEmployeeFilters = () => {
    setSelectedEmployeeFilters([]);
    setSavedEmployeeFilters([]);
  };

  // active status filter is handled on click

  const clearDateFilter = () => {
    setDateFilterStart(null);
    setDateFilterEnd(null);
    setSavedDateFilter(null);
    setIsDateFilterApplied(false);
  };

  return {
    companyMetrics,
    userId,
    // filter variables
    isEmployeeFilterOpen,
    isActiveFilterOpen,
    isDateRangeFilterOpen,
    employeeFilters,
    selectedEmployeeFilters,
    savedEmployeeFilters,
    isActiveFilterSet,
    dateFilterStart,
    dateFilterEnd,
    savedDateFilter,
    isDateFilterApplied,
    // filter functions
    setIsEmployeeFilterOpen,
    setIsActiveFilterOpen,
    setIsDateRangeFilterOpen,
    onEmployeeFilterListItemClick,
    onActiveFilterOptionClick,
    onChangeDateFilter,
    applyEmployeeFilters,
    applyDateFilters,
    clearEmployeeFilters,
    clearDateFilter,
    // modal variables
    isMetricsNotesModalOpen,
    isDownloadMetricsModalOpen,
    // modal functions
    setIsMetricsNotesModalOpen,
    setIsDownloadMetricsModalOpen,
  };
};

export const useMetricsFunctions = () => useContext(MetricsContext);
