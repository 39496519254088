import { createContext, useContext, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';
import { addOrRemoveFromArray, formatDate } from 'Utils/helpers';
import { emailFiltersSelector, statusFiltersSelector } from 'Containers/RocketPay/selectors';

export const PaymentContext = createContext({});

export const PaymentFunctions = () => {
  // states for admin portal filters
  // selected filters: filters that are currently selected, but are not applied yet
  // saved filters: filters that will be sent through the API once clicking the apply filters button
  const [isEmailFilterOpen, setIsEmailFilterOpen] = useState(false);
  const [selectedEmailFilters, setSelectedEmailFilters] = useState([]);
  const [savedEmailFilters, setSavedEmailFilters] = useState([]);
  const [isStatusFilterOpen, setIsStatusFilterOpen] = useState(false);
  const [selectedStatusFilters, setSelectedStatusFilters] = useState([]);
  const [savedStatusFilters, setSavedStatusFilters] = useState([]);
  const [isDateRangeFilterOpen, setIsDateRangeFilterOpen] = useState(false);
  const [dateFilterStart, setDateFilterStart] = useState(null);
  const [dateFilterEnd, setDateFilterEnd] = useState(null);
  const [savedDateFilter, setSavedDateFilter] = useState(null);
  const [isDateFilterApplied, setIsDateFilterApplied] = useState(false);

  const emailFilters = useSelector(emailFiltersSelector, areEqual);
  const statusFilters = useSelector(statusFiltersSelector, areEqual);

  // handle changing filters
  const onStatusFilterListItemClick = useCallback(
    (statusId: number) => {
      setSelectedStatusFilters(addOrRemoveFromArray(selectedStatusFilters, statusId));
    },
    [selectedStatusFilters]
  );

  const onEmailFilterListItemClick = useCallback(
    (emailId: number) => {
      setSelectedEmailFilters(addOrRemoveFromArray(selectedEmailFilters, emailId));
    },
    [selectedEmailFilters]
  );

  const onChangeDateFilter = (dates) => {
    const [start, end] = dates;
    setDateFilterStart(start);
    setDateFilterEnd(end);
  };

  // handle applying filters
  const applyEmailFilters = useCallback(() => {
    setSavedEmailFilters(selectedEmailFilters);
  }, [selectedEmailFilters]);

  const applyStatusFilters = useCallback(() => {
    setSavedStatusFilters(selectedStatusFilters);
  }, [selectedStatusFilters]);

  const applyDateFilters = useCallback(() => {
    if (dateFilterStart && dateFilterEnd) {
      setSavedDateFilter({
        startDate: formatDate(dateFilterStart, 'yyyy-MM-dd'),
        endDate: formatDate(dateFilterEnd, 'yyyy-MM-dd'),
      });
      setIsDateFilterApplied(true);
    }
  }, [dateFilterStart, dateFilterEnd]);

  // handle clearing filters
  const clearStatusFilters = () => {
    setSelectedStatusFilters([]);
    setSavedStatusFilters([]);
  };

  const clearEmailFilters = () => {
    setSelectedEmailFilters([]);
    setSavedEmailFilters([]);
  };

  const clearDateFilter = () => {
    setDateFilterStart(null);
    setDateFilterEnd(null);
    setSavedDateFilter(null);
    setIsDateFilterApplied(false);
  };

  return {
    // filter variables
    isEmailFilterOpen,
    isStatusFilterOpen,
    isDateRangeFilterOpen,
    selectedStatusFilters,
    selectedEmailFilters,
    savedEmailFilters,
    savedStatusFilters,
    dateFilterStart,
    dateFilterEnd,
    savedDateFilter,
    isDateFilterApplied,

    // filter functions
    setIsEmailFilterOpen,
    setIsStatusFilterOpen,
    setIsDateRangeFilterOpen,
    onStatusFilterListItemClick,
    onEmailFilterListItemClick,
    onChangeDateFilter,
    applyEmailFilters,
    applyStatusFilters,
    applyDateFilters,
    clearStatusFilters,
    clearEmailFilters,
    clearDateFilter,

    emailFilters,
    statusFilters,
  };
};

export const usePaymentFunctions = () => useContext(PaymentContext);
