import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { areEqual } from 'Utils/equalityChecks';

import { PhotoView } from 'Components/RocketScan';
import { PhotoModal } from 'Containers/PhotoViewCarousel/Models';
import { formatDate } from 'Utils/helpers';

import { selectedPhotoSelector } from 'Containers/RocketScan/PhotoView/Carousel/selectors';

function PhotoViewContainer() {
  const [photoUploadedDate, setPhotoUploadedDate] = useState('');
  const [photoUploadedBy, setPhotoUploadedBy] = useState('');

  const selectedPhoto: PhotoModal = useSelector(selectedPhotoSelector, areEqual);

  useEffect(() => {
    if (selectedPhoto?.id) {
      const { created_at: dateCreated } = selectedPhoto;

      let fullName = '[deleted user]';
      if (selectedPhoto.creator) {
        fullName = selectedPhoto.creator.full_name;
      }

      const formattedDate = formatDate(dateCreated, 'h:mmaaa - MMM d, yyyy');

      setPhotoUploadedDate(formattedDate);
      setPhotoUploadedBy(fullName);
    }
  }, [selectedPhoto]);

  const onClickBack = useCallback(() => {
    window.history.back();
  }, []);

  return (
    <PhotoView
      selectedPhoto={selectedPhoto}
      photoUploadedDate={photoUploadedDate}
      photoUploadedBy={photoUploadedBy}
      onClickBack={onClickBack}
    />
  );
}

const PhotoViewContainerMemo = memo(PhotoViewContainer, areEqual);

export { PhotoViewContainerMemo as PhotoViewContainer };
