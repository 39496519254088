import React, { memo } from 'react';
import { Icon } from 'Components/Icons';
import { formatDate } from 'Utils/helpers';

import { Modal } from 'Components/Modal';

import { atmosphericLogType } from 'Containers/RocketDry/types';

import classes from './viewLogModal.module.css';

interface Props {
  log: any;
  type: atmosphericLogType;
  isOpen: boolean;
  setIsOpen: (e: any) => void;
}

function ViewAtmosphericLogModal({ log, type, isOpen, setIsOpen }: Props) {
  switch (type) {
    case atmosphericLogType.External:
      return (
        <Modal title="External Atmospheric Reading" isOpen={isOpen} classes={{ modalDialog: classes.modalOverride }}>
          <h5 className={classes.heading}>
            External Atmospheric Reading{' '}
            <Icon type="close" className={classes.closeModal} onClick={() => setIsOpen(false)} />
          </h5>
          <div className={classes.topWrapper}>
            <img src={log.photo && log.photo.sizes.raw} alt="External Atmospheric Reading" />
            <div className={classes.readingMetaData}>
              <div>
                Recorded By:
                {` ${log.creator?.name}` || ' - '}
              </div>
              <div>
                Recorded On:
                {` ${log.createdAt ? formatDate(log.createdAt, 'PP') : ' - '}`}
              </div>
              <div>
                Image Added:
                {` ${log.photo?.created_at ? formatDate(log.photo?.created_at, 'PP') : ' - '}`}
              </div>
              <div>
                Last Edited On:
                {` ${log.updatedAt ? formatDate(log.updatedAt, 'PP') : ' - '}`}
              </div>
            </div>
          </div>
          <div className={classes.bottomWrapper}>
            <div>
              <h5>Relative Humidity</h5>
              <span>{log.relativeHumidity ? `${log.relativeHumidity} %` : ' - '}</span>
            </div>
            <div>
              <h5>Temperature</h5>
              <span>{log.temperature ? `${log.temperature} °F` : ' - '}</span>
            </div>
            <div>
              <h5>Pressure</h5>
              <span>{log.pressure ? `${log.pressure} kPA` : ' - '}</span>
            </div>
            <div>
              <h5>Wind Speed</h5>
              <span>{log.windSpeed ? `${log.windSpeed} mph` : ' - '}</span>
            </div>
          </div>
        </Modal>
      );
    case atmosphericLogType.Internal:
      return (
        <Modal title="Internal Atmospheric Reading" isOpen={isOpen} classes={{ modalDialog: classes.modalOverride }}>
          <h5 className={classes.heading}>
            Internal Atmospheric Reading{' '}
            <Icon type="close" className={classes.closeModal} onClick={() => setIsOpen(false)} />
          </h5>
          <div className={classes.topWrapper}>
            <img src={log.photo && log.photo.sizes.raw} alt="Internal Atmospheric Reading" />
            <div className={classes.readingMetaData}>
              <div>
                Recorded By:
                {` ${log.creator?.name}` || ' - '}
              </div>
              <div>
                Recorded On:
                {` ${log.createdAt ? formatDate(log.createdAt, 'PP') : ' - '}`}
              </div>
              <div>
                Image Added:
                {` ${log.photo?.created_at ? formatDate(log.photo?.created_at, 'PP') : ' - '}`}
              </div>
              <div>
                Last Edited On:
                {` ${log.updatedAt ? formatDate(log.updatedAt, 'PP') : ' - '}`}
              </div>
            </div>
          </div>
          <div className={classes.bottomWrapper}>
            <div>
              <h5>Relative Humidity</h5>
              <span>{log.relativeHumidity ? `${log.relativeHumidity} %` : ' - '}</span>
            </div>
            <div>
              <h5>Temperature</h5>
              <span>{log.temperature ? `${log.temperature} °F` : ' - '}</span>
            </div>
            <div>
              <h5>Dew Point</h5>
              <span>{log.dewPoint ? `${log.dewPoint}` : ' - '}</span>
            </div>
            <div>
              <h5>GPP</h5>
              <span>{log.gpp ? `${log.gpp}` : ' - '}</span>
            </div>
          </div>
        </Modal>
      );
    default:
      return null;
  }
}

const ViewAtmosphericLogModalMemo = memo(ViewAtmosphericLogModal);

export { ViewAtmosphericLogModalMemo as ViewAtmosphericLogModal };
