import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Albums } from './Albums';
import classes from './CustomReportEditor.module.css';

interface Props {
  room: any;
  onPhotoClick: (e: any) => void;
}

const Rooms = ({ room, onPhotoClick }: Props) => (
  <>
    <div className="">
      <ul className={classes.marginAuto}>
        <li className={`${classes.roomHead} ${classes.marginAuto}`}>{room.name}</li>
      </ul>
      {room.albums.length
        ? room.albums.map((album: any, index: number) => (
            <Albums key={`${index + 1}`} album={album} onPhotoClick={onPhotoClick} />
          ))
        : null}
    </div>
  </>
);

const RoomsMemo = memo(Rooms, areEqual);

export { RoomsMemo as Rooms };
