const legacyFlooringWhitelist = ['Carpet', 'Laminate', 'Engineered', 'Hardwood', 'Concrete'];

const legacyWallsWhitelist = ['Interior Wall', 'Fire Wall', 'Exterior Wall'];

const legacyCeilingWhitelist = [
  'Flat Drywall Ceiling',
  'Textured Drywall',
  'Ceiling Tile',
  'Concrete',
  'Concrete Textured',
];

const legacyPlumbingWhitelist = ['Countertop', 'Vanity Unit'];

const legacyStructuralWhitelist = [
  'Baseboard',
  'Countertop',
  'Door Trim',
  'Drywall',
  'Full Height Cabinetry',
  'Insulation',
  'Lower Cabinetry',
  'Shelving',
  'Upper Cabinetry',
  'Vanity Unit',
  'Window Board',
];

export const legacyWhitelists = {
  flooring: legacyFlooringWhitelist,
  walls: legacyWallsWhitelist,
  ceiling: legacyCeilingWhitelist,
  plumbing: legacyPlumbingWhitelist,
  structural: legacyStructuralWhitelist,
};

const carpentryWhitelist = [
  'Door',
  'Door - Double',
  'Crown Molding',
  'Window Board',
  'Door Trim',
  'Baseboard',
  'Cabinetry - Lower',
  'Cabinetry - Upper',
  'Cabinetry - Full Height',
  'Toe Kick',
  'Door - Bifold',
  'Countertop - Laminate',
  'Vanity Unit',
  'Furring Strip',
  'Countertop - flat laid plastic laminate',
  'Countertop - solid surface/granite',
  'Countertop - solid surface/granite - after hours',
  'Interior door slab only',
  'Wooden Cabinets',
  'Baseboard - North Wall',
  'Baseboard - North East Wall',
  'Baseboard - East Wall',
  'Baseboard - South East Wall',
  'Baseboard - South Wall',
  'Baseboard - South West Wall',
  'Baseboard - West Wall',
  'Baseboard - North West Wall',
];

const ceilingWhitelist = [
  'Ceiling Tile',
  'Concrete',
  'Concrete Textured',
  'Flat Drywall',
  'Popcorn Ceiling',
  'Textured Ceiling',
  'Textured Drywall',
];

const flooringWhitelist = [
  'Carpet',
  'Carpet Pad',
  'Lift Carpet For Drying',
  'Underlay',
  'Hardwood',
  'Tackless Strip',
  'Engineered Wood Flooring',
  'Floating Floor',
  'Concrete',
  'Wooden Top Plate',
  'Concrete slab',
  'Wooden subfloor',
  'Wooden floor joists',
];

const plumbingWhitelist = ['Countertop', 'Vanity Unit'];

const wallsWhitelist = [
  '5/8" Drywall',
  '5/8" Drywall - 4"',
  '5/8" Drywall - 2\'',
  'Insulation - Batt',
  '1/2" Drywall',
  '1/2" Drywall - 4"',
  '1/2" Drywall - 2\'',
  'Tape Joint For New To Existing Drywall',
  'Texture Drywall - Smooth / Skim Coat',
  'Drywall Installer / Finisher - Per Hour',
  'Tile',
  'Wainscoting',
  'Dado Rail',
  'Insulation - Loose-Fill',
  'Insulation - Rigid Foams',
  'Insulation - Spray Foam',
  'Drywall - North Wall',
  'Drywall - North East Wall',
  'Drywall - East Wall',
  'Drywall - South East Wall',
  'Drywall - South Wall',
  'Drywall - South West Wall',
  'Drywall - West Wall',
  'Drywall - North West Wall',
  'Drywall',
  'Wooden paneling',
  'Plaster',
  'Wooden Studs',
  'Wooden Bottom Plate',
];

export const dryingWhitelists = {
  carpentry: carpentryWhitelist,
  ceiling: ceilingWhitelist,
  flooring: flooringWhitelist,
  plumbing: plumbingWhitelist,
  walls: wallsWhitelist,
};
// Appliances, Cleaning, Electrical, Misc, Mitigation, Protection don't have dryable materials
