import React, { memo, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { areEqual } from 'Utils/equalityChecks';

import { userFeatureFlagsSelector } from 'Containers/User/selector';

import { SideBar } from 'Components/SideBar';
import { navItems } from 'Utils/navItems';
import { useUser } from 'Context/User';
import { UserModel } from 'Containers/User/Models/UserModel';

interface Props {
  sideBarDesktop: boolean;
  sideBarMobile: boolean;
  toggleSideBar: () => void;
  pathname: string;
}

function SideBarContainer({ sideBarDesktop, sideBarMobile, toggleSideBar, pathname }: Props) {
  const user = useUser();

  const { companies } = user;
  const { eSignature, timeTracking, rocketScope } = useSelector(userFeatureFlagsSelector, areEqual);
  const { isCompanyAdmin }: UserModel = useUser();

  const [availableItems, setAvailableItems] = useState([]);

  useLayoutEffect(() => {
    let filtered = navItems;

    if (!eSignature) {
      filtered = filtered.filter((navItem) => navItem.title !== 'Forms');
    }

    if (!timeTracking) {
      filtered = filtered.filter((navItem) => navItem.title !== 'Timesheet');
    }

    if (!rocketScope) {
      filtered = filtered.filter((navItem) => navItem.title !== 'Rocket Scope');
    }

    if (!isCompanyAdmin) {
      filtered = filtered.filter((navItem) => !navItem.adminOnly);
    }

    setAvailableItems(filtered);
  }, [eSignature]);

  return (
    <SideBar
      sideBarDesktop={sideBarDesktop}
      sideBarMobile={sideBarMobile}
      toggleSideBar={toggleSideBar}
      navItems={availableItems}
      pathname={pathname}
      companyName={companies?.[0]?.name}
    />
  );
}
const SideBarContainerMemo = memo(SideBarContainer, areEqual);

export { SideBarContainerMemo as SideBarContainer };
