import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { Table, TableBody, TableColumn, TableHeader, TableRow, Th } from 'Components/Table';
import { CompanyProjectsDataModal, CompanyProjectsModal } from 'Containers/Projects/Modals/CompanyProjectsModal';

import { Icon } from 'Components/Icons';
import classes from './timeTrackingProjectsList.module.css';

interface Props {
  sortBy?: string;
  searchValue?: string;
  projects: CompanyProjectsDataModal;
  onClickRow?: (e: any) => void;
  onClickSort?: (sort: string) => void;
}

const TimeTrackingProjectsList = ({ sortBy, searchValue, projects, onClickRow, onClickSort }: Props) =>
  projects?.data?.length > 0 ? (
    <Table className={`table ${classes.projectListWrapper}`}>
      <TableHeader>
        <TableRow>
          <Th>Address</Th>
          <Th>
            <span
              className={classes.thSpan}
              role="button"
              tabIndex={-1}
              onClick={() => onClickSort('-uid')}
              onKeyUp={() => onClickSort('-uid')}
            >
              Project Number
              {sortBy === '-uid' ? (
                <Icon className={classes.sortIcon} type="caretup" />
              ) : (
                <Icon className={classes.sortIcon} type="caretdown" />
              )}
            </span>
          </Th>
        </TableRow>
      </TableHeader>
      <TableBody>
        {projects.data.map(
          ({ id, address: { address: addressPrimary, address_2: addressSecondary }, uid }: CompanyProjectsModal) => (
            <TableRow key={id}>
              <TableColumn dataId={id} tdOnClick={onClickRow}>
                <div className={classes.address}>
                  <p className={classes.street}>
                    {`${addressPrimary}${addressSecondary ? `, ${addressSecondary}` : ''}`}
                  </p>
                </div>
              </TableColumn>

              <TableColumn dataId={id} tdOnClick={onClickRow} className={classes.columnContent}>
                <p className={classes.numberAndDate}>{uid}</p>
              </TableColumn>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  ) : (
    <>
      <Table className={`table ${classes.projectListWrapper}`}>
        <TableHeader>
          <TableRow>
            <Th>Address</Th>
            <Th>
              <span
                className={classes.thSpan}
                role="button"
                tabIndex={-1}
                onClick={() => onClickSort('-uid')}
                onKeyUp={() => onClickSort('-uid')}
              >
                Project Number
              </span>
            </Th>
          </TableRow>
        </TableHeader>
      </Table>
      <div
        className={`d-flex justify-content-center align-items-center flex-column w-100 ${classes.noProjectsContent}`}
      >
        <p className={classes.noProjectsText}>
          {searchValue?.length >= 2
            ? `No projects found for "${searchValue}".`
            : 'No projects yet. Create a new project.'}
        </p>
        <Icon type="rocketemblemtwo" />
      </div>
    </>
  );

TimeTrackingProjectsList.defaultProps = {
  searchValue: null,
  sortBy: null,
  onClickRow: null,
  onClickSort: null,
};

const TimeTrackingProjectsListMemo = memo(TimeTrackingProjectsList, areEqual);

export { TimeTrackingProjectsListMemo as TimeTrackingProjectsList };
