// types
import {
  RocketPayTypes,
  SET_WEB_COMPONENT_TOKEN,
  SET_TOKEN_FETCHED,
  RESET_TOKEN_STATE,
  SET_ROCKETPAY_PAYMENT_LIST,
  SET_EMAIL_FILTERS,
} from 'Containers/RocketPay/actions';

// state
const initialState = {
  payments: { data: [], meta: {}, links: {} },
  emailFilters: [],
  webComponentToken: '',
  tokenFetched: false,
};

export const rocketPayReducer = (state = initialState, action: RocketPayTypes) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ROCKETPAY_PAYMENT_LIST:
      return {
        ...state,
        payments: payload,
      };
    case SET_EMAIL_FILTERS:
      return {
        ...state,
        emailFilters: payload,
      };
    case SET_WEB_COMPONENT_TOKEN:
      return {
        ...state,
        webComponentToken: payload,
      };
    case SET_TOKEN_FETCHED:
      return {
        ...state,
        tokenFetched: payload,
      };
    case RESET_TOKEN_STATE:
      return initialState;
    default:
      return state;
  }
};
