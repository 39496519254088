import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Icon } from 'Components/Icons';
import { PillButton } from 'Components/PillButton';
import { PillBadge } from 'Components/PillBadge';
import { TimesheetFilterList } from 'Components/TimeTracking';
import { SearchBox } from 'Components/SearchBox';
import { PurpleButton } from 'Components/Button';

import DatePicker from 'react-datepicker';

import { formatDate } from 'Utils/helpers';

import classes from './metricsFilters.module.css';

import 'react-datepicker/dist/react-datepicker-min.module.css';

interface Props {
  startDate: any;
  endDate: any;
  isEmployeeFilterOpen: boolean;
  isActiveFilterOpen: boolean;
  isDateRangeFilterOpen: boolean;
  // employee search
  employeeFilters: any[];
  selectedEmployeeFilters: any[];
  employeeSearchRef: any;
  employeeSearchValue: string;
  savedEmployeeFiltersCount: number;
  // active filter
  activeFilterSet: boolean;
  // date filter
  dateFilterApplied: boolean;
  // employee filter functions
  onEmployeeFilterClick: (e: any) => void;
  onEmployeeFilterListItemClick: (e: any) => void;
  onChangeEmployeeSearchValue: (e: any) => void;
  onClickEmployeeSearchClearButton: (e: any) => void;
  // active filter functions
  onActiveFilterClick: (e: any) => void;
  onActiveFilterOptionClick: (e: any) => void;
  // date filter functions
  onDateRangeFilterClick: (e: any) => void;
  onChangeDate: (e: any) => void;
  onClearDateFilterButtonClick: (e: any) => void;
  // apply filter function
  onApplyFilterButtonClick: (e: any) => void;
  onClearFilterButtonClick: (e: any) => void;
}

function MetricsFilters({
  startDate,
  endDate,
  isEmployeeFilterOpen,
  isActiveFilterOpen,
  isDateRangeFilterOpen,
  // employee search
  employeeFilters,
  selectedEmployeeFilters,
  employeeSearchRef,
  employeeSearchValue,
  savedEmployeeFiltersCount,
  // active filter
  activeFilterSet,
  // date filter
  dateFilterApplied,
  // employee search functions
  onEmployeeFilterClick,
  onEmployeeFilterListItemClick,
  onChangeEmployeeSearchValue,
  onClickEmployeeSearchClearButton,
  // active filter functions
  onActiveFilterClick,
  onActiveFilterOptionClick,
  // date filter functions
  onDateRangeFilterClick,
  onChangeDate,
  onClearDateFilterButtonClick,
  // apply filter function
  onApplyFilterButtonClick,
  onClearFilterButtonClick,
}: Props) {
  return (
    <div className={classes.container}>
      Filters
      <div className={classes.filterRow}>
        <PillButton className={classes.filterButton} onClick={onEmployeeFilterClick}>
          Employee Name
          {savedEmployeeFiltersCount > 0 && (
            <PillBadge text={savedEmployeeFiltersCount.toString()} className={classes.badge} />
          )}
          <Icon className={classes.sortIcon} type={isEmployeeFilterOpen ? 'caretUp' : 'caretdown'} />
        </PillButton>
        <PillButton className={classes.filterButton} onClick={onActiveFilterClick}>
          Active
          {activeFilterSet && <PillBadge text="✓" className={classes.badge} />}
          <Icon className={classes.sortIcon} type={isActiveFilterOpen ? 'caretUp' : 'caretdown'} />
        </PillButton>
        <PillButton className={classes.filterButton} onClick={onDateRangeFilterClick}>
          Date Range
          {dateFilterApplied && <PillBadge text="✓" className={classes.badge} />}
          <Icon className={classes.sortIcon} type={isDateRangeFilterOpen ? 'caretUp' : 'caretdown'} />
        </PillButton>
      </div>
      {isEmployeeFilterOpen && (
        <div className={classes.filterOptionContainer}>
          <SearchBox
            ref={employeeSearchRef}
            id="employee-search"
            name="search"
            ariaLabel="Search a employee"
            value={employeeSearchValue}
            onChangeValue={onChangeEmployeeSearchValue}
            onClickClearButton={onClickEmployeeSearchClearButton}
          />
          <TimesheetFilterList
            items={employeeFilters}
            type="employee"
            onClickItem={onEmployeeFilterListItemClick}
            selectedItems={selectedEmployeeFilters}
            onApplyFilterButtonClicked={onApplyFilterButtonClick}
            onClearFilterButtonClicked={onClearFilterButtonClick}
          />
        </div>
      )}
      {isActiveFilterOpen && (
        <div className={`${classes.activeFilterOptionContainer} ${classes.secondFilterOption}`}>
          <span role="button" onClick={onActiveFilterOptionClick} onKeyUp={onActiveFilterOptionClick} tabIndex={0}>
            <Icon type={activeFilterSet ? 'checkbox' : 'square'} className={classes.icon} />
            Show only active employees
          </span>
        </div>
      )}
      {isDateRangeFilterOpen && (
        <div className={`${classes.dateFilterOptionContainer} ${classes.thirdFilterOption}`}>
          <div className={classes.dateFilterSelectionContainer}>
            <div>
              Start:
              {startDate ? formatDate(startDate, 'PP') : ''}
            </div>
            <br />
            <div>
              End:
              {endDate ? formatDate(endDate, 'PP') : ''}
            </div>
          </div>

          <div className={classes.datePickerContainer}>
            <DatePicker
              todayButton="Today"
              selected={startDate}
              onChange={onChangeDate}
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              selectsRange
              inline
              isClearable
            />
          </div>

          <div className={classes.dateFilterButtonContainer}>
            <PurpleButton onClick={onApplyFilterButtonClick} id="date">
              Apply Filter
            </PurpleButton>
            <br />
            <PurpleButton onClick={onClearDateFilterButtonClick} id="date">
              Clear
            </PurpleButton>
          </div>
        </div>
      )}
    </div>
  );
}

const MetricsFiltersMemo = memo(MetricsFilters, areEqual);

export { MetricsFiltersMemo as MetricsFilters };
