import { Spinner } from 'Components/Spinner';
import { ProjectsPagination } from 'Components/Projects';
import { ProjectsList } from 'Containers/Projects';
import { TabContent } from 'Components/Tabs';
import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import classes from './projectsBase.module.css';

interface Props {
  sortBy?: string;
  projects: any;
  fetching: boolean;
  initialPage: number;
  pageCount: number;
  projectCategory: string;
  onPageChange: (e: any) => void;
  onClickRow?: (e: any) => void;
  onClickSort?: (e: any) => void;
}

function ProjectsBase({
  projects,
  sortBy,
  initialPage,
  pageCount,
  projectCategory,
  onPageChange,
  onClickRow,
  onClickSort,
  fetching,
}: Props) {
  return (
    <TabContent key={`tab-content-${projectCategory}`} id={projectCategory} className="position-relative">
      <div className={classes.projectContent}>
        {fetching && <Spinner loading />}
        {!fetching && (
          <ProjectsList
            iconType="my"
            projects={projects}
            sortBy={sortBy}
            onClickRow={onClickRow}
            onClickSort={onClickSort}
          />
        )}
      </div>
      {projects?.meta?.total >= projects?.meta?.per_page && (
        <ProjectsPagination initialPage={initialPage} pageCount={pageCount} onPageChange={onPageChange} />
      )}
    </TabContent>
  );
}

ProjectsBase.defaultProps = {
  sortBy: null,
  onClickRow: null,
  onClickSort: null,
};

const ProjectsBaseMemo = memo(ProjectsBase, areEqual);

export { ProjectsBaseMemo as ProjectsBase };
