import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { MultiUnitBody } from 'Components/TabContentBody';
import { Spinner } from 'Components/Spinner';

import { ProjectNotes } from 'Containers/RocketScan';

import { fetchingProjectSelector } from 'Containers/RocketScan/selectors';
import { setRefreshLocations } from 'Containers/RocketScan/MultiUnit/Locations/actions';
import { clearRoomsObject } from 'Containers/RocketScan/RoomsView/Rooms/actions';

import { selectPhotosModeSelector } from 'Containers/RocketScan/Header/ActionsCenter/selectors';

interface Props {
  isCommercialProperty?: boolean;
}

function MultiUnitContainer({ isCommercialProperty }: Props) {
  const dispatch = useDispatch();

  const [localLocationCreated, setLocalLocationCreated] = useState(false);
  const [isUnitModalOpen, setIsUnitModalOpen] = useState(false);
  const [isFloorModalOpen, setIsFloorModalOpen] = useState(false);

  const fetching = useSelector(fetchingProjectSelector, areEqual);
  const isSelectingPhotoMode = useSelector(selectPhotosModeSelector, areEqual);

  // clear rooms data on rooms view when switching units
  useEffect(() => {
    dispatch(clearRoomsObject());
  }, []);

  const setUnitModalStatus = useCallback(() => {
    setIsFloorModalOpen(false);
    setIsUnitModalOpen(true);
  }, []);

  const setFloorModalStatus = useCallback(() => {
    setIsUnitModalOpen(false);
    setIsFloorModalOpen(true);
  }, []);

  const onClickCloseLocationCreate = useCallback(() => {
    setIsUnitModalOpen(false);
    setIsFloorModalOpen(false);
  }, []);

  useEffect(() => {
    if (localLocationCreated) {
      onClickCloseLocationCreate();
    }
  }, [localLocationCreated]);

  useEffect(() => {
    if (localLocationCreated && !isUnitModalOpen && !isFloorModalOpen) {
      dispatch(setRefreshLocations(true));
      setLocalLocationCreated(false);
    }
  }, [localLocationCreated, isUnitModalOpen, isFloorModalOpen]);

  return (
    <>
      <Spinner loading={fetching} />

      <br />
      <ProjectNotes />
      {!fetching && (
        <MultiUnitBody
          isCommercialProperty={isCommercialProperty}
          isUnitModalOpen={isUnitModalOpen}
          setUnitModalStatus={setUnitModalStatus}
          isFloorModalOpen={isFloorModalOpen}
          setFloorModalStatus={setFloorModalStatus}
          closeModal={onClickCloseLocationCreate}
          localLocationCreated={localLocationCreated}
          isSelectingPhotoMode={isSelectingPhotoMode}
          setLocalLocationCreated={setLocalLocationCreated}
        />
      )}
    </>
  );
}

MultiUnitContainer.defaultProps = {
  isCommercialProperty: false,
};

const MultiUnitContainerMemo = memo(MultiUnitContainer, areEqual);

export { MultiUnitContainerMemo as MultiUnitContainer };
