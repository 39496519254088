import React, { memo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { DownloadMetricsModal } from 'Components/Metrics';

import { useMetricsFunctions } from 'Context/Metrics';

import { firstCompanyIdSelector } from 'Containers/Projects/selectors';

import { exportCompanyMetricsCsv } from 'Containers/Metrics/actions';

import { MetricsFilters } from 'Containers/Metrics/types';

function DownloadMetricsModalContainer() {
  const dispatch = useDispatch();

  const {
    savedEmployeeFilters,
    isActiveFilterSet,
    savedDateFilter,
    isDownloadMetricsModalOpen,
    setIsDownloadMetricsModalOpen,
  }: any = useMetricsFunctions();

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

  const [loading, setLoading] = useState(false);

  const onDownloadButtonClick = useCallback(async () => {
    setLoading(true);
    // const url = ''; // dispatch whatever
    if (firstCompanyId) {
      const filters: MetricsFilters = {};
      if (savedDateFilter) {
        filters.startsBetween = savedDateFilter;
      }
      if (savedEmployeeFilters) {
        filters.employeeId = savedEmployeeFilters.toString();
      }
      if (isActiveFilterSet) {
        filters.active = isActiveFilterSet;
      }

      dispatch(exportCompanyMetricsCsv(firstCompanyId, filters));
    }
    setLoading(false);
  }, [firstCompanyId, savedEmployeeFilters, isActiveFilterSet, savedDateFilter]);

  const closeModal = () => {
    setIsDownloadMetricsModalOpen(false);
  };

  return (
    <DownloadMetricsModal
      loading={loading}
      isOpen={isDownloadMetricsModalOpen}
      onDownloadButtonClick={onDownloadButtonClick}
      modalCloseClick={closeModal}
    />
  );
}

const DownloadMetricsModalContainerMemo = memo(DownloadMetricsModalContainer, areEqual);

export { DownloadMetricsModalContainerMemo as DownloadMetricsModal };
