import React, { memo } from 'react';

import { ViewAtmosphericLogModal } from 'Components/RocketDry';

import { useRocketDryFunctions } from 'Context/RocketDry';

function ViewAtmosphericLogModalContainer() {
  const {
    selectedAtmosphericLog,
    selectedAtmosphericLogType,
    isViewAtmosphericLogModalOpen,
    setIsViewAtmosphericLogModalOpen,
  }: any = useRocketDryFunctions();

  return (
    <ViewAtmosphericLogModal
      log={selectedAtmosphericLog}
      type={selectedAtmosphericLogType}
      isOpen={isViewAtmosphericLogModalOpen}
      setIsOpen={setIsViewAtmosphericLogModalOpen}
    />
  );
}

const ViewAtmosphericLogModalMemo = memo(ViewAtmosphericLogModalContainer);

export { ViewAtmosphericLogModalMemo as ViewAtmosphericLogModal };
