import React, { memo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { ClockedInTimeCardNotes } from 'Components/TimeTracking';

import { createTimeCardNoteErrorsSelector, timeCardNotesSelector } from 'Containers/TimeTracking/selectors';

import { createTimecardNote, listTimeCardNotes } from 'Containers/TimeTracking/actions';

import { useNotesFunctions } from 'Context/Notes';

import { NOTE_LENGTH_LIMIT } from 'Utils/constants';

import { limitText } from 'Utils/helpers';

interface Props {
  notesOpen: boolean;
  projectClockedInCardId: number;
  setNotesOpen: (e: any) => void;
}

const ClockedInTimeCardNotesContainer = ({ notesOpen, projectClockedInCardId, setNotesOpen }: Props) => {
  const dispatch = useDispatch();

  // get note delete related functions
  const { noteDeleted, setNoteId, setIsOpenDeleteNoteModal, setNoteDeleted }: any = useNotesFunctions();

  const timeCardNotes = useSelector(timeCardNotesSelector, areEqual);
  const noteErrors = useSelector(createTimeCardNoteErrorsSelector, areEqual);

  const [notePlaceholder, setNotePlaceholder] = useState('');
  const [newNoteText, setNewNoteText] = useState('');
  const [disablePostButton, setDisablePostButton] = useState(false);

  // get notes
  const getNotes = useCallback((cardId, page = 1) => {
    if (cardId) {
      dispatch(listTimeCardNotes(cardId, page));
    }
  }, []);

  // initial fetch for notes
  useEffect(() => {
    if (projectClockedInCardId) {
      getNotes(projectClockedInCardId);
    } else {
      setNotePlaceholder('');
    }
  }, [projectClockedInCardId]);

  // update the recent note placeholder
  useEffect(() => {
    if (timeCardNotes?.length > 0) {
      const [note] = timeCardNotes;
      const { body } = note;

      setNotePlaceholder(limitText(body, 30));
    }
  }, [timeCardNotes]);

  // form errors
  const errors = {
    body: noteErrors?.body || [],
  };

  // fetch on delete
  useEffect(() => {
    if (noteDeleted && projectClockedInCardId) {
      getNotes(projectClockedInCardId);
      setNoteDeleted(false);
    }
  }, [noteDeleted, projectClockedInCardId]);

  const onNotesSectionClick = useCallback(() => {
    if (projectClockedInCardId) {
      setNotesOpen(true);
    }
  }, [projectClockedInCardId]);

  const onChangeNoteText = useCallback(({ target: { value } }: any) => {
    if (value.length <= NOTE_LENGTH_LIMIT) {
      setNewNoteText(value);
    }
  }, []);

  const onTimeCareNoteCreated = () => {
    setNewNoteText('');
  };

  const onPostNoteButtonClick = useCallback(
    (e: any) => {
      e.preventDefault();
      setDisablePostButton(true);
      setTimeout(() => {
        setDisablePostButton(false);
      }, 1000);
      if (projectClockedInCardId) {
        dispatch(createTimecardNote(projectClockedInCardId, { body: newNoteText }, onTimeCareNoteCreated));
      }
      setNotePlaceholder('Add your notes here');
    },
    [newNoteText, projectClockedInCardId]
  );

  const onClickDeleteIcon = useCallback((id: number) => {
    setNoteId(id);
    setIsOpenDeleteNoteModal(true);
  }, []);

  return (
    <ClockedInTimeCardNotes
      projectClockedIn={!!projectClockedInCardId}
      notesOpen={notesOpen}
      notePlaceholder={notePlaceholder}
      newNoteText={newNoteText}
      timeCardNotes={timeCardNotes}
      postNoteButtonDisabled={disablePostButton}
      noteErrors={errors}
      onNotesSectionClick={onNotesSectionClick}
      onChangeNoteText={onChangeNoteText}
      onPostNoteButtonClick={onPostNoteButtonClick}
      onClickDeleteIcon={onClickDeleteIcon}
    />
  );
};

const ClockedInTimeCardNotesContainerMemo = memo(ClockedInTimeCardNotesContainer, areEqual);

export { ClockedInTimeCardNotesContainerMemo as ClockedInTimeCardNotes };
