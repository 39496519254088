export function selectHeaderIcon(propertyType: string) {
  switch (propertyType) {
    case 'singleunit':
      return 'singlehome';
    case 'singlelocation':
      return 'unitlg';
    case 'multiunit':
    case 'commercial':
    case 'exterior':
    case 'school':
      return propertyType;
    default:
      return 'singlehome';
  }
}
