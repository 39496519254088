import React, { memo, ReactNode } from 'react';

import { areEqual } from 'Utils/equalityChecks';
import { TimeTrackingContext, TimeTrackingFunctions } from '.';

interface Props {
  children: ReactNode;
}

const TimeTrackingProvider = ({ children }: Props) => {
  const timeTrackings = TimeTrackingFunctions();

  return <TimeTrackingContext.Provider value={{ ...timeTrackings }}>{children}</TimeTrackingContext.Provider>;
};

const TimeTrackingProviderMemo = memo(TimeTrackingProvider, areEqual);

export { TimeTrackingProviderMemo as TimeTrackingProvider };
