import React, { memo } from 'react';

import { areEqualShallow } from 'Utils/equalityChecks';

import DownloadEmpty from '../../../Assets/download_empty.svg';

// Custom css
import classes from './downloadEmpty.module.css';

interface Props {
  className?: string;
  id?: string;
}

const DownloadEmptySvg = ({ className = '', id }: Props) => (
  <DownloadEmpty id={id} className={`${classes.iconBase} ${className || ''}`} />
);

const DownloadEmptySvgMemo = memo(DownloadEmptySvg, areEqualShallow);
export { DownloadEmptySvgMemo as DownloadEmptySvg };
