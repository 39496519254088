import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Modal } from 'Components/Modal';
import { DarkPurpleButton, DeleteButton } from 'Components/Button';

import classes from './deleteEsignatureModal.module.css';

interface Props {
  loading: boolean;
  showDeleteModal: boolean;
  reportName: string;
  esign: any;
  onDeleteButtonClick: (e: any) => void;
  onCancelButtonClick: (e: any) => void;
  modalCloseClick: (e: any) => void;
}

const DeleteEsignatureModal = ({
  esign,
  loading,
  showDeleteModal,
  reportName,
  onDeleteButtonClick,
  onCancelButtonClick,
  modalCloseClick,
}: Props) => (
  <Modal
    title="Delete Document?"
    isOpen={showDeleteModal}
    classes={classes}
    modalHeader
    modalCloseClick={modalCloseClick}
  >
    <div>
      <p>{reportName}</p>
      <p>This action cannot be undone</p>
    </div>
    <div className="d-flex w-100 justify-content-center">
      <DeleteButton onClick={() => onDeleteButtonClick(esign)} className={classes.buttons} disabled={loading}>
        Delete
      </DeleteButton>
      <DarkPurpleButton onClick={onCancelButtonClick} className={classes.buttons} disabled={loading}>
        Cancel
      </DarkPurpleButton>
    </div>
  </Modal>
);

const DeleteEsignatureModalMemo = memo(DeleteEsignatureModal, areEqual);

export { DeleteEsignatureModalMemo as DeleteEsignatureModal };
