// types
import { handleApiRequest } from 'Utils/handleApiRequest';
import { separateMultiDayTimeCardsForTimeSheet } from 'Containers/TimeTracking/helpers';

import { intervalToDuration, isSameDay } from 'date-fns';
import { downloadLocal } from 'Utils/helpers';
import { TimeTrackingCard, TimeCardFilters, TimeTrackingCardTimesheet } from './types';

export const SET_USER_TIME_CARDS = 'SET_USER_TIME_CARDS';
export const SET_USER_CLOCKED_IN_TIME_CARDS = 'SET_USER_CLOCKED_IN_TIME_CARDS';
export const SET_PROJECT_TIME_CARDS = 'SET_PROJECT_TIME_CARDS';
export const CLOCK_IN_SUCCESS = 'CLOCK_IN_SUCCESS';
export const CLOCK_OUT_SUCCESS = 'CLOCK_OUT_SUCCESS';
export const REFRESH_TIME_CARDS = 'REFRESH_TIME_CARDS';
export const REFRESH_COMPANY_TIME_CARDS = 'REFRESH_COMPANY_TIME_CARDS';
export const TIME_CARD_CREATED = 'TIME_CARD_CREATED';
export const TIME_CARD_UPDATED = 'TIME_CARD_UPDATED';
export const TIME_CARD_NOTE_CREATED = 'TIME_CARD_NOTE_CREATED';
export const SET_COMPANY_TIME_CARDS = 'SET_COMPANY_TIME_CARDS';
export const SET_EMPLOYEES_FILTERS = 'SET_EMPLOYEES_FILTERS';
export const SET_PROJECT_NUMBERS_FILTERS = 'SET_PROJECT_NUMBERS_FILTERS';
export const SET_ADDRESSES_FILTERS = 'SET_ADDRESSES_FILTERS';
export const SET_PROJECT_NUMBERS_SIMPLE_LIST = 'SET_PROJECT_NUMBERS_SIMPLE_LIST';
export const SET_ADDRESSES_SIMPLE_LIST = 'SET_ADDRESSES_SIMPLE_LIST';
export const SET_EMPLOYEES_DROPDOWN = 'SET_EMPLOYEES_DROPDOWN';
export const SET_PROJECTS_DROPDOWN = 'SET_PROJECTS_DROPDOWN';
export const CREATE_TIME_CARD_ERRORS = 'CREATE_TIME_CARD_ERRORS';
export const UPDATE_TIME_CARD_ERRORS = 'UPDATE_TIME_CARD_ERRORS';
export const CREATE_TIME_CARD_NOTE_ERRORS = 'CREATE_TIME_CARD_NOTE_ERRORS';
export const SET_TIME_CARD_NOTES = 'SET_TIME_CARD_NOTES';

interface ActionTypes {
  SET_USER_TIME_CARDS: TimeTrackingCard[];
  SET_USER_CLOCKED_IN_TIME_CARDS: TimeTrackingCard[];
  CLOCK_IN_SUCCESS: boolean;
  CLOCK_OUT_SUCCESS: boolean;
  REFRESH_TIME_CARDS: boolean;
  REFRESH_COMPANY_TIME_CARDS: boolean;
  TIME_CARD_CREATED: boolean;
  TIME_CARD_UPDATED: boolean;
  TIME_CARD_NOTE_CREATED: boolean;
  SET_COMPANY_TIME_CARDS: any[];
  SET_EMPLOYEES_FILTERS: any[];
  SET_PROJECT_NUMBERS_FILTERS: any[];
  SET_ADDRESSES_FILTERS: any[];
  SET_EMPLOYEES_DROPDOWN: any[];
  SET_PROJECT_NUMBERS_SIMPLE_LIST: any[];
  SET_ADDRESSES_SIMPLE_LIST: any[];
  SET_PROJECTS_DROPDOWN: any[];
  CREATE_TIME_CARD_ERRORS: any;
  UPDATE_TIME_CARD_ERRORS: any;
  CREATE_TIME_CARD_NOTE_ERRORS: any;
  SET_TIME_CARD_NOTES: any[];
}

interface MessageAction {
  type: keyof ActionTypes;
  payload: any;
}

export type TimeTrackingTypes = MessageAction;

/*
 * NON-API THUNKS
 * */
export const setClockInSuccess = (status: boolean) => async (dispatch: any) =>
  dispatch({
    type: CLOCK_IN_SUCCESS,
    payload: status,
  });

export const setClockOutSuccess = (status: boolean) => async (dispatch: any) =>
  dispatch({
    type: CLOCK_OUT_SUCCESS,
    payload: status,
  });

export const setRefreshTimeCards = (status: boolean) => async (dispatch: any) =>
  dispatch({
    type: REFRESH_TIME_CARDS,
    payload: status,
  });

export const setRefreshCompanyTimeCards = (status: boolean) => async (dispatch: any) =>
  dispatch({
    type: REFRESH_COMPANY_TIME_CARDS,
    payload: status,
  });

export const setTimeCardNoteCreated = (value: boolean) => (dispatch) => {
  dispatch({
    type: TIME_CARD_NOTE_CREATED,
    payload: value,
  });
};

export const setTimeCardNotes = (value: any[]) => (dispatch) => {
  dispatch({
    type: SET_TIME_CARD_NOTES,
    payload: value,
  });
};

/*
 * API THUNKS
 * */
/* eslint-disable */
export const setUserTimeCards =
  (userId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.get(`users/${userId}/timecards`, {
        params: {
          include: 'timecardType,project,project.address',
        },
      })
    );

    if (response?.data) {
      const userTimeCards: TimeTrackingCard[] = response.data.map((card) => {
        const timeInParsed = Date.parse(card.time_in);
        const timeOutParsed = card.time_out ? Date.parse(card.time_out) : null;

        const { project: cardProject } = card;
        const newCard: TimeTrackingCard = {
          id: card.id.toString(),
          projectId: cardProject?.id.toString(),
          address: cardProject?.address?.address,
          timeIn: card.time_in,
          timeOut: card.time_out,
          timecardType: card.timecard_type,
          elapsed: card.elapsed,
          sameDay: isSameDay(timeInParsed, timeOutParsed ?? new Date()),
        };

        if (timeOutParsed) {
          newCard.duration = intervalToDuration({
            start: timeInParsed,
            end: timeOutParsed,
          });
        }

        return newCard;
      });

      dispatch({
        type: SET_USER_TIME_CARDS,
        payload: userTimeCards,
      });
    }
  };

export const setUserClockedInTimeCards =
  (userId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.get(`users/${userId}/timecards`, {
        params: {
          'filter[no_time_outs]': 1,
          include: 'timecardType,project,project.address',
        },
      })
    );

    if (response?.data) {
      const userClockedCards: TimeTrackingCard[] = response.data.map((card) => {
        const timeInParsed = Date.parse(card.time_in);
        const timeOutParsed = card.time_out ? Date.parse(card.time_out) : null;
        const { project: cardProject } = card;

        const newCard: TimeTrackingCard = {
          id: card.id.toString(),
          projectId: cardProject?.id.toString(),
          address: cardProject?.address?.address,
          timeIn: card.time_in,
          timeOut: card.time_out,
          timecardType: card.timecard_type,
          sameDay: isSameDay(timeInParsed, timeOutParsed ?? new Date()),
        };

        return newCard;
      });

      dispatch({
        type: SET_USER_CLOCKED_IN_TIME_CARDS,
        payload: userClockedCards,
      });
    }
  };

export const setUserTimeCardsForTimeRange =
  (userId: number, startDate: string, endDate: string) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.get(`users/${userId}/timecards`, {
        params: {
          include: 'timecardType,project,project.address',
          'filter[starts_between]': `${startDate},${endDate}`,
        },
      })
    );

    if (response?.data) {
      const userTimeCards: TimeTrackingCard[] = response.data.map((card) => {
        const timeInParsed = Date.parse(card.time_in);
        const timeOutParsed = card.time_out ? Date.parse(card.time_out) : null;
        const { project: cardProject } = card;
        const newCard: TimeTrackingCard = {
          id: card.id.toString(),
          projectId: cardProject?.id.toString(),
          address: cardProject?.address?.address,
          projectNumber: cardProject?.uid,
          timeIn: card.time_in,
          timeOut: card.time_out,
          timecardType: card.timecard_type,
          elapsed: card.elapsed,
          sameDay: isSameDay(timeInParsed, timeOutParsed ?? new Date()),
        };

        if (timeOutParsed) {
          newCard.duration = intervalToDuration({
            start: timeInParsed,
            end: timeOutParsed,
          });
        }

        return newCard;
      });

      return userTimeCards;
    } else {
      return [];
    }
  };

export const createTimeCard =
  (
    projectId: number,
    timeIn: string,
    timecardType: number,
    timeOut?: string,
    userId?: string,
    onTimeCardCreated?: any
  ) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.post(`projects/${projectId}/timecards`, {
        time_in: timeIn,
        timecard_type_id: timecardType,
        time_out: timeOut,
        user_id: userId,
      }),
      CREATE_TIME_CARD_ERRORS
    );

    if (response?.data) {
      dispatch(setRefreshCompanyTimeCards(true));
      if (onTimeCardCreated) {
        onTimeCardCreated();
      }
    }
  };

export const updateTimeCard =
  (timeCardId: number, timeCardType: number, userId: number, timeIn: string, timeOut: string, onTimeCardEdited?: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.put(`timecards/${timeCardId}`, {
        time_in: timeIn,
        timecard_type_id: timeCardType,
        time_out: timeOut,
        user_id: userId,
      }),
      UPDATE_TIME_CARD_ERRORS
    );

    if (response?.data) {
      dispatch(setRefreshCompanyTimeCards(true));
      if (onTimeCardEdited) {
        onTimeCardEdited();
      }
    }
  };

export const deleteTimeCard =
  (timeCardId: number, onTimeCardDeleted?: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(dispatch, utils.Api.delete(`timecards/${timeCardId}`));

    if (typeof response === 'string') {
      dispatch(setRefreshCompanyTimeCards(true));
      if (onTimeCardDeleted) {
        onTimeCardDeleted();
      }
    }
  };

export const clockIn =
  (projectId: number, timeIn: string, timeCardType: number, refreshTimeCards: boolean = false) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.post(`projects/${projectId}/timecards`, {
        time_in: timeIn,
        timecard_type_id: timeCardType,
      })
    );

    if (response?.data) {
      dispatch(setClockInSuccess(true));
      // post-processing for when user clocks in while clocked in to another project (i.e. they want to switch)
      // see the clockOut comment for details
      if (refreshTimeCards) {
        dispatch(setRefreshTimeCards(true));
      }
    }
  };

export const clockOut =
  (timeCard: TimeTrackingCard, timeOut: string, willClockInNext: boolean = false) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const { id: timeCardId, timeIn, timecardType: timecardType } = timeCard;
    const response = await handleApiRequest(
      dispatch,
      utils.Api.put(`timecards/${timeCardId}`, {
        time_in: timeIn,
        time_out: timeOut,
        timecard_type_id: timecardType.id,
      })
    );

    if (response?.data) {
      // When user clocks in while clocked in to another project (i.e. they want to switch), don't set
      // clockOutSuccess to true. Otherwise the post-processing code can run multiple times.
      // That can be handled in the clock in process.
      if (!willClockInNext) {
        dispatch(setClockOutSuccess(true));
      }
    }
  };

export const clockOutUserTimeCardForProject =
  (userId: number, projectId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.get(`users/${userId}/timecards`, {
        params: {
          include: 'timecardType',
          'filter[no_time_outs]': 1,
          'filter[project_id]': projectId,
        },
      })
    );

    if (response?.data?.length > 0) {
      const clockOutTime = new Date().toISOString();
      const userClockedInTimeCards: TimeTrackingCard[] = response.data.map((card) => {
        const newCard: TimeTrackingCard = {
          id: card.id.toString(),
          timeIn: card.time_in,
          timecardType: card.timecard_type,
          sameDay: true,
        };

        return newCard;
      });

      userClockedInTimeCards.forEach((timeCard: TimeTrackingCard) => {
        dispatch(clockOut(timeCard, clockOutTime, true));
      });

      dispatch(setClockOutSuccess(true));
    }
  };

export const setProjectTimeCards = (projectId: number, page = 1) => {
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.get(`projects/${projectId}/timecards`, {
        params: {
          page,
        },
      })
    );

    if (response?.data) {
      dispatch({
        type: SET_USER_TIME_CARDS,
        payload: {
          ...response,
          page,
        },
      });
    }
  };
};

export const listCompanyTimeCards =
  (companyId: number, filters: TimeCardFilters, page = 1) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const params = {
      include: 'timecardType,project,project.address,user,notes_count',
      page,
      'filter[project_id]': filters.projectId,
      'filter[user_id]': filters.employeeId,
      'filter[address_id]': filters.addressId,
    };

    if (filters.startsBetween) {
      params['filter[starts_between]'] = `${filters.startsBetween.startDate},${filters.startsBetween.endDate}`;
    }

    const response = await handleApiRequest(
      dispatch,
      utils.Api.get(`companies/${companyId}/timecards`, {
        params,
      })
    );

    // if (filters) {
    //   console.log('filters', filters);
    // }

    if (response?.data) {
      const companyTimeCards: TimeTrackingCard[] = response.data.map((card) => {
        const timeInParsed = Date.parse(card.time_in);
        const timeOutParsed = card.time_out ? Date.parse(card.time_out) : null;
        const { project: cardProject } = card;
        const newCard: TimeTrackingCard = {
          id: card.id.toString(),
          projectId: cardProject?.id.toString(),
          projectNumber: cardProject?.uid,
          timeIn: card.time_in,
          timeOut: card.time_out,
          timecardType: card.timecard_type,
          elapsed: card.elapsed,
          sameDay: isSameDay(timeInParsed, timeOutParsed ?? new Date()),
          user: card.user.full_name,
          userId: card.user.id,
          notesCount: card.notes_count,
        };

        if (cardProject) {
          const { address } = cardProject;
          newCard.address = address.address;
          newCard.address2 = `${address.city}, ${address.state}, ${address.zip}`;
        }

        if (timeOutParsed) {
          newCard.duration = intervalToDuration({
            start: timeInParsed,
            end: timeOutParsed,
          });
        }

        return newCard;
      });
      // console.log({
      //   data: companyTimeCards,
      //   meta: response.meta ?? {},
      //   links: response.links ?? {},
      // });

      const companyTimeCardsSplitByDay: TimeTrackingCardTimesheet[] = companyTimeCards.reduce(
        (cards: TimeTrackingCardTimesheet[], currentCard: TimeTrackingCard) => {
          if (currentCard.sameDay) {
            cards.push(currentCard);
          } else {
            const newCards: TimeTrackingCardTimesheet[] = separateMultiDayTimeCardsForTimeSheet(currentCard);
            cards.push(...newCards);
          }

          return cards;
        },
        []
      );
      dispatch({
        type: SET_COMPANY_TIME_CARDS,
        payload: {
          data: companyTimeCardsSplitByDay,
          meta: response.meta ?? {},
          links: response.links ?? {},
        },
      });
    } else {
      dispatch({
        type: SET_COMPANY_TIME_CARDS,
        payload: response,
      });
    }
  };

export const listEmployeesForTimesheets =
  (companyId: number, searchValue = '') =>
  async (dispatch: any, _getState = null, utils: any) => {
    let lastPage = false;
    let currentEmployees = [];
    let page = 1;

    while (!lastPage) {
      const response = await handleApiRequest(
        dispatch,
        utils.Api.get(`companies/${companyId}/users`, {
          params: {
            'filter[search]': searchValue,
            page,
            limit: 100,
          },
        })
      );

      lastPage = response?.meta?.current_page === response?.meta?.last_page;

      page += 1;
      if (response?.data) {
        currentEmployees.push(...response.data);
      }
    }

    const employeeNameIds = currentEmployees
      .filter((employee: any) => !/^alex\+.*@rocketplantech.com$/.test(employee.email))
      .map((employee: any) => {
        return {
          id: employee.id,
          name: employee.full_name,
        };
      });
    dispatch({
      type: SET_EMPLOYEES_FILTERS,
      payload: employeeNameIds,
    });
    if (!searchValue) {
      dispatch({
        type: SET_EMPLOYEES_DROPDOWN,
        payload: currentEmployees,
      });
    }
  };

export const listProjectsAndAddressesForTimesheets =
  (companyId: number, searchValue = '', sort = '-created_at') =>
  async (dispatch: any, _getState = null, utils: any) => {
    let lastPage = false;
    let currentProjects = [];
    let page = 1;

    while (!lastPage) {
      const response = await handleApiRequest(
        dispatch,
        utils.Api.get(`companies/${companyId}/projects`, {
          params: {
            include: 'address',
            sort,
            page,
            'filter[search]': searchValue,
          },
        })
      );

      lastPage = response?.meta?.current_page === response?.meta?.last_page;

      page += 1;
      if (response?.data) {
        currentProjects.push(...response.data);
      }
    }

    const [projectNumbers, addresses, projectInfoSimple] = currentProjects.reduce(
      ([a, b, c], project) => {
        const { id: projectId, uid: projectNumber, address } = project;

        a.push({
          id: projectId,
          name: projectNumber,
        });

        const { id: addressId, address: addressText } = address;
        b.push({
          id: addressId,
          name: addressText,
        });

        c.push({
          id: projectId,
          name: addressText,
          name2: projectNumber,
        });
        return [a, b, c];
      },
      [[], [], []]
    );

    dispatch({
      type: SET_PROJECT_NUMBERS_FILTERS,
      payload: projectNumbers,
    });
    dispatch({
      type: SET_ADDRESSES_FILTERS,
      payload: addresses,
    });
    if (!searchValue) {
      dispatch({
        type: SET_PROJECTS_DROPDOWN,
        payload: projectInfoSimple,
      });
    }
  };

export const createTimecardNote =
  (timecardId: number, requestData: any, onTimeCardNoteCreated?: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.post(`timecards/${timecardId}/notes`, requestData),
      CREATE_TIME_CARD_NOTE_ERRORS
    );

    if (response?.data) {
      // dispatch(setTimeCardNoteCreated(true));
      dispatch(listTimeCardNotes(timecardId));
      if (onTimeCardNoteCreated) {
        onTimeCardNoteCreated();
      }
    }
  };

export const listTimeCardNotes =
  (timecardId: number, startDate?: any, endDate?: string) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const params = {};
    if (startDate && endDate) {
      params['filter[starts_between]'] = `${startDate},${endDate}`;
    }

    const response = await handleApiRequest(dispatch, utils.Api.get(`timecards/${timecardId}/notes`, params));

    if (response?.data) {
      const { data } = response;
      dispatch({
        type: SET_TIME_CARD_NOTES,
        payload: data,
      });
    }
  };

export const exportCompanyTimeCardCsv =
  (companyId: number, filters: TimeCardFilters) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const params = {
      limit: 120,
      'filter[project_id]': filters.projectId,
      'filter[user_id]': filters.employeeId,
      'filter[address_id]': filters.addressId,
    };

    if (filters.startsBetween) {
      params['filter[starts_between]'] = `${filters.startsBetween.startDate},${filters.startsBetween.endDate}`;
    }

    const response = await handleApiRequest(
      dispatch,
      utils.Api.csvGet(`companies/${companyId}/timecards/export`, {
        params,
      })
    );

    if (response) {
      const name = 'timesheets.csv';
      downloadLocal(response, name);
    }
  };
