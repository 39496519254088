import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { Icon } from 'Components/Icons';

import { PurpleButton } from 'Components/Button';

import classes from './formPlaceHolder.module.css';

interface Props {
  onClickPlaceholderButton: (e: any) => void;
}

const FormPlaceholder = ({ onClickPlaceholderButton }: Props) => (
  <div className={`d-flex align-items-center justify-content-center ${classes.placeHolderWrapper}`}>
    <div className={`d-flex flex-column align-items-center justify-content-center ${classes.placeHolderContainer}`}>
      <div className={classes.formIcon}>
        <Icon type="projects" />
      </div>
      <div className={classes.text}>You haven't added any contracts yet</div>

      <PurpleButton className={classes.buttonInvite} onClick={onClickPlaceholderButton}>
        Add Contract +
      </PurpleButton>
    </div>
  </div>
);

const FormPlaceholderMemo = memo(FormPlaceholder, areEqual);
export { FormPlaceholderMemo as FormPlaceholder };
