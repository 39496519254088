export const processData = (json: any) => {
  // filter out photos with missing rooms and locations
  json = json.filter((data) => data.photoable);
  json = json.filter((data: { photoable: { location: any } }) => data.photoable.location !== null);
  // Generates Units
  const units = json
    .map((data: { photoable: { location: { name: any } } }) => ({ unit: data.photoable.location.name, rooms: [] }))
    .filter(
      (item: { unit: any }, index: any, self: any[]) =>
        index === self.findIndex((selfItem) => selfItem.unit === item.unit)
    )
    .filter(Boolean);

  // Maps all rooms to their respective unit
  const unitsWithRooms = units.map((unit: { unit: any; rooms: any[] }) => {
    const rooms = json
      .map((data: any) => {
        if (data.photoable.location.name === unit.unit) {
          return { name: data.photoable.room_type.name, albums: [] };
        }
        return undefined;
      })
      .filter(Boolean);
    // filter out duplicate rooms before spread
    unit.rooms = [
      ...rooms.filter(
        (item: { name: any }, index: any, self: any[]) =>
          index === self.findIndex((selfItem) => selfItem.name === item.name)
      ),
    ];

    return unit;
  });

  // Maps all albums to their respective room
  // next up unitsRoomsAlbums
  const unitsRoomsAlbums = unitsWithRooms.map((unitWithRooms: { rooms: any[]; unit: any }) => {
    const unitWithRoomsAlbums = unitWithRooms.rooms.map((room) => {
      const albums = json
        .map((data: any) => {
          if (data.photoable.location.name === unitWithRooms.unit && data.photoable.room_type.name === room.name) {
            return { name: data.albums[0].name, photos: [] };
          }
          return undefined;
        })
        .filter(Boolean);
      // filter out duplicate albums before spread
      room.albums = [
        ...albums.filter(
          (item: { name: any }, index: any, self: any[]) =>
            index === self.findIndex((selfItem) => selfItem.name === item.name)
        ),
      ];
      return room;
    });
    unitWithRooms.rooms = [...unitWithRoomsAlbums];
    return unitWithRooms;
  });

  // Maps all photos to their respective album
  // then unitsRoomsAlbumsPhotos
  const unitsRoomsAlbumsPhotos = unitsRoomsAlbums.map((unitWithRoomsAlbums: { rooms: any[]; unit: any }) => {
    const unitWithRoomsAlbumsPhotos = unitWithRoomsAlbums.rooms.map((room) => {
      const albumsPhotos = room.albums.map((album: { name: any; photos: any[] }) => {
        const photos = json
          .map((data: any) => {
            if (
              data.photoable.location.name === unitWithRoomsAlbums.unit &&
              data.photoable.room_type.name === room.name &&
              data.albums[0].name === album.name
            ) {
              return data.sizes;
            }
            return undefined;
          })
          .filter(Boolean);

        album.photos = [...photos];
        return album;
      });

      room.albums = [...albumsPhotos];
      return room;
    });
    unitWithRoomsAlbums.rooms = [...unitWithRoomsAlbumsPhotos];
    return unitWithRoomsAlbums;
  });
  // Returns processed data
  return unitsRoomsAlbumsPhotos;
};
