import { handleApiRequest } from 'Utils/handleApiRequest';
import { PaymentFilters } from 'Containers/RocketPay/RocketPayPending/RocketPayPending';

export const SET_WEB_COMPONENT_TOKEN = 'SET_WEB_COMPONENT_TOKEN';
export const SET_TOKEN_FETCHED = 'SET_TOKEN_FETCHED';
export const RESET_TOKEN_STATE = 'RESET_TOKEN_STATE';

export const SET_ROCKETPAY_PAYMENT_LIST = 'SET_ROCKETPAY_PAYMENT_LIST';
export const SET_EMAIL_FILTERS = 'SET_EMAIL_FILTERS';

interface ActionTypes {
  SET_ROCKETPAY_PAYMENT_LIST: any[];
  SET_EMAIL_FILTERS: string[];
  SET_WEB_COMPONENT_TOKEN: string;
  SET_TOKEN_FETCHED: string;
  RESET_TOKEN_STATE: any;
}

interface MessageAction {
  type: keyof ActionTypes;
  payload: any;
}

export type RocketPayTypes = MessageAction;

/*
 * NON-API THUNKS
 * */
export const setWebComponentToken = (token: string) => ({
  type: SET_WEB_COMPONENT_TOKEN,
  payload: token,
});

export const setTokenFetched = (fetched: boolean) => ({
  type: SET_TOKEN_FETCHED,
  payload: fetched,
});

export const resetTokenState = () => (dispatch) => {
  dispatch({
    type: RESET_TOKEN_STATE,
  });
};

/*
 * API THUNKS
 * */
/* eslint-disable */
export const getRocketPayStatus =
  (companyId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(dispatch, utils.Api.get(`/companies/${companyId}/rocket-pay`));
  };

export const createJustiFiBusinessForCompany =
  (companyId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(dispatch, utils.Api.post(`/companies/${companyId}/rocket-pay`));
  };

export const fetchWebComponentToken =
  (companyId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const state = _getState();
    if (state.rocketPay.tokenFetched) {
      return;
    }
    dispatch(setTokenFetched(true));

    const response = await handleApiRequest(
      dispatch,
      utils.Api.get(`/companies/${companyId}/rocket-pay/web-component-token`)
    );
    if (response.web_component_token) {
      dispatch(setWebComponentToken(response.web_component_token));
    }
  };

export const listEmailsForPayments =
  (companyId: number, searchValue = '') =>
  async (dispatch: any, _getState = null, utils: any) => {
    let lastPage = false;
    let currentEmails = [];
    let page = 1;

    while (!lastPage) {
      const response = await handleApiRequest(
        dispatch,
        utils.Api.get(`companies/${companyId}/rocket-pay/payment/emails`, {
          params: {
            'filter[email]': searchValue,
            limit: 100,
            page,
          },
        })
      );

      lastPage = response?.meta?.current_page === response?.meta?.last_page;

      page += 1;
      currentEmails.push(
        ...response.data.map((data: { email: string }, index: number) => {
          return {
            id: index + 1,
            name: data.email,
          };
        })
      );
    }
    dispatch({
      type: SET_EMAIL_FILTERS,
      payload: currentEmails,
    });
  };

export const getPaymentList =
  (companyId: number, page: number, sortBy: string, filters: PaymentFilters) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const params = {
      page,
      sort: sortBy,
      'filter[email]': filters.email,
      'filter[payment_status]': 'unpaid',
    };

    if (filters.startsBetween) {
      params['filter[created_at]'] = `${filters.startsBetween.startDate},${filters.startsBetween.endDate}`;
    }

    const response = await handleApiRequest(
      dispatch,
      utils.Api.get(`/companies/${companyId}/rocket-pay/payments`, {
        params,
      })
    );

    if (response?.data) {
      dispatch({
        type: SET_ROCKETPAY_PAYMENT_LIST,
        payload: {
          data: response.data,
          meta: response.meta ?? {},
          links: response.links ?? {},
        },
      });
    }
  };

export const addBankAccountToRocketPay =
  (
    companyId: number,
    accountOwnerName: string,
    accountType: string,
    accountNumber: string,
    routingNumber: string,
    bankName?: string
  ) =>
  async (dispatch: any, _getState = null, utils: any) => {
    let requestData = {
      account_owner_name: accountOwnerName,
      account_type: accountType,
      account_number: accountNumber,
      routing_number: routingNumber,
    };

    if (bankName) {
      requestData['bank_name'] = bankName;
    }

    return await handleApiRequest(
      dispatch,
      utils.Api.post(`/companies/${companyId}/rocket-pay/add-bank-account`, requestData)
    );
  };

export const acceptJustiFiTerms =
  (companyId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(dispatch, utils.Api.post(`/companies/${companyId}/rocket-pay/accept`));
  };

export const getRocketPayWebComponentToken =
  (companyId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(dispatch, utils.Api.get(`/companies/${companyId}/rocket-pay/web-component-token`));
  };

export const syncRocketPayAccountInfo =
  (companyId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(dispatch, utils.Api.post(`/companies/${companyId}/rocket-pay/synchronize`));
  };

export const testProvisionPayment =
  (companyId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(
      dispatch,
      utils.Api.post(`/companies/${companyId}/rocket-pay/test-provision-payment`)
    );
  };
