import React, { memo } from 'react';

import { areEqualShallow } from 'Utils/equalityChecks';
import ClockIn from '../../../Assets/clock-in.svg';

// Custom css
import classes from './clockIn.module.css';

interface Props {
  className?: string;
  id?: string;
  onClick?: (e: any) => void;
  onKeyUp?: (e: any) => void;
}
const ClockInSvg = ({ className = '', id, onClick, onKeyUp }: Props) => (
  <ClockIn id={id} className={`${classes.iconBase} ${className || ''}`} onClick={onClick} onKeyUp={onKeyUp} />
);

ClockInSvg.defaultProps = {
  className: undefined,
  id: undefined,
  onClick: undefined,
  onKeyUp: undefined,
};

const ClockInSvgMemo = memo(ClockInSvg, areEqualShallow);

export { ClockInSvgMemo as ClockInSvg };
