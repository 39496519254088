import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { CompanyProjectsDataModal } from 'Containers/Projects/Modals';
import { useSelector } from 'react-redux';

// import { Spinner } from 'Components/Spinner';
import { ProjectRow } from 'Containers/ProjectRow';
import { Spinner } from 'Components/Spinner';
import { fetchingStProjects } from 'Containers/Projects/selectors';
import classes from './projectSelection.module.css';

interface Props {
  myProjects: CompanyProjectsDataModal;
  fetching: any;
  handleChangeProject: any;
  activeProject: any;
  activeTab: number;
  handleViewAll: any;
  onlyShowMyProjects: boolean;
  setTabWIP: (e: any) => void;
  setTabMyProjects: (e: any) => void;
}

function ProjectSelection({
  myProjects,
  fetching,
  handleChangeProject,
  activeProject,
  activeTab,
  handleViewAll,
  onlyShowMyProjects,
  setTabWIP,
  setTabMyProjects,
}: Props) {
  const StJobsLoading = useSelector(fetchingStProjects);

  return (
    <div className={`card ${classes.cardBase}`}>
      <div onKeyUp={handleViewAll} onClick={handleViewAll} role="button" tabIndex={0} className={`${classes.viewAll}`}>
        View All
      </div>
      <div className={classes.tabsRow}>
        <div
          onKeyUp={setTabMyProjects}
          role="button"
          tabIndex={0}
          onClick={setTabMyProjects}
          className={`${classes.tabContainer} ${activeTab === 0 ? classes.selectedTabContainer : ''} `}
        >
          My Projects
        </div>
        {!onlyShowMyProjects && (
          <div
            onKeyUp={setTabWIP}
            onClick={setTabWIP}
            role="button"
            tabIndex={0}
            className={`${classes.tabContainer} ${classes.secondPosition} ${
              activeTab === 1 ? classes.selectedTabContainer : ''
            }`}
          >
            WIP
          </div>
        )}
      </div>
      {fetching && (
        <div className={classes.relativePos}>
          <Spinner loading />
        </div>
      )}
      <div className={classes.projectsContainer}>
        {StJobsLoading && <div className={classes.loadingStJobs}>Loading ServiceTitan Jobs</div>}
        {/* TODO:: TO AJ - don't use nested ternary expressions (eslint doesn't like them) */}
        {!fetching &&
          myProjects?.data?.length > 0 &&
          myProjects.data.map((project) => (
            <ProjectRow
              handleChangeProject={handleChangeProject}
              selected={activeProject?.id === project.id}
              key={project.uid}
              project={project}
              title={project.address.address}
              numOfPhotos={project.photos_count}
              numOfUnits={project.units_count}
              numOfFloors={project.floors_count}
              uid={project.uid}
              alias={project.alias}
            />
          ))}
      </div>
    </div>
  );
}

const ProjectSelectionMemo = memo(ProjectSelection, areEqual);

export { ProjectSelectionMemo as ProjectSelection };
