import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { TextBox } from 'Components/TextBox';
import { Icon } from 'Components/Icons';
import { DropDown } from 'Components/DropDown';

import classes from './rocketCallEmployeeDropdown.module.css';

interface Props {
  dropdownType: string;
  className?: string;
  selectedOption: string;
  choices: Array<any>;
  selectedOptionId: number;
  showDropDown: boolean;
  onChangeValueSelect: (e: any) => void;
  onSelectItem: (e: any) => void;
  onClickIcon: (e: any) => void;
}

function RocketCallEmployeeDropdown({
  dropdownType,
  className,
  selectedOption,
  choices,
  selectedOptionId,
  showDropDown,
  onChangeValueSelect,
  onSelectItem,
  onClickIcon,
}: Props) {
  return (
    <div className={`dropdown ${className || ''} ${classes.dropdownSelectBase}`}>
      <TextBox
        name={`${dropdownType}Select`}
        type="text"
        ariaLabel="Select an Employee"
        className={classes.inlineTextbox}
        value={selectedOption}
        onChange={onChangeValueSelect}
        autoComplete="off"
        placeholder=""
      />
      <Icon className={classes.icon} type={showDropDown ? 'caretup' : 'caretdown'} onClick={onClickIcon} />
      {choices && (
        <DropDown
          className={classes.dropDown}
          id={dropdownType}
          items={choices}
          selected={selectedOptionId}
          onSelectItem={onSelectItem}
          showDropDown={showDropDown}
        />
      )}
    </div>
  );
}

RocketCallEmployeeDropdown.defaultProps = {
  className: undefined,
};

const RocketCallEmployeeDropdownMemo = memo(RocketCallEmployeeDropdown, areEqual);

export { RocketCallEmployeeDropdownMemo as RocketCallEmployeeDropdown };
