import React, { memo } from 'react';

import { areEqualShallow } from 'Utils/equalityChecks';
import Save from '../../../Assets/save.svg';

interface Props {
  className?: string;
  id?: string;
}
const SaveSvg = ({ className = '', id }: Props) => (
  <Save
    id={id}
    className={`${className || ''}`}
  />
);

const SaveSvgMemo = memo(SaveSvg, areEqualShallow);
export { SaveSvgMemo as SaveSvg };