import React, { memo } from 'react';

import { areEqualShallow } from 'Utils/equalityChecks';
import Undo from '../../../Assets/undo.svg';

interface Props {
  className?: string;
  id?: string;
}
const UndoSvg = ({ className = '', id }: Props) => (
  <Undo
    id={id}
    className={`${className || ''}`}
  />
);

const UndoSvgMemo = memo(UndoSvg, areEqualShallow);
export { UndoSvgMemo as UndoSvg };