import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { TimeTrackingProjectsList } from 'Components/TimeTracking';
import { CompanyProjectsDataModal } from 'Containers/Projects/Modals/CompanyProjectsModal';

import { useProjectsFunctions } from 'Context/Projects';

interface Props {
  sortBy?: string;
  projects: CompanyProjectsDataModal;
  onClickRow?: (e: any) => void;
  onClickSort?: (sort: string) => void;
}

const TimeTrackingProjectsListContainer = ({ sortBy, projects, onClickRow, onClickSort }: Props) => {
  const { searchValue }: any = useProjectsFunctions();

  return (
    <TimeTrackingProjectsList
      projects={projects}
      searchValue={searchValue}
      sortBy={sortBy}
      onClickRow={onClickRow}
      onClickSort={onClickSort}
    />
  );
};

TimeTrackingProjectsListContainer.defaultProps = {
  sortBy: null,
  onClickRow: null,
  onClickSort: null,
};

const TimeTrackingProjectsListContainerMemo = memo(TimeTrackingProjectsListContainer, areEqual);

export { TimeTrackingProjectsListContainerMemo as TimeTrackingProjectsList };
