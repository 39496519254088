import { AnyAction } from 'redux';
import {
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  SET_SCOPE_SHEETS,
  SET_CURRENT_SHEET,
  SET_HAS_CHANGES,
  SET_IS_EDITING,
  SET_LOADING,
  SET_SELECTED_ROW,
  SET_CHANGES,
  SET_REMOVED,
  SET_NEW_CATEGORY,
  SET_IS_NEW_CATEGORY_OPEN,
  SET_NEW_CATEGORY_FORM_ERRORS,
  SET_SAVE_ERROR,
  SET_HAS_FILE_UPLOAD,
} from './actions';

const initialState = {
  scopeSheets: undefined,
  currentSheet: 0,
  hasChanges: false,
  hasFileUpload: false,
  isEditing: false,
  loading: false,
  selectedRow: null,
  changes: {},
  removed: {},
  newCategory: '',
  isNewCategoryOpen: false,
  newCategoryFormErrors: { category: [] },
  saveError: '',
};

export const rocketScopeReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return { ...state, loading: true };
    case FETCH_DATA_SUCCESS:
      return { ...state, loading: false };
    case FETCH_DATA_FAILURE:
      return { ...state, loading: false, saveError: action.error };
    case SET_SCOPE_SHEETS:
      return { ...state, scopeSheets: action.payload };
    case SET_CURRENT_SHEET:
      return { ...state, currentSheet: action.payload };
    case SET_HAS_CHANGES:
      return { ...state, hasChanges: action.payload };
    case SET_IS_EDITING:
      return { ...state, isEditing: action.payload };
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_SELECTED_ROW:
      return { ...state, selectedRow: action.payload };
    case SET_CHANGES:
      return { ...state, changes: action.payload };
    case SET_REMOVED:
      return { ...state, removed: action.payload };
    case SET_NEW_CATEGORY:
      return { ...state, newCategory: action.payload };
    case SET_IS_NEW_CATEGORY_OPEN:
      return { ...state, isNewCategoryOpen: action.payload };
    case SET_NEW_CATEGORY_FORM_ERRORS:
      return { ...state, newCategoryFormErrors: action.payload };
    case SET_SAVE_ERROR:
      return { ...state, saveError: action.payload };
    case SET_HAS_FILE_UPLOAD:
      return { ...state, hasFileUpload: action.payload };
    default:
      return state;
  }
};
