import { createContext, useCallback, useContext, useState } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

export const RoomsViewContext = createContext({});

export const RoomsViewFunctions = () => {
  const location = useLocation();
  const history = useHistory();

  const [currentRoomInfo, setCurrentRoomInfo] = useState(undefined);
  const [currentPhotoId, setCurrentPhotoId] = useState(undefined);
  const [isOpenDeleteRoomModal, setIsOpenDeleteRoomModal] = useState(false);
  const [isOpenPhotoViewModal, setIsOpenPhotoViewModal] = useState(false);

  const openDeleteRoomModal = useCallback(() => {
    setIsOpenDeleteRoomModal(true);
  }, []);

  const openPhotoViewModal = useCallback(
    (photoId) => {
      setIsOpenPhotoViewModal((original) => {
        if (original !== true) {
          const params = new URLSearchParams({ photoId });
          history.push({ pathname: location.pathname, search: params.toString() });
        }
        return true;
      });
      if (photoId !== currentPhotoId) {
        setCurrentPhotoId(photoId);
      }
    },
    [currentPhotoId]
  );

  const closePhotoViewModal = useCallback(() => {
    setIsOpenPhotoViewModal(false);
  }, []);

  const setRoomInfo = useCallback((roomId, roomName, levelName) => {
    setCurrentRoomInfo({
      roomId,
      roomName,
      levelName,
    });
  }, []);

  return {
    currentRoomInfo,
    setRoomInfo,
    isOpenDeleteRoomModal,
    setIsOpenDeleteRoomModal,
    openDeleteRoomModal,
    closePhotoViewModal,
    isOpenPhotoViewModal,
    openPhotoViewModal,
    currentPhotoId,
  };
};

export const useRoomsViewFunctions = () => useContext(RoomsViewContext);
