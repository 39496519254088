import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useDispatch, useSelector } from 'react-redux';
import { ContractForm } from 'Components/ContractForm/ContractForm/ContractForm';
import { createCompanyContracts, setContractCreated } from '../actions';
import { firstCompanyIdSelector } from '../../Projects/selectors';
import { contractCreatedSelector } from '../selectors';

interface Props {
  closeEditor: () => void;
}

function CreateContractContainer({ closeEditor }: Props) {
  const dispatch = useDispatch();
  const mounted = useRef(true);
  const editorRef = useRef(null);
  // const textareaRef = useRef(null);
  const [formName, setFormName] = useState('');
  const [template, setTemplate] = useState('');
  const [replacementTags, setReplacementTags] = useState(['company', 'company_logo', 'address']);
  const [status, setStatus] = useState('inactive');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [hasSignature, setHasSignature] = useState(false);
  const [loading, setLoading] = useState(false);
  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);
  const contractCreated = useSelector(contractCreatedSelector, areEqual);

  const onChangeFormName = useCallback((e: any) => {
    const { value } = e.target;
    setStatus('active');
    setFormName(value);
    setIsButtonEnabled(value.length !== 0 || template.length !== 0);
  }, []);

  const onHasSignatureClick = useCallback(
    (e) => {
      setHasSignature(e.target.checked);
    },
    [hasSignature]
  );

  // const onChangeTemplate = useCallback((e: any) => {
  //   const { value } = e.target;

  //   setTemplate(value);
  //   setIsButtonEnabled(value.length === 0);
  //   e.target.focus();
  // }, []);

  const updateEditor = useCallback((value: any) => {
    editorRef.current.execCommand('mceInsertContent', false, `${value} `);
    setTemplate(editorRef.current.getContent());
  }, []);

  const onChangeReplacementTags = useCallback(
    (value: string) => {
      if (/~~~/.test(value)) {
        const replacementTagStripped = value.replace(/~~~/g, '');
        setIsButtonEnabled(formName.length !== 0 || template.length !== 0);

        if (replacementTags.indexOf(replacementTagStripped) < 0) {
          const replacementTagsNew = replacementTags;
          replacementTagsNew.push(replacementTagStripped);
          setReplacementTags(replacementTagsNew);
        }
      }
      updateEditor(value);

      // const start = textareaRef.current.value.slice(0, textareaRef.current.selectionStart);
      // const end = textareaRef.current.value.slice(textareaRef.current.selectionStart, textareaRef.current.value.length);
      // const newTemplate = start + value + end;
      // setTemplate(newTemplate);
      // textareaRef.current.focus();
      // textareaRef.current.selectionEnd = end + value.length;
    },
    [formName, template]
  );

  const onFormButtonClick = useCallback(
    (e: any) => {
      e.preventDefault();
      const template = editorRef.current.getContent();

      setLoading(true);
      dispatch(
        createCompanyContracts(firstCompanyId, formName, replacementTags.join(','), status, template, hasSignature)
      );
    },
    [formName, replacementTags, status, hasSignature]
  );

  const onCloseClick = useCallback((e: any) => {
    e.preventDefault();
    closeEditor();
  }, []);

  useEffect(() => {
    mounted.current = true;
    setTemplate(
      `<p> ~~~company~~~ </p>
      <p> ~~~company_logo~~~ </p>
      <p> ~~~company_address~~~ </p>
    `
    );
    return () => {
      if (mounted) {
        mounted.current = false;
      }
    };
  }, [firstCompanyId]);

  useEffect(() => {
    if (contractCreated) {
      setLoading(false);
      closeEditor();
      dispatch(setContractCreated(false));
      setFormName('');
      setTemplate(
        `<p> ~~~company~~~ </p>
        <p> ~~~company_logo~~~ </p>
        <p> ~~~company_address~~~ </p>
      `
      );
    }
  }, [contractCreated]);

  return (
    <ContractForm
      formName={formName}
      template={template}
      hasSignature={hasSignature}
      loading={loading}
      editorRef={editorRef}
      // textareaRef={textareaRef}
      isButtonEnabled={isButtonEnabled}
      onChangeFormName={onChangeFormName}
      // onChangeTemplate={onChangeTemplate}
      onChangeReplacementTags={onChangeReplacementTags}
      onFormButtonClick={onFormButtonClick}
      onHasSignatureClick={onHasSignatureClick}
      onCloseClick={onCloseClick}
    />
  );
}

const CreateContractContainerMemo = memo(CreateContractContainer, areEqual);

export { CreateContractContainerMemo as CreateContract };
