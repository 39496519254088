import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Icon } from 'Components/Icons';
import { PillButton } from 'Components/PillButton';
import { PillBadge } from 'Components/PillBadge';
import { PaymentFilterList } from 'Components/RocketPay/RocketPayPending/PaymentFilterList';
import { SearchBox } from 'Components/SearchBox';
import { PurpleButton } from 'Components/Button';

import DatePicker from 'react-datepicker';

import { formatDate } from 'Utils/helpers';

import classes from './paymentFilters.module.css';

import 'react-datepicker/dist/react-datepicker-min.module.css';

interface Props {
  startDate: any;
  endDate: any;
  isEmailFilterOpen: boolean;
  isDateRangeFilterOpen: boolean;
  // email search
  emailFilters: any[];
  selectedEmailFilters: any[];
  emailSearchRef: any;
  emailSearchValue: string;
  savedEmailFiltersCount: number;
  // date filter
  dateFilterApplied: boolean;
  // email filter functions
  onEmailFilterClick: (e: any) => void;
  onEmailFilterListItemClick: (e: any) => void;
  onChangeEmailSearchValue: (e: any) => void;
  onClickEmailSearchClearButton: (e: any) => void;
  // date filter functions
  onDateRangeFilterClick: (e: any) => void;
  onChangeDate: (e: any) => void;
  onClearDateFilterButtonClick: (e: any) => void;
  // apply filter function
  onApplyFilterButtonClick: (e: any) => void;
  onClearFilterButtonClick: (e: any) => void;
}

function PaymentFilters({
  startDate,
  endDate,
  isEmailFilterOpen,
  isDateRangeFilterOpen,
  // email search
  emailFilters,
  selectedEmailFilters,
  emailSearchRef,
  emailSearchValue,
  savedEmailFiltersCount,
  // date filter
  dateFilterApplied,
  // email search functions
  onEmailFilterClick,
  onEmailFilterListItemClick,
  onChangeEmailSearchValue,
  onClickEmailSearchClearButton,
  // date filter functions
  onDateRangeFilterClick,
  onChangeDate,
  onClearDateFilterButtonClick,
  // apply filter function
  onApplyFilterButtonClick,
  onClearFilterButtonClick,
}: Props) {
  return (
    <div className={classes.container}>
      Filters
      <div className={classes.filterRow}>
        <PillButton className={classes.filterButton} onClick={onEmailFilterClick}>
          Email
          {savedEmailFiltersCount > 0 && (
            <PillBadge text={savedEmailFiltersCount.toString()} className={classes.badge} />
          )}
          <Icon className={classes.sortIcon} type={isEmailFilterOpen ? 'caretUp' : 'caretdown'} />
        </PillButton>
        <PillButton className={classes.filterButton} onClick={onDateRangeFilterClick}>
          Date Range
          {dateFilterApplied && <PillBadge text="✓" className={classes.badge} />}
          <Icon className={classes.sortIcon} type={isDateRangeFilterOpen ? 'caretUp' : 'caretdown'} />
        </PillButton>
      </div>
      {isEmailFilterOpen && (
        <div className={classes.filterOptionContainer}>
          <SearchBox
            ref={emailSearchRef}
            id="email-search"
            name="search"
            ariaLabel="Search a email"
            value={emailSearchValue}
            onChangeValue={onChangeEmailSearchValue}
            onClickClearButton={onClickEmailSearchClearButton}
          />
          <PaymentFilterList
            items={emailFilters}
            type="email"
            onClickItem={onEmailFilterListItemClick}
            selectedItems={selectedEmailFilters}
            onApplyFilterButtonClicked={onApplyFilterButtonClick}
            onClearFilterButtonClicked={onClearFilterButtonClick}
          />
        </div>
      )}
      {isDateRangeFilterOpen && (
        <div className={`${classes.dateFilterOptionContainer} ${classes.thirdFilterOption}`}>
          <div className={classes.dateFilterSelectionContainer}>
            <div>
              Start:
              {startDate ? formatDate(startDate, 'PP') : ''}
            </div>
            <br />
            <div>
              End:
              {endDate ? formatDate(endDate, 'PP') : ''}
            </div>
          </div>

          <div className={classes.datePickerContainer}>
            <DatePicker
              todayButton="Today"
              selected={startDate}
              onChange={onChangeDate}
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              selectsRange
              inline
              isClearable
            />
          </div>

          <div className={classes.dateFilterButtonContainer}>
            <PurpleButton onClick={onApplyFilterButtonClick} id="date">
              Apply Filter
            </PurpleButton>
            <br />
            <PurpleButton onClick={onClearDateFilterButtonClick} id="date">
              Clear
            </PurpleButton>
          </div>
        </div>
      )}
    </div>
  );
}

const PaymentFiltersMemo = memo(PaymentFilters, areEqual);

export { PaymentFiltersMemo as PaymentFilters };
