import React, { memo, useCallback, useRef, useEffect, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useParams, useHistory } from 'react-router-dom';
import formClasses from 'Themes/form/form.module.css';
import { Label } from 'Components/Label';
import { TextBox } from 'Components/TextBox';
import { ValidateBackGround } from 'Components/Validation';
import { Editor } from '@tinymce/tinymce-react';
import { PurpleButton } from 'Components/Button';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from 'Containers/User/selector';
import { projectAddressSelector, projectIdSelector, projectAliasSelector } from 'Containers/RocketScan/selectors';
import { createReport, setReportUpdated, updateReport } from 'Containers/ReportsAndDocuments/Reports/actions';
import { formatDate } from 'Utils/helpers';
import { getPropertyData } from 'Containers/ProjectData/PropertyData/actions';
import { propertyDataSelector } from 'Containers/ProjectData/PropertyData/selectors';
import { reportUpdatedSelector, selectedReportSelector } from 'Containers/ReportsAndDocuments/Reports/selectors';
import { useUser } from 'Context/User';
import { UserModel } from 'Containers/User/Models/UserModel';
import classes from './CustomReportEditor.module.css';
import { ProjectInfo } from './ProjectInfo';
import { populateCustomReportData, populateUnitsPhotosData } from '../actions';
import { customReportDataSelector } from '../selectors';

interface Props {
  title: string;
  fetching: boolean;
  formErrors: any;
  onChangeTitle: (e: any) => void;
}

// helpers to handle auto-filling the initial template contents - also used when manually inserting content
const fullCompanyAddressFill = (companyAddress) => {
  if (companyAddress) {
    return `${companyAddress.address}, ${companyAddress.city}, ${companyAddress.state}, ${companyAddress.country} `;
  }
  return '';
};

const fullProjectAddressFill = (address) => {
  if (address) {
    return `${address.address}, ${address.city}, ${address.state}, ${address.country}`;
  }
  return '';
};

const lossDateFill = (lossDate) => {
  if (lossDate) {
    return formatDate(lossDate, 'PP');
  }
  return 'No Loss Date';
};

const damageTypesFill = (damageTypes) => {
  if (damageTypes?.length) {
    const types = damageTypes.map((type: { name: any }) => type?.name);
    return types.join(', ');
  }
  return '';
};

const policyHoldersFill = (claims) => {
  if (claims?.length) {
    const claimHolders = claims.map((claim: any) => claim?.policy_holder).filter((x) => x);
    return claimHolders.join(', ');
  }
  return '';
};

const policyNumbersFill = (claims) => {
  if (claims?.length) {
    const policyNumbers = claims.map((claim: any) => claim?.policy_number).filter((x) => x);
    return policyNumbers.join(', ');
  }
  return '';
};

const claimNumbersFill = (claims) => {
  if (claims?.length) {
    const claimNumbers = claims.map((claim: any) => claim?.claim_number).filter((x) => x);
    return claimNumbers.join(', ');
  }
  return '';
};

const providersFill = (claims) => {
  if (claims?.length) {
    const providers = claims.map((claim: any) => claim?.provider).filter((x) => x);
    return providers.join(', ');
  }
  return '';
};

function CustomReportEditor({ fetching, title, formErrors, onChangeTitle }: Props) {
  const editorRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const user: UserModel = useUser();

  const [selectedReportContent, setSelectedReportContent] = useState(undefined);

  const assignEditor = (_evt: any, editor: any) => {
    editorRef.current = editor;

    editorRef.current.on('focus', () => {
      editorRef.current.getContainer().style.boxShadow = '0 0 0 .2rem rgba(0, 123, 255, .25)';
      editorRef.current.getContainer().style.borderColor = '#80bdff';
    });
    editorRef.current.on('blur', () => {
      editorRef.current.getContainer().style.boxShadow = '';
      editorRef.current.getContainer().style.borderColor = '';
    });
  };

  const { full_name: fullName } = useSelector(userSelector, areEqual);
  const {
    companies: [company],
  } = useSelector(userSelector, areEqual);
  const projectId = useSelector(projectIdSelector, areEqual);
  const alias = useSelector(projectAliasSelector, areEqual);
  const projectAddress = useSelector(projectAddressSelector, areEqual);
  const customReportData = useSelector(customReportDataSelector, areEqual);
  const selectedReport = useSelector(selectedReportSelector, areEqual);
  const reportUpdated = useSelector(reportUpdatedSelector, areEqual);
  const propertyData = useSelector(propertyDataSelector, areEqual);
  const { address: companyAddress, uuid } = company;
  const { claims, project, unitsRoomsAlbumsWithPhotos } = customReportData;

  // const initialContent = `<img width="100" height="100" src=${company?.logo_url} />
  //   <p>${company?.name}</p>
  //   <p>${companyAddress?.address}, ${companyAddress?.city}, ${companyAddress?.state}, ${companyAddress?.country} </p>
  //   <p>${company?.website}</p>
  //   <p>Dear [insert name],</p>`;
  const initialContent = `
  <p>
    <img height="100" src=${company?.logo_url} />
  </p>
  <p>${company?.name}</p>
  <p>${fullCompanyAddressFill(companyAddress)}</p>
  <p>${company?.website}</p>
  <p style="text-align: center;"><strong>Final Mitigation Report</strong></p>
  <table style="border-collapse: collapse; width: 100%; height: 98px; border-width: 0px;" border="1">
    <colgroup>
      <col style="width: 33.2869%;">
      <col style="width: 33.2869%;">
      <col style="width: 33.2869%;">
    </colgroup>
    <tbody>
      <tr style="height: 19.6px;">
      <td style="height: 19.6px; border-width: 0px;">Claim ID:</td>
      <td style="height: 19.6px; border-width: 0px;">Date of Loss: ${lossDateFill(propertyData?.lossDate)}</td>
      <td style="height: 19.6px; border-width: 0px;">Date Claim Created:</td>
      </tr>
      <tr style="height: 19.6px;">
      <td style="height: 19.6px; border-width: 0px;">Type of Loss: ${damageTypesFill(
        propertyData?.propertyDamageTypes
      )}</td>
      <td style="height: 19.6px; border-width: 0px;">Project Manager: ${fullName}</td>
      <td style="height: 19.6px; border-width: 0px;">Address: ${fullProjectAddressFill(project?.address)}</td>
      </tr>
      <tr style="height: 19.6px;">
      <td style="height: 19.6px; border-width: 0px;">${
        claims?.filter(Boolean).length > 1 ? 'Policy Holders' : 'Policy Holder'
      }: ${policyHoldersFill(claims)}</td>
      <td style="height: 19.6px; border-width: 0px;">${
        claims?.map((claim) => claim?.provider).filter(Boolean).length > 1 ? 'Providers' : 'Provider'
      }: ${providersFill(claims)}</td>
      <td style="height: 19.6px; border-width: 0px;">Adjuster:</td>
      </tr>
      <tr style="height: 19.6px;">
      <td style="height: 19.6px; border-width: 0px;">${
        claims?.filter(Boolean).length > 1 ? 'Policy Numbers' : 'Policy Number'
      }: ${policyNumbersFill(claims)}</td>
      <td style="height: 19.6px; border-width: 0px;">${
        claims?.length > 1 ? 'Claim Numbers' : 'Claim Number'
      }: ${claimNumbersFill(claims)}</td>
      <td style="height: 19.6px; border-width: 0px;">&nbsp;</td>
      </tr>
      <tr style="height: 19.6px;">
      <td style="height: 19.6px; border-width: 0px;">Emergency Estimate / Reserve:&nbsp;</td>
      <td style="height: 19.6px; border-width: 0px;">Repair Estimate / Reserve:</td>
      <td style="height: 19.6px; border-width: 0px;">Contents Estimate / Reserve:</td>
      </tr>
      </tbody>
      </table>
  <p></p>
  <p></p>
  `;

  const initialContent2 = `
  <p style="text-align: center;">DSB Claims Preliminary Inspection Report</p>
  <table style="border-collapse: collapse; width: 100%; height: 98px;" border="1">
    <colgroup>
      <col style="width: 33.2869%;">
      <col style="width: 33.2869%;">
      <col style="width: 33.2869%;">
    </colgroup>
    <tbody>
      <tr style="height: 19.6px;">
      <td style="height: 19.6px; ">Claim ID:</td>
      <td style="height: 19.6px; ">Date of Loss: ${lossDateFill(propertyData?.lossDate)}</td>
      <td style="height: 19.6px; ">Date Claim Created:</td>
      </tr>
      <tr style="height: 19.6px;">
      <td style="height: 19.6px; ">Type of Loss: ${damageTypesFill(propertyData?.propertyDamageTypes)}</td>
      <td style="height: 19.6px; ">First Contacted: </td>
      <td style="height: 19.6px; ">Location of Loss: ${fullProjectAddressFill(project?.address)}</td>
      </tr>
      <tr style="height: 19.6px;">
      <td style="height: 19.6px; ">${
        claims?.filter(Boolean).length > 1 ? 'Policy Holders' : 'Policy Holder'
      }: ${policyHoldersFill(claims)}</td>
      <td style="height: 19.6px; ">${
        claims?.map((claim) => claim?.provider).filter(Boolean).length > 1 ? 'Providers' : 'Provider'
      }: ${providersFill(claims)}</td>
      <td style="height: 19.6px; ">Adjuster:</td>
      </tr>
      <tr style="height: 19.6px;">
      <td style="height: 19.6px; ">${
        claims?.filter(Boolean).length > 1 ? 'Policy Numbers' : 'Policy Number'
      }: ${policyNumbersFill(claims)}</td>
      <td style="height: 19.6px; ">${claims?.length > 1 ? 'Claim Numbers' : 'Claim Number'}: ${claimNumbersFill(
    claims
  )}</td>
      <td style="height: 19.6px; ">&nbsp;</td>
      </tr>
      <tr style="height: 19.6px;">
      <td style="height: 19.6px; ">Emergency Estimate / Reserve:&nbsp;</td>
      <td style="height: 19.6px; ">Repair Estimate / Reserve:</td>
      <td style="height: 19.6px; ">Contents Estimate / Reserve:</td>
      </tr>
      </tbody>
      </table>
  <p></p>
  <p><b>Claim Summary</b></p>
  <table style="border-collapse: collapse; width: 100%; height: 102px;" border="1">
    <td style="vertical-align: top; word-break: break-all;"></td>
  </table>
  <p><b>Loss Investigation</b></p>
  <table style="border-collapse: collapse; width: 100%; height: 102px;" border="1">
    <td style="vertical-align: top; word-break: break-all;"></td>
  </table>
  <p><b>Loss and Damages</b></p>
  <table style="border-collapse: collapse; width: 100%; height: 102px;" border="1">
    <td style="vertical-align: top; word-break: break-all;"></td>
  </table>
  <p><b>Salvage</b></p>
  <table style="border-collapse: collapse; width: 100%; height: 102px;" border="1">
    <td style="vertical-align: top; word-break: break-all;"></td>
  </table>
  <p><b>Subrogation</b></p>
  <table style="border-collapse: collapse; width: 100%; height: 102px;" border="1">
    <td style="vertical-align: top; word-break: break-all;"></td>
  </table>
  <p><b>Recommendations</b></p>
  <table style="border-collapse: collapse; width: 100%; height: 102px;" border="1">
    <td style="vertical-align: top; word-break: break-all;"></td>
  </table>
  <p><b>Proposed Future Handling</b></p>
  <table style="border-collapse: collapse; width: 100%; height: 102px;" border="1">
    <td style="vertical-align: top; word-break: break-all;"></td>
  </table>
  <p><b>Remarks</b></p>
  <table style="border-collapse: collapse; width: 100%; height: 102px;" border="1">
    <td style="vertical-align: top; word-break: break-all;"></td>
  </table>
  <p></p>
  `;

  const initialContent3 = `<p>
  <img
      src="${company?.logo_url}"
      height="100"
  />
</p>
<p>Gci Consultants</p>
<p>www.gciconsultants.com</p>
<p style="text-align: center">&nbsp;</p>
<p style="text-align: center"><strong>DISASTER SPECIMEN SHEET</strong></p>
<table style="border-collapse: collapse; width: 100%" border="1">
  <colgroup>
      <col style="width: 50%" />
      <col style="width: 50%" />
  </colgroup>
  <tbody>
      <tr>
          <td>PROJECT NAME: ${fullProjectAddressFill(project?.address)}&nbsp;</td>
          <td>PROJECT #: ${project?.alias ?? ''}&nbsp;</td>
      </tr>
      <tr>
          <td>TEST TECHNICIANS:&nbsp;</td>
          <td>SPECIMEN ID:</td>
      </tr>
      <tr>
          <td>TEST DATE:</td>
          <td>INTERVIEW:</td>
      </tr>
      <tr>
          <td>ASSEMBLY TYPE:</td>
          <td>ASSEMBLY MATERIAL:</td>
      </tr>
      <tr>
          <td>ASSEMBLY MANUFACTURER:</td>
          <td>BUILDING CONSTRUCTION:</td>
      </tr>
      <tr>
          <td>BUILDING CLADDING:</td>
          <td>BLDG:</td>
      </tr>
      <tr>
          <td>ELEVATION:</td>
          <td>FLOOR:</td>
      </tr>
      <tr>
          <td>UNIT:</td>
          <td>ROOM:</td>
      </tr>
  </tbody>
</table>
<p>
  <strong
      >PVC Rack: Water pressure = 15 PSI. Nozzle distance from specimen =
      15&rdquo;. Nozzle pattern = 15&rdquo;.</strong
  ><br /><strong
      >Rainmaker Rack: Water pressure = 12 PSI. Nozzle distance from specimen
      = 20&rdquo;. Nozzle pattern = 24&rdquo;.</strong
  >
</p>
<p style="text-align: center">SKETCH OF SPECIMEN</p>
<table style="border-collapse: collapse; width: 100%" border="1">
  <colgroup>
      <col style="width: 99.8615%" />
  </colgroup>
  <tbody>
      <tr>
          <td>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
          </td>
      </tr>
  </tbody>
</table>
<p>&nbsp;</p>
<p>PRE-TEST OBSERVATIONS:</p>
<p>&nbsp;</p>
<p>
  TESTING OF GLAZING ASSEMBLY (UNIFORM). ISOLATE EXTERIOR WALLS AND SEALANT.
</p>
<p><br />TEST #1: SPRAY RACK ONLY FOR 15 MINUTES. ISOLATION IN PLACE</p>
<p>&nbsp;</p>
<table style="border-collapse: collapse; width: 100%" border="1">
  <colgroup>
      <col style="width: 24.9307%" />
      <col style="width: 24.9307%" />
      <col style="width: 24.9307%" />
      <col style="width: 24.9307%" />
  </colgroup>
  <tbody>
      <tr>
          <td>ELAPSED TIME</td>
          <td>OBSERVATION</td>
          <td>&nbsp; &nbsp;PHOTO#</td>
          <td>PHOTO</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
  </tbody>
</table>
<p>&nbsp;</p>
<p>TEST #2: SPRAY RACK AND CHAMBER ( PSF) FOR 15 MINUTES. ISOLATION IN PLACE</p>
<table style="border-collapse: collapse; width: 100%" border="1">
  <colgroup>
      <col style="width: 24.9307%" />
      <col style="width: 24.9307%" />
      <col style="width: 24.9307%" />
      <col style="width: 24.9307%" />
  </colgroup>
  <tbody>
      <tr>
          <td>ELAPSED TIME</td>
          <td>OBSERVATION</td>
          <td>&nbsp; &nbsp;PHOTO#</td>
          <td>PHOTO</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
  </tbody>
</table>
<p>&nbsp;</p>
<p>TEST #3: SPRAY RACK AND CHAMBER ( PSF) FOR 15 MINUTES. ISOLATION REMOVED</p>
<table style="border-collapse: collapse; width: 100%" border="1">
  <colgroup>
      <col style="width: 24.9307%" />
      <col style="width: 24.9307%" />
      <col style="width: 24.9307%" />
      <col style="width: 24.9307%" />
  </colgroup>
  <tbody>
      <tr>
          <td>ELAPSED TIME</td>
          <td>OBSERVATION</td>
          <td>&nbsp; &nbsp;PHOTO#</td>
          <td>PHOTO</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
  </tbody>
</table>
<p>&nbsp;</p>
<p>TEST #4: SILL DAM TEST, IF APPLICABLE</p>
<table style="border-collapse: collapse; width: 100%" border="1">
  <colgroup>
      <col style="width: 24.9307%" />
      <col style="width: 24.9307%" />
      <col style="width: 24.9307%" />
      <col style="width: 24.9307%" />
  </colgroup>
  <tbody>
      <tr>
          <td>ELAPSED TIME</td>
          <td>OBSERVATION</td>
          <td>&nbsp; &nbsp;PHOTO#</td>
          <td>PHOTO</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
      <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
      </tr>
  </tbody>
</table>
<p>&nbsp;</p>
<p>POST-TEST OBSERVATIONS/ADDITIONAL NOTES:</p>
`;

  const initialContentAllDry = `<p style="text-align: center; margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">
    <img height="100" src=${company?.logo_url} />
</span>
</p>
<p><br><br><br></p>
<p style="text-align: center; margin-top: 0pt; margin-bottom: 0pt;">
<strong>
    <span style="font-size: 38pt; font-family: Calibri, sans-serif; color: rgb(31, 56, 100);">Water Damage Mitigation Report</span>
</strong>
</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
    <span style="font-size: 11pt; font-family: Arial, sans-serif;"> </span>
    <span style="font-size: 11pt; font-family: Arial, sans-serif;"> </span>
    <span style="font-size: 11pt; font-family: Arial, sans-serif;"> </span>
    <span style="font-size: 11pt; font-family: Arial, sans-serif;"> </span>
    <span style="font-size: 11pt; font-family: Arial, sans-serif;"> </span>
</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">Job Number#: ${project?.alias ?? ''}</span>
</p>
<p>&nbsp;</p>
<div align="left">
<table style="border-collapse: collapse; border: medium solid currentcolor;" border="1">
    <colgroup>
        <col style="width: 124px;"><col style="width: 500px;">
    </colgroup>
    <tbody>
        <tr>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;">
        <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">Prepared By</span></p>
        </td>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;">
        <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;"></span></p>
        </td>
        </tr>
        <tr>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;">
        <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">Contact Phone</span></p>
        </td>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;">
        <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">&nbsp;</span></p>
        </td>
        </tr>
        <tr>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;">
        <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">Contact Email</span></p>
        </td>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;">
        <p style="margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;"></span></p>
        </td>
        </tr>
    </tbody>
</table>
</div>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;"> </span>
<span style="font-size: 11pt; font-family: Arial, sans-serif;"> </span>
</p>
<p><br><br></p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">1. Subject Property</span>
</p>
<p>&nbsp;</p>
<div align="left">
<table style="border-collapse: collapse; border: medium solid currentcolor;" border="1">
    <colgroup>
        <col style="width: 93px;">
        <col style="width: 531px;">
    </colgroup>
    <tbody>
        <tr>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;">
        <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">Name</span></p>
        </td>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;">
        <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">${policyHoldersFill(
          claims
        )}</span></p>
        </td>
        </tr>
        <tr>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;">
        <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">Address</span></p>
        </td>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;">
        <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">${fullProjectAddressFill(
          project?.address
        )}</span></p>
        </td>
        </tr>
    </tbody>
</table>
</div>
<p><br><br></p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">2.Insurance Information</span>
</p>
<p>&nbsp;</p>
<div align="left">
<table style="border-collapse: collapse; border: medium solid currentcolor;" border="1">
    <colgroup>
        <col style="width: 128px;">
        <col style="width: 496px;">
    </colgroup>
    <tbody>
        <tr>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;">
        <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">Insurance</span></p>
        </td>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;">
        <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">${providersFill(
          claims
        )}</span></p>
        </td>
        </tr>
        <tr>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;">
        <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">Policy Number</span></p>
        </td>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;">
        <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">${policyNumbersFill(
          claims
        )}</span></p>
        </td>
        </tr>
        <tr>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;">
        <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">Claim Number</span></p>
        </td>
        <td style="border-width: 1pt; border-style: solid; vertical-align: top;">
        <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Arial, sans-serif;">${claimNumbersFill(
          claims
        )}</span></p>
        </td>
        </tr>
    </tbody>
</table>
</div>
<p>&nbsp;</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">3. Background Summary:</span>
</p>
<p><br><br></p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">4. Source of Loss</span>
</p>
<p>&nbsp;</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">4.1 Description</span>
</p>
<p>&nbsp;</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">4.2 Photos of Source of Loss</span>
</p>
<p>&nbsp;</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">5. Floor Plan and Impact Mapping:</span>
</p>
<p>&nbsp;</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">5.1 Floor Plan:</span>
</p>
<p>&nbsp;</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">5.2 Floor Surface Impact</span>
</p>
<p>&nbsp;</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">5.3 Ceiling Impact</span>
</p>
<p>&nbsp;</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">5.4 Wall Impact</span>
</p>
<p>&nbsp;</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">6. Stabilization Activities</span>
</p>
<p>&nbsp;</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">7. Post Stabilization</span>
</p>
<p>&nbsp;</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">7.1 Recommendations</span>
</p>
<p>&nbsp;</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">7.2 Dry Out Process</span>
</p>
<p>&nbsp;</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">7.2.1 Chamber Conditions and Recommendations</span>
</p>
<p>&nbsp;</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">7.2.2 Chamber Dry Logs</span>
</p>
<p>&nbsp;</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;">8. Daily Log</span>
</p>
<p>&nbsp;</p>
<p style="margin-top: 0pt; margin-bottom: 0pt;">
<span style="font-size: 11pt; font-family: Arial, sans-serif;"> </span>
<span style="font-size: 11pt; font-family: Arial, sans-serif;"> </span>
</p>
<p>&nbsp;</p>`;

  // temp change to handle custom reports
  const [defaultContent, setDefaultContent] = useState('');

  const { customid }: any = useParams();

  useEffect(() => {
    if (uuid === '63fc4d66-5b0b-4e0e-8980-fb4441a731a6' && customid === 'alldry') {
      setDefaultContent(initialContentAllDry);
    } else if (uuid === '701fcbc8-9fad-4bdf-b83f-c28c064ef738') {
      setDefaultContent(initialContent2);
    } else if (uuid === '87c9b173-d93f-48e1-8d3f-1a4681f5d36e') {
      setDefaultContent(initialContent3);
    } else {
      setDefaultContent(initialContent);
    }
  }, [uuid, initialContent, initialContent2, initialContent3, initialContentAllDry]);

  // add info through sidebar
  const insertContent = useCallback((value) => {
    editorRef.current.execCommand('mceInsertContent', false, `${value} `);
  }, []);

  const onUserNameClick = useCallback(() => {
    insertContent(fullName);
  }, []);

  const onCompanyAddressClick = useCallback(() => {
    const { address } = company;
    insertContent(`${address.address}, ${address.city}, ${address.state}, ${address.country}`);
  }, []);

  const onCompanyLogoClick = useCallback(() => {
    const { logo_url: logoUrl } = company;
    insertContent(`<img height="100" src=${logoUrl} />`);
  }, []);

  const onCompanyNameClick = useCallback(() => {
    const { name } = company;
    insertContent(name);
  }, []);

  const onProjectAddressClick = useCallback((address: any) => {
    insertContent(fullProjectAddressFill(address));
  }, []);

  const onProjectAliasClick = useCallback((alias: any) => {
    if (alias) {
      insertContent(alias);
    } else {
      insertContent('');
    }
  }, []);

  const onProjectNumberClick = useCallback((uid: any) => {
    if (uid) {
      insertContent(uid);
    } else {
      insertContent('');
    }
  }, []);

  const onProjectTypeClick = useCallback((projectType: any) => {
    if (projectType) {
      insertContent(projectType);
    } else {
      insertContent('');
    }
  }, []);

  const onAsbestosStatusClick = useCallback((name: any) => {
    if (name) {
      insertContent(name);
    } else {
      insertContent('');
    }
  }, []);

  const onPropertyTypeClick = useCallback((type: any) => {
    if (type) {
      insertContent(type);
    }
  }, []);

  const onYearBuiltClick = useCallback((year: any) => {
    if (year) {
      insertContent(year.toString());
    } else {
      insertContent('');
    }
  }, []);

  const onDamageCauseClick = useCallback((damageCause: any) => {
    if (damageCause) {
      insertContent(damageCause);
    } else {
      insertContent('');
    }
  }, []);

  const onDamageTypesClick = useCallback((damageTypes: any) => {
    insertContent(damageTypesFill(damageTypes));
  }, []);

  const onBuildingNameClick = useCallback((name: any) => {
    if (name) {
      insertContent(name);
    } else {
      insertContent('');
    }
  }, []);

  const onDateLossClick = useCallback((lossDate) => {
    insertContent(lossDateFill(lossDate));
  }, []);

  const onClaimInfoClick = useCallback((claimInfo: any) => {
    if (claimInfo) {
      insertContent(claimInfo);
    }
  }, []);

  const onPhotoClick = useCallback((src) => {
    insertContent(`<img width="200" src=${src}  />`);
  }, []);

  const onCreateReport = useCallback(() => {
    if (projectId) {
      const reportContent = editorRef.current.getContent();
      const settings = JSON.stringify({
        format: 'custom_report',
        content: reportContent,
      });
      const formData = new FormData();
      formData.append('name', title);
      formData.append('settings', settings);

      dispatch(createReport(projectId, formData, alias, user));
    }
  }, [title, projectId]);

  const onUpdateReport = useCallback(() => {
    if (selectedReport?.id) {
      const { id: reportId } = selectedReport;
      const reportContent = editorRef.current.getContent();
      const settings = JSON.stringify({
        format: 'custom_report',
        content: reportContent,
      });

      dispatch(updateReport(reportId, { name: title, settings }));
    }
  }, [title, selectedReport]);

  useEffect(() => {
    if (projectId) {
      dispatch(populateCustomReportData(projectId));
      dispatch(populateUnitsPhotosData(projectId));
    }
  }, [projectId]);

  useEffect(() => {
    if (project?.propertyId) {
      const { propertyId } = project;
      dispatch(getPropertyData(propertyId));
    }
  }, [project]);

  useEffect(() => {
    if (selectedReport?.name) {
      onChangeTitle(selectedReport?.name);
    }
    if (selectedReport?.settings) {
      const { content } = JSON.parse(selectedReport?.settings);
      setSelectedReportContent(content);
    }
  }, [selectedReport]);

  useEffect(() => {
    if (reportUpdated) {
      dispatch(setReportUpdated(false));
      history.push(`/projects/${projectId}/rocketreports`);
    }
  }, [reportUpdated]);

  return (
    <div className={classes.generateReportsForm}>
      <h4 className={classes.title}>Create a Custom Report</h4>
      <ValidateBackGround isValid={!formErrors?.title.length} className={formClasses.inputContainer}>
        <Label ariaLabel="Report Title" className={formClasses.label} htmlFor="title">
          Report Title
        </Label>
        <TextBox
          value={title}
          name="title"
          type="text"
          className={`mb-0 pb-0 ${formClasses.validateField} ${
            formErrors?.title.length ? formClasses.invalidField : formClasses.validField
          } ${formErrors?.title.length ? 'is-invalid' : ''}`}
          required
          placeholder="Report Name Here"
          ariaLabel="Please enter report title"
          onChange={onChangeTitle}
          autoComplete="off"
        />
        <div
          className={`${formClasses.invalidFieldFeedback} invalid-feedback ${
            formErrors?.title.length ? 'd-block' : ''
          }`}
        >
          {formErrors?.title?.[0]}
        </div>
      </ValidateBackGround>
      <div className={`${classes.flex} ${classes.customReportFrame} mt-3`}>
        <ProjectInfo
          units={unitsRoomsAlbumsWithPhotos}
          projectAddress={projectAddress}
          project={project}
          property={propertyData}
          claims={claims}
          onUserNameClick={onUserNameClick}
          onCompanyNameClick={onCompanyNameClick}
          onCompanyAddressClick={onCompanyAddressClick}
          onCompanyLogoClick={onCompanyLogoClick}
          onProjectNumberClick={onProjectNumberClick}
          onProjectAliasClick={onProjectAliasClick}
          onProjectAddressClick={onProjectAddressClick}
          onPhotoClick={onPhotoClick}
          onProjectTypeClick={onProjectTypeClick}
          onPropertyTypeClick={onPropertyTypeClick}
          onYearBuiltClick={onYearBuiltClick}
          onBuildingNameClick={onBuildingNameClick}
          onDateLossClick={onDateLossClick}
          onClaimInfoClick={onClaimInfoClick}
          onDamageTypesClick={onDamageTypesClick}
          onDamageCauseClick={onDamageCauseClick}
          onAsbestosStatusClick={onAsbestosStatusClick}
        />

        {/* @ts-ignore */}
        <Editor
          apiKey="y16002q3bh0dat4uao0pcxu9d7i5dkgyr1sc1vm78tcvpgyu"
          onInit={(evt, editor) => assignEditor(evt, editor)}
          initialValue={selectedReportContent || defaultContent}
          init={{
            branding: false,
            height: 500,
            menubar: true,
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'help',
              'wordcount',
              'table',
              'a11ychecker',
              'casechange',
              'export',
              'formatpainter',
              'image',
              'editimage',
              'linkchecker',
              'autolink',
              'lists',
              'checklist',
              'media',
              'mediaembed',
              'pageembed',
              'permanentpen',
              'powerpaste',
              'table',
              'advtable',
              'tableofcontents',
              'tinymcespellchecker',
            ],
            convert_fonts_to_spans: true,
            fontsize_formats: '8pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 24pt 36pt',
            font_size_style_values: '8pt,10pt,11pt,12pt,14pt,16pt,18pt,20pt,24pt,36pt',
            toolbar:
              'undo redo | sizeselect fontselect fontsizeselect formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | pagebreak | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | spellchecker | help | a11ycheck addcomment showcomments casechange checklist export formatpainter image editimage pageembed permanentpen',
            toolbar_mode: 'floating',
            tinycomments_mode: 'embedded',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            browser_spellcheck: true,
            spellchecker_callback(method: string, text: string, success: (arg0: {}) => void) {
              const words = text.match(editorRef.current.getWordCharPattern());
              if (method === 'spellcheck') {
                const suggestions = {};
                for (let i = 0; i < words.length; i += 1) {
                  suggestions[words[i]] = ['First', 'Second'];
                }
                success(suggestions);
              }
            },
          }}
        />
      </div>
      {selectedReport?.id ? (
        <PurpleButton
          type="submit"
          className={classes.submitButton}
          disabled={!title || fetching}
          onClick={onUpdateReport}
        >
          Save Report
        </PurpleButton>
      ) : (
        <PurpleButton
          type="submit"
          className={classes.submitButton}
          disabled={!title || fetching}
          onClick={onCreateReport}
        >
          Generate Report
        </PurpleButton>
      )}
    </div>
  );
}

const CustomReportEditorMemo = memo(CustomReportEditor, areEqual);

export { CustomReportEditorMemo as CustomReportEditor };
