import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Modal } from 'Components/Modal';
import { PurpleButton } from 'Components/Button';

import classes from './downloadTimesheetsModal.module.css';

interface Props {
  loading: boolean;
  isOpen: boolean;
  onDownloadButtonClick: (e: any) => void;
  modalCloseClick: (e: any) => void;
}

const DownloadTimesheetsModal = ({ loading, isOpen, onDownloadButtonClick, modalCloseClick }: Props) => (
  <Modal title="Download Timesheets?" isOpen={isOpen} classes={classes} modalHeader modalCloseClick={modalCloseClick}>
    <p>Download a copy to your local files</p>
    <PurpleButton onClick={onDownloadButtonClick} className={classes.downloadButton} disabled={loading}>
      Download
    </PurpleButton>
  </Modal>
);

const DownloadTimesheetsModalMemo = memo(DownloadTimesheetsModal, areEqual);

export { DownloadTimesheetsModalMemo as DownloadTimesheetsModal };
