import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { PurpleButton } from 'Components/Button';
import { Modal } from 'Components/Modal';

import classes from './PhotoCarouselModal.module.css';

interface Props {
  isOpen: boolean;
  activeIndex: number;
  photos: any;
  currentAlbumName: string;
  modalCloseClick: (e: any) => void;
  onPhotoClick: (e: any) => void;
  onNextPhoto: () => void;
  onPrevPhoto: () => void;
  onChangePhoto: (e: any) => void;
}
const PhotoCarouselModal = ({
  isOpen,
  activeIndex,
  currentAlbumName,
  photos,
  onPhotoClick,
  modalCloseClick,
  onNextPhoto,
  onPrevPhoto,
  onChangePhoto,
}: Props) => (
  <Modal
    id="custom-report-photo-modal"
    classes={classes}
    title="Select Photo"
    isOpen={isOpen}
    modalHeader
    dataBsBackdrop="static"
    dataBsKeyboard="false"
    modalCloseClick={modalCloseClick}
  >
    <div id="carouselCaptions" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        {photos.length
          ? photos.map((_: any, index: number) => (
              <button
                key={`${index + 1}`}
                type="button"
                data-bs-target="#carouselCaptions"
                data-bs-slide-to={index}
                className={`${activeIndex === index ? 'active' : ''}`}
                aria-current={activeIndex === index}
                aria-label={`${currentAlbumName} Photo ${index + 1}`}
                onClick={() => onChangePhoto(index)}
              />
            ))
          : null}
      </div>

      <div className="carousel-inner">
        {photos.length
          ? photos.map((photo: any, index: number) => (
              <div key={`${index + 1}`} className={`carousel-item ${activeIndex === index ? 'active' : ''}`}>
                <img src={photo.raw} className={`d-block w-100 ${classes.carouselImage}`} alt="..." />
                <div className="carousel-caption d-none d-md-block">
                  <h5 className={classes.carouselAlbumName}>
                    {currentAlbumName} Photo
                    {index + 1}
                  </h5>
                  <PurpleButton
                    className={classes.photoAddButton}
                    onKeyUp={() => {}}
                    onClick={() => onPhotoClick(photo.raw)}
                  >
                    Add Photo
                  </PurpleButton>
                </div>
              </div>
            ))
          : null}
      </div>

      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselCaptions"
        data-bs-slide="prev"
        onClick={onPrevPhoto}
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselCaptions"
        data-bs-slide="next"
        onClick={onNextPhoto}
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  </Modal>
);

const PhotoCarouselModalMemo = memo(PhotoCarouselModal, areEqual);

export { PhotoCarouselModalMemo as PhotoCarouselModal };
