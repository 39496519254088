import React, { useState, useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getEsignatureApiWithToken, setWebviewToken, signCompanyContractWithToken } from 'Containers/Esignature/action';
import { Span } from 'Components/Span';
import { PurpleButton, CancelButton } from 'Components/Button';
import { areEqual } from 'Utils/equalityChecks';
import { eSignatureSharedSelector } from 'Containers/Esignature/selector';
import { Toast } from 'Components/Toast';
import { Spinner } from 'Components/Spinner';
import classes from './esignatureContract.module.css';

export const UnsecureEsignatureContract = () => {
  const dispatch = useDispatch();
  const { signid }: any = useParams();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  const [loading, setLoading] = useState(true);
  const [signBase64, setSignBase64] = useState(null);
  const [content, setContent] = useState('');

  const signaturePadRef: any = useRef(null);
  const eSignatureSelected = useSelector(eSignatureSharedSelector, areEqual);

  const clear = () => {
    signaturePadRef.current.clear();
  };

  const replaceInputTextBox = (content: string) => {
    const searchPattern = /~~~input~~~/;
    let str = searchPattern.test(content);

    while (str) {
      const id = `i_${Math.floor(Math.random() * 1000)}`;
      content = content.replace(searchPattern, `<input id=${id} type="text" class="contractInputs" value=""/>`);

      str = searchPattern.test(content);
    }
    return content;
  };

  const replaceTildeWrappers = (content: string) => {
    const searchPattern = /~~~~~~/;
    let str = searchPattern.test(content);

    while (str) {
      const id = `i_${Math.floor(Math.random() * 1000)}`;
      content = content.replace(searchPattern, `<input id=${id} type="text" class="contractInputs" value=""/>`);

      str = searchPattern.test(content);
    }
    return content;
  };

  const replaceInputCheckbox = (content: string) => {
    const searchPattern = /~~~checkbox~~~/;
    let str = searchPattern.test(content);

    while (str) {
      const id = `c_${Math.floor(Math.random() * 1000)}`;
      content = content.replace(
        searchPattern,
        `<input id=${id} type="checkbox" class="contractInputCheckboxes" value=""/>`
      );

      str = searchPattern.test(content);
    }
    return content;
  };

  const replaceInputDropdown = (content: string) => {
    const searchPattern = /~~~[a-zA-Z0-9_\-# ]*(,[a-zA-Z0-9_\-# ]*)*~~~/;
    let match = content.match(searchPattern);

    while (match && match.length > 0) {
      const substitute = match[0];
      const parts = substitute
        .replace(/~~~/g, '')
        .split(',')
        .map((subString) => `<option value="${subString}">${subString}</option>`);
      const id = `d_${Math.floor(Math.random() * 1000)}`;

      content = content.replace(
        substitute,
        `<select id="${id}" class="contractInputComboBox">${parts.join('')}</select>`
      );

      match = content.match(searchPattern);
    }
    return content;
  };

  const replaceContent = (content: string) => {
    content = content.replace(/\n/g, '');
    // replace tilde wrapper first, please do not change order
    content = replaceTildeWrappers(content);
    content = replaceInputTextBox(content);
    content = replaceInputCheckbox(content);
    content = replaceInputDropdown(content);
    return content;
  };

  useEffect(() => {
    setLoading(true);
    dispatch(setWebviewToken(token));
    dispatch(getEsignatureApiWithToken(signid));
  }, []);

  useEffect(() => {
    if (eSignatureSelected) {
      setLoading(false);
      setContent(replaceContent(eSignatureSelected.content));
    }
  }, [eSignatureSelected]);

  const onSignEnd = () => {
    setSignBase64(signaturePadRef?.current?.toDataURL());
  };

  const substituteValueForTextBox = (contractContent: string) => {
    const inputs: any = document.querySelectorAll('.contractInputs');
    inputs.forEach((input: any) => {
      contractContent = contractContent.replace(
        `<input id=${input.id} type="text" class="contractInputs" value=""/>`,
        input.value
      );
    });

    return contractContent;
  };

  const substituteValueForCheckBox = (contractContent: string) => {
    const inputs: any = document.querySelectorAll('.contractInputCheckboxes');

    inputs.forEach((input: any) => {
      contractContent = contractContent.replace(
        `<input id=${input.id} type="checkbox" class="contractInputCheckboxes" value=""/>`,
        input.checked ? '[x]' : '[ ]'
      );
    });

    return contractContent;
  };

  const substituteValueForDropdown = (contractContent: string) => {
    const inputs: any = document.querySelectorAll('.contractInputComboBox');

    inputs.forEach((input: any) => {
      contractContent = contractContent.replace(input.outerHTML, input.value);
    });

    return contractContent;
  };

  const saveContract = () => {
    let contractContent = content;

    // substitute required fields
    contractContent = substituteValueForTextBox(contractContent);
    contractContent = substituteValueForCheckBox(contractContent);
    contractContent = substituteValueForDropdown(contractContent);

    // Sign or Save Contract
    if (!loading && !!eSignatureSelected.contract_form?.has_signature && signBase64) {
      dispatch(signCompanyContractWithToken(signid, signBase64, contractContent));
    } else {
      dispatch(signCompanyContractWithToken(signid, 'no signature', contractContent));
    }
  };

  return (
    <>
      <div className={classes.contractContainer}>
        <Span className={classes.heading}>{(!loading && eSignatureSelected.contract_form?.name) || 'Contract'} </Span>
        <div className={classes.contractContentContainer}>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <div>
              {!loading ? (
                <div className={classes.contractText}>
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              ) : null}
            </div>
          )}
        </div>
        {!loading && !!eSignatureSelected.contract_form?.has_signature && eSignatureSelected.status !== 'signed' ? (
          <>
            <Span className={classes.signHeading}>Signature Below</Span>
            <SignatureCanvas
              penColor="black"
              backgroundColor="#D9D9D9"
              canvasProps={{ width: window.innerWidth * 0.9, height: 160, className: 'sigCanvas' }}
              onEnd={onSignEnd}
              ref={signaturePadRef}
            />
          </>
        ) : null}
        {!loading && eSignatureSelected.status === 'signed' && eSignatureSelected.signature !== 'no signature' && (
          <>
            <Span className={classes.signHeading}>Signature</Span>
            <img src={eSignatureSelected.signature} className={classes.signatureImg} alt="..." />
          </>
        )}
        {!loading && eSignatureSelected.status !== 'signed' && (
          <div className={classes.buttonContainer}>
            <PurpleButton
              className={classes.saveBtn}
              onClick={saveContract}
              disabled={!loading && !!eSignatureSelected.contract_form?.has_signature && !signBase64}
            >
              {!loading && !!eSignatureSelected.contract_form?.has_signature ? 'Sign' : 'Save'}
            </PurpleButton>
            {!loading && !!eSignatureSelected.contract_form?.has_signature ? (
              <CancelButton className={classes.saveBtn} onClick={clear}>
                Clear
              </CancelButton>
            ) : null}
          </div>
        )}
      </div>
      <Toast bottomCenter />
    </>
  );
};
