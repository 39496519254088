export const PROJECT_DASHBOARD = '/projectDashboard';
export const PHOTO_MANAGEMENT = '/photoManagement';
export const ALL_LOCATIONS = '/allLocations';
export const ADD_LOCATIONS = '/addLocations';
export const SINGLE = '/single';
export const MULTI_UNIT = '/multiUnit';

export const NOTE_LENGTH_LIMIT = 10000;
export const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
export const MILLISECONDS_PER_HOUR = 1000 * 60 * 60;
export const MILLISECONDS_PER_MINUTE = 1000 * 60;

export const ProjectStatus = {
  WIP: 1,
  COMPLETE: 2,
  ESTIMATE: 3,
  CONTRACT: 4,
  LEAD: 5,
  INVOICED: 6,
  PAID: 7,
  RECON: 8,
};
