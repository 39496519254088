import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { PurpleButton } from 'Components/Button';
import { TabContent } from 'Components/Tabs';
import { Icon } from 'Components/Icons';

import { ClockedInTimeCardNotes } from 'Containers/TimeTracking';

import { NotesProvider } from 'Context/Notes';

import { Duration } from 'date-fns';
import { formatDurationForTimeTracking } from 'Containers/TimeTracking/helpers';

import classes from './timeCardTab.module.css';

interface Props {
  address: string;
  currentTime: string;
  projectClockedIn: boolean;
  clockButtonDisabled: boolean;
  dayProjectTime: Duration;
  dayTotalTime: Duration;
  // notes
  notesOpen: boolean;
  projectClockedInCardId: any;
  onProjectSectionClick: (e: any) => void;
  onClockInButtonClick: (e: any) => void;
  onClockOutButtonClick: (e: any) => void;
  // notes functions
  setNotesOpen: (e: any) => void;
}

const TimeCardTab = ({
  address,
  currentTime,
  projectClockedIn,
  clockButtonDisabled,
  dayTotalTime,
  dayProjectTime,
  // notes
  notesOpen,
  projectClockedInCardId,
  onProjectSectionClick,
  onClockInButtonClick,
  onClockOutButtonClick,
  // notes functions
  setNotesOpen,
}: Props) => (
  <TabContent key="tab-content-time-tracking" id="timecard" className="show active position-relative">
    <div className={classes.dayTotalContainer}>
      <Icon type="clock" />
      <div className={classes.dayTotal}>
        <div className={classes.sectionHeader}>Day Total</div>
        <div className={classes.sectionText}>{formatDurationForTimeTracking(dayTotalTime)}</div>
      </div>
      <div className={classes.dayProject}>{formatDurationForTimeTracking(dayProjectTime)}</div>
    </div>

    <div
      className={classes.outline}
      role="button"
      onClick={onProjectSectionClick}
      onKeyUp={onProjectSectionClick}
      tabIndex={0}
    >
      <div className={classes.sectionHeader}>Project</div>
      <div className={classes.sectionText}>{address}</div>
    </div>

    <div className={classes.outline}>
      <div className={classes.sectionHeader}>Start Time</div>
      <div className={classes.sectionText}>
        Today,&nbsp;
        {currentTime}
      </div>
    </div>

    <div className={classes.outline}>
      <div className={classes.sectionHeader}>Notes</div>
      <NotesProvider>
        <ClockedInTimeCardNotes
          notesOpen={notesOpen}
          projectClockedInCardId={projectClockedInCardId}
          setNotesOpen={setNotesOpen}
        />
      </NotesProvider>
    </div>

    <div className={classes.buttonRow}>
      {projectClockedIn ? (
        <PurpleButton className={classes.button} disabled={clockButtonDisabled} onClick={onClockOutButtonClick}>
          Clock Out
        </PurpleButton>
      ) : (
        <PurpleButton className={classes.button} disabled={clockButtonDisabled} onClick={onClockInButtonClick}>
          Clock In
        </PurpleButton>
      )}
    </div>
  </TabContent>
);

const TimeCardTabMemo = memo(TimeCardTab, areEqual);

export { TimeCardTabMemo as TimeCardTab };
