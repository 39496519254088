import {
  SET_EQUIPMENT_INVENTORY,
  FETCHING_EQUIPMENT_INVENTORY,
  EQUIPMENT_INVENTORY_CREATED,
  EQUIPMENT_INVENTORY_UPDATED,
  EQUIPMENT_INVENTORY_DELETED,
  SELECTED_EQUIPMENT_FOR_UPDATE,
  SELECTED_EQUIPMENT_FOR_DELETE,
  equipmentInventoryActionTypes,
} from './actions';

const initialState = {
  equipmentInventory: [],
  fetchingEquipmentInventory: true,

  equipmentCreated: false,
  equipmentUpdated: false,
  equipmentDeleted: false,
  selectedEquipmentForUpdate: undefined,
  selectedEquipmentForDelete: undefined,
};

export const equipmentInventoryReducer = (state = initialState, action: equipmentInventoryActionTypes) => {
  const { type, payload } = action;
  switch (type) {
    case SET_EQUIPMENT_INVENTORY:
      return {
        ...state,
        equipmentInventory: payload,
      };
    case FETCHING_EQUIPMENT_INVENTORY:
      return {
        ...state,
        fetchingEquipmentInventory: payload,
      };
    case EQUIPMENT_INVENTORY_CREATED:
      return {
        ...state,
        equipmentCreated: payload,
      };
    case EQUIPMENT_INVENTORY_UPDATED:
      return {
        ...state,
        equipmentUpdated: payload,
      };
    case EQUIPMENT_INVENTORY_DELETED:
      return {
        ...state,
        equipmentDeleted: payload,
      };
    case SELECTED_EQUIPMENT_FOR_UPDATE:
      return {
        ...state,
        selectedEquipmentForUpdate: payload,
      };
    case SELECTED_EQUIPMENT_FOR_DELETE:
      return {
        ...state,
        selectedEquipmentForDelete: payload,
      };
    default:
      return state;
  }
};
