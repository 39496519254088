import React, { memo } from 'react';
import { Prompt } from 'react-router-dom';

import { areEqual } from 'Utils/equalityChecks';

import { RocketScopeTabs } from 'Components/RocketScope';
import { RocketScopeViewTabContainer, RocketScopeUploadTabContainer } from 'Containers/RocketScope';
import { useSelector } from 'react-redux';
import { hasChangesSelector, hasFileUploadSelector } from 'Containers/RocketScope/selectors';

function RocketScopeTabsContainer() {
  const hasFileUpload = useSelector(hasFileUploadSelector, areEqual);
  const hasChanges = useSelector(hasChangesSelector, areEqual);

  return (
    <RocketScopeTabs id="rocketscope-tabs">
      <RocketScopeViewTabContainer />
      <RocketScopeUploadTabContainer />
      <Prompt
        message={() => {
          if (hasFileUpload || hasChanges) {
            if (hasFileUpload) {
              return 'You have selected an Excel scope sheet but have not saved it yet, are you sure you want to leave?';
            }
            return 'You have unsaved changes to your scope. Are you sure you want to leave?';
          }
          return true;
        }}
      />
    </RocketScopeTabs>
  );
}

const RocketScopeTabsContainerMemo = memo(RocketScopeTabsContainer, areEqual);

export { RocketScopeTabsContainerMemo as RocketScopeTabsContainer };
