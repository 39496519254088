import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { ClockInOutTabs } from 'Components/Tabs';
import { TimeCardTab, OverviewTab } from 'Containers/TimeTracking';

interface Props {
  projectId: number;
  onClickReturn: (e: any) => void;
}

const ClockInOutScreen = ({ projectId, onClickReturn }: Props) => (
  <ClockInOutTabs id="timecard-tab">
    <TimeCardTab projectId={projectId} changeProject={onClickReturn} />
    <OverviewTab />
  </ClockInOutTabs>
);

const TimeTrackingClockInOutScreen = memo(ClockInOutScreen, areEqual);

export { TimeTrackingClockInOutScreen as ClockInOutScreen };
