import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { PurpleButton } from 'Components/Button/PurpleButton';

import {
  TimesheetsList,
  TimesheetFilters,
  CreateTimeCardModal,
  EditTimeCardModal,
  DeleteTimeCardModal,
  TimeCardNotesModal,
  DownloadTimesheetsModal,
} from 'Containers/TimeTracking';

import classes from './timesheets.module.css';

interface Props {
  getTimeCards: (e: any) => void;
  onCreateTimeCardButtonClick: (e: any) => void;
  onDownloadButtonClick: (e: any) => void;
}

const Timesheets = ({ getTimeCards, onCreateTimeCardButtonClick, onDownloadButtonClick }: Props) => (
  <div className={classes.container}>
    <div className={`d-flex justify-content-start align-items-center ${classes.contentHeader}`}>
      <h2>Timesheets</h2>

      <PurpleButton className={classes.addTimecardButton} onClick={onCreateTimeCardButtonClick}>
        Create Time Card +
      </PurpleButton>
      <PurpleButton className={classes.downloadButton} onClick={onDownloadButtonClick}>
        Download
      </PurpleButton>
    </div>
    <TimesheetFilters />
    <TimesheetsList getTimeCards={getTimeCards} />
    <CreateTimeCardModal />
    <TimeCardNotesModal />
    <EditTimeCardModal />
    <DeleteTimeCardModal />
    <DownloadTimesheetsModal />
  </div>
);

const TimesheetsMemo = memo(Timesheets, areEqual);

export { TimesheetsMemo as Timesheets };
