import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

// eslint-disable-next-line
import '@justifi/webcomponents/dist/module/justifi-business-details.js';
// eslint-disable-next-line
import '@justifi/webcomponents/dist/webcomponents/webcomponents.css';

import { RocketPayCompanyModel } from 'Containers/User/Models/RocketPayModel/RocketPayCompanyModel';
import { TabContent } from 'Components/Tabs';
import classes from './rocketPayStatus.module.css';

interface Props {
  companyInfo: RocketPayCompanyModel;
  authToken: string;
}

function RocketPayStatus({ companyInfo, authToken }: Props) {
  return (
    <TabContent key="tab-content-rocketpay-status" id="rocketpaystatus" className="position-relative">
      <div className={classes.sectionContainer}>
        {companyInfo && authToken && (
          <justifi-business-details business-id={companyInfo?.business_id} auth-token={authToken} />
        )}
      </div>
    </TabContent>
  );
}

const RocketPayStatusMemo = memo(RocketPayStatus, areEqual);
export { RocketPayStatusMemo as RocketPayStatus };
