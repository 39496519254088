import React, { memo } from 'react';

import { areEqualShallow } from 'Utils/equalityChecks';

import RocketSketch from '../../../../Assets/NavItems/rocketsketch.svg';

// Custom css
import classes from './rocketSketch.module.css';

interface Props {
  className?: string;
  id?: string;
  onClick?: (e: any) => void;
  onKeyUp?: (e: any) => void;
}
function RocketSketchSvg({ className = '', id, onClick, onKeyUp }: Props) {
  return (
    <RocketSketch id={id} className={`${classes.iconBase} ${className || ''}`} onClick={onClick} onKeyUp={onKeyUp} />
  );
}

RocketSketchSvg.defaultProps = {
  className: undefined,
  id: undefined,
  onClick: undefined,
  onKeyUp: undefined,
};

const RocketSketchSvgMemo = memo(RocketSketchSvg, areEqualShallow);
export { RocketSketchSvgMemo as RocketSketchSvg };
