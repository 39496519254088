import React, { memo, useCallback, useEffect, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { PhotoReportForm, GenerateReportWrapper } from 'Components/ReportsAndDocuments';
import { useDispatch, useSelector } from 'react-redux';
import { createReport, setReportCreated } from 'Containers/ReportsAndDocuments/Reports/actions';
import { projectSelector, projectAliasSelector } from 'Containers/RocketScan/selectors';
import {
  reportCreatedSelector,
  reportCreatingSelector,
  selectedReportLocationsSelector,
  selectedReportRoomsSelector,
  titleErrorSelector,
} from 'Containers/ReportsAndDocuments/Reports/selectors';
import { useHistory } from 'react-router-dom';

import { useUser } from 'Context/User';
import { UserModel } from 'Containers/User/Models/UserModel';
import { userFeatureFlagsSelector } from 'Containers/User/selector';

function PhotoReportContainer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const user: UserModel = useUser();

  const project = useSelector(projectSelector, areEqual);
  const fetching = useSelector(reportCreatingSelector, areEqual);
  const reportCreated = useSelector(reportCreatedSelector, areEqual);
  const selectedLocations = useSelector(selectedReportLocationsSelector, areEqual);
  const selectedRooms = useSelector(selectedReportRoomsSelector, areEqual);
  const alias = useSelector(projectAliasSelector, areEqual);

  const { companies }: any = user;
  const [company] = companies;
  const {
    name: companyName,
    logo_url: logoUrl,
    secondary_name: secondaryCompanyName,
    secondary_logo_url: secondaryLogoUrl,
  } = company;

  const { secondaryCompany: showSecondaryCompanyOption } = useSelector(userFeatureFlagsSelector, areEqual);

  const [name, setName] = useState('');
  const [useSecondaryCompany, setUseSecondaryCompany] = useState('no');
  const [format, setFormat] = useState('compact');
  const [unitType, setUnitType] = useState('full_project');
  const [photosType, setPhotosType] = useState('all');
  const [notesType, setNotesType] = useState('all');
  const [photoNotesType, setPhotoNotesType] = useState('all');
  const [damagedMaterials, setDamagedMaterials] = useState('yes');

  const errors = {
    title: useSelector(titleErrorSelector, areEqual),
  };

  const onChangeTitle = useCallback(({ target: { value } }: any) => {
    setName(value);
  }, []);

  // this one uses id and not value because the format choice doesn't use radio buttons
  const onSelectFormat = useCallback(({ currentTarget: { id } }: any) => {
    setFormat(id);
  }, []);

  const onCompanyClick = useCallback(({ currentTarget: { value } }: any) => {
    setUseSecondaryCompany(value);
  }, []);

  const onUnitTypeClick = useCallback(({ currentTarget: { value } }: any) => {
    setUnitType(value);
  }, []);

  const onPhotosTypeClick = useCallback(({ currentTarget: { value } }: any) => {
    setPhotosType(value);
  }, []);

  const onNotesTypeClick = useCallback(({ currentTarget: { value } }: any) => {
    setNotesType(value);
  }, []);

  const onPhotoNotesTypeClick = useCallback(({ currentTarget: { value } }: any) => {
    setPhotoNotesType(value);
  }, []);

  const onDamagedMaterialsClick = useCallback(({ currentTarget: { value } }: any) => {
    setDamagedMaterials(value);
  }, []);

  const onFormSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      if (project?.id) {
        const { id: projectId } = project;

        const settings = JSON.stringify({
          format,
          unit_type: unitType,
          photos: photosType,
          notes: notesType,
          photo_notes: photoNotesType,
          damaged_materials: damagedMaterials,
          use_secondary_company: useSecondaryCompany,
          locations: unitType === 'full_project' ? [] : selectedLocations,
          rooms: unitType === 'full_project' ? [] : selectedRooms,
        });

        dispatch(createReport(projectId, { name, settings }, alias, user));
      }
    },
    [
      name,
      project,
      format,
      unitType,
      photosType,
      notesType,
      photoNotesType,
      damagedMaterials,
      useSecondaryCompany,
      selectedLocations,
      selectedRooms,
    ]
  );

  useEffect(() => {
    if (reportCreated) {
      const { id: projectId } = project;

      history.push(`/projects/${projectId}/rocketreports`);
    }

    return () => {
      if (reportCreated) {
        dispatch(setReportCreated(false));
      }
    };
  }, [reportCreated, project]);

  return (
    <GenerateReportWrapper>
      <PhotoReportForm
        title={name}
        useSecondaryCompany={useSecondaryCompany}
        companyName={companyName}
        companyLogo={logoUrl}
        secondaryCompanyName={secondaryCompanyName}
        secondaryCompanyLogo={secondaryLogoUrl}
        format={format}
        unitType={unitType}
        photosType={photosType}
        notesType={notesType}
        photoNotesType={photoNotesType}
        damagedMaterials={damagedMaterials}
        formErrors={errors}
        fetching={fetching}
        showSecondaryCompanyOption={showSecondaryCompanyOption}
        onUnitTypeClick={onUnitTypeClick}
        onChangeTitle={onChangeTitle}
        onCompanyClick={onCompanyClick}
        onSelectFormat={onSelectFormat}
        onPhotosTypeClick={onPhotosTypeClick}
        onNotesTypeClick={onNotesTypeClick}
        onPhotoNotesTypeClick={onPhotoNotesTypeClick}
        onDamagedMaterialsClick={onDamagedMaterialsClick}
        onFormSubmit={onFormSubmit}
      />
    </GenerateReportWrapper>
  );
}

const PhotoReportContainerMemo = memo(PhotoReportContainer, areEqual);

export { PhotoReportContainerMemo as PhotoReport };
