import React, { memo } from 'react';

import { ViewMoistureLogModal } from 'Components/RocketDry';

import { useRocketDryFunctions } from 'Context/RocketDry';

function ViewMoistureLogModalContainer() {
  const { selectedMoistureLog, isViewMoistureLogModalOpen, setIsViewMoistureLogModalOpen }: any =
    useRocketDryFunctions();

  return (
    <ViewMoistureLogModal
      log={selectedMoistureLog}
      isOpen={isViewMoistureLogModalOpen}
      setIsOpen={setIsViewMoistureLogModalOpen}
    />
  );
}

const ViewMoistureLogModalMemo = memo(ViewMoistureLogModalContainer);

export { ViewMoistureLogModalMemo as ViewMoistureLogModal };
