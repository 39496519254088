import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  userTimeCardsSelector,
  userClockedInTimeCardsSelector,
  clockInSuccessSelector,
  clockOutSuccessSelector,
  refreshTimeCardsSelector,
  companyTimeCardsSelector,
  employeeFiltersSelector,
  projectNumFiltersSelector,
  addressFiltersSelector,
  projectDropdownSelector,
} from 'Containers/TimeTracking/selectors';
import {
  setClockInSuccess,
  setClockOutSuccess,
  setRefreshTimeCards,
  setUserClockedInTimeCards,
  setUserTimeCards,
} from 'Containers/TimeTracking/actions';
import {
  filterCardsByDateRange,
  calculateTotalClockDurationClamped,
  sortCardsByTime,
} from 'Containers/TimeTracking/helpers';

import { areEqual } from 'Utils/equalityChecks';
import { useUser } from 'Context/User';
import { TimeTrackingCard } from 'Containers/TimeTracking/types';
import { addOrRemoveFromArray, formatDate } from 'Utils/helpers';

export const TimeTrackingContext = createContext({});

export const TimeTrackingFunctions = () => {
  const dispatch = useDispatch();

  const { id: userId } = useUser();

  const [isTimeTrackingModalOpen, setIsTimeTrackingModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(undefined);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [clockedInProjectId, setClockedInProjectId] = useState(undefined);
  const [showProjectList, setShowProjectList] = useState(false);
  const [todayAllCards, setTodayAllCards] = useState([]);
  const [todayTotalTime, setTodayTotalTime] = useState({});

  // states for admin portal filters
  // selected filters: filters that are currently selected, but are not applied yet
  // saved filters: filters that will be sent through the API once clicking the apply filters button
  const [isEmployeeFilterOpen, setIsEmployeeFilterOpen] = useState(false);
  const [selectedEmployeeFilters, setSelectedEmployeeFilters] = useState([]);
  const [savedEmployeeFilters, setSavedEmployeeFilters] = useState([]);
  const [isAddressFilterOpen, setIsAddressFilterOpen] = useState(false);
  const [selectedAddressFilters, setSelectedAddressFilters] = useState([]);
  const [savedAddressFilters, setSavedAddressFilters] = useState([]);
  const [isProjectNumFilterOpen, setIsProjectNumFilterOpen] = useState(false);
  const [selectedProjectNumFilters, setSelectedProjectNumFilters] = useState([]);
  const [savedProjectNumFilters, setSavedProjectNumFilters] = useState([]);
  const [isDateRangeFilterOpen, setIsDateRangeFilterOpen] = useState(false);
  const [dateFilterStart, setDateFilterStart] = useState(null);
  const [dateFilterEnd, setDateFilterEnd] = useState(null);
  const [savedDateFilter, setSavedDateFilter] = useState(null);
  const [isDateFilterApplied, setIsDateFilterApplied] = useState(false);

  // states for modals
  const [isCreateTimeCardModalOpen, setIsCreateTimeCardModalOpen] = useState(false);
  const [isEditTimeCardModalOpen, setIsEditTimeCardModalOpen] = useState(false);
  const [isDeleteTimeCardModalOpen, setIsDeleteTimeCardModalOpen] = useState(false);
  const [isTimeCardNotesModalOpen, setIsTimeCardNotesModalOpen] = useState(false);
  const [isDownloadTimesheetsModalOpen, setIsDownloadTimesheetsModalOpen] = useState(false);
  const [selectedTimeCardId, setSelectedTimeCardId] = useState(undefined);
  const [selectedTimeCard, setSelectedTimeCard] = useState(undefined);

  const userTimeCards: TimeTrackingCard[] = useSelector(userTimeCardsSelector, areEqual);
  const userClockedInTimeCards: TimeTrackingCard[] = useSelector(userClockedInTimeCardsSelector, areEqual);
  const clockInSuccess = useSelector(clockInSuccessSelector, areEqual);
  const clockOutSuccess = useSelector(clockOutSuccessSelector, areEqual);
  const refreshTimeCards = useSelector(refreshTimeCardsSelector, areEqual);
  const companyTimeCards = useSelector(companyTimeCardsSelector, areEqual);
  const employeeFilters = useSelector(employeeFiltersSelector, areEqual);
  const projectNumberFilters = useSelector(projectNumFiltersSelector, areEqual);
  const addressFilters = useSelector(addressFiltersSelector, areEqual);
  const projectDropdownItems = useSelector(projectDropdownSelector, areEqual);

  // get list of user's cards (incl. clocked in cards) when starting up
  // in theory at most one card should be clocked in
  // but the code should handle any mistakes in that
  useEffect(() => {
    dispatch(setUserTimeCards(userId));
    dispatch(setUserClockedInTimeCards(userId));
  }, []);

  // when the user clocks in, update list of user's clocked in cards
  useEffect(() => {
    if (clockInSuccess) {
      dispatch(setUserClockedInTimeCards(userId));
      dispatch(setClockInSuccess(false));
    }
  }, [clockInSuccess]);

  // do the same when the user clocks out
  useEffect(() => {
    if (clockOutSuccess) {
      dispatch(setUserTimeCards(userId));
      dispatch(setUserClockedInTimeCards(userId));
      dispatch(setClockOutSuccess(false));
    }
  }, [clockOutSuccess]);

  //
  useEffect(() => {
    if (refreshTimeCards) {
      dispatch(setUserTimeCards(userId));
      dispatch(setRefreshTimeCards(false));
    }
  }, [refreshTimeCards]);

  // filter user's time cards for today
  useEffect(() => {
    const filteredCards = filterCardsByDateRange(userTimeCards, new Date(), new Date());
    setTodayAllCards(sortCardsByTime(filteredCards));
    // setTodayCards(filterCardsByDateRange(userTimeCards, new Date(2022, 9, 20), new Date(2022, 9, 20)));
  }, [userTimeCards]);

  useEffect(() => {
    setTodayTotalTime(calculateTotalClockDurationClamped(todayAllCards, new Date(), new Date()));
    // setTodayTotalTime(calculateTotalClockDurationClamped(todayCards, new Date(2022, 9, 20), new Date(2022, 9, 20)));
  }, [todayAllCards]);

  // handle changing filters
  const onAddressFilterListItemClick = useCallback(
    (addressId: any) => {
      setSelectedAddressFilters(addOrRemoveFromArray(selectedAddressFilters, addressId));
    },
    [selectedAddressFilters]
  );

  const onProjectNumFilterListItemClick = useCallback(
    (projectId: any) => {
      setSelectedProjectNumFilters(addOrRemoveFromArray(selectedProjectNumFilters, projectId));
    },
    [selectedProjectNumFilters]
  );

  const onEmployeeFilterListItemClick = useCallback(
    (employeeId: any) => {
      setSelectedEmployeeFilters(addOrRemoveFromArray(selectedEmployeeFilters, employeeId));
    },
    [selectedEmployeeFilters]
  );

  const onChangeDateFilter = (dates) => {
    const [start, end] = dates;
    setDateFilterStart(start);
    setDateFilterEnd(end);
  };

  // handle applying filters
  const applyEmployeeFilters = useCallback(() => {
    setSavedEmployeeFilters(selectedEmployeeFilters);
  }, [selectedEmployeeFilters]);

  const applyAddressFilters = useCallback(() => {
    setSavedAddressFilters(selectedAddressFilters);
  }, [selectedAddressFilters]);

  const applyProjectNumFilters = useCallback(() => {
    setSavedProjectNumFilters(selectedProjectNumFilters);
  }, [selectedProjectNumFilters]);

  const applyDateFilters = useCallback(() => {
    if (dateFilterStart && dateFilterEnd) {
      setSavedDateFilter({
        startDate: formatDate(dateFilterStart, 'yyyy-MM-dd'),
        endDate: formatDate(dateFilterEnd, 'yyyy-MM-dd'),
      });
      setIsDateFilterApplied(true);
    }
  }, [dateFilterStart, dateFilterEnd]);

  // handle clearing filters
  const clearAddressFilters = () => {
    setSelectedAddressFilters([]);
    setSavedAddressFilters([]);
  };

  const clearProjectNumFilters = () => {
    setSelectedProjectNumFilters([]);
    setSavedProjectNumFilters([]);
  };

  const clearEmployeeFilters = () => {
    setSelectedEmployeeFilters([]);
    setSavedEmployeeFilters([]);
  };

  const clearDateFilter = () => {
    setDateFilterStart(null);
    setDateFilterEnd(null);
    setSavedDateFilter(null);
    setIsDateFilterApplied(false);
  };

  return {
    isTimeTrackingModalOpen,
    selectedProject,
    selectedAddress,
    clockedInProjectId,
    userTimeCards,
    userClockedInTimeCards,
    clockInSuccess,
    clockOutSuccess,
    showProjectList,
    todayAllCards,
    todayTotalTime,
    companyTimeCards,
    // filter variables
    isEmployeeFilterOpen,
    isAddressFilterOpen,
    isProjectNumFilterOpen,
    isDateRangeFilterOpen,
    employeeFilters,
    projectNumberFilters,
    addressFilters,
    selectedAddressFilters,
    selectedEmployeeFilters,
    selectedProjectNumFilters,
    savedEmployeeFilters,
    savedAddressFilters,
    savedProjectNumFilters,
    dateFilterStart,
    dateFilterEnd,
    savedDateFilter,
    isDateFilterApplied,
    // modal variables
    isCreateTimeCardModalOpen,
    isEditTimeCardModalOpen,
    projectDropdownItems,
    isDeleteTimeCardModalOpen,
    isTimeCardNotesModalOpen,
    isDownloadTimesheetsModalOpen,
    selectedTimeCardId,
    selectedTimeCard,
    setIsTimeTrackingModalOpen,
    setSelectedProject,
    setSelectedAddress,
    setClockedInProjectId,
    setShowProjectList,
    // filter functions
    setIsEmployeeFilterOpen,
    setIsAddressFilterOpen,
    setIsProjectNumFilterOpen,
    setIsDateRangeFilterOpen,
    onAddressFilterListItemClick,
    onProjectNumFilterListItemClick,
    onEmployeeFilterListItemClick,
    onChangeDateFilter,
    applyEmployeeFilters,
    applyAddressFilters,
    applyProjectNumFilters,
    applyDateFilters,
    clearAddressFilters,
    clearProjectNumFilters,
    clearEmployeeFilters,
    clearDateFilter,
    // modal functions
    setIsCreateTimeCardModalOpen,
    setIsEditTimeCardModalOpen,
    setIsDeleteTimeCardModalOpen,
    setIsTimeCardNotesModalOpen,
    setIsDownloadTimesheetsModalOpen,
    setSelectedTimeCardId,
    setSelectedTimeCard,
    userId,
  };
};

export const useTimeTrackingFunctions = () => useContext(TimeTrackingContext);
