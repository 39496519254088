import React, { memo, useCallback, useEffect, useState, useRef } from 'react';

import { areEqual } from 'Utils/equalityChecks';
// import { useUser } from 'Context/User';
// import { UserModel } from 'Containers/User/Models/UserModel';
// import { isCompanyAdmin } from 'Utils/roles';
import { useDispatch, useSelector } from 'react-redux';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';

import {
  contractsInitialsSelector,
  totalContractsSelector,
  fetchingContractsSelector,
  contractCreatedSelector,
  contractEditedSelector,
  contractsDeletedSelector,
  contractDeleteIdSelector,
} from 'Containers/ContractForm/selectors';
// import { CreateContractModal } from 'Components/ContractForm/CreateContractModal';
import { DeleteContractModal } from 'Components/ContractForm/DeleteContractModal';
// import { EditContractModal } from 'Components/ContractForm/EditContractModal';
import { Toast } from 'Components/Toast';
import { toastSelector } from 'Containers/Core/selectors';
import { userFeatureFlagsSelector } from 'Containers/User/selector';
import { listCompanyContracts, setIdToDelete, setIdToEdit } from './actions';
import { ContractForm } from '../../components/ContractForm';
import { CreateContract } from './CreateContract';
import { EditContract } from './EditContract';

function ContractTabContainer() {
  const dispatch = useDispatch();

  const mounted = useRef(true);

  // const { id: userId }: UserModel = useUser();
  // const user: UserModel = useUser();
  // const [isAdmin] = useState(isCompanyAdmin(user.roles));

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);
  const contractFormInitials = useSelector(contractsInitialsSelector, areEqual);
  const totalContracts = useSelector(totalContractsSelector, areEqual);
  const fetching = useSelector(fetchingContractsSelector, areEqual);
  const contractCreated = useSelector(contractCreatedSelector, areEqual);
  const contractEdited = useSelector(contractEditedSelector, areEqual);
  const deletedContract = useSelector(contractsDeletedSelector, areEqual);
  const { show } = useSelector(toastSelector, areEqual);
  const contractId = useSelector(contractDeleteIdSelector, areEqual);

  // feature flag selector
  const { hideDeleteButton } = useSelector(userFeatureFlagsSelector, areEqual);

  // const [showContactTypeToast, setShowContactTypeToast] = useState(false);
  const [isOpenCreateContract, setIsOpenCreateContract] = useState(false);
  const [isOpenEditContract, setIsOpenEditContract] = useState(false);
  const [isOpenDeleteContract, setIsOpenDeleteContract] = useState(false);

  const getCompanyContracts = useCallback(async () => {
    dispatch(listCompanyContracts(firstCompanyId));
  }, [firstCompanyId]);

  useEffect(() => {
    mounted.current = true;
    (async function fetchData() {
      await getCompanyContracts();
    })();
    return () => {
      if (mounted) {
        mounted.current = false;
      }
    };
  }, [firstCompanyId, deletedContract]);

  // Open Modals
  const onClickOpenCreateContract = useCallback((e: any) => {
    e.preventDefault();
    setIsOpenCreateContract(true);
  }, []);

  const onClickOpenEditContract = useCallback(({ currentTarget }) => {
    const id = currentTarget.getAttribute('data-id');
    dispatch(setIdToEdit(Number(id)));
    setIsOpenEditContract(true);
  }, []);

  const onClickOpenDeleteContract = useCallback((contractId: any) => {
    dispatch(setIdToDelete(contractId));
    setIsOpenDeleteContract(true);
  }, []);

  // Close Modals
  const onClickCloseCreateContract = useCallback(() => {
    // e.preventDefault();
    setIsOpenCreateContract(false);
  }, []);

  const onClickCloseDeleteContract = useCallback(() => {
    setIsOpenDeleteContract(false);
  }, []);

  const onClickCloseEditContract = useCallback(() => {
    dispatch(setIdToEdit(null));
    setIsOpenEditContract(false);
  }, []);

  // to close the edit contact modal and open the info modal
  // const onClickCloseEditContact = useCallback((e: any) => {
  //   e.preventDefault();
  //   setIsOpenEditContact(false);
  // }, []);

  // to close the company type Toast
  // const onClickCloseCompanyTypeToast = useCallback(() => setShowContactTypeToast(false), []);

  // const testOnClick = (e) => {
  //   console.log('testOnClick', e);
  // };
  useEffect(() => {}, [contractId, show, deletedContract, contractFormInitials]);

  useEffect(() => {
    if (contractCreated) {
      setIsOpenCreateContract(false);
      (async function fetchData() {
        await getCompanyContracts();
      })();
    }
    if (contractEdited) {
      setIsOpenEditContract(false);
      setIsOpenCreateContract(false);
      (async function fetchData() {
        await getCompanyContracts();
      })();
    }
  }, [contractCreated, contractEdited]);

  return (
    <div className="container-fluid px-0 position-relative">
      {!isOpenCreateContract && !isOpenEditContract && (
        <ContractForm
          contractforms={contractFormInitials}
          totalForms={totalContracts}
          canDelete={!hideDeleteButton}
          fetching={fetching}
          onButtonClick={onClickOpenCreateContract}
          onDeleteClick={onClickOpenDeleteContract}
          onClickRow={onClickOpenEditContract}
        />
      )}
      {isOpenCreateContract && (
        <CreateContract
          // isOpenCreateContract={isOpenCreateContract}
          closeEditor={onClickCloseCreateContract}
        />
      )}
      {isOpenEditContract && (
        <EditContract
          // isOpenEditContract={isOpenEditContract}
          closeEditor={onClickCloseEditContract}
        />
      )}

      {!hideDeleteButton && (
        <DeleteContractModal
          isOpenDeleteContract={isOpenDeleteContract}
          contractId={contractId}
          onClickCloseDeleteContract={onClickCloseDeleteContract}
        />
      )}
      <Toast bottomCenter />
      {/* <EditContactModal
        contactTypeIcon={contactTypeIcon}
        showContactTypeToast={showContactTypeToast}
        isOpenEditContact={isOpenEditContact}
        onClickCloseEditContact={onClickCloseEditContact}
        onClickCloseToast={onClickCloseCompanyTypeToast}
      /> */}

      {/* {selectedContact && (
        <DeleteCardModal
          id={selectedContact.id}
          title="contact"
          name={selectedContact.fullName}
          isOpen={isOpenDeleteContact}
          coreFetching={coreFetching}
          modalCloseClick={onClickDeleteModalClose}
          onDeleteButtonClick={onClickDeleteContact}
        />
      )} */}
    </div>
  );
}

const ContractTabContainerMemo = memo(ContractTabContainer, areEqual);

export { ContractTabContainerMemo as ContractTab };
