import { Pagination } from 'Components/Pagination';
import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import classes from './metricsPagination.module.css';

interface Props {
  initialPage: number;
  pageCount: number;
  onPageChange: (e: any) => void;
}

function MetricsPagination({ initialPage, pageCount, onPageChange }: Props) {
  return (
    <div className={classes.metricsPaginationBase}>
      <Pagination className="project" initialPage={initialPage} pageCount={pageCount} onPageChange={onPageChange} />
    </div>
  );
}

const MetricsPaginationMemo = memo(MetricsPagination, areEqual);

export { MetricsPaginationMemo as MetricsPagination };
