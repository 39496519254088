import React, { memo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { areEqual } from 'Utils/equalityChecks';
import { useUser } from 'Context/User';

import { UserModel } from 'Containers/User/Models/UserModel';
import { CompanyInfoCard } from 'Components/Profiles/Account/CompanyProfile/CompanyInfoCard';
import { getCompanyPhones, updateCompanyDetails } from 'Containers/Company/actions';
import { companyPhonesSelector } from 'Containers/Company/selectors';
import { formatPhoneInternationalWithCountryCode } from 'Utils/helpers';
import { getTimeZones } from '@vvo/tzdb';

const CompanyInfoCardContainer = () => {
  const dispatch = useDispatch();

  const user: UserModel = useUser();

  const companyPhoneNumbers = useSelector(companyPhonesSelector, areEqual);

  const [companyId, setCompanyId] = useState(undefined);
  const [companyName, setCompanyName] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [userCompanyAddress, setUserCompanyAddress] = useState('');
  const [companyAddress, setCompanyAddress] = useState(undefined);
  const [timeZone, setTimeZone] = useState('');
  const [timeZoneDisplay, setTimeZoneDisplay] = useState('');

  const timeZonesDatabase = getTimeZones();
  useEffect(() => {
    if (user?.id) {
      const { companies } = user;

      if (companies.length > 0) {
        const [company] = companies;
        const { id, address, name, website, timezone } = company;
        if (address) {
          setCompanyAddress(address);
        }
        dispatch(getCompanyPhones(id));
        setCompanyName(name);
        setCompanyId(id);
        setCompanyWebsite(website);
        setTimeZone(timezone);
      }
    }
  }, [user]);

  const updateCompanyTimeZoneQuick = useCallback(
    (timezoneName: string) => {
      if (companyId && companyName) {
        dispatch(
          updateCompanyDetails(companyId, {
            name: companyName,
            timezone: timezoneName,
          })
        );
      }
    },
    [companyId, companyName]
  );

  useEffect(() => {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timeZone) {
      if (timeZone === 'UTC') {
        const zone = timeZonesDatabase.find((zone) => zone.group.includes(browserTimeZone));
        if (zone) {
          setTimeZoneDisplay(zone.alternativeName);
          updateCompanyTimeZoneQuick(zone.name);
        }
      } else {
        const zone = timeZonesDatabase.find((zone) => zone.group.includes(timeZone));
        if (zone) {
          setTimeZoneDisplay(zone.alternativeName);
        }
      }
    } else {
      const zone = timeZonesDatabase.find((zone) => zone.group.includes(browserTimeZone));
      if (zone) {
        setTimeZoneDisplay(zone.alternativeName);
      }
    }
  }, [timeZone]);

  useEffect(() => {
    if (companyPhoneNumbers.length > 0) {
      const [phone] = companyPhoneNumbers;
      const { country_code: code, value } = phone;
      setCompanyPhone(formatPhoneInternationalWithCountryCode(code, value));
    }
  }, [companyPhoneNumbers]);

  useEffect(() => {
    if (companyAddress?.id) {
      const { address, zip, city, state, country }: any = companyAddress;
      setUserCompanyAddress(`${address}, ${zip}, ${city}, ${state}, ${country}`);
    }
  }, [companyAddress]);

  return (
    <CompanyInfoCard
      companyName={companyName}
      companyPhone={companyPhone}
      companyWebsite={companyWebsite}
      companyAddress={userCompanyAddress}
      timeZone={timeZoneDisplay}
    />
  );
};
const CompanyInfoCardContainerMemo = memo(CompanyInfoCardContainer, areEqual);

export { CompanyInfoCardContainerMemo as CompanyInfoCardContainer };
