import {
  Duration,
  areIntervalsOverlapping,
  startOfDay,
  endOfDay,
  isBefore,
  isAfter,
  intervalToDuration,
  compareAsc,
  eachDayOfInterval,
  formatISO,
} from 'date-fns';
import { TimeTrackingCard, TimeTrackingCardTimesheet } from 'Containers/TimeTracking/types';
import { addDurations } from 'Utils/helpers';

export const filterCardsByDateRange = (
  projectTimeCards: TimeTrackingCard[],
  startDate: Date,
  endDate: Date
): TimeTrackingCard[] => {
  const clamp = {
    start: startOfDay(startDate),
    end: endOfDay(endDate),
  };
  const filteredCards = projectTimeCards.filter((timeCard: TimeTrackingCard) =>
    areIntervalsOverlapping(
      // interval between start and end
      clamp,
      // compared to interval on timecard
      {
        start: Date.parse(timeCard.timeIn),
        end: timeCard.timeOut ? Date.parse(timeCard.timeOut) : Date.now(),
      },
      { inclusive: true }
    )
  );

  return filteredCards;
};

// Gets the total clocked in duration over a date interval
// the total duration will be clamped by the date range.
// That is, cards with a clock in time before the start date will only count from the start date.
// Caller is responsible for making sure that only the relevant time cards are used
// (see filterCardsByDateRange)
export const calculateTotalClockDurationClamped = (
  timeCards: TimeTrackingCard[],
  startDate: Date,
  endDate: Date
): Duration => {
  const clamp = {
    start: startOfDay(startDate),
    end: endOfDay(endDate),
  };
  const totalClockDuration: Duration = timeCards.reduce(
    (accumulatedDuration: Duration, timeCard: TimeTrackingCard) => {
      // find out if a clamped time range should be used
      const useClampStart = isBefore(Date.parse(timeCard.timeIn), clamp.start);
      let useClampEnd;
      if (timeCard.timeOut) {
        useClampEnd = isAfter(Date.parse(timeCard.timeOut), clamp.end);
      } else {
        useClampEnd = isAfter(Date.now(), clamp.end);
      }

      // calculate durations
      if (!useClampStart && !useClampEnd) {
        // normal case - no clamping needed
        if (timeCard.duration) {
          return addDurations(accumulatedDuration, timeCard.duration);
        }
        const usableDuration: Duration = intervalToDuration({
          start: Date.parse(timeCard.timeIn),
          end: Date.now(),
        });
        return addDurations(accumulatedDuration, usableDuration);
      }
      if (useClampStart && !useClampEnd) {
        // clamping at start time
        const usableDuration: Duration = intervalToDuration({
          start: clamp.start,
          end: timeCard.timeOut ? Date.parse(timeCard.timeOut) : Date.now(),
        });
        return addDurations(accumulatedDuration, usableDuration);
      }
      if (!useClampStart && useClampEnd) {
        // clamping at end time
        const usableDuration: Duration = intervalToDuration({
          start: Date.parse(timeCard.timeIn),
          end: clamp.end,
        });
        return addDurations(accumulatedDuration, usableDuration);
      }
      // clamping on both sides
      // same as adding a day
      return addDurations(accumulatedDuration, { days: 1 });
    },
    { hours: 0, minutes: 0, seconds: 0 }
  );
  return totalClockDuration;
};

// Gets the time card duration that applies to today.
export const calculateDurationForToday = (timeCard: TimeTrackingCard): Duration => {
  const todayStart = startOfDay(new Date());

  const useClampStart = isBefore(Date.parse(timeCard.timeIn), todayStart);
  // clamping at start time
  if (useClampStart) {
    return intervalToDuration({
      start: todayStart,
      end: timeCard.timeOut ? Date.parse(timeCard.timeOut) : Date.now(),
    });
  }
  return intervalToDuration({
    start: Date.parse(timeCard.timeIn),
    end: timeCard.timeOut ? Date.parse(timeCard.timeOut) : Date.now(),
  });
};

// sort timecards by ascending clock in time
export const sortCardsByTime = (timeCards: TimeTrackingCard[]): TimeTrackingCard[] =>
  timeCards.sort((card1, card2) => compareAsc(Date.parse(card1.timeIn), Date.parse(card2.timeIn)));

// format duration for time tracking components only
export const formatDurationForTimeTracking = (duration: Duration, showSeconds?: boolean): string => {
  if (!duration) {
    return '0h 0m';
  }
  if (duration.days > 0) {
    const totalHours = duration.days * 24 + (duration.hours ?? 0);
    return `${totalHours}h ${duration.minutes ?? 0}m`;
  }
  if (!duration.days && !duration.hours && !duration.minutes) {
    if (showSeconds) {
      return `${duration.seconds ?? 0}s`;
    }
    return '0h 0m';
  }
  return `${duration.hours ?? 0}h ${duration.minutes ?? 0}m`;
};

// calculates and formats the clock in time for a project
export const calculateProjectClockInTime = (projectTimeCards: TimeTrackingCard[]): string => {
  const totalClockDuration: Duration = projectTimeCards.reduce(
    (accumulatedDuration: Duration, timeCard: TimeTrackingCard) => {
      if (timeCard.duration) {
        return addDurations(accumulatedDuration, timeCard.duration);
      }
      const ongoingCardDuration: Duration = intervalToDuration({
        start: Date.parse(timeCard.timeIn),
        end: Date.now(),
      });
      return addDurations(accumulatedDuration, ongoingCardDuration);
    },
    { hours: 0, minutes: 0, seconds: 0 }
  );

  return formatDurationForTimeTracking(totalClockDuration);
};

// Split a multi-day time card into multiple time cards, one for each day.
export const separateMultiDayTimeCardsForTimeSheet = (timeCard: TimeTrackingCard): TimeTrackingCardTimesheet[] => {
  // don't split time cards that hasn't been clocked out yet
  if (!timeCard.timeOut) {
    return [timeCard];
  }

  const timeCardDays = eachDayOfInterval({
    start: Date.parse(timeCard.timeIn),
    end: Date.parse(timeCard.timeOut),
  });

  const splitTimecards: TimeTrackingCardTimesheet[] = [];
  let counter: number = 1;
  timeCardDays.forEach((day: Date) => {
    const dayStart = startOfDay(day);
    const dayEnd = endOfDay(day);
    const useClampStart = isBefore(Date.parse(timeCard.timeIn), dayStart);
    const useClampEnd = isAfter(Date.parse(timeCard.timeOut), dayEnd);

    const dayCard: TimeTrackingCardTimesheet = {
      id: timeCard.id,
      projectId: timeCard.projectId,
      address: timeCard.address,
      address2: timeCard.address2,
      projectNumber: timeCard.projectNumber,
      timeIn: useClampStart ? formatISO(dayStart) : timeCard.timeIn,
      timeOut: useClampEnd ? formatISO(dayEnd) : timeCard.timeOut,
      timecardType: timeCard.timecardType,
      elapsed: timeCard.elapsed,
      sameDay: true,
      user: timeCard.user,
      cardDay: counter.toString(),
    };

    dayCard.duration = intervalToDuration({
      start: Date.parse(dayCard.timeIn),
      end: Date.parse(dayCard.timeOut),
    });

    splitTimecards.push(dayCard);
    counter += 1;
  });

  return splitTimecards.reverse();
};
