import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { TabContent } from 'Components/Tabs';
import { Duration, format, endOfWeek } from 'date-fns';
import { formatDurationForTimeTracking } from 'Containers/TimeTracking/helpers';
import { TimeTrackingCard, WeekClockInTotals } from 'Containers/TimeTracking/types';
import { DayTotalEntry } from 'Containers/TimeTracking';

import classes from './overviewTab.module.css';

interface Props {
  dayTimeCards: TimeTrackingCard[];
  dayTotalTime: Duration;
  weekTotalTime: Duration;
  weekDailyTimes: WeekClockInTotals[];
}

const OverviewTab = ({ dayTimeCards, dayTotalTime, weekTotalTime, weekDailyTimes }: Props) => (
  <TabContent key="tab-content-time-tracking" id="overview" className="position-relative">
    <div className={classes.sectionContainer}>
      <div className={classes.sectionHeader}>Day Total</div>
      <div className={classes.sectionContent}>
        <div className={classes.sectionTotalTime}>{formatDurationForTimeTracking(dayTotalTime)}</div>
      </div>
      {dayTimeCards && dayTimeCards.map((card) => <DayTotalEntry key={card.id} card={card} />)}
    </div>

    <div className={classes.sectionContainer}>
      <div className={classes.sectionHeader}>Week Total</div>
      <div className={classes.sectionContent}>
        <div className={classes.sectionTotalTime}>{formatDurationForTimeTracking(weekTotalTime)}</div>
      </div>
      <div className={classes.weekHeader}>
        {weekDailyTimes[0] &&
          `${format(weekDailyTimes[0].day, 'MMM, d')} - ${format(endOfWeek(weekDailyTimes[0].day), 'MMM, d')}`}
      </div>
      {weekDailyTimes &&
        weekDailyTimes.map((day) => (
          <div className={classes.weekRow} key={format(day.day, 'EEEE')}>
            <span>{format(day.day, 'EEEE')}</span>
            <span>{formatDurationForTimeTracking(day.duration)}</span>
          </div>
        ))}
    </div>

    {/* <div className={classes.sectionContainer}>
      <div className={classes.sectionHeader}>Pay Period</div>
      <div className={classes.sectionContent} />
    </div> */}
  </TabContent>
);

const OverviewTabMemo = memo(OverviewTab, areEqual);

export { OverviewTabMemo as OverviewTab };
