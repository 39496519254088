import React, { memo, useEffect, useState, useCallback } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { CustomReportEditor, GenerateReportWrapper } from 'Components/ReportsAndDocuments';
import { useDispatch, useSelector } from 'react-redux';

import { setReportCreated } from 'Containers/ReportsAndDocuments/Reports/actions';
import { projectSelector } from 'Containers/RocketScan/selectors';

import {
  reportCreatedSelector,
  reportCreatingSelector,
  titleErrorSelector,
} from 'Containers/ReportsAndDocuments/Reports/selectors';
import { useHistory } from 'react-router-dom';

const CustomReportContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const project = useSelector(projectSelector, areEqual);
  const fetching = useSelector(reportCreatingSelector, areEqual);
  const reportCreated = useSelector(reportCreatedSelector, areEqual);

  const [name, setName] = useState('');

  const errors = {
    title: useSelector(titleErrorSelector, areEqual),
  };

  const onChangeTitle = useCallback((e: any) => {
    if (typeof e === 'string') {
      setName(e);
    } else {
      const {
        target: { value },
      } = e;
      setName(value);
    }
  }, []);

  useEffect(() => {
    if (reportCreated) {
      const { id: projectId } = project;

      history.push(`/projects/${projectId}/rocketreports`);
    }

    return () => {
      if (reportCreated) {
        dispatch(setReportCreated(false));
      }
    };
  }, [reportCreated, project]);

  return (
    <GenerateReportWrapper>
      <CustomReportEditor title={name} fetching={fetching} formErrors={errors} onChangeTitle={onChangeTitle} />
    </GenerateReportWrapper>
  );
};

const CustomReportContainerMemo = memo(CustomReportContainer, areEqual);

export { CustomReportContainerMemo as CustomReport };
