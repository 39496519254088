import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Icon } from 'Components/Icons';
import classes from './deleteContractForm.module.css';

interface Props {
  contractId: number;
  onDeleteClick: (e: any) => void;
}

const DeleteContractFormContainer = ({ contractId, onDeleteClick }: Props) => {
  const defaultDeleteIcon = (
    <Icon type="trash" className={classes.downloadIcon} onClick={() => onDeleteClick(contractId)} />
  );

  return defaultDeleteIcon;
};

const DeleteContractFormContainerMemo = memo(DeleteContractFormContainer, areEqual);

export { DeleteContractFormContainerMemo as DeleteContractFormContainer };
