import React, { memo, useCallback } from 'react';

import { Anchor } from 'Components/Anchor';
import { areEqual } from 'Utils/equalityChecks';
import { Icon } from 'Components/Icons';

import classes from './paymentFilterListItem.module.css';

interface Props {
  item: any;
  isSelected: boolean;
  type: string;
  onSelectItem?: (e: any) => void;
}

const getFilterText = (item, type): string => {
  switch (type) {
    case 'email':
      return item.name;
    case 'status':
      return item.name;
    default:
      return '';
  }
};

const PaymentFilterListItemContainer = ({ item, isSelected, type, onSelectItem }: Props) => {
  const onClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (onSelectItem) onSelectItem(item.id);
      // setSelected((prev) => !prev);
    },
    [onSelectItem]
  );

  return (
    <Anchor
      id={item.id.toLocaleString()}
      href="#"
      className={`list-group-item list-group-item-action ${classes.listItemText}`}
      ariaLabel="name"
      dataId={item.id}
      dataName={getFilterText(item, type)}
      onClick={onClick}
    >
      <Icon className={classes.icon} type={isSelected ? 'checkbox' : 'square'} />
      {getFilterText(item, type)}
    </Anchor>
  );
};

PaymentFilterListItemContainer.defaultProps = {
  onSelectItem: undefined,
};

const PaymentFilterListItemContainerMemo = memo(PaymentFilterListItemContainer, areEqual);

export { PaymentFilterListItemContainerMemo as PaymentFilterListItem };
