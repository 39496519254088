import React, { memo } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { TextBox } from 'Components/TextBox';
import { FieldList } from 'Components/Address/FieldList';
import { Icon } from 'Components/Icons';

import classes from './timeZoneAutocomplete.module.css';

interface Props {
  className?: string;
  timezone: string;
  allowedTimeZones: Array<any>;
  invalid?: boolean;
  showDropDown: boolean;
  showCaretIcon: boolean;
  onChangeTimeZone: (e: any) => void;
  onSelectItem: (e: any) => void;
  onClickCaretIcon: (e: any) => void;
}

const TimeZoneAutocomplete = ({
  className,
  timezone,
  allowedTimeZones,
  invalid,
  onChangeTimeZone,
  onSelectItem,
  showDropDown,
  showCaretIcon,
  onClickCaretIcon,
}: Props) => (
  <div className={`dropdown ${className || ''}`}>
    <TextBox
      name="timezone"
      type="text"
      ariaLabel="Select a timezone"
      className={`${classes.validateField} ${invalid ? classes.invalidField : classes.validField}`}
      value={timezone}
      onChange={onChangeTimeZone}
      autoComplete="off"
      placeholder="ex. Pacific Time"
    />

    {showCaretIcon && (
      <Icon className={classes.icon} type={showDropDown ? 'caretup' : 'caretdown'} onClick={onClickCaretIcon} />
    )}

    {allowedTimeZones.length > 0 && (
      <FieldList
        id="timezoneDropDown"
        list={allowedTimeZones}
        onSelectItem={onSelectItem}
        showDropDown={showDropDown}
      />
    )}
  </div>
);

TimeZoneAutocomplete.defaultProps = {
  className: undefined,
  invalid: false,
};

const TimeZoneAutocompleteMemo = memo(TimeZoneAutocomplete, areEqual);

export { TimeZoneAutocompleteMemo as TimeZoneAutocomplete };
