import React, { memo, useEffect, useRef, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useSelector } from 'react-redux';
import { selectedRoomIdSelector } from 'Containers/RocketScan/RoomsView/RoomButtons/selectors';
import { Api } from 'Utils/api';
import { EmptySketchPlaceholder } from 'Components/Project/Unit/Sketch/EmptySketchPlaceholder';
import { PurpleButton } from 'Components/Button';

import headerClasses from '../../../../containers/RocketScan/RoomsView/RoomContent/RoomContentHeader/roomContentHeader.module.css';
import containerClasses from '../../../../containers/RocketScan/RoomsView/RoomContent/RoomContent/roomContent.module.css';
import sketchClasses from './sketch.module.css';

interface SketchData {
  id: number;
  created_at: string;
  updated_at: string;
  project_id: number;
  user_id: number;
  realsee_project_id: string;
  realsee_url: string;
  realsee_floorplan_url: string;
  location_id: number;
  deleted_at?: string;
  resource_code: string;
}

interface Props {
  locationId: number;
}

function Sketch({ locationId }: Props) {
  const [sketchData, setSketchData] = useState<SketchData[]>([]);
  const selectedRoomId = useSelector(selectedRoomIdSelector, areEqual);
  const ref = useRef<HTMLDivElement | null>(null);
  const iframeRefs = useRef<Array<HTMLIFrameElement | null>>([]);

  useEffect(() => {
    (async () => {
      const { data } = await Api.get(`/virtual-tours/location/${locationId}`);
      setSketchData(data.data);
    })();
  }, []);

  useEffect(() => {
    iframeRefs.current = iframeRefs.current.slice(0, sketchData.length);
  }, [sketchData]);

  // scroll to the sketch section
  useEffect(() => {
    if (selectedRoomId && Number(selectedRoomId) === Number(0) && ref.current) {
      ref.current.scrollIntoView();
    }
  }, [selectedRoomId]);

  const handleActivate = async (resourceCode: string, idx: number) => {
    await Api.post('/virtual-tours/activate', {
      resource_code: resourceCode,
    });
    iframeRefs.current[idx].src += ''; // force reload of iframe without rerendering the component by changing the src
  };

  return (
    <div
      ref={ref}
      className={`d-flex flex-column justify-content-flex-start w-100 py-0 px-3 ${containerClasses.containerWrapper}`}
    >
      <div
        className={`container-fluid d-flex flex-row justify-content-between px-0 py-2 ${headerClasses.headerWrapper}`}
      >
        <div className={`d-flex flex-row ${headerClasses.headerTitle}`}>
          <h2 className={headerClasses.roomName}>Sketch</h2>
        </div>
      </div>
      <div className={sketchClasses.iframeWrapper}>
        {sketchData.length > 0 ? (
          sketchData.map((sketch, idx) => (
            <div key={sketch.id}>
              <span className={sketchClasses.headingDate}> {new Date(sketch.created_at).toDateString()} </span>
              <iframe
                ref={(el) => {
                  iframeRefs.current[idx] = el;
                }}
                title={`realsee project: ${sketch.realsee_project_id}`}
                className={sketchClasses.virtualFrame}
                loading="lazy"
                src={sketch.realsee_url}
              />
              <PurpleButton
                className={sketchClasses.activateButton}
                onClick={() => handleActivate(sketch.resource_code, idx)}
              >
                Activate
              </PurpleButton>
            </div>
          ))
        ) : (
          <EmptySketchPlaceholder />
        )}
      </div>
    </div>
  );
}
const SketchMemo = memo(Sketch, areEqual);

export { SketchMemo as Sketch };
