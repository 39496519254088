import React, { memo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { RocketPayBankInfoForm } from 'Components/RocketPay';

import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { addBankAccountToRocketPay, testProvisionPayment } from '../actions';

interface Props {
  recheckStatus: (e?: any) => void;
}

function RocketPayBankInfoFormContainer({ recheckStatus }: Props) {
  const dispatch = useDispatch();

  const bankAccountTypes = [
    { id: 1, name: 'checking' },
    { id: 2, name: 'savings' },
  ];

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

  const [accountOwner, setAccountOwner] = useState('');
  const [accountTypeId, setAccountTypeId] = useState(1);
  const [accountType, setAccountType] = useState('checking');
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastError, setToastError] = useState(false);

  const onChangeAccountOwner = useCallback(({ target: { value } }: any) => {
    setAccountOwner(value);
  }, []);

  const onChangeAccountType = useCallback((id: number) => {
    setAccountTypeId(id);

    const type = bankAccountTypes.find((type: any) => type.id === id);
    setAccountType(type.name);

    setShowDropDown(false);
  }, []);

  const onChangeAccountNumber = useCallback(({ target: { value } }: any) => {
    setAccountNumber(value);
  }, []);

  const onChangeRoutingNumber = useCallback(({ target: { value } }: any) => {
    setRoutingNumber(value);
  }, []);

  const onChangeBankName = useCallback(({ target: { value } }: any) => {
    setBankName(value);
  }, []);

  const toggleAccountTypeDropdown = useCallback(() => {
    setShowDropDown((prevState) => !prevState);
  }, []);

  const onFormSubmit = useCallback(async () => {
    if (firstCompanyId) {
      setLoading(true);
      setShowToast(false);
      const addBankInfoResponse: any = await dispatch(
        addBankAccountToRocketPay(firstCompanyId, accountOwner, accountType, accountNumber, routingNumber, bankName)
      );
      if (!addBankInfoResponse) {
        // failed to add bank info
        setToastMessage('Failed to submit bank info. Please check if you have entered the correct information.');
        setToastError(true);
        setShowToast(true);
        setLoading(false);
      } else if (addBankInfoResponse?.data?.bank_account_id) {
        await dispatch(testProvisionPayment(firstCompanyId));
        setToastMessage('Successfully added bank info.');
        setToastError(false);
        setShowToast(true);
        recheckStatus();
        setLoading(false);
      }
    }
  }, [firstCompanyId, accountOwner, accountType, accountNumber, routingNumber, bankName]);

  return (
    <RocketPayBankInfoForm
      accountOwner={accountOwner}
      accountType={accountType}
      bankAccountTypes={bankAccountTypes}
      accountTypeId={accountTypeId}
      accountNumber={accountNumber}
      routingNumber={routingNumber}
      bankName={bankName}
      showDropDown={showDropDown}
      onChangeAccountOwner={onChangeAccountOwner}
      onChangeAccountType={onChangeAccountType}
      toggleAccountTypeDropdown={toggleAccountTypeDropdown}
      onChangeAccountNumber={onChangeAccountNumber}
      onChangeRoutingNumber={onChangeRoutingNumber}
      onChangeBankName={onChangeBankName}
      onFormSubmit={onFormSubmit}
      loading={loading}
      showToast={showToast}
      toastError={toastError}
      toastMessage={toastMessage}
    />
  );
}

const RocketPayBankInfoFormMemo = memo(RocketPayBankInfoFormContainer, areEqual);
export { RocketPayBankInfoFormMemo as RocketPayBankInfoForm };
