import React, { memo, useEffect, useState, useRef } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { Modal as BsModal } from 'bootstrap';
import { PhotoViewWrapper } from 'Containers/PhotoView/PhotoViewWrapper';
import { PhotoView } from 'Containers/RocketScan';
import { useRoomsViewFunctions } from 'Context/Project/RoomsView';

function PhotoViewModal() {
  const { isOpenPhotoViewModal, closePhotoViewModal }: any = useRoomsViewFunctions();

  const [modal, setModal] = useState(null);
  const modalRef = useRef(undefined);

  useEffect(() => {
    // Instantiate the bs modal
    if (!modal) {
      setModal(new BsModal(modalRef.current));
    }

    // If the modal exists and the user wants it open, open it
    if (modal && isOpenPhotoViewModal) {
      modal.show();
    }

    // If the modal exists and the user wants it close, close it
    if (modal && !isOpenPhotoViewModal) {
      modal.hide();
    }

    return () => {
      if (modal && isOpenPhotoViewModal) {
        modal.hide();
      }
    };
  }, [isOpenPhotoViewModal, modal]);

  // Listen for the popstate event
  window.addEventListener('popstate', (ev) => {
    ev.preventDefault();
    closePhotoViewModal();
  });

  return (
    <div
      style={{ zIndex: 100002 }}
      className="modal fade"
      ref={(ref: HTMLDivElement) => {
        // Set the ref.current to the ref, before useEffect is called.
        modalRef.current = ref;
      }}
      tabIndex={-1}
      id="view-photo-modal"
      aria-labelledby="view-photo-modal"
      aria-hidden="true"
    >
      <PhotoViewWrapper>
        <PhotoView />
      </PhotoViewWrapper>
    </div>
  );
}

const PhotoViewModalMemo = memo(PhotoViewModal, areEqual);

export { PhotoViewModalMemo as PhotoViewModal };
