import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { MetricsNotesModal } from 'Components/Metrics';

import { userMetricsNotesSelector } from 'Containers/Metrics/selectors';

import { useMetricsFunctions } from 'Context/Metrics';
import { resetUserMetricsNotes } from '../actions';

function MetricsNotesModalContainer() {
  const dispatch = useDispatch();

  const { isMetricsNotesModalOpen, setIsMetricsNotesModalOpen }: any = useMetricsFunctions();

  const notes = useSelector(userMetricsNotesSelector, areEqual);

  const closeModal = () => {
    dispatch(resetUserMetricsNotes());
    setIsMetricsNotesModalOpen(false);
  };

  return <MetricsNotesModal isOpen={isMetricsNotesModalOpen} notes={notes} modalCloseClick={closeModal} />;
}

const MetricsNotesModalContainerMemo = memo(MetricsNotesModalContainer, areEqual);

export { MetricsNotesModalContainerMemo as MetricsNotesModal };
