import React, { memo, ReactNode } from 'react';

import { Modal } from 'Components/Modal';

import { areEqual } from 'Utils/equalityChecks';

import classes from './clockInModal.module.css';

interface Props {
  isOpen: boolean;
  title: string;
  children: ReactNode;
  onCloseClick: (e: any) => void;
}

const ClockInModal = ({ isOpen, title, children, onCloseClick }: Props) => (
  <Modal
    id="clock-in-modal"
    classes={classes}
    title={title}
    isOpen={isOpen}
    modalHeader
    dataBsBackdrop="static"
    dataBsKeyboard="false"
    modalCloseClick={onCloseClick}
  >
    {children}
  </Modal>
);

const ClockInModalMemo = memo(ClockInModal, areEqual);

export { ClockInModalMemo as ClockInModal };
