import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { DeleteButton, DarkPurpleButton } from 'Components/Button';

import { Modal } from 'Components/Modal';

import classes from './deleteFileModal.module.css';

interface Props {
  isOpen: boolean;
  pdf: any;
  modalCloseClick: (e: any) => void;
  deleteButtonClick: (e: any) => void;
}

function DeleteFileModal({ isOpen, pdf, modalCloseClick, deleteButtonClick }: Props) {
  return (
    <Modal
      id="pdf-delete-modal"
      classes={classes}
      title="Delete PDF?"
      isOpen={isOpen}
      modalHeader
      dataBsBackdrop="static"
      dataBsKeyboard="false"
      modalCloseClick={modalCloseClick}
    >
      Are you sure you want to delete {pdf?.name}
      ? This action cannot be undone.
      <br />
      <div className="d-flex w-100 justify-content-center">
        <DeleteButton onClick={deleteButtonClick} className={classes.buttons}>
          Delete
        </DeleteButton>
        <DarkPurpleButton onClick={modalCloseClick} className={classes.buttons}>
          Cancel
        </DarkPurpleButton>
      </div>
    </Modal>
  );
}

const DeleteFileModalMemo = memo(DeleteFileModal, areEqual);

export { DeleteFileModalMemo as DeleteFileModal };
