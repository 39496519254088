// type
import { SET_COMPANY_METRICS, SET_USER_METRICS_NOTES, MetricsTypes } from 'Containers/Metrics/actions';

// state
const initialState = {
  companyMetrics: [],
  userMetricsNotes: [],
};

export const metricsReducer = (state = initialState, action: MetricsTypes) => {
  const { type, payload } = action;

  switch (type) {
    case SET_COMPANY_METRICS:
      return {
        ...state,
        companyMetrics: payload,
      };
    case SET_USER_METRICS_NOTES:
      return {
        ...state,
        userMetricsNotes: payload,
      };

    default:
      return state;
  }
};
