import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { PurpleButton } from 'Components/Button';
import { ProjectDataHeader } from 'Containers/ProjectData';

import classes from './uploadsHeader.module.css';

interface Props {
  onClickUploadPDF: (e: any) => void;
}

function UploadsHeader({ onClickUploadPDF }: Props) {
  return (
    <div className={`d-flex justify-content-between ${classes.headerContainer}`}>
      <div className={classes.header}>
        <ProjectDataHeader />
      </div>
      <PurpleButton className={classes.button} outlined onClick={onClickUploadPDF}>
        Upload PDF
      </PurpleButton>
    </div>
  );
}

const UploadsHeaderMemo = memo(UploadsHeader, areEqual);

export { UploadsHeaderMemo as UploadsHeader };
