import React, { memo } from 'react';
import classes from './emptySketchPlaceholder.module.css';

function EmptySketchPlaceholder() {
  return (
    <div className={classes.emptyRoomsPlaceholderBase}>
      <p className={classes.noSketchText}>No Sketches Available</p>
    </div>
  );
}

const EmptySketchPlaceholderMemo = memo(EmptySketchPlaceholder);

export { EmptySketchPlaceholderMemo as EmptySketchPlaceholder };
