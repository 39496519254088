import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Table, TableBody, TableColumn, TableRow } from 'Components/Table';
import { TableHead } from 'Containers/Table';

import { formatDate } from 'Utils/helpers';
import { NoRecordingsPlaceholder } from 'Components/RocketDry';

import { ExternalAtmosphericLog } from 'Containers/RocketDry/types';

import classes from './externalAtmosphericTable.module.css';

interface Props {
  headers: any[];
  logs: ExternalAtmosphericLog[];
  handleViewPicture: (e: any) => void;
}

function ExternalAtmosphericTable({ headers, logs, handleViewPicture }: Props) {
  return (
    <div className={classes.tableBase}>
      <Table className="table w-100">
        <TableHead headers={headers} sortBy="date" onClickSort={() => {}} />
        {logs?.length > 0 && (
          <TableBody>
            {logs.map((log: ExternalAtmosphericLog) => (
              <TableRow key={log.id}>
                <TableColumn className={classes.tableColumn}>{formatDate(log?.createdAt, 'PP')}</TableColumn>
                <TableColumn className={classes.tableColumn}>{log.relativeHumidity}%</TableColumn>
                <TableColumn className={classes.tableColumn}>
                  {log.temperature}
                  °F
                </TableColumn>
                <TableColumn className={classes.tableColumn}>{log.pressure} kPA</TableColumn>
                <TableColumn className={classes.tableColumn}>{log.windSpeed} mph</TableColumn>
                <TableColumn className={`${classes.tableColumn} ${classes.center}`}>
                  {log.photo && (
                    <button className={classes.viewPictureButton} onClick={() => handleViewPicture(log)}>
                      View Picture
                    </button>
                  )}
                </TableColumn>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {logs?.length === 0 && <NoRecordingsPlaceholder />}
    </div>
  );
}

const ExternalAtmosphericTableMemo = memo(ExternalAtmosphericTable, areEqual);

export { ExternalAtmosphericTableMemo as ExternalAtmosphericTable };
