import React, { memo } from 'react';

import { areEqualShallow } from 'Utils/equalityChecks';
import DeleteButton from '../../../Assets/delete-button.svg';

// Custom css
import classes from './deleteButton.module.css';

interface Props {
  className?: string;
  id?: string;
  onClick?: (e: any) => void;
  onKeyUp?: (e: any) => void;
}
const DeleteButtonSvg = ({ className = '', id, onClick, onKeyUp }: Props) => (
  <DeleteButton
    id={id}
    className={`${classes.deleteButtonBase} ${className || ''}`}
    onClick={onClick}
    onKeyUp={onKeyUp}
  />
);

DeleteButtonSvg.defaultProps = {
  className: undefined,
  id: undefined,
  onClick: undefined,
  onKeyUp: undefined,
};

const DeleteButtonSvgMemo = memo(DeleteButtonSvg, areEqualShallow);
export { DeleteButtonSvgMemo as DeleteButtonSvg };
