import React, { memo, useCallback } from 'react';

import { ScopeData, SelectedItem } from 'Containers/RocketScope/types';
import { Icon } from 'Components/Icons';
import { Button } from 'Components/Button';
import { useDamagedMaterialFunctions } from 'Context/RocketScan';
import { areEqual } from 'Utils/equalityChecks';
import { ScopeOfWorkAdjust } from 'Containers/RocketScan/RoomsView';
import classes from './scopeOfWorkCategoryList.module.css';

interface Props {
  scopeItems: ScopeData[];
}

const ScopeOfWorkCategoryList = ({ scopeItems }: Props) => {
  const { addedSelectedItems, updateSelectedItems, selectedScopeSheet, setAddItemQuantity } =
    useDamagedMaterialFunctions() as {
      addedSelectedItems: SelectedItem[];
      updateSelectedItems: (selectedItems: SelectedItem[]) => void;
      selectedScopeSheet: number;
      setAddItemQuantity: (item: SelectedItem, quantity: number) => void;
    };

  const addRemoveSelectedItemClick = useCallback(
    (scopeData: ScopeData) => {
      const searchItem = addedSelectedItems.find((item) => item.id === scopeData.id);

      let newSelectedItems: SelectedItem[];
      if (searchItem) {
        newSelectedItems = addedSelectedItems.filter((item) => item !== searchItem);
      } else {
        newSelectedItems = [
          ...addedSelectedItems,
          {
            id: scopeData.id,
            sheetId: selectedScopeSheet,
            quantity: 1,
            category: scopeData.category,
            codePart1: scopeData.codePart1,
            codePart2: scopeData.codePart2,
            description: scopeData.description,
            unit: scopeData.unit,
            rate: scopeData.rate,
          },
        ];
      }
      updateSelectedItems(newSelectedItems);
    },
    [addedSelectedItems]
  );

  const selectedItemFromScopeItem = useCallback(
    (scopeItem: ScopeData) => addedSelectedItems.find((item) => item.id === scopeItem.id),
    [addedSelectedItems]
  );

  return (
    <div>
      {scopeItems.map((scopeItem) => (
        <div key={scopeItem.id}>
          <div className={classes.containerRow}>
            <div className={classes.scopeItem}>
              <div className={classes.scopeCode}>
                {scopeItem.codePart1}
                {scopeItem.codePart2}
              </div>
              <div className={classes.scopeDescription}>{scopeItem.description}</div>
            </div>
            <div>
              <Button
                type="button"
                onClick={() => addRemoveSelectedItemClick(scopeItem)}
                onKeyUp={() => addRemoveSelectedItemClick(scopeItem)}
                className={`${classes.plusMinusButton}`}
              >
                {selectedItemFromScopeItem(scopeItem) ? <Icon type="minus" /> : <Icon type="plus" />}
              </Button>
            </div>
          </div>
          <ScopeOfWorkAdjust
            selectedScopeItem={selectedItemFromScopeItem(scopeItem)}
            setItemQuantity={setAddItemQuantity}
          />
        </div>
      ))}
    </div>
  );
};

const ScopeOfWorkCategoryListMemo = memo(ScopeOfWorkCategoryList, areEqual);

export { ScopeOfWorkCategoryListMemo as ScopeOfWorkCategoryList };
