import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Table, TableBody, TableColumn, TableRow } from 'Components/Table';
import { TableHead } from 'Containers/Table';

import { Icon } from 'Components/Icons';
import { SpinnerBlock } from 'Components/SpinnerBlock';
import { ReportsEmpty } from 'Components/ReportsAndDocuments';
import { formatDate } from 'Utils/helpers';
import { Pagination } from 'Components/Pagination';
import { ActionButtonWrapper } from 'Containers/ActionButtonWrapper';
import classes from './reportsTable.module.css';

interface Props {
  headers: any[];
  reports: any;
  initialPage: number;
  pageCount: number;
  sortBy: string;
  isCompanyAdmin: boolean;
  canDelete: boolean;
  fetching: boolean;
  onClickSort: (e: any) => void;
  onPageChange: (e: any) => void;
  onDownloadIconClick: (e: any) => void;
  displayToast: (e: any) => void;
  onShareIconClick: (e: any) => void;
  onDeleteIconClick: (e: any) => void;
  onEditIconClick: (e: any) => void;
}

function ReportsTable({
  headers,
  reports,
  initialPage,
  pageCount,
  sortBy,
  isCompanyAdmin,
  canDelete,
  fetching,
  onClickSort,
  onPageChange,
  onDownloadIconClick,
  displayToast,
  onShareIconClick,
  onDeleteIconClick,
  onEditIconClick,
}: Props) {
  return (
    <div className={classes.reportsTableBase}>
      <Table className="table w-100">
        <TableHead headers={headers} sortBy={sortBy} onClickSort={onClickSort} />
        {reports?.meta?.total > 0 && (
          <TableBody>
            {reports.data.map((report: any) => (
              <TableRow key={report.id}>
                <TableColumn className={classes.tableColumn}>{report.name}</TableColumn>

                <TableColumn className={classes.tableColumn}>
                  {report?.creator?.full_name || '[deleted user]'}
                </TableColumn>

                <TableColumn className={classes.tableColumn}>{formatDate(report?.created_at, 'PP')}</TableColumn>

                <TableColumn className={classes.tableColumn}>{report?.type}</TableColumn>
                <TableColumn className={classes.tableColumn}>{report?.status}</TableColumn>

                <TableColumn className={classes.tableColumn}>
                  <div className="w-100 text-center">
                    {report?.type !== 'Custom' ? (
                      <ActionButtonWrapper
                        onClick={onDownloadIconClick}
                        item={report}
                        disabled={report?.status?.toLocaleLowerCase() !== 'completed'}
                      >
                        <Icon type="download" />
                      </ActionButtonWrapper>
                    ) : (
                      <div onMouseOver={displayToast} onFocus={displayToast}>
                        <Icon type="download" onClick={displayToast} className={classes.iconDisabled} />
                      </div>
                    )}
                  </div>
                </TableColumn>

                <TableColumn className={classes.tableColumn}>
                  <div className="w-100 text-center">
                    <ActionButtonWrapper
                      onClick={onShareIconClick}
                      item={report}
                      disabled={report?.status?.toLocaleLowerCase() !== 'completed'}
                    >
                      <Icon type="share32" />
                    </ActionButtonWrapper>
                  </div>
                </TableColumn>

                <TableColumn className={classes.tableColumn}>
                  <div className="w-100 text-center">
                    {report?.type === 'Custom' && (
                      <ActionButtonWrapper item={report} onClick={onEditIconClick}>
                        <Icon type="edit" />
                      </ActionButtonWrapper>
                    )}
                  </div>
                </TableColumn>

                {canDelete && isCompanyAdmin && (
                  <TableColumn className={classes.tableColumn}>
                    <div className="w-100 text-center">
                      <ActionButtonWrapper onClick={onDeleteIconClick} item={report}>
                        <Icon type="trash" />
                      </ActionButtonWrapper>
                    </div>
                  </TableColumn>
                )}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {reports?.meta?.total === 0 && <ReportsEmpty />}
      <SpinnerBlock fetching={fetching} />
      {reports?.meta?.total > 5 && (
        <div className={classes.reportsPaginationBase}>
          <Pagination className="project" initialPage={initialPage} pageCount={pageCount} onPageChange={onPageChange} />
        </div>
      )}
    </div>
  );
}

const ReportsTableMemo = memo(ReportsTable, areEqual);

export { ReportsTableMemo as ReportsTable };
