import React, { memo, useState, useCallback, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { UploadFileModal } from 'Components/Uploads';
import { projectIdSelector } from 'Containers/RocketScan/selectors';

import { uploadPDF } from 'Containers/Uploads/actions';
import { awsStore } from 'Utils/awsStore';

interface Props {
  isOpen: boolean;
  setIsOpen: (e: any) => void;
}

const postTransformObject = (response: any) =>
  // Create a new object to match the json schema for the back end request
  ({
    uuid: response.uuid,
    s3_key: response.key,
    bucket: response.bucket,
    file_name: `${response.name}${response.extension}`,
    name: response.name,
  });

function UploadFileModalContainer({ isOpen, setIsOpen }: Props) {
  const dispatch = useDispatch();

  const projectId = useSelector(projectIdSelector, areEqual);

  const [fetching, setFetching] = useState(false);
  const [file, setFile] = useState<File>();
  const [inputKey, setInputKey] = useState(Date.now());

  const onUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const modalCloseClick = useCallback(() => {
    setIsOpen(false);
    setFetching(false);
  }, []);

  const onUploadComplete = useCallback(() => {
    setIsOpen(false);
    setInputKey(Date.now()); // reset file input
    setFetching(false);
  }, []);

  const uploadButtonClick = useCallback(async () => {
    setFetching(true);

    const response = await awsStore(file, '');
    const transformed = postTransformObject(response);
    dispatch(uploadPDF(projectId, transformed, onUploadComplete));
  }, [file, projectId]);

  return (
    <UploadFileModal
      isOpen={isOpen}
      fetching={fetching}
      inputKey={inputKey}
      modalCloseClick={modalCloseClick}
      uploadButtonClick={uploadButtonClick}
      onUploadChange={onUploadChange}
    />
  );
}

const UploadFileModalContainerMemo = memo(UploadFileModalContainer, areEqual);

export { UploadFileModalContainerMemo as UploadFileModal };
