import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { NoteItem } from 'Containers/Notes';

interface Props {
  notes: any[];
  isReadOnly: boolean;
  markable?: boolean;
  onClickDeleteIcon: (e: any) => void;
}

const NoteItems = ({ notes, isReadOnly, markable, onClickDeleteIcon }: Props) => (
  <>
    {notes.length > 0 &&
      notes.map((note: any) => (
        <NoteItem
          key={note.id}
          note={note}
          isReadOnly={isReadOnly}
          markable={markable}
          onClickDeleteIcon={onClickDeleteIcon}
        />
      ))}
  </>
);

NoteItems.defaultProps = {
  markable: true,
};

const NoteItemsMemo = memo(NoteItems, areEqual);

export { NoteItemsMemo as NoteItems };
