import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Modal } from 'Components/Modal';

import { formatDate } from 'Utils/helpers';

import classes from './metricsNotesModal.module.css';

interface Props {
  isOpen: boolean;
  notes: any;
  modalCloseClick: (e: any) => void;
}

function MetricsNotesModal({ isOpen, notes, modalCloseClick }: Props) {
  return (
    <Modal
      id="metrics-notes-modal"
      classes={classes}
      title="Notes"
      isOpen={isOpen}
      modalHeader
      dataBsBackdrop="static"
      dataBsKeyboard="false"
      modalCloseClick={modalCloseClick}
    >
      {notes?.data?.length > 0 &&
        notes.data.map((note: any) => (
          <div key={note.id}>
            <div>
              <div className={classes.name}>
                {note.project.address?.address} ({note.project.uid})
              </div>
              <div className={classes.datePosted}>
                {formatDate(note.created_at, 'MMM d')}
                {' at '}
                {formatDate(note.created_at, 'h:mmaaa')}
              </div>
            </div>
            <div className={`${classes.noteContent}`}>{note.body}</div>
            <br />
          </div>
        ))}
    </Modal>
  );
}

const MetricsNotesModalMemo = memo(MetricsNotesModal, areEqual);

export { MetricsNotesModalMemo as MetricsNotesModal };
