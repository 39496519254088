import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { useClaimsDataFunctions } from 'Context/ClaimsData';
import { ClaimsDataEdit } from 'Components/ProjectData';
import { createClaim, updateClaim, getClaims } from '../actions';

const ClaimsDataEditContainer = () => {
  const dispatch = useDispatch();

  const ownershipTypes = [
    { id: 0, name: '' },
    { id: 1, name: 'Owner' },
    { id: 2, name: 'Tenant' },
    { id: 3, name: 'Property Manager' },
  ];

  const { setEditIsOpen, project, claims, claimCreated, claimUpdated, setClaimCreated, setClaimUpdated }: any =
    useClaimsDataFunctions();

  const [claimId, setClaimId] = useState(0);
  const [policyHolder, setPolicyHolder] = useState('');
  const [representative, setRepresentative] = useState('');
  const [provider, setProvider] = useState('');
  const [policyNumber, setPolicyNumber] = useState('');
  const [claimNumber, setClaimNumber] = useState('');
  const [ownershipStatus, setOwnershipStatus] = useState('');
  const [ownershipStatusId, setOwnershipStatusId] = useState(0);
  const [showOwnershipStatusDropDown, setShowOwnershipStatusDropDown] = useState(false);
  const [policyHolderPhone, setPolicyHolderPhone] = useState('');
  const [policyHolderEmail, setPolicyHolderEmail] = useState('');
  const [insuranceDeductible, setInsuranceDeductible] = useState('');
  const [adjuster, setAdjuster] = useState('');
  const [adjusterEmail, setAdjusterEmail] = useState('');
  const [adjusterPhone, setAdjusterPhone] = useState('');
  const [projectId, setProjectId] = useState(project.id);

  useEffect(() => {
    if (project?.id) {
      const { id } = project;
      dispatch(getClaims(id));
      setProjectId(id);
    }
  }, [project]);

  useEffect(() => {
    if (claims.length > 0) {
      const [claim] = claims;
      const {
        id,
        policy_holder: policyHolder,
        ownership_status: ownershipStatus,
        policy_holder_phone: policyHolderPhone,
        policy_holder_email: policyHolderEmail,
        representative,
        provider,
        insurance_deductible: insuranceDeductible,
        policy_number: policyNumber,
        claim_number: claimNumber,
        adjuster: adjuster,
        adjuster_phone: adjusterPhone,
        adjuster_email: adjusterEmail,
      } = claim;
      setClaimId(id || 0);
      setPolicyHolder(policyHolder);
      setRepresentative(representative);
      setProvider(provider);
      setPolicyNumber(policyNumber);
      setClaimNumber(claimNumber);
      setOwnershipStatus(ownershipStatus || '');
      setPolicyHolderPhone(policyHolderPhone);
      setPolicyHolderEmail(policyHolderEmail);
      setInsuranceDeductible(insuranceDeductible || '');
      setAdjuster(adjuster);
      setAdjusterPhone(adjusterPhone );
      setAdjusterEmail(adjusterEmail);
    }
  }, [claims]);

  useEffect(() => {
    if (claimCreated || claimUpdated) {
      setEditIsOpen(false);
      dispatch(getClaims(projectId));
    }
    return () => {
      if (claimCreated || claimUpdated) {
        dispatch(setClaimCreated(false));
        dispatch(setClaimUpdated(false));
      }
    };
  }, [claimCreated, claimUpdated]);

  const onChangePolicyHolder = useCallback(({ target: { value } }) => {
    setPolicyHolder(value);
  }, []);

  const onChangeOwnershipStatus =  useCallback((id: number) => {
    setOwnershipStatusId(id);

    const type = ownershipTypes.find((type: any) => type.id === id);
    setOwnershipStatus(type.name);

    setShowOwnershipStatusDropDown(false);

  }, []);

  const toggleOwnershipStatusDropdown = useCallback(() => {
    setShowOwnershipStatusDropDown((prevState) => !prevState);
  }, []);

  const onChangePolicyHolderPhone = useCallback(({ target: { value } }) => {
    setPolicyHolderPhone(value);
  }, []);

  const onChangePolicyHolderEmail = useCallback(({ target: { value } }) => {
    setPolicyHolderEmail(value);
  }, []);

  const onChangeRepresentative = useCallback(({ target: { value } }) => {
    setRepresentative(value);
  }, []);

  const onChangeProvider = useCallback(({ target: { value } }) => {
    setProvider(value);
  }, []);

  const onChangeInsuranceDeductible = useCallback(({ target: { value } }) => {
    setInsuranceDeductible(value);
  }, []);

  const onChangePolicyNumber = useCallback(({ target: { value } }) => {
    setPolicyNumber(value);
  }, []);

  const onChangeClaimNumber = useCallback(({ target: { value } }) => {
    setClaimNumber(value);
  }, []);

  const onChangeAdjuster = useCallback(({ target: { value } }) => {
    setAdjuster(value);
  }, []);

  const onChangeAdjusterEmail = useCallback(({ target: { value } }) => {
    setAdjusterEmail(value);
  }, []);

  const onChangeAdjusterPhone = useCallback(({ target: { value } }) => {
    setAdjusterPhone(value);
  }, []);

  const onSaveButtonClick = useCallback(
    (e: any) => {
      e.preventDefault();
      if (claims.length === 0) {
        dispatch(
          createClaim(projectId, {
            policy_holder: policyHolder,
            ownership_status: ownershipStatus,
            policy_holder_phone: policyHolderPhone,
            policy_holder_email: policyHolderEmail,
            representative,
            provider,
            insurance_deductible: insuranceDeductible,
            policy_number: policyNumber,
            claim_number: claimNumber,
            adjuster,
            adjuster_email: adjusterEmail,
            adjuster_phone: adjusterPhone,
            project_id: projectId,
          })
        );
      }
      if (claims.length > 0) {
        dispatch(
          updateClaim(claimId, {
            policy_holder: policyHolder,
            ownership_status: ownershipStatus,
            policy_holder_phone: policyHolderPhone,
            policy_holder_email: policyHolderEmail,
            representative,
            provider,
            insurance_deductible: insuranceDeductible,
            policy_number: policyNumber,
            claim_number: claimNumber,
            adjuster,
            adjuster_phone: adjusterPhone,
            adjuster_email: adjusterEmail,
            project_id: projectId,
          })
        );
      }
    },
    [claimId, policyHolder, ownershipStatus, policyHolderPhone, policyHolderEmail,
      representative, provider, insuranceDeductible, policyNumber, claimNumber,
      adjuster, adjusterPhone, adjusterEmail, projectId]
  );

  const onCancelButtonClick = useCallback((e: any) => {
    e.preventDefault();
    setEditIsOpen(false);
  }, []);

  return (
    <ClaimsDataEdit
      policyHolder={policyHolder}
      ownershipStatus={ownershipStatus}
      ownershipStatusId={ownershipStatusId}
      showOwnershipStatusDropDown={showOwnershipStatusDropDown}
      ownershipTypes={ownershipTypes}
      policyHolderPhone={policyHolderPhone}
      policyHolderEmail={policyHolderEmail}
      representative={representative}
      provider={provider}
      insuranceDeductible={insuranceDeductible}
      policyNumber={policyNumber}
      claimNumber={claimNumber}
      adjuster={adjuster}
      adjusterEmail={adjusterEmail}
      adjusterPhone={adjusterPhone}
      onChangePolicyHolder={onChangePolicyHolder}
      onChangeOwnershipStatus={onChangeOwnershipStatus}
      toggleOwnershipStatusDropdown={toggleOwnershipStatusDropdown}
      onChangePolicyHolderPhone={onChangePolicyHolderPhone}
      onChangePolicyHolderEmail={onChangePolicyHolderEmail}
      onChangeRepresentative={onChangeRepresentative}
      onChangeProvider={onChangeProvider}
      onChangeInsuranceDeductible={onChangeInsuranceDeductible}
      onChangePolicyNumber={onChangePolicyNumber}
      onChangeClaimNumber={onChangeClaimNumber}
      onChangeAdjuster={onChangeAdjuster}
      onChangeAdjusterEmail={onChangeAdjusterEmail}
      onChangeAdjusterPhone={onChangeAdjusterPhone}
      onSaveButtonClick={onSaveButtonClick}
      onCancelButtonClick={onCancelButtonClick}
    />
  );
};

ClaimsDataEditContainer.defaultProps = {};

const ClaimsDataEditContainerMemo = memo(ClaimsDataEditContainer, areEqual);

export { ClaimsDataEditContainerMemo as ClaimsDataEdit };
