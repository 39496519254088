import React, { memo, useCallback } from 'react';

import { Modal } from 'Components/Modal';

import { areEqual } from 'Utils/equalityChecks';
import { Button } from 'Components/Button';
import { Form } from 'Components/Form';
import { ValidateBackGround } from 'Components/Validation';
import { Label } from 'Components/Label';
import { TextBox } from 'Components/TextBox';
import classes from './newScopeCategoryModal.module.css';

interface Props {
  isOpen: boolean;
  formErrors: { category: string[] };
  modalCloseClick: (e: any) => void;
  onNewCategoryClick: () => void;
  newCategory: string;
  onCategoryChange: (e: any) => void;
}
const NewScopeCategoryModal = ({
  isOpen,
  modalCloseClick,
  onNewCategoryClick,
  formErrors,
  newCategory,
  onCategoryChange,
}: Props) => {
  const addCategoryClick = useCallback((e) => {
    e.preventDefault();
    onNewCategoryClick();
  }, []);

  return (
    <div>
      <Modal
        classes={classes}
        title="New Category Name"
        isOpen={isOpen}
        modalHeader
        modalFooter
        closeButtonText="Cancel"
        dataBsBackdrop="static"
        dataBsKeyboard="false"
        modalCloseClick={modalCloseClick}
      >
        <Form className={classes.form} onSubmit={() => {}} noValidate>
          <ValidateBackGround isValid={!formErrors.category.length}>
            <Label ariaLabel="Email" htmlFor="email">
              Type new category name
            </Label>
            <TextBox
              name="category"
              type="text"
              className={`${classes.validateField}
                ${formErrors?.category.length ? `is-invalid ${classes.invalidField}` : classes.validField}
                `}
              placeholder="New Category Name"
              ariaLabel="Category"
              value={newCategory}
              onChange={onCategoryChange}
            />
            <div className="invalid-feedback">{formErrors?.category?.[0]}</div>
          </ValidateBackGround>
        </Form>
        <Button
          className={`${classes.closeButtonClass}`}
          onClick={addCategoryClick}
          disabled={formErrors?.category?.length > 0}
        >
          Add Category
        </Button>
      </Modal>
    </div>
  );
};

const NewScopeCategoryModalMemo = memo(NewScopeCategoryModal, areEqual);

export { NewScopeCategoryModalMemo as NewScopeCategoryModal };
