import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Modal } from 'Components/Modal';
import { Label } from 'Components/Label';
import { PurpleButton } from 'Components/Button';

import { ValidateBackGround } from 'Components/Validation';
import { OptionsDropDown } from 'Containers/ProjectData';
import DatePicker from 'react-datepicker';

import formClasses from 'Themes/form/form.module.css';
import classes from './createTimeCardModal.module.css';

import 'react-datepicker/dist/react-datepicker-min.module.css';

interface Props {
  // modal variables
  isOpen: boolean;
  canCreate: boolean;
  formErrors: any;
  // employee variables
  employees: any[];
  selectedEmployeeId: number;
  // projectVariables
  projects: any[];
  selectedProjectId: number;
  // date variables
  startDate: any;
  endDate: any;
  // modal functions
  modalCloseClick: (e: any) => void;
  onCreateButtonClick: (e: any) => void;
  // employee functions
  setSelectedEmployee: (e: any) => void;
  // project functions
  setSelectedProject: (e: any) => void;
  // date functions
  onChangeStartDate: (e: any) => void;
  onChangeEndDate: (e: any) => void;
}

const CreateTimeCardModal = ({
  isOpen,
  canCreate,
  employees,
  selectedEmployeeId,
  projects,
  selectedProjectId,
  formErrors,
  startDate,
  endDate,
  modalCloseClick,
  onCreateButtonClick,
  setSelectedEmployee,
  setSelectedProject,
  onChangeStartDate,
  onChangeEndDate,
}: Props) => (
  <Modal
    id="add-timecard-modal"
    classes={classes}
    title="Add Timecard"
    isOpen={isOpen}
    modalHeader
    dataBsBackdrop="static"
    dataBsKeyboard="false"
    modalCloseClick={modalCloseClick}
  >
    <form className={`requires-validation g-3 ${formClasses.formBase}`} noValidate action="#">
      <ValidateBackGround isValid={!formErrors?.user_id?.length}>
        <OptionsDropDown
          className={classes.dropDown}
          label="Employee"
          placeHolder="Select Employee"
          items={employees}
          optionSelectedId={selectedEmployeeId}
          setStatusSelected={setSelectedEmployee}
        />
        <div
          className={`${formClasses.invalidFieldFeedback} invalid-feedback ${
            formErrors?.user_id?.length ? 'd-block' : ''
          }`}
        >
          {formErrors?.user_id?.[0]}
        </div>
      </ValidateBackGround>
      <ValidateBackGround isValid={!formErrors?.project_id?.length}>
        <OptionsDropDown
          className={classes.dropDown}
          label="Project"
          placeHolder="Select Project"
          items={projects}
          optionSelectedId={selectedProjectId}
          setStatusSelected={setSelectedProject}
        />
        <div
          className={`${formClasses.invalidFieldFeedback} invalid-feedback ${
            formErrors?.project_id?.length ? 'd-block' : ''
          }`}
        >
          {formErrors?.project_id?.[0]}
        </div>
      </ValidateBackGround>
      <br />
      <ValidateBackGround isValid={!formErrors?.time_in?.length}>
        <Label ariaLabel="Clock In Time" className={formClasses.label} htmlFor="clockOutTime">
          Clock In Time
        </Label>
        <DatePicker
          todayButton="Today"
          selected={startDate}
          onChange={onChangeStartDate}
          isClearable
          maxDate={new Date()}
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy h:mm aa"
          showTimeInput
          shouldCloseOnSelect={false}
          className={`form-control ${formClasses.validateField} ${formClasses.validField} ${classes.dateEntry}`}
        />
        <div
          className={`${formClasses.invalidFieldFeedback} invalid-feedback ${
            formErrors?.time_in?.length ? 'd-block' : ''
          }`}
        >
          {formErrors?.time_in?.[0]}
        </div>
      </ValidateBackGround>
      <br />
      <ValidateBackGround isValid={!formErrors?.time_out?.length}>
        <Label ariaLabel="Clock Out Time" className={formClasses.label} htmlFor="clockOutTime">
          Clock Out Time
        </Label>
        <DatePicker
          todayButton="Today"
          selected={endDate}
          onChange={onChangeEndDate}
          isClearable
          maxDate={new Date()}
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy h:mm aa"
          showTimeInput
          shouldCloseOnSelect={false}
          className={`form-control ${formClasses.validateField} ${formClasses.validField} ${classes.dateEntry}`}
        />
        <div
          className={`${formClasses.invalidFieldFeedback} invalid-feedback ${
            formErrors?.time_out?.length ? 'd-block' : ''
          }`}
        >
          {formErrors?.time_out?.[0]}
        </div>
      </ValidateBackGround>
      <br />
      <div className={`modal-footer ${classes.modalFooter}`}>
        <PurpleButton onClick={modalCloseClick} className={classes.cancelButton}>
          Cancel
        </PurpleButton>
        <PurpleButton onClick={onCreateButtonClick} disabled={!canCreate} className={classes.createButton}>
          Create
        </PurpleButton>
      </div>
    </form>
  </Modal>
);

const CreateTimeCardModalMemo = memo(CreateTimeCardModal, areEqual);

export { CreateTimeCardModalMemo as CreateTimeCardModal };
