import React, { memo } from 'react';

import { Icon } from 'Components/Icons';
import { areEqual } from 'Utils/equalityChecks';

import classes from './noteHeader.module.css';

interface Props {
  postedByName: string;
  datePosted: string;
  canModify: boolean;
  canDelete: boolean;
  markable: boolean;
  bookmarked: boolean;
  flagged: boolean;
  onClickFlagIcon: (e: any) => void;
  onClickBookmarkIcon: (e: any) => void;
  onClickEditIcon: (e: any) => void;
  onClickDeleteIcon: (e: any) => void;
}

function NoteHeader({
  postedByName,
  datePosted,
  canModify,
  canDelete,
  markable,
  bookmarked,
  flagged,
  onClickFlagIcon,
  onClickBookmarkIcon,
  onClickEditIcon,
  onClickDeleteIcon,
}: Props) {
  return (
    <div className={`d-flex justify-content-between align-items-start ${classes.noteHeader}`}>
      <div>
        <div className={classes.name}>{postedByName}</div>
        <div className={classes.datePosted}>{datePosted}</div>
      </div>

      <div className="d-flex justify-content-end">
        {markable && (
          <>
            <div className={classes.editIcon}>
              <Icon type={flagged ? 'flagpurple' : 'flag'} onClick={onClickFlagIcon} />
            </div>
            <div className={classes.editIcon}>
              <Icon type={bookmarked ? 'bookmarkpurple' : 'bookmark'} onClick={onClickBookmarkIcon} />
            </div>
          </>
        )}
        {canModify && (
          <>
            <div className={classes.editIcon}>
              <Icon type="editmd" onClick={onClickEditIcon} />
            </div>
            {canDelete && (
              <div>
                <Icon type="trashsmdark" onClick={onClickDeleteIcon} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const NoteHeaderMemo = memo(NoteHeader, areEqual);

export { NoteHeaderMemo as NoteHeader };
