import React, { memo, useCallback, useState, useEffect } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useDispatch, useSelector } from 'react-redux';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';

import { CreateEquipmentModal } from 'Components/EquipmentInventory';
import { createEquipmentInInventory } from '../actions';

interface Props {
  isOpen: boolean;
  onCloseClick: (e: any) => void;
}

function CreateEquipmentModalContainer({ isOpen, onCloseClick }: Props) {
  const dispatch = useDispatch();

  const companyId = useSelector(firstCompanyIdSelector, areEqual);

  const [equipmentName, setEquipmentName] = useState('');
  const [canCreate, setCanCreate] = useState(false);

  const onChangeEquipmentName = useCallback((e: any) => {
    const { value } = e.target;

    setCanCreate(value.length > 0);

    setEquipmentName(value);
  }, []);

  const onCreateButtonClick = useCallback(
    (e: any) => {
      e.preventDefault();
      dispatch(createEquipmentInInventory(companyId, equipmentName));
    },
    [companyId, equipmentName]
  );

  useEffect(() => {
    if (!isOpen) {
      setEquipmentName('');
    }
  }, [isOpen]);

  return (
    <CreateEquipmentModal
      isOpen={isOpen}
      canCreate={canCreate}
      formErrors={{}}
      equipmentName={equipmentName}
      modalCloseClick={onCloseClick}
      onCreateButtonClick={onCreateButtonClick}
      onChangeEquipmentName={onChangeEquipmentName}
    />
  );
}

const CreateEquipmentModalContainerMemo = memo(CreateEquipmentModalContainer, areEqual);

export { CreateEquipmentModalContainerMemo as CreateEquipmentModal };
