import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { TimeCardNotesModal } from 'Components/TimeTracking';

import { timeCardNotesSelector } from 'Containers/TimeTracking/selectors';

import { useTimeTrackingFunctions } from 'Context/TimeTracking';

const TimeCardNotesModalContainer = () => {
  const { isTimeCardNotesModalOpen, setIsTimeCardNotesModalOpen }: any = useTimeTrackingFunctions();

  const notes = useSelector(timeCardNotesSelector, areEqual);

  const closeModal = () => {
    setIsTimeCardNotesModalOpen(false);
  };

  return <TimeCardNotesModal isOpen={isTimeCardNotesModalOpen} notes={notes} modalCloseClick={closeModal} />;
};

const TimeCardNotesModalContainerMemo = memo(TimeCardNotesModalContainer, areEqual);

export { TimeCardNotesModalContainerMemo as TimeCardNotesModal };
