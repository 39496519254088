import React, { ChangeEvent, memo, useCallback, useState } from 'react';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { areEqual } from 'Utils/equalityChecks';

import { RequestPaymentModal } from 'Components/RocketPay';
import { formatCurrency as formatCurrencyHelper } from 'Utils/helpers';
import { handleApiRequest } from 'Utils/handleApiRequest';
import { Api } from 'Utils/api';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

interface Props {
  isOpen: boolean;
  setIsOpen: (e: any) => void;
}

function formatCurrency(input) {
  // Ensure input is a string
  if (typeof input !== 'string') {
    input = String(input);
  }

  // Trim whitespace and check if the first character is $
  input = input.trim();
  const numericPart = input.startsWith('$') ? input.slice(1).trim() : input;

  // Convert the string to a number
  let number = parseFloat(numericPart);

  // If the conversion to number fails, default to 0
  if (isNaN(number)) {
    number = 0;
  }

  return formatCurrencyHelper(number, 'USD');
}

function RequestPaymentModalContainer({ isOpen, setIsOpen }: Props) {
  const [hasErrors, setHasErrors] = useState({ email: false, amount: false, description: false });
  const [canProceed, setCanProceed] = useState(false);
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState(formatCurrency('0'));
  const [description, setDescription] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastError, setToastError] = useState(false);

  const companyId = useSelector(firstCompanyIdSelector, areEqual);

  const dispatch = useDispatch();

  const onSuccess = useCallback(() => {
    setToastMessage(`Payment request sent to ${email} for ${amount}`);
    setToastError(false);
    setShowToast(true);
    setTimeout(() => {
      setIsOpen(false);
    }, 3000);
  }, [email, amount]);

  const onError = useCallback(
    (message: string) => {
      setToastMessage(`Error: ${message}`);
      setToastError(true);
      setShowToast(true);
    },
    [setToastError]
  );

  function checkCanProceed(email: string, amount: string, description: string): void {
    // Validate email using a regular expression
    const isEmailValid = emailRegex.test(email);

    // Validate amount: starts with $, followed by a positive number
    let isAmountValid = false;
    if (amount.startsWith('$')) {
      const numericPart = amount.slice(1).trim();
      const number = parseFloat(numericPart);
      isAmountValid = !isNaN(number) && number > 0;
    }

    // Validate description: must have at least 5 characters
    const isDescriptionValid = description.length >= 5;

    // Perform all validations and return the result
    setCanProceed(isEmailValid && isAmountValid && isDescriptionValid);
  }

  const setEmailAndCheck = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
  }, []);

  const checkEmail = useCallback(() => {
    setHasErrors({ email: !emailRegex.test(email), amount: hasErrors.amount, description: hasErrors.description });
    checkCanProceed(email, amount, description);
  }, [email, amount, description, hasErrors]);

  const setAmountAndCheck = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const newAmount = e.target.value;
      setAmount(newAmount);
      checkCanProceed(email, newAmount, description);
    },
    [email, amount, description, hasErrors]
  );

  const checkAmount = useCallback(() => {
    setAmount(formatCurrency(amount));
    const numericPart = amount.slice(1).trim();
    const number = parseFloat(numericPart);
    setHasErrors({ email: hasErrors.email, amount: isNaN(number), description: hasErrors.description });
  }, [amount, hasErrors]);

  const setDescriptionAndCheck = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>): void => {
      const newDescription = e.target.value;
      setDescription(newDescription);
      checkCanProceed(email, amount, newDescription);
    },
    [email, amount, description]
  );

  const checkDescription = useCallback(() => {
    setHasErrors({ email: hasErrors.email, amount: hasErrors.amount, description: description.length < 5 });
  }, [hasErrors, description]);

  const onRequestClick = useCallback(
    async (e: any) => {
      e.preventDefault();
      setCanProceed(false);
      const response = await handleApiRequest(
        dispatch,
        Api.post(`companies/${companyId}/rocket-pay/request-external-payment`, {
          email,
          amount: Math.round(parseFloat(amount.slice(1).replace(',', '')) * 100),
          description,
          currency: 'usd',
        })
      );

      if (response?.success) {
        onSuccess();
      } else {
        onError('An error occurred while requesting payment');
        setCanProceed(true);
      }
    },
    [email, amount, description]
  );

  return (
    <RequestPaymentModal
      isOpen={isOpen}
      modalCloseClick={() => setIsOpen(false)}
      email={email}
      setEmail={setEmailAndCheck}
      checkEmail={checkEmail}
      amount={amount}
      setAmount={setAmountAndCheck}
      checkAmount={checkAmount}
      description={description}
      setDescription={setDescriptionAndCheck}
      checkDescription={checkDescription}
      hasErrors={hasErrors}
      onRequestButtonClick={onRequestClick}
      showToast={showToast}
      toastMessage={toastMessage}
      toastError={toastError}
      canProceed={canProceed}
    />
  );
}

const RequestPaymentModalContainerMemo = memo(RequestPaymentModalContainer, areEqual);

export { RequestPaymentModalContainerMemo as RequestPaymentModal };
