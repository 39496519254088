import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { areEqual } from 'Utils/equalityChecks';
import { RoomButtonsContent, Rooms, PhotosFilter, DeleteRoomModal } from 'Containers/RocketScan';
import { PhotoViewModal } from 'Containers/PhotoView';
import { RoomsViewProvider } from 'Context/Project';
import { userFeatureFlagsSelector } from 'Containers/User/selector';
import { DamagedMaterialToast } from '../DamagedMaterials/DamagedMaterialToast';

import classes from './roomsView.module.css';

interface Props {
  location: any;
  locationId: number;
  locationType?: string;
  isCommercial?: boolean;
}

function RoomsViewContainer({ location, locationId, locationType, isCommercial }: Props) {
  const { hideDeleteButton } = useSelector(userFeatureFlagsSelector, areEqual);

  return (
    <RoomsViewProvider>
      <div className={classes.roomsViewBase}>
        <PhotosFilter />
        <div className={classes.roomsContent}>
          <RoomButtonsContent
            location={location}
            locationId={locationId}
            locationType={locationType}
            isCommercial={isCommercial}
          />
          <Rooms locationId={locationId} />
          <DamagedMaterialToast />
        </div>
      </div>
      {!hideDeleteButton && <DeleteRoomModal />}
      <PhotoViewModal />
    </RoomsViewProvider>
  );
}

RoomsViewContainer.defaultProps = {
  locationType: undefined,
  isCommercial: undefined,
};

const RoomsViewContainerMemo = memo(RoomsViewContainer, areEqual);

export { RoomsViewContainerMemo as RoomsViewContainer };
