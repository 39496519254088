import React, { memo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { DownloadTimesheetsModal } from 'Components/TimeTracking';

import { useTimeTrackingFunctions } from 'Context/TimeTracking';

import { firstCompanyIdSelector } from 'Containers/Projects/selectors';

import { exportCompanyTimeCardCsv } from 'Containers/TimeTracking/actions';

import { TimeCardFilters } from 'Containers/TimeTracking/types';

const DownloadTimesheetsModalContainer = () => {
  const dispatch = useDispatch();

  const {
    savedEmployeeFilters,
    savedAddressFilters,
    savedProjectNumFilters,
    savedDateFilter,
    isDownloadTimesheetsModalOpen,
    setIsDownloadTimesheetsModalOpen,
  }: any = useTimeTrackingFunctions();

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

  const [loading, setLoading] = useState(false);

  const onDownloadButtonClick = useCallback(async () => {
    setLoading(true);
    // const url = ''; // dispatch whatever
    if (firstCompanyId) {
      const filters: TimeCardFilters = {};
      if (savedDateFilter) {
        filters.startsBetween = savedDateFilter;
      }
      if (savedEmployeeFilters) {
        filters.employeeId = savedEmployeeFilters.toString();
      }
      if (savedAddressFilters) {
        filters.addressId = savedAddressFilters.toString();
      }
      if (savedProjectNumFilters) {
        filters.projectId = savedProjectNumFilters.toString();
      }

      dispatch(exportCompanyTimeCardCsv(firstCompanyId, filters));
    }
    setLoading(false);
  }, [firstCompanyId, savedEmployeeFilters, savedAddressFilters, savedProjectNumFilters, savedDateFilter]);

  const closeModal = () => {
    setIsDownloadTimesheetsModalOpen(false);
  };

  return (
    <DownloadTimesheetsModal
      loading={loading}
      isOpen={isDownloadTimesheetsModalOpen}
      onDownloadButtonClick={onDownloadButtonClick}
      modalCloseClick={closeModal}
    />
  );
};

const DownloadTimesheetsModalContainerMemo = memo(DownloadTimesheetsModalContainer, areEqual);

export { DownloadTimesheetsModalContainerMemo as DownloadTimesheetsModal };
