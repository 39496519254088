import {
  SET_CONTRACTS,
  SET_CONTRACTS_DELETE_ID,
  SET_CONTRACTS_EDIT_ID,
  FETCHING_CONTRACTS,
  contractActionTypes,
  CONTRACT_CREATED,
  CONTRACT_EDITED,
  DELETE_CONTRACTS,
  SET_CONTRACT,
} from './actions';

const initialState = {
  contractsInitials: [],
  contractInitials: {},
  totalContracts: 0,
  fetchingContracts: true,
  contractCreated: false,
  contractEdited: false,
  contractDeleteId: null,
  contractEditId: null,
};

export const contractFormReducer = (state = initialState, action: contractActionTypes) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CONTRACTS: {
      // const { data: contracts } = payload;
      return {
        ...state,
        contractsInitials: payload,
        totalContracts: payload.length, // set total to show or hide the placeholders
        fetchingContracts: false, // we'll hide the spinner once everything processed
      };
    }
    case SET_CONTRACT:
      return {
        ...state,
        contractInitials: payload,
        fetchingContracts: false, // we'll hide the spinner once everything processed
      };
    case SET_CONTRACTS_DELETE_ID:
      return {
        ...state,
        contractDeleteId: payload,
      };
    case SET_CONTRACTS_EDIT_ID:
      return {
        ...state,
        contractEditId: payload,
      };
    case FETCHING_CONTRACTS:
      return {
        ...state,
        fetchingContracts: payload,
      };
    case CONTRACT_CREATED:
      return {
        ...state,
        contractCreated: payload,
      };
    case CONTRACT_EDITED:
      return {
        ...state,
        contractEdited: payload,
      };
    case DELETE_CONTRACTS:
      return {
        ...state,
        contractFormDeleted: payload,
      };
    default:
      return state;
  }
};
