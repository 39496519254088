import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { DeleteFileModal } from 'Components/Uploads';

import { deletePDF } from 'Containers/Uploads/actions';

interface Props {
  isOpen: boolean;
  selectedPDF: any;
  setIsOpen: (e: any) => void;
  onDeleteSuccess: (e: any) => void;
}

function DeleteFileModalContainer({ isOpen, selectedPDF, setIsOpen, onDeleteSuccess }: Props) {
  const dispatch = useDispatch();

  const modalCloseClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  const deleteButtonClick = useCallback(() => {
    if (selectedPDF?.id) {
      dispatch(deletePDF(selectedPDF.id, onDeleteSuccess));
    }
  }, [selectedPDF]);

  return (
    <DeleteFileModal
      isOpen={isOpen}
      pdf={selectedPDF}
      modalCloseClick={modalCloseClick}
      deleteButtonClick={deleteButtonClick}
    />
  );
}

const DeleteFileModalContainerMemo = memo(DeleteFileModalContainer, areEqual);

export { DeleteFileModalContainerMemo as DeleteFileModal };
