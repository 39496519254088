import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { Icon } from 'Components/Icons';
import { ScopeOfWorkCategoryList } from 'Containers/RocketScan/RoomsView';
import { ScopeData, SelectedItem } from 'Containers/RocketScope/types';
import classes from './scopeOfWorkCategory.module.css';

interface Props {
  name: string;
  isSelected: boolean;
  selectedItems: SelectedItem[];
  onSelectedItemClick: (e: any) => void;
  scopeItems: ScopeData[];
}

const ScopeOfWorkCategory = ({ name, isSelected, selectedItems, onSelectedItemClick, scopeItems }: Props) => (
  <div className={classes.container}>
    <span
      role="button"
      className={`${classes.materialLabel} ${isSelected ? classes.checked : ''}`}
      onClick={onSelectedItemClick}
      onKeyUp={onSelectedItemClick}
      tabIndex={0}
    >
      <Icon className={classes.icon} type={isSelected ? 'checkbox' : 'square'} />
      {name}
    </span>
    {isSelected && <ScopeOfWorkCategoryList scopeItems={scopeItems} />}
    {!isSelected &&
      selectedItems &&
      selectedItems.map((item) => (
        <div className={classes.workScopeAction} key={item.id}>
          <div className={classes.actionCode}>
            {item.codePart1}
            {item.codePart2}
          </div>
          <div className={classes.actionDescription}>{item.description}</div>
          <div className={classes.actionAmount}>
            {item.unit} x{item.quantity}
          </div>
        </div>
      ))}
  </div>
);

const ScopeOfWorkCategoryMemo = memo(ScopeOfWorkCategory, areEqual);

export { ScopeOfWorkCategoryMemo as ScopeOfWorkCategory };
