import React, { memo, useCallback, useEffect, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useUser } from 'Context/User';
import { UserModel } from 'Containers/User/Models/UserModel';
import { DownloadReportModal } from 'Components/ReportsAndDocuments/Reports/DownloadReportModal';
import { useDispatch, useSelector } from 'react-redux';
import { employeesAttachedSelector } from 'Containers/Crew/selectors';
import { download } from 'Utils/helpers';
import { listProjectMembers, setEmployeesAttached } from 'Containers/Crew/actions';
import { PurpleButton } from 'Components/Button';
import {
  EsignatureWrapper,
  Esignature,
  EsignatureTable,
  ContractFormModal,
  ShareEsignatureModal,
} from 'Components/Esignature';

import { useHistory, useParams } from 'react-router-dom';
import { DeleteEsignatureModal } from 'Components/ReportsAndDocuments/Reports/DeleteEsignatureModal';
import { projectSelector } from 'Containers/RocketScan/selectors';
import { userFeatureFlagsSelector } from 'Containers/User/selector';
import {
  deleteEsignature,
  listEsignatureApi,
  resetDeleteEsignature,
  signCompanyContract,
  shareEsignature,
  setEsignatureShared,
} from 'Containers/Esignature/action';
import {
  eSignatureCreatedSelector,
  eSignatureDeletedSelector,
  eSignatureSelector,
  eSignatureSharingSelector,
  eSignatureSharedSelector,
  emailErrorSelector,
} from './selector';

import classes from './esignature.module.css';

function EsignatureContainer() {
  const { companies }: UserModel = useUser();
  const dispatch = useDispatch();
  const { id: userId } = useUser();
  const history = useHistory();
  const { projectId }: any = useParams();

  // local variables
  const [isOpenSelectMembersModal, setIsOpenSelectMembersModal] = useState(false);
  const [selectedSign, setSelectedSign] = useState(undefined);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showContractFormModal, setContractFormModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [eSignToDelete, setEsignToDelete] = useState(null);
  const [email, setEmail] = useState('');

  // selectors
  const employeesAttached = useSelector(employeesAttachedSelector, areEqual);
  const project = useSelector(projectSelector, areEqual);
  const eSignatureDeleted = useSelector(eSignatureDeletedSelector, areEqual);
  const eSignatureCreated = useSelector(eSignatureCreatedSelector, areEqual);
  const eSignatureSharing = useSelector(eSignatureSharingSelector, areEqual);
  const eSignatureShared = useSelector(eSignatureSharedSelector, areEqual);
  const eSignatures = useSelector(eSignatureSelector);

  // feature flag selector
  const { hideDeleteButton } = useSelector(userFeatureFlagsSelector, areEqual);

  /*
   * share functions
   * */
  const errors = {
    email: useSelector(emailErrorSelector, areEqual),
  };

  const onChangeEmail = useCallback(({ target: { value } }: any) => {
    setEmail(value);
  }, []);

  const onFormSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      const { id } = selectedSign;

      dispatch(shareEsignature(id, { email }));
    },
    [email, selectedSign]
  );

  // api
  const getProjectMembers = useCallback(() => {
    dispatch(listProjectMembers(project.id, userId));
  }, [project]);

  // initial api call
  useEffect(() => {
    if (project) {
      getProjectMembers();
    }
  }, [project]);

  useEffect(() => {
    if (employeesAttached) {
      setIsOpenSelectMembersModal(false);
    }
  }, [employeesAttached]);

  useEffect(() => {
    if (!isOpenSelectMembersModal && employeesAttached) {
      getProjectMembers();
    }

    return () => {
      if (!isOpenSelectMembersModal && employeesAttached) {
        dispatch(setEmployeesAttached(false));
      }
    };
  }, [isOpenSelectMembersModal, employeesAttached]);

  useEffect(() => {
    if (eSignatureShared) {
      setShowShareModal(false);
      dispatch(setEsignatureShared(false));
    }

    return () => {
      if (eSignatureShared) {
        dispatch(setEsignatureShared(false));
      }
    };
  }, [eSignatureShared]);

  const getCompanySigns = useCallback(() => {
    if (companies.length > 0 && projectId) {
      const [company] = companies;
      const { id: companyId } = company;
      // const { id: projectId } = project;
      dispatch(listEsignatureApi(companyId, projectId));
    }
  }, [companies, projectId]);

  const onDownloadIconClick = useCallback(
    (esign: any) => {
      setSelectedSign(esign);
      setShowDownloadModal(true);
    },
    [eSignatures]
  );

  const onEditIconClick = useCallback(
    (esign: any) => {
      const { id } = esign;
      history.replace(`/projects/${projectId}/documents/${id}`);
    },
    [selectedSign, projectId]
  );

  const getRegeneratedSignature = async (signature: any) => {
    setSelectedSign(signature);
    const { url }: any = signature;
    await download(url, 'E-SIGN', setLoading);
    getCompanySigns();
  };

  const onDownloadButtonClick = useCallback(async () => {
    if (selectedSign) {
      setLoading(true);
      const { url } = selectedSign;
      if (url) {
        await download(url, 'E-SIGN', setLoading);
      } else {
        const { id, content, signature } = selectedSign;
        dispatch(signCompanyContract(id, signature, content, getRegeneratedSignature));
      }
    }
  }, [selectedSign, getCompanySigns]);

  const onShareIconClick = useCallback((esig: any) => {
    setSelectedSign(esig);
    setShowShareModal(true);
  }, []);

  const onDeleteButtonClick = useCallback(
    (esign: any) => {
      dispatch(resetDeleteEsignature());
      const { id } = esign;
      dispatch(deleteEsignature(id));
      setShowDeleteModal(false);
    },
    [selectedSign]
  );

  const onCancelButtonClick = useCallback((e: any) => {
    e.preventDefault();
    setTimeout(() => setSelectedSign(undefined), 500);
    setShowDownloadModal(false);
    setShowShareModal(false);
    setContractFormModal(false);
    setShowDeleteModal(false);
  }, []);

  const onShowDeleteModal = useCallback((esign: any) => {
    setEsignToDelete(esign);
    setShowDeleteModal(true);
  }, []);

  useEffect(() => {
    getCompanySigns();
  }, [showContractFormModal, eSignatureDeleted, eSignatureCreated]);

  return (
    <EsignatureWrapper>
      <Esignature>
        <div className={`d-flex justify-content-start align-items-center ${classes.eSignContent}`}>
          <h2 className={classes.eSignHeading}>Documents</h2>
          <PurpleButton className={classes.addButton} onClick={() => setContractFormModal(true)}>
            Add +
          </PurpleButton>
        </div>
        <EsignatureTable
          esignatures={{ meta: { total: 1 }, data: eSignatures }}
          canDelete={!hideDeleteButton}
          onDownloadIconClick={onDownloadIconClick}
          onShareIconClick={onShareIconClick}
          onShowDeleteModal={onShowDeleteModal}
          onEditIconClick={onEditIconClick}
        />
      </Esignature>

      <DownloadReportModal
        loading={loading}
        isOpen={showDownloadModal}
        onDownloadButtonClick={onDownloadButtonClick}
        modalCloseClick={onCancelButtonClick}
        reportName="Signed Document"
      />
      <ShareEsignatureModal
        loading={eSignatureSharing}
        esignatureName={selectedSign?.contract_form?.name ?? 'Untitled Form'}
        email={email}
        isOpen={showShareModal}
        formErrors={errors}
        modalCloseClick={onCancelButtonClick}
        onChangeEmail={onChangeEmail}
        onFormSubmit={onFormSubmit}
      />

      {!hideDeleteButton && (
        <DeleteEsignatureModal
          loading={loading}
          esign={eSignToDelete}
          reportName=""
          showDeleteModal={showDeleteModal}
          onDeleteButtonClick={onDeleteButtonClick}
          onCancelButtonClick={onCancelButtonClick}
          modalCloseClick={onCancelButtonClick}
        />
      )}
      <ContractFormModal
        isOpen={showContractFormModal}
        setContractFormModal={setContractFormModal}
        modalCloseClick={onCancelButtonClick}
      />
    </EsignatureWrapper>
  );
}

const EsignatureContainerMemo = memo(EsignatureContainer, areEqual);

export { EsignatureContainerMemo as Esignature };
