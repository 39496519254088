import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { Table, TableBody, TableColumn, TableHeader, TableRow, Th } from 'Components/Table';

import { Icon } from 'Components/Icons';
import { ActionButtonWrapper } from 'Containers/ActionButtonWrapper';

import classes from './equipmentInventoryList.module.css';
// import { DeleteEquipmentInventory } from '../DeleteEquipmentInventory';

interface Props {
  equipmentList: any;
  canDelete: boolean;
  onEditIconClick: (e: any) => void;
  onDeleteIconClick: (e: any) => void;
}

function EquipmentInventoryList({ equipmentList, canDelete, onEditIconClick, onDeleteIconClick }: Props) {
  return (
    <Table className={`table ${classes.equipmentListWrapper}`}>
      <TableHeader>
        <TableRow>
          <Th />
          <Th />
          {canDelete && <Th />}
        </TableRow>
      </TableHeader>
      <TableBody>
        {equipmentList.map((equipment: any) => (
          <TableRow key={equipment.id}>
            <TableColumn>
              <p className={classes.equipmentName}>{equipment.name}</p>
            </TableColumn>
            <TableColumn className={classes.columnContent}>
              <ActionButtonWrapper item={equipment} onClick={onEditIconClick}>
                <Icon type="edit" />
              </ActionButtonWrapper>
            </TableColumn>
            {canDelete && (
              <TableColumn className={classes.columnContent}>
                <ActionButtonWrapper item={equipment} onClick={onDeleteIconClick}>
                  <Icon type="trash" />
                </ActionButtonWrapper>
              </TableColumn>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
const EquipmentInventoryListMemo = memo(EquipmentInventoryList, areEqual);

export { EquipmentInventoryListMemo as EquipmentInventoryList };
