import React, { memo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { EditTimeCardModal } from 'Components/TimeTracking';

import { updateTimeCard } from 'Containers/TimeTracking/actions';

import { useTimeTrackingFunctions } from 'Context/TimeTracking';
import { updateTimeCardErrorSelector } from 'Containers/TimeTracking/selectors';

const EditTimeCardModalContainer = () => {
  const dispatch = useDispatch();

  const [timeIn, setTimeIn] = useState(undefined);
  const [timeOut, setTimeOut] = useState(undefined);

  const { isEditTimeCardModalOpen, setIsEditTimeCardModalOpen, selectedTimeCard }: any = useTimeTrackingFunctions();

  const errors = useSelector(updateTimeCardErrorSelector, areEqual);
  // console.log(errors);

  useEffect(() => {
    if (selectedTimeCard?.timeIn && selectedTimeCard?.timeOut) {
      // console.log('card time in', selectedTimeCard.timeIn);
      setTimeIn(new Date(selectedTimeCard.timeIn));
      setTimeOut(new Date(selectedTimeCard.timeOut));
    }
  }, [selectedTimeCard]);

  const resetInputs = () => {
    setTimeIn(new Date(selectedTimeCard.timeIn));
    setTimeOut(new Date(selectedTimeCard.timeOut));
  };

  const closeModal = () => {
    setIsEditTimeCardModalOpen(false);
    resetInputs();
  };

  const onEditButtonClick = useCallback(() => {
    dispatch(
      updateTimeCard(
        selectedTimeCard.id,
        selectedTimeCard.timecardType.id,
        selectedTimeCard.userId,
        timeIn,
        timeOut,
        closeModal
      )
    );
  }, [timeIn, timeOut, selectedTimeCard]);

  return (
    <EditTimeCardModal
      // modal variables
      isOpen={isEditTimeCardModalOpen}
      formErrors={errors}
      canEdit={timeIn && timeOut}
      // timecard
      name={selectedTimeCard?.user ?? ''}
      address={selectedTimeCard?.address ?? ''}
      // date variables
      startDate={timeIn}
      endDate={timeOut}
      // modal functions
      modalCloseClick={closeModal}
      onEditButtonClick={onEditButtonClick}
      // date functions
      onChangeStartDate={setTimeIn}
      onChangeEndDate={setTimeOut}
    />
  );
};

const EditTimeCardModalContainerMemo = memo(EditTimeCardModalContainer, areEqual);

export { EditTimeCardModalContainerMemo as EditTimeCardModal };
