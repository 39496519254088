import React, { memo, useCallback, useState, useEffect } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useDispatch, useSelector } from 'react-redux';

import { EditEquipmentModal } from 'Components/EquipmentInventory';
import { updateEquipmentInInventory } from '../actions';
import { selectedEquipmentForUpdateSelector } from '../selectors';

interface Props {
  isOpen: boolean;
  onCloseClick: (e: any) => void;
}

function EditEquipmentModalContainer({ isOpen, onCloseClick }: Props) {
  const dispatch = useDispatch();

  const selectedEquipment = useSelector(selectedEquipmentForUpdateSelector, areEqual);

  const [equipmentName, setEquipmentName] = useState('');
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    if (selectedEquipment?.name) {
      setEquipmentName(selectedEquipment?.name);
    }
  }, [selectedEquipment]);

  const onChangeEquipmentName = useCallback((e: any) => {
    const { value } = e.target;

    setCanEdit(value.length > 0);

    setEquipmentName(value);
  }, []);

  const onEditButtonClick = useCallback(
    (e: any) => {
      e.preventDefault();
      if (selectedEquipment?.id) {
        dispatch(updateEquipmentInInventory(selectedEquipment.id, equipmentName));
      }
    },
    [selectedEquipment, equipmentName]
  );

  return (
    <EditEquipmentModal
      isOpen={isOpen}
      canEdit={canEdit}
      formErrors={{}}
      equipmentName={equipmentName}
      modalCloseClick={onCloseClick}
      onEditButtonClick={onEditButtonClick}
      onChangeEquipmentName={onChangeEquipmentName}
    />
  );
}

const EditEquipmentModalContainerMemo = memo(EditEquipmentModalContainer, areEqual);

export { EditEquipmentModalContainerMemo as EditEquipmentModal };
