import { Icon } from 'Components/Icons';
import React, { memo } from 'react';
import { areEqualShallow } from 'Utils/equalityChecks';

interface Props {
  iconClassName: string;
  iconType?: string;
}
const RocketPlanHeader = ({ iconClassName, iconType }: Props) => <Icon className={iconClassName} type={iconType} />;

RocketPlanHeader.defaultProps = {
  iconType: 'logo',
};

const RocketPlanHeaderMemo = memo(RocketPlanHeader, areEqualShallow);

export { RocketPlanHeaderMemo as RocketPlanHeader };
