import React, { memo, useCallback } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { ReportLocation } from 'Components/ReportsAndDocuments';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedReportLocations, setSelectedReportRooms } from 'Containers/ReportsAndDocuments/Reports/actions';
import {
  selectedReportLocationsSelector,
  selectedReportRoomsSelector,
} from 'Containers/ReportsAndDocuments/Reports/selectors';

interface Props {
  location: any;
}

const ReportLocationContainer = ({ location }: Props) => {
  const dispatch = useDispatch();

  const { id, name, rooms } = location;

  const selectedReportLocations = useSelector(selectedReportLocationsSelector, areEqual);
  const selectedReportRooms = useSelector(selectedReportRoomsSelector, areEqual);

  const onLocationClick = useCallback(() => {
    dispatch(setSelectedReportLocations(id));
  }, [id]);

  const onRoomClick = useCallback((id: number) => {
    dispatch(setSelectedReportRooms(id));
  }, []);

  return (
    <ReportLocation
      name={name}
      selectedLocations={selectedReportLocations.includes(id)}
      selectedRooms={selectedReportRooms}
      rooms={rooms}
      onLocationClick={onLocationClick}
      onRoomClick={onRoomClick}
    />
  );
};

const ReportLocationContainerMemo = memo(ReportLocationContainer, areEqual);

export { ReportLocationContainerMemo as ReportLocationContainer };
