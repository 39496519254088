import React, { memo, ReactNode } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { SingleProjectProvider } from 'Context/Project';
import { ProjectTabsLayout } from 'Containers/Layouts';
import { TabContent } from 'Components/Tabs';

import classes from './uploadsWrapper.module.css';

interface Props {
  children: ReactNode;
}

function UploadsWrapper({ children }: Props) {
  return (
    <SingleProjectProvider tab="uploads">
      <ProjectTabsLayout tab="uploads">
        <TabContent id="uploads" className={`active show position-relative ${classes.tabContent}`}>
          {children}
        </TabContent>
      </ProjectTabsLayout>
    </SingleProjectProvider>
  );
}

const UploadsWrapperMemo = memo(UploadsWrapper, areEqual);

export { UploadsWrapperMemo as UploadsWrapper };
