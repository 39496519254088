import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { TableColumn, TableRow } from 'Components/Table';

import { formatDate } from 'Utils/helpers';

import { InternalAtmosphericLog } from 'Containers/RocketDry/types';

import classes from './inletOutletRows.module.css';

type LogGroup = {
  inlet: InternalAtmosphericLog;
  outlet?: InternalAtmosphericLog;
};

interface Props {
  logGroup: LogGroup;
  handleViewPicture: (e: any) => void;
}

function InletOutletRows({ logGroup, handleViewPicture }: Props) {
  return (
    <>
      {/* inlet row */}
      {!logGroup.inlet.emptyEntry && (
        <TableRow>
          <TableColumn className={classes.tableColumn}>{formatDate(logGroup.inlet.createdAt, 'PP')}</TableColumn>
          <TableColumn className={classes.tableColumn}>Inlet</TableColumn>
          <TableColumn className={classes.tableColumn}>{logGroup.inlet.relativeHumidity}%</TableColumn>
          <TableColumn className={classes.tableColumn}>
            {logGroup.inlet.temperature}
            °F
          </TableColumn>
          <TableColumn className={classes.tableColumn}>{logGroup.inlet.dewPoint}</TableColumn>
          <TableColumn className={classes.tableColumn}>{logGroup.inlet.gpp}</TableColumn>
          <TableColumn className={`${classes.tableColumn} ${classes.center}`}>
            {logGroup.inlet.photo && (
              <button onClick={() => handleViewPicture(logGroup.inlet)} className={classes.viewPictureButton}>
                View Picture
              </button>
            )}
          </TableColumn>
        </TableRow>
      )}
      {/* outlet row */}
      {logGroup.outlet && (
        <TableRow>
          <TableColumn className={classes.tableColumn}>{formatDate(logGroup.inlet.createdAt, 'PP')}</TableColumn>
          <TableColumn className={classes.tableColumn}>Outlet</TableColumn>
          <TableColumn className={classes.tableColumn}>{logGroup.outlet.relativeHumidity}%</TableColumn>
          <TableColumn className={classes.tableColumn}>
            {logGroup.outlet.temperature}
            °F
          </TableColumn>
          <TableColumn className={classes.tableColumn}>{logGroup.outlet.dewPoint}</TableColumn>
          <TableColumn className={classes.tableColumn}>{logGroup.outlet.gpp}</TableColumn>
          <TableColumn className={`${classes.tableColumn} ${classes.center}`}>
            {logGroup.outlet.photo && (
              <button onClick={() => handleViewPicture(logGroup.outlet)} className={classes.viewPictureButton}>
                View Picture
              </button>
            )}
          </TableColumn>
        </TableRow>
      )}
      {/* depreciation row */}
      {!logGroup.inlet.emptyEntry && logGroup.outlet && (
        <TableRow>
          <TableColumn className={classes.tableColumn}>{formatDate(logGroup.inlet.createdAt, 'PP')}</TableColumn>
          <TableColumn className={classes.tableColumn}>Depreciation</TableColumn>
          <TableColumn className={classes.tableColumn}>
            <br />
          </TableColumn>
          <TableColumn className={classes.tableColumn}>
            <br />
          </TableColumn>
          <TableColumn className={classes.tableColumn}>
            <br />
          </TableColumn>
          <TableColumn className={classes.tableColumn}>
            {(logGroup.outlet.gpp - logGroup.inlet.gpp).toFixed(2)}
          </TableColumn>
          <TableColumn className={classes.tableColumn}>
            <br />
          </TableColumn>
        </TableRow>
      )}
    </>
  );
}

const InletOutletRowsMemo = memo(InletOutletRows, areEqual);

export { InletOutletRowsMemo as InletOutletRows };
