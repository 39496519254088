export const userTimeCardsSelector = ({ timeTracking: { userTimeCards: value = [] } }) => value;
export const userClockedInTimeCardsSelector = ({ timeTracking: { userClockedInTimeCards: value = [] } }) => value;
export const clockInSuccessSelector = ({ timeTracking: { clockInSuccess: value = false } }) => value;
export const clockOutSuccessSelector = ({ timeTracking: { clockOutSuccess: value = false } }) => value;
export const timeCardNoteCreatedSelector = ({ timeTracking: { timeCardNoteCreated: value = false } }) => value;
export const refreshTimeCardsSelector = ({ timeTracking: { refreshTimeCards: value = false } }) => value;
export const refreshCompanyTimeCardsSelector = ({ timeTracking: { refreshCompanyTimeCards: value = false } }) => value;
export const companyTimeCardsSelector = ({ timeTracking: { companyTimeCards: value = { data: [] } } }) => value;
export const employeeFiltersSelector = ({ timeTracking: { employeeFilters: value = { data: [] } } }) => value;
export const projectNumFiltersSelector = ({ timeTracking: { projectNumFilters: value = { data: [] } } }) => value;
export const addressFiltersSelector = ({ timeTracking: { addressFilters: value = { data: [] } } }) => value;
export const projectDropdownSelector = ({ timeTracking: { projectDropdownItems: value = { data: [] } } }) => value;
export const createTimeCardErrorSelector = ({ timeTracking: { createTimeCardErrors: value = false } }) => value;
export const updateTimeCardErrorSelector = ({ timeTracking: { updateTimeCardErrors: value = false } }) => value;
export const createTimeCardNoteErrorsSelector = ({
  timeTracking: { createTimeCardNoteErrors: value = { body: [] } },
}) => value;
export const timeCardNotesSelector = ({ timeTracking: { timeCardNotes: value = [] } }) => value;
