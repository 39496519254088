import React, { memo, ReactNode } from 'react';

import { areEqual } from 'Utils/equalityChecks';
import { width } from 'Utils/screen';
// import { Icon } from "Components/Icons";
import { ProjectsSearch } from 'Containers/Projects';
import { Tab } from '../Tab';

import classes from './projectsTabMenu.module.css';

interface Props {
  id?: string;
  className?: string;
  children?: ReactNode;
  activeTab?: string;
  onlyShowMyProjects?: boolean;
  setActiveTab: (e: any) => void;
}

const createTabs = (activeTab: string, onlyShowMyProjects: boolean, onTabClick: (e: any) => void) => (
  <>
    <Tab
      key="my-projects-tab"
      id="my-projects-tab"
      className={`${classes.flexCenter} ${classes.button} ${
        activeTab === 'my-projects-tab' ? `active ${classes['active-Tab']}` : ''
      }`}
      target="my-projects"
      onClick={onTabClick}
    >
      <span>My Projects</span>
    </Tab>

    {!onlyShowMyProjects && (
      <>
        <Tab
          key="wip-projects-tab"
          id="wip-projects-tab"
          className={`${classes.flexCenter} ${classes.buttonShort} ${
            activeTab === 'wip-projects-tab' ? `active ${classes['active-Tab']}` : ''
          }`}
          target="wip-projects"
          onClick={onTabClick}
        >
          <span>WIP</span>
        </Tab>
        <Tab
          key="recon-projects-tab"
          id="recon-projects-tab"
          className={`${classes.flexCenter} ${classes.buttonShort} ${
            activeTab === 'recon-projects-tab' ? `active ${classes['active-Tab']}` : ''
          }`}
          target="recon-projects"
          onClick={onTabClick}
        >
          <span>Recon</span>
        </Tab>
        <Tab
          key="lead-projects-tab"
          id="lead-projects-tab"
          className={`${classes.flexCenter} ${classes.buttonShort} ${
            activeTab === 'lead-projects-tab' ? `active ${classes['active-Tab']}` : ''
          }`}
          target="lead-projects"
          onClick={onTabClick}
        >
          <span>Lead</span>
        </Tab>
        <Tab
          key="contract-projects-tab"
          id="contract-projects-tab"
          className={`${classes.flexCenter} ${classes.button} ${
            activeTab === 'contract-projects-tab' ? `active ${classes['active-Tab']}` : ''
          }`}
          target="contract-projects"
          onClick={onTabClick}
        >
          <span>Contract</span>
        </Tab>
        <Tab
          key="estimate-projects-tab"
          id="estimate-projects-tab"
          className={`${classes.flexCenter} ${classes.button} ${
            activeTab === 'estimate-projects-tab' ? `active ${classes['active-Tab']}` : ''
          }`}
          target="estimate-projects"
          onClick={onTabClick}
        >
          <span>Estimate</span>
        </Tab>
        <Tab
          key="invoiced-projects-tab"
          id="invoiced-projects-tab"
          className={`${classes.flexCenter} ${classes.button} ${
            activeTab === 'invoiced-projects-tab' ? `active ${classes['active-Tab']}` : ''
          }`}
          target="invoiced-projects"
          onClick={onTabClick}
        >
          <span>Invoiced</span>
        </Tab>
        <Tab
          key="paid-projects-tab"
          id="paid-projects-tab"
          className={`${classes.flexCenter} ${classes.buttonShort} ${
            activeTab === 'paid-projects-tab' ? `active ${classes['active-Tab']}` : ''
          }`}
          target="paid-projects"
          onClick={onTabClick}
        >
          <span>Paid</span>
        </Tab>
        <Tab
          key="completed-projects-tab"
          id="completed-projects-tab"
          className={`${classes.flexCenter} ${classes.button} ${
            activeTab === 'completed-projects-tab' ? `active ${classes['active-Tab']}` : ''
          }`}
          target="completed-projects"
          onClick={onTabClick}
        >
          <span>Completed</span>
        </Tab>
      </>
    )}
  </>
);

/*
  In order to override bootstraps active class on tabs, there is a click event onTabClick, which will get the name of the tab that was clicked
  and then trigger a re-render.  Note in the createTabs method above, where the active class is added or not, based on which tab was clicked.
*/
function ProjectsTabMenu({ id = 'tabs', className, children, activeTab, onlyShowMyProjects, setActiveTab }: Props) {
  const onTabClick = (e: any) => {
    // Occasionally, e.currentTarget is undefined.  Set the current activeTab if we run into this bug

    setActiveTab(e?.currentTarget?.id || activeTab);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className={classes.projectsTabWrapper}>
            <div className={classes.tabsContainer}>
              <ul
                className={`nav nav-tabs pr-4 ${width < 576 ? 'flex-sm-column' : 'width'}   ${classes.tabs} ${
                  className || ''
                }`}
                id={id}
                role="tablist"
              >
                {createTabs(activeTab, onlyShowMyProjects, onTabClick)}
              </ul>
              {/* <div className={`${classes.filterButtonContainer} ${classes.flexCenter}`}>
                <span className={classes.filterButton}>
                  Filter <Icon type="filter" className={classes.filterIcon} />
                </span>
              </div> */}
            </div>
            <div className={classes.searchContainer}>
              <ProjectsSearch />
            </div>
            <div className="tab-content w-100 h-100 d-inline-block" id="myTabContent" style={{ height: 'auto' }}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ProjectsTabMenu.defaultProps = {
  id: undefined,
  className: undefined,
  children: undefined,
  activeTab: undefined,
  onlyShowMyProjects: false,
};

const ProjectsTabMenuMemo = memo(ProjectsTabMenu, areEqual);
export { ProjectsTabMenuMemo as ProjectsTabMenu };
