import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { Editor } from '@tinymce/tinymce-react';
import { ValidateBackGround } from 'Components/Validation';
import { Label } from 'Components/Label';
import { TextBox } from 'Components/TextBox';
import { PurpleButton } from 'Components/Button';
import { Icon } from 'Components/Icons';
import { Spinner } from 'Components/Spinner';
import { CheckBox } from '../../CheckBox';
// import { TextArea } from '../../TextArea';

// css
import classes from './contractForm.module.css';

// import 'tinymce/skins/ui/oxide/skin.min.css';

interface Props {
  formName: string;
  template: string;
  isButtonEnabled: boolean;
  hasSignature: boolean;
  loading: boolean;
  submitButtonText?: string;
  editorRef: any;
  // textareaRef: any;
  onChangeFormName: (e: any) => void;
  // onChangeTemplate: (e: any) => void;
  onChangeReplacementTags: (e: string) => void;
  onFormButtonClick: (e: any) => void;
  onHasSignatureClick: (e: any) => void;
  onCloseClick: (e: any) => void;
}

function ContractForm({
  formName,
  template,
  editorRef,
  loading,
  isButtonEnabled,
  hasSignature,
  submitButtonText,
  onChangeFormName,
  onChangeReplacementTags,
  onFormButtonClick,
  onHasSignatureClick,
  onCloseClick,
}: Props) {
  const assignEditor = (_evt: any, editor: any) => {
    editorRef.current = editor;

    editorRef.current.on('execCommand', (e: any) => {
      if (e.command === 'mceInsertContent') {
        editorRef.current.execCommand('mceFocus', false);
        const node = editorRef.current.dom.select('p');
        const lastChildNodeLength = node[node.length - 1].toString().length;
        editorRef.current.selection.setCursorLocation(node[node.length - 1], lastChildNodeLength - 7);
        editorRef.current.focus();
      }
    });

    editorRef.current.on('focus', () => {
      editorRef.current.getContainer().style.boxShadow = '0 0 0 .2rem rgba(0, 123, 255, .25)';
      editorRef.current.getContainer().style.borderColor = '#80bdff';
    });
    editorRef.current.on('blur', () => {
      editorRef.current.getContainer().style.boxShadow = '';
      editorRef.current.getContainer().style.borderColor = '';
    });
  };

  return (
    <form className={`requires-validation g-3 ${classes.formBase}`} noValidate action="#">
      <a href="#" role="button" className={`${classes.closeBtn}`} onClick={onCloseClick} aria-label="Close">
        <Icon type="modalClose" />
      </a>
      <ValidateBackGround isValid className={classes.inputContainer}>
        <Label ariaLabel="Form Name" className={classes.label} htmlFor="formName">
          Form Name
        </Label>
        <TextBox
          value={formName}
          name="formName"
          type="text"
          className="mb-0 pb-0"
          required
          maxLength={100}
          placeholder="Authorization Form"
          ariaLabel="Please enter form name"
          onChange={onChangeFormName}
          autoComplete="off"
        />
      </ValidateBackGround>
      <ValidateBackGround isValid className={classes.inputContainer}>
        <Label ariaLabel="Require Signature" className={classes.label} htmlFor="hasSignature">
          Require Signature
        </Label>

        <CheckBox checked={hasSignature} name="hasSignature" className="m-1 pb-0" onChange={onHasSignatureClick} />
      </ValidateBackGround>

      <Label ariaLabel="Contract Template" className={`mt-3 ${classes.label}`} htmlFor="template">
        Contract Template
      </Label>
      <div className={classes.gridForm}>
        <div className={`${classes.optionsPane}`}>
          <button type="button" onClick={() => onChangeReplacementTags('~~~name~~~')}>
            name
          </button>
          <button type="button" onClick={() => onChangeReplacementTags('~~~project~~~')}>
            project
          </button>
          <button type="button" onClick={() => onChangeReplacementTags('~~~project_address~~~')}>
            project_address
          </button>
          <button type="button" onClick={() => onChangeReplacementTags('~~~project_alias~~~')}>
            project_alias
          </button>
          <button type="button" onClick={() => onChangeReplacementTags('~~~job_no~~~')}>
            job_no
          </button>
          <button type="button" onClick={() => onChangeReplacementTags('~~~company~~~')}>
            company
          </button>
          {/* <button type='button' onClick={() => onChangeReplacementTags('~~~company_logo~~~')}>company_logo</button> */}
          <button type="button" onClick={() => onChangeReplacementTags('~~~current_date~~~')}>
            current_date
          </button>
          <button type="button" onClick={() => onChangeReplacementTags('~~~date_of_loss~~~')}>
            date_of_loss
          </button>
          <button type="button" onClick={() => onChangeReplacementTags('~~~company_address~~~')}>
            company_address
          </button>
          <button type="button" onClick={() => onChangeReplacementTags('~~~policy_holder_name~~~')}>
            policy_holder_name
          </button>
          <button type="button" onClick={() => onChangeReplacementTags('~~~policy_number~~~')}>
            policy_number
          </button>
          <button type="button" onClick={() => onChangeReplacementTags('~~~claim_number~~~')}>
            claim_number
          </button>
          <button type="button" onClick={() => onChangeReplacementTags('~~~provider~~~')}>
            provider
          </button>
          <button type="button" onClick={() => onChangeReplacementTags('~~~cause_of_damage~~~')}>
            cause_of_damage
          </button>
          <button type="button" onClick={() => onChangeReplacementTags('~~~types_of_damage~~~')}>
            types_of_damage
          </button>
          <button type="button" onClick={() => onChangeReplacementTags('~~~input~~~')}>
            input
          </button>
          <button type="button" onClick={() => onChangeReplacementTags('~~~checkbox~~~')}>
            checkbox
          </button>
        </div>

        {/* @ts-ignore */}
        <Editor
          apiKey="y16002q3bh0dat4uao0pcxu9d7i5dkgyr1sc1vm78tcvpgyu"
          onInit={(evt, editor) => assignEditor(evt, editor)}
          initialValue={template}
          init={{
            branding: false,
            height: 400,
            menubar: true,
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'help',
              'wordcount',
              'table',
              'a11ychecker',
              'casechange',
              'export',
              'formatpainter',
              'image',
              'editimage',
              'linkchecker',
              'autolink',
              'lists',
              'checklist',
              'media',
              'mediaembed',
              'pageembed',
              'permanentpen',
              'powerpaste',
              'table',
              'advtable',
              'tableofcontents',
              'tinymcespellchecker',
            ],
            convert_fonts_to_spans: true,
            fontsize_formats: '8pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 24pt 36pt',
            font_size_style_values: '8pt,10pt,11pt,12pt,14pt,16pt,18pt,20pt,24pt,36pt',
            toolbar:
              'undo redo | sizeselect fontselect fontsizeselect formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat  | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | spellchecker | help | a11ycheck addcomment showcomments casechange export formatpainter image editimage pageembed permanentpen',
            toolbar_mode: 'scrolling',
            inline_styles: true,
            content_css: false,
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } .tox-tinymce-aux { z-index: 100000009 !important } .tox .tox-dialog { z-index: 1000009 !important } .tox .tox-dialog-wrap { z-index: 1000009 !important } .tox .tox-dialog-wrap__backdrop { z-index: 1000009 !important } .tox .tox-menu { z-index: 1000009 !important }',
            browser_spellcheck: true,
            spellchecker_callback(method: string, text: string, success: (arg0: {}) => void) {
              const words = text.match(editorRef.current.getWordCharPattern());
              if (method === 'spellcheck') {
                const suggestions = {};
                for (let i = 0; i < words.length; i += 1) {
                  suggestions[words[i]] = ['First', 'Second'];
                }
                success(suggestions);
              }
            },
          }}
        />

        {/* <TextArea
        id="contract-form"
        ref={textareaRef}
        value={template}
        name="template"
        className={`mb-0 pb-0 ${classes.contractForm}`}
        required
        placeholder=""
        ariaLabel="Please enter contract template"
        onChange={onChangeTemplate}
        autoComplete="off"
      /> */}
      </div>
      <div className={`${classes.buttonContainer}`}>
        <PurpleButton onClick={onFormButtonClick} disabled={!isButtonEnabled || loading}>
          <Spinner loading={loading} /> {submitButtonText}
        </PurpleButton>
      </div>
    </form>
  );
}

ContractForm.defaultProps = {
  submitButtonText: 'Add Contract',
};

const ContractFormMemo = memo(ContractForm, areEqual);

export { ContractFormMemo as ContractForm };
