import A from 'axios';
import { Api } from 'Utils/api';

import * as Sentry from '@sentry/react';

import { isLocal } from 'Utils/helpers';

const tusVersion = '1.0.0';

const getFileName = (files: File[]) => files.map((file: File) => file.name);
const createAssembly = (params, numberOfFiles) => {
  const formData = new FormData();
  formData.append('params', JSON.stringify(params));
  formData.append('num_expected_upload_files', numberOfFiles);
  return A.post('https://api2.transloadit.com/assemblies', formData).then((r) => r.data);
};

// Needed for local development
if (isLocal) {
  Sentry.addTracingExtensions();
}

const uploadFileToAssembly = async (file, assembly) =>
  new Promise<{ success: boolean; error?: any }>((resolve, reject) => {
    // set up Sentry logging
    // https://docs.sentry.io/platforms/javascript/guides/react/performance/instrumentation/custom-instrumentation/
    const transaction = Sentry.startTransaction({ name: 'transloaditPhotoUpload' });
    Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transaction));

    const span = transaction.startChild({
      op: 'http',
      description: 'Transloadit photo upload',
    });

    // upload process
    const metadata = `assembly_url ${btoa(assembly.assembly_url)},filename ${btoa(file.name)},fieldname ZmlsZQ==`;
    const tusHeaders = {
      'tus-resumable': tusVersion,
      'upload-length': String(file.size),
      'upload-metadata': metadata,
    };
    A.post(assembly.tus_url, {}, { headers: tusHeaders })
      .then((tusResponse) => {
        const uploadHeaders = {
          'tus-resumable': tusVersion,
          'upload-offset': '0',
          'content-type': 'application/offset+octet-stream',
        };
        A.patch(tusResponse.headers.location, file, { headers: uploadHeaders })
          .then(() => {
            resolve({ success: true });
            span.setStatus('ok');
          })
          .catch((e) => {
            reject(e.toString());
            span.setStatus('unknown_error');
          });
      })
      .catch((e) => {
        reject(e.toString());
        span.setStatus('unknown_error');
      });

    // finish Sentry logging
    span.finish();
    transaction.finish();
  });

const linkAssemblyToGroup = (roomId, assembly, numberOfFiles, uuid, projectId) =>
  Api.post(`/rooms/${roomId}/photo-assemblies`, {
    assembly_id: assembly.assembly_id,
    total_files: numberOfFiles,
    room_id: roomId,
    project_id: projectId,
    group_uuid: uuid,
  });

export const handleUpload = async (userId, roomId, albumId, acceptedFiles, numberOfFiles, uuid, project) => {
  const params = {
    num_expected_upload_files: numberOfFiles,
    template_id: import.meta.env.VITE_TRANSLOADIT_TEMPLATE_ID,
    auth: {
      key: import.meta.env.VITE_TRANSLOADIT_AUTH_ID,
    },
    fields: {
      user_id: userId,
      room_id: roomId,
      // @ts-ignore
      albums: { [albumId]: getFileName(acceptedFiles) },
    },
  };
  return new Promise<any>((resolve) => {
    createAssembly(params, numberOfFiles).then(async (assembly) => {
      await linkAssemblyToGroup(roomId, assembly, numberOfFiles, uuid, project?.id);
      const promises: Promise<{ success: boolean; error?: any }>[] = [];
      for (let i = 0; i < acceptedFiles.length; i += 5) {
        for (let j = i; j < Math.min(acceptedFiles.length, i + 5); j += 1) {
          promises.push(uploadFileToAssembly(acceptedFiles[j], assembly));
        }
        /* eslint-disable no-await-in-loop */
        await Promise.all(promises);
      }
      resolve(true);
    });
  });
};
