import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { DayTotalEntry } from 'Components/TimeTracking';
import { TimeTrackingCard } from 'Containers/TimeTracking/types';
import { intervalToDuration } from 'date-fns';
import { calculateDurationForToday, formatDurationForTimeTracking } from 'Containers/TimeTracking/helpers';
import { formatDate } from 'Utils/helpers';

interface Props {
  card: TimeTrackingCard;
}

const getDisplayDuration = (card: TimeTrackingCard) => {
  if (card.sameDay) {
    return card.timeOut
      ? formatDurationForTimeTracking(card.duration)
      : formatDurationForTimeTracking(
          intervalToDuration({
            start: Date.parse(card.timeIn),
            end: Date.now(),
          })
        );
  }
  return formatDurationForTimeTracking(calculateDurationForToday(card));
};

const getDisplayRange = (card: TimeTrackingCard) => {
  const start = card.sameDay ? `${formatDate(card.timeIn, 'p')} - ` : `${formatDate(card.timeIn, 'MMM, d p')} - `;
  const end = card.timeOut ? `${formatDate(card.timeOut, 'p')}` : 'now';
  return `${start}${end}`;
};

const DayTotalEntryContainer = ({ card }: Props) => (
  <DayTotalEntry address={card.address} duration={getDisplayDuration(card)} range={getDisplayRange(card)} />
);

const DayTotalEntryContainerMemo = memo(DayTotalEntryContainer, areEqual);

export { DayTotalEntryContainerMemo as DayTotalEntry };
