import React, { memo, useCallback, useRef } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Icon } from 'Components/Icons';
import { Units } from './Units';
import classes from './CustomReportEditor.module.css';

interface Props {
  units: any;
  projectAddress: any;
  project: any;
  property: any;
  claims: any;
  onUserNameClick: (e: any) => void;
  onCompanyNameClick: (e: any) => void;
  onCompanyAddressClick: (e: any) => void;
  onCompanyLogoClick: (e: any) => void;
  onProjectNumberClick: (e: any) => void;
  onProjectAliasClick: (e: any) => void;
  onProjectAddressClick: (e: any) => void;
  onPhotoClick: (e: any) => void;
  onProjectTypeClick: (e: any) => void;
  onPropertyTypeClick: (e: any) => void;
  onYearBuiltClick: (e: any) => void;
  onBuildingNameClick: (e: any) => void;
  onDateLossClick: (e: any) => void;
  onClaimInfoClick: (e: any) => void;
  onDamageTypesClick: (e: any) => void;
  onDamageCauseClick: (e: any) => void;
  onAsbestosStatusClick: (e: any) => void;
}

const ProjectInfo = ({
  units,
  projectAddress,
  project,
  property,
  claims,
  onPhotoClick,
  onUserNameClick,
  onCompanyAddressClick,
  onCompanyLogoClick,
  onCompanyNameClick,
  onProjectAddressClick,
  onProjectAliasClick,
  onProjectNumberClick,
  onProjectTypeClick,
  onPropertyTypeClick,
  onYearBuiltClick,
  onBuildingNameClick,
  onDateLossClick,
  onClaimInfoClick,
  onDamageTypesClick,
  onDamageCauseClick,
  onAsbestosStatusClick,
}: Props) => {
  const claimsHeadRef: any = useRef(null);
  const toggleInfo = useCallback((e: any) => {
    claimsHeadRef.current = e.currentTarget;
    const collapsiblePanel = claimsHeadRef.current.nextElementSibling;
    if (collapsiblePanel.style.maxHeight) {
      collapsiblePanel.style.maxHeight = null;
      claimsHeadRef.current.children[0].style.transform = 'rotate(0deg)';
    } else {
      collapsiblePanel.style.maxHeight = `${collapsiblePanel.scrollHeight}px`;
      claimsHeadRef.current.children[0].style.transform = 'rotate(-180deg)';
    }
  }, []);

  return (
    <div className={classes.projectInfoPanel}>
      <div className={classes.mainHeader}>Insert Project Info:</div>

      <div style={{ marginTop: '10px' }}>
        <div className={classes.strongHeader}>Profile:</div>
        <button onClick={onUserNameClick} className={classes.projectInfoListItemButton}>
          Users Name
        </button>
        <button onClick={onCompanyNameClick} className={classes.projectInfoListItemButton}>
          Company Name
        </button>

        <button onClick={onCompanyAddressClick} className={classes.projectInfoListItemButton}>
          Company address
        </button>

        <button onClick={onCompanyLogoClick} className={classes.projectInfoListItemButton}>
          Company Logo
        </button>
      </div>

      <div style={{ marginTop: '10px' }}>
        <div className={classes.strongHeader}>Project Level:</div>

        <button
          disabled={!project?.address?.address}
          onClick={() => onProjectAddressClick(project?.address)}
          className={classes.projectInfoListItemButton}
        >
          Project Address
        </button>

        <button
          disabled={!project?.alias}
          onClick={() => onProjectAliasClick(project?.alias)}
          className={classes.projectInfoListItemButton}
        >
          Project Alias
        </button>

        <button
          disabled={!project?.uid}
          onClick={() => onProjectNumberClick(project?.uid)}
          className={classes.projectInfoListItemButton}
        >
          Project Number
        </button>
      </div>

      <div style={{ marginTop: '10px' }}>
        <div className={classes.strongHeader}>Project/Loss Info:</div>

        <button
          disabled={!project?.projectType?.id}
          onClick={() => onProjectTypeClick(project?.projectType?.name)}
          className={classes.projectInfoListItemButton}
        >
          Project Type
        </button>

        <button
          disabled={!property?.propertyType?.name}
          onClick={() => onPropertyTypeClick(property?.propertyType?.name)}
          className={classes.projectInfoListItemButton}
        >
          Property Type
        </button>

        <button
          disabled={!property?.asbestosStatus?.id}
          onClick={() => onAsbestosStatusClick(property?.asbestosStatus?.name)}
          className={classes.projectInfoListItemButton}
        >
          Asbestos Status
        </button>

        <button
          disabled={!property?.yearBuilt}
          onClick={() => onYearBuiltClick(property?.yearBuilt)}
          className={classes.projectInfoListItemButton}
        >
          Year Built
        </button>

        <button
          disabled={!property?.buildingName}
          onClick={() => onBuildingNameClick(property?.buildingName)}
          className={classes.projectInfoListItemButton}
        >
          Building Name
        </button>

        <button
          disabled={!property?.damageCause?.id}
          onClick={() => onDamageCauseClick(property?.damageCause?.name)}
          className={classes.projectInfoListItemButton}
        >
          Cause of Damage
        </button>

        <button
          disabled={!property?.propertyDamageTypes?.length}
          onClick={() => onDamageTypesClick(property?.propertyDamageTypes)}
          className={classes.projectInfoListItemButton}
        >
          Types of Damage
        </button>

        <button
          disabled={!property?.lossDate}
          onClick={() => onDateLossClick(property?.lossDate)}
          className={classes.projectInfoListItemButton}
        >
          Date of Loss
        </button>

        <button
          disabled={!claims?.length}
          className={`${classes.projectInfoListItemButton} ${classes.claimInfoCollapseBtn}`}
          onClick={(e) => toggleInfo(e)}
        >
          {claims?.filter(Boolean).length > 1 ? 'Policy Holders' : 'Policy Holder'}
          <div className={`${classes.caretIcon}`}>
            <Icon type="caretdown" />
          </div>
        </button>
        {claims?.filter(Boolean).length ? (
          <div className={`${classes.claimInfo}`}>
            {claims
              .map((claim: any) => (
                <button
                  key={claim?.policy_holder}
                  onClick={() => onClaimInfoClick(claim?.policy_holder)}
                  className={classes.projectInfoListItemButton}
                >
                  <strong style={{ fontWeight: 900 }}>*</strong> {claim?.policy_holder}
                </button>
              ))
              .filter(Boolean)}
          </div>
        ) : null}
        <button
          disabled={!claims?.length}
          className={`${classes.projectInfoListItemButton} ${classes.claimInfoCollapseBtn}`}
          onClick={(e) => toggleInfo(e)}
        >
          {claims?.map((claim) => claim?.provider).filter(Boolean).length > 1 ? 'Providers' : 'Provider'}
          <div className={`${classes.caretIcon}`}>
            <Icon type="caretdown" />
          </div>
        </button>
        {claims?.filter(Boolean).length ? (
          <div className={`${classes.claimInfo}`}>
            {claims
              .map((claim: any) =>
                claim.provider ? (
                  <button
                    key={claim?.provider}
                    onClick={() => onClaimInfoClick(claim?.provider)}
                    className={classes.projectInfoListItemButton}
                  >
                    <strong style={{ fontWeight: 900 }}>*</strong> {claim?.provider}
                  </button>
                ) : null
              )
              .filter(Boolean)}
          </div>
        ) : null}

        <button
          disabled={!claims?.length}
          className={`${classes.projectInfoListItemButton} ${classes.claimInfoCollapseBtn}`}
          onClick={(e) => toggleInfo(e)}
        >
          {claims?.filter(Boolean).length > 1 ? 'Policy Numbers' : 'Policy Number'}
          <div className={`${classes.caretIcon}`}>
            <Icon type="caretdown" />
          </div>
        </button>
        {claims?.filter(Boolean).length ? (
          <div className={`${classes.claimInfo}`}>
            {claims
              .map((claim: any) => (
                <button
                  key={claim?.policy_number}
                  onClick={() => onClaimInfoClick(claim?.policy_number)}
                  className={classes.projectInfoListItemButton}
                >
                  <strong style={{ fontWeight: 900 }}>*</strong> {claim?.policy_number}
                </button>
              ))
              .filter(Boolean)}
          </div>
        ) : null}

        <button
          disabled={!claims?.length}
          className={`${classes.projectInfoListItemButton} ${classes.claimInfoCollapseBtn}`}
          onClick={(e) => toggleInfo(e)}
        >
          {claims?.length > 1 ? 'Claim Numbers' : 'Claim Number'}
          <div className={`${classes.caretIcon}`}>
            <Icon type="caretdown" />
          </div>
        </button>
        {claims?.filter(Boolean).length ? (
          <div className={`${classes.claimInfo}`}>
            {claims
              .map((claim: any) => (
                <button
                  key={claim?.claim_number}
                  onClick={() => onClaimInfoClick(claim?.claim_number)}
                  className={classes.projectInfoListItemButton}
                >
                  <strong style={{ fontWeight: 900 }}>*</strong> {claim?.claim_number}
                </button>
              ))
              .filter(Boolean)}
          </div>
        ) : null}
      </div>

      <div style={{ marginTop: '10px' }}>
        <div className={classes.strongHeader}>Photos:</div>
        <div className={classes.strongHeader}>{projectAddress && projectAddress}</div>
        {units.length
          ? units.map((unit: any) => <Units key={unit.unit} onPhotoClick={onPhotoClick} unit={unit} />)
          : null}
      </div>
    </div>
  );
};
const ProjectInfoMemo = memo(ProjectInfo, areEqual);

export { ProjectInfoMemo as ProjectInfo };
