import React, { memo, useCallback } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { Icon } from 'Components/Icons';

import { useTimeTrackingFunctions } from 'Context/TimeTracking';

import classes from 'Components/Nav/nav.module.css';

const TimeTrackingButtonNav = () => {
  const { setIsTimeTrackingModalOpen, userClockedInTimeCards }: any = useTimeTrackingFunctions();

  const onTimeTrackingButtonClick = useCallback((e: any) => {
    e.preventDefault();
    setIsTimeTrackingModalOpen(true);
  }, []);

  return (
    <div className={classes.supportIconContainer}>
      <a
        href="#"
        id="timeTracking"
        onClick={onTimeTrackingButtonClick}
        onKeyUp={onTimeTrackingButtonClick}
        role="button"
      >
        <Icon type={userClockedInTimeCards.length > 0 ? 'clockinactive' : 'clockin'} />
      </a>
    </div>
  );
};

const TimeTrackingButtonNavContainerMemo = memo(TimeTrackingButtonNav, areEqual);

export { TimeTrackingButtonNavContainerMemo as TimeTrackingButtonNav };
