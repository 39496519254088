import React, { useState, useEffect, useCallback, memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from 'Context/User';
import { UserModel } from 'Containers/User/Models/UserModel';
import { updateUser } from 'Containers/User/actions';
import axios from 'axios';
import { SecondarySpinner } from 'Components/SecondarySpinner';
import getSTJobs from 'Utils/serviceTitanJobs';
import { fetchingStProjects } from 'Containers/Projects/selectors';
import classes from './TechnicianInput.module.css';

interface Props {
  setShowAlternateComponent: (e: any) => void;
}

function TechnicianForm({ setShowAlternateComponent }: Props) {
  const user: UserModel = useUser();
  const [techInput, setTechInput] = useState(user.st_technician_id || '');
  const [emailInput, setEmailInput] = useState('');
  const [tenInput, setTenInput] = useState(user.st_tenant_id || '');
  const [isValid, setIsValid] = useState(true);
  const [message, setMessage] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const stJobsLoading = useSelector(fetchingStProjects);

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();

      const requestData = {
        techId: techInput,
        email: emailInput,
      };

      const headers = {
        'access-key': import.meta.env.VITE_APP_LAMBDA_ACCESS_KEY,
      };

      try {
        setIsLoading(true);

        const verificationResponse = await axios.post(
          `${import.meta.env.VITE_APP_ST_API}verify?tenantId=${tenInput}`,
          requestData,
          { headers }
        );

        setIsLoading(false);

        setMessage(verificationResponse.data.message);

        let date = new Date();
        date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        const formattedDate = date.toISOString().split('T')[0];

        if (verificationResponse.status === 200) {
          setIsVerified(true);
          dispatch(
            updateUser(`users/${user.id}`, 'put', {
              st_technician_id: techInput,
              st_technician_date: formattedDate,
              st_tenant_id: tenInput,
              first_name: user.first_name,
              last_name: user.last_name,
              email: user.email,
            })
          );
        } else {
          setIsVerified(false);
          setMessage(verificationResponse.data.message);
        }
      } catch (error) {
        setIsLoading(false);
        setIsVerified(false);
        const errMessage = errorCheckMsg(error);
        if (errMessage) {
          setMessage(errMessage);
        } else {
          setMessage('There was an unexpected error. Please try again');
        }
      }
    },
    [techInput, emailInput, tenInput]
  );

  function errorCheckMsg(err) {
    if (err.response?.data?.message) {
      return err.response.data.message;
    }
    return false;
  }

  useEffect(() => {
    if (user.st_technician_id && user.st_tenant_id && !stJobsLoading) getSTJobs(user, dispatch);
  }, [user.st_technician_id, user.st_tenant_id]);

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmailInput(newEmail);
    setIsValid(validateEmail(newEmail));
  };

  const handleCancel = () => {
    setShowAlternateComponent(true);
  };

  return (
    <form className={classes.form}>
      <label className={classes.label}>ServiceTitan Technician ID:</label>
      <input
        className={classes.textBox}
        type="text"
        pattern="[0-9]*"
        disabled={isLoading}
        placeholder={user.st_technician_id ? '' : 'Input Technician ID'}
        value={techInput}
        onChange={(e) => setTechInput((v) => (e.target.validity.valid ? e.target.value : v))}
      />
      <br />
      <label className={classes.label}>ServiceTitan Email:</label>
      <input
        className={classes.textBox}
        type="email"
        disabled={isLoading}
        style={{ borderColor: isValid ? '#d2cfda' : 'red' }}
        placeholder="Input email"
        value={emailInput}
        onChange={handleEmailChange}
      />
      {!isValid && <p style={{ color: 'red', marginBottom: '0' }}>Please enter a valid email address</p>}
      <br />
      <label className={classes.label}>ServiceTitan Tenant ID:</label>
      <input
        className={classes.textBox}
        type="text"
        pattern="[0-9]*"
        disabled={isLoading}
        placeholder={user.st_tenant_id ? '' : 'Input Tenant ID'}
        value={tenInput}
        onChange={(e) => setTenInput(e.target.value)}
      />
      {message && <p style={isVerified ? { color: '#6EE172' } : { color: 'red' }}>{message}</p>}
      <div className={classes.formButtonWrapper}>
        <button
          type="submit"
          className={`${classes.button} ${classes.buttonHover}`}
          onClick={handleSubmit}
          disabled={isLoading}
          style={
            isLoading
              ? { background: 'linear-gradient(135deg, #DEDEDE 0%, #C8C8C8 100%)', color: '#747474', border: '0px' }
              : {}
          }
        >
          Register
        </button>
        <button className={`${classes.cancelButton} ${classes.button}`} onClick={handleCancel} type="button">
          <span>Cancel</span>
        </button>
        <div className={classes.spinnerContainer}>{isLoading ? <SecondarySpinner loading /> : null}</div>
      </div>
    </form>
  );
}

function ConfirmedMessage() {
  const user: UserModel = useUser();
  return (
    <div>
      <p className={classes.label}>
        Your ServiceTitan Technician ID is:&nbsp;
        {user.st_technician_id}
      </p>
      <p className={classes.label}>
        Your ServiceTitan Tenant ID is:&nbsp;
        {user.st_tenant_id}
      </p>
    </div>
  );
}

function TechnicianInput() {
  const [showAlternateComponent, setShowAlternateComponent] = useState(false);
  const user: UserModel = useUser();

  useEffect(() => {
    if (user?.id) {
      if (user.st_technician_id?.length === 0 && !isNaN(parseInt(user.st_technician_id, 10))) {
        setShowAlternateComponent(false);
      } else {
        setShowAlternateComponent(true);
      }
    }
  }, [user]);

  return (
    <div className={classes.container}>
      <div className={classes.boxHeader}>
        <h2 className={classes.title}>Connect with ServiceTitan</h2>
        <button
          className={`${classes.edButton} ${classes.button}`}
          style={showAlternateComponent ? { display: 'flex' } : { display: 'none' }}
          onClick={() => setShowAlternateComponent(!showAlternateComponent)}
        >
          <span>Edit</span>
        </button>
      </div>
      <div>
        {showAlternateComponent ? (
          <ConfirmedMessage />
        ) : (
          <TechnicianForm setShowAlternateComponent={setShowAlternateComponent} />
        )}
      </div>
    </div>
  );
}

const TechnicianInputMemo = memo(TechnicianInput, areEqual);

export { TechnicianInputMemo as TechnicianInput };
