import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { areEqual } from 'Utils/equalityChecks';

import { Modal } from 'Components/Modal';

import { Anchor } from 'Components/Anchor';
import { PurpleButton } from 'Components/Button';

import classes from './genericBlocker.module.css';

function RocketPayBlocker() {
  const history = useHistory();

  const onClickCloseButton = useCallback(() => {
    history.push('/dashboard');
  }, []);

  return (
    <Modal
      id="blocker-modal"
      classes={classes}
      title="Activate Feature"
      isOpen
      modalHeader
      dataBsBackdrop="static"
      dataBsKeyboard="false"
      modalCloseClick={onClickCloseButton}
    >
      <div className={classes.sectionContainer}>
        <div className={classes.description}>Book a meeting with our Team to activate your account.</div>
        <br />
        <Anchor href="https://calendly.com/alex-2y0/60min?month=2024-03" openInNewTab>
          <PurpleButton>Book meeting</PurpleButton>
        </Anchor>
      </div>
    </Modal>
  );
}

const RocketPayBlockerMemo = memo(RocketPayBlocker, areEqual);

export { RocketPayBlockerMemo as RocketPayBlocker };
