import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Table, TableBody, TableColumn, TableRow, TableHeader, Th } from 'Components/Table';
import { Icon } from 'Components/Icons';
import { formatDate } from 'Utils/helpers';
import { ActionButtonWrapper } from 'Containers/ActionButtonWrapper';
import classes from './esignatureTable.module.css';

interface Props {
  esignatures: any;
  canDelete: boolean;
  onDownloadIconClick: (e: any) => void;
  onShareIconClick: (e: any) => void;
  onEditIconClick: (e: any) => void;
  onShowDeleteModal: (e: any) => void;
}

function EsignatureTable({
  esignatures,
  canDelete,
  onDownloadIconClick,
  onShareIconClick,
  onEditIconClick,
  onShowDeleteModal,
}: Props) {
  return (
    <div className={`${classes.reportsTableBase}`}>
      <Table className="table">
        <TableHeader>
          <TableRow>
            <Th>Form Name</Th>
            <Th>Date Created</Th>
            <Th>Status</Th>
            <Th />
            <Th />
            <Th />
            {canDelete && <Th />}
          </TableRow>
        </TableHeader>
        {esignatures?.meta?.total > 0 && (
          <TableBody>
            {esignatures.data.map((eSignature: any) => (
              <TableRow key={eSignature.id}>
                <TableColumn className={`${classes.tableColumn} ${classes.textWrap}`}>
                  {eSignature.contract_form?.name || 'na'}
                </TableColumn>
                <TableColumn className={classes.tableColumn}>{formatDate(eSignature?.created_at, 'PP')}</TableColumn>
                <TableColumn className={classes.tableColumn}>
                  {eSignature.contract_form?.has_signature ? eSignature?.status : null}
                </TableColumn>
                <TableColumn className={classes.tableColumn}>
                  <div className="w-100 text-center">
                    <ActionButtonWrapper onClick={onEditIconClick} item={eSignature}>
                      {eSignature?.status === 'signed' ? <Icon type="eye" /> : <Icon type="edit" />}
                    </ActionButtonWrapper>
                  </div>
                </TableColumn>
                <TableColumn className={classes.tableColumn}>
                  <div className="w-100 text-center">
                    <ActionButtonWrapper
                      onClick={onDownloadIconClick}
                      item={eSignature}
                      disabled={eSignature?.status !== 'signed' && !eSignature?.url}
                    >
                      <Icon type="download" />
                    </ActionButtonWrapper>
                  </div>
                </TableColumn>
                <TableColumn className={classes.tableColumn}>
                  <div className="w-100 text-center">
                    <ActionButtonWrapper
                      onClick={onShareIconClick}
                      item={eSignature}
                      disabled={eSignature?.status !== 'signed' && !eSignature?.url}
                    >
                      <Icon type="share32" />
                    </ActionButtonWrapper>
                  </div>
                </TableColumn>
                {canDelete && (
                  <TableColumn className={classes.tableColumn}>
                    <div className="w-100 text-center">
                      <ActionButtonWrapper onClick={() => onShowDeleteModal(eSignature)} item={eSignature}>
                        <Icon type="trash" />
                      </ActionButtonWrapper>
                    </div>
                  </TableColumn>
                )}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </div>
  );
}

const EsignatureTableMemo = memo(EsignatureTable, areEqual);

export { EsignatureTableMemo as EsignatureTable };
