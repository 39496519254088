import { SET_PROJECT_PDFS, UploadsTypes } from 'Containers/Uploads/actions';

const initialState = {
  projectPDFs: [],
};

export const uploadsReducer = (state = initialState, action: UploadsTypes) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PROJECT_PDFS:
      return {
        ...state,
        projectPDFs: payload,
      };
    default:
      return state;
  }
};
