import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { MoistureLogsTable } from 'Components/RocketDry';

import { moistureLogTableHeaders } from 'Utils/table';

interface Props {
  materialWithLogs: any;
  handleViewPicture: (e: any) => void;
}

function MoistureLogsTableContainer({ materialWithLogs, handleViewPicture }: Props) {
  const { logs, materialName } = materialWithLogs;

  return (
    <MoistureLogsTable
      headers={moistureLogTableHeaders}
      logs={logs}
      materialName={materialName}
      handleViewPicture={handleViewPicture}
    />
  );
}

const MoistureLogsTableContainerMemo = memo(MoistureLogsTableContainer, areEqual);

export { MoistureLogsTableContainerMemo as MoistureLogsTableContainer };
