import React, { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { RocketPayCompanyModel } from 'Containers/User/Models/RocketPayModel/RocketPayCompanyModel';
import { TabContent } from 'Components/Tabs';
import { Spinner } from 'Components/Spinner';
import { Modal } from 'Components/Modal';
import { PurpleButton } from 'Components/Button';
import classes from './rocketPayPayouts.module.css';

// eslint-disable-next-line
import '@justifi/webcomponents/dist/module/justifi-payouts-list.js';
// eslint-disable-next-line
import '@justifi/webcomponents/dist/module/justifi-payout-details.js';
// eslint-disable-next-line
import '@justifi/webcomponents/dist/webcomponents/webcomponents.css';

interface Props {
  companyInfo: RocketPayCompanyModel;
  authToken: string;
}

function RocketPayPayouts({ companyInfo, authToken }: Props) {
  const formRefPayout = useRef(null);
  const [isFormReady, setIsFormReady] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [payoutId, setPayoutId] = useState('');

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (formRefPayout.current) {
        setIsFormReady(true);
        observer.disconnect();
      }
    });

    if (formRefPayout.current) {
      setIsFormReady(true);
    } else {
      observer.observe(document.body, { childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, []);

  useLayoutEffect(() => {
    if (isFormReady) {
      const form = formRefPayout.current;
      if (form) {
        const payoutRowClicked = (event) => {
          setPayoutId(event.detail.id);
          setIsModalVisible(true);
        };
        form.addEventListener('payout-row-clicked', payoutRowClicked);

        // Cleanup
        return () => form.removeEventListener('payout-row-clicked', payoutRowClicked);
      }
    }
    return () => {};
  }, [isFormReady]);

  return (
    <TabContent key="tab-content-rocketpay-payouts" id="rocketpaypayouts" className="position-relative">
      <div className={classes.sectionContainer}>
        {companyInfo && authToken ? (
          <justifi-payouts-list ref={formRefPayout} account-id={companyInfo.sub_account_id} auth-token={authToken} />
        ) : (
          <Spinner loading />
        )}
      </div>
      <Modal
        id="paymentDetailsModal"
        title="Payment Details"
        isOpen={isModalVisible}
        modalHeader
        classes={{ modalDialog: classes.modalOverride }}
        modalCloseClick={() => setIsModalVisible(false)}
      >
        {payoutId && authToken ? (
          <justifi-payout-details payout-id={payoutId} auth-token={authToken} />
        ) : (
          <Spinner loading />
        )}
        <div className="d-flex justify-content-end">
          <PurpleButton className={classes.closeButton} onClick={() => setIsModalVisible(false)}>
            Close
          </PurpleButton>
        </div>
      </Modal>
    </TabContent>
  );
}

const RocketPayPayoutsMemo = memo(RocketPayPayouts, areEqual);
export { RocketPayPayoutsMemo as RocketPayPayouts };
