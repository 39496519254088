import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { PurpleButton } from 'Components/Button';
import { CheckBox } from 'Components/CheckBox';
import { Anchor } from 'Components/Anchor';
import { SpinnerBlock } from 'Components/SpinnerBlock';

import { RocketPayToast } from 'Components/RocketPay/RocketPayToast';
import classes from './rocketPayAgreementScreen.module.css';

interface Props {
  acceptCheckboxTicked: boolean;
  onCheckboxClick: (e: any) => void;
  onActivateButtonClick: (e: any) => void;

  loading: boolean;
  showToast: boolean;
  message: string;
  error: boolean;
}

function RocketPayAgreementScreen({
  acceptCheckboxTicked,
  onCheckboxClick,
  onActivateButtonClick,
  loading,
  showToast,
  message,
  error,
}: Props) {
  return (
    <div className={classes.sectionContainer}>
      Before using RocketPay, you must read and accept JustiFi's terms and conditions at
      <Anchor href="https://justifi.tech/terms-of-service/" openInNewTab>
        https://justifi.tech/terms-of-service/
      </Anchor>
      <div className={classes.checkboxContainer}>
        <CheckBox checked={acceptCheckboxTicked} onChange={onCheckboxClick} name="acceptTerms" className="m-1 pb-0" /> I
        have read and accept these terms.
      </div>
      <PurpleButton
        className={classes.button}
        disabled={!acceptCheckboxTicked || loading}
        onClick={onActivateButtonClick}
      >
        Activate RocketPay
      </PurpleButton>
      <SpinnerBlock fetching={loading} />
      <RocketPayToast showToast={showToast} message={message} error={error} />
    </div>
  );
}

const RocketPayAgreementScreenMemo = memo(RocketPayAgreementScreen, areEqual);
export { RocketPayAgreementScreenMemo as RocketPayAgreementScreen };
