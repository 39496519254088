/* eslint-disable */
import { handleApiRequest } from 'Utils/handleApiRequest';
import { setToaster } from 'Containers/Core/actions';

export const SET_CONTRACTS = 'SET_CONTRACTS';
export const SET_CONTRACT = 'SET_CONTRACT';
export const SET_CONTRACTS_EDIT_ID = 'SET_CONTRACTS_EDIT_ID';
export const SET_CONTRACTS_DELETE_ID = 'SET_CONTRACTS_DELETE_ID';
export const FETCHING_CONTRACTS = 'FETCHING_CONTRACTS';
export const DELETE_CONTRACTS = 'DELETE_CONTRACTS';
export const CONTRACT_CREATED = 'CONTRACT_CREATED';
export const CONTRACT_EDITED = 'CONTRACT_EDITED';

interface ActionTypes {
  SET_CONTRACTS: object;
  SET_CONTRACT: object;
  SET_CONTRACTS_DELETE_ID: object;
  SET_CONTRACTS_EDIT_ID: object;
  CONTRACT_CREATED: object;
  CONTRACT_EDITED: object;
  FETCHING_CONTRACTS: boolean;
  DELETE_CONTRACTS: number;
}

interface MessageAction {
  type: keyof ActionTypes;
  payload: any;
}

export type contractActionTypes = MessageAction;

export const listCompanyContracts =
  (companyId: string) =>
  async (dispatch: any, _getState = null, utils: any) => {
    setFetchingContracts(true);

    const response = await handleApiRequest(
      dispatch,
      utils.Api.get(`/companies/${companyId}/contract-forms`, {
        params: {},
      })
    );

    if (response?.data) {
      dispatch({
        type: SET_CONTRACTS,
        payload: [...response.data],
      });
    } else {
      // we'll disable the spinner if something goes wrong with the API
      setFetchingContracts(false);
    }
  };

export const getCompanyContract =
  (contractFormId: string) =>
  async (dispatch: any, _getState = null, utils: any) => {
    setFetchingContracts(true);

    const response = await handleApiRequest(
      dispatch,
      utils.Api.get(`/contract-forms/${contractFormId}`, {
        params: {},
      })
    );

    if (response?.data) {
      dispatch({
        type: SET_CONTRACT,
        payload: response.data,
      });
    } else {
      // we'll disable the spinner if something goes wrong with the API
      setFetchingContracts(false);
    }
  };

export const createCompanyContracts =
  (
    companyId: string,
    name: string,
    replacement_tags: string,
    status: string,
    template: string,
    has_signature: boolean
  ) =>
  async (dispatch: any, _getState = null, utils: any) => {
    dispatch(setContractCreated(false));
    // console.log(companyId, name, replacement_tags, status, template, has_signature);
    const response = await handleApiRequest(
      dispatch,
      utils.Api.post(`/contract-forms`, {
        company_id: companyId,
        name,
        replacement_tags,
        status,
        template,
        has_signature,
      })
    );
    // console.log(response);
    if (response?.data) {
      dispatch(setContractCreated(true));
      dispatch(setToaster('Contract Created', true));
    }
  };

export const setContractEdited = (payload: boolean) => {
  return {
    type: CONTRACT_EDITED,
    payload,
  };
};

export const setContractCreated = (payload: boolean) => {
  return {
    type: CONTRACT_CREATED,
    payload,
  };
};

export const editCompanyContract =
  (
    companyId: string,
    name: string,
    replacement_tags: string,
    status: string,
    template: string,
    has_signature: boolean,
    contractFormId: number
  ) =>
  async (dispatch: any, _getState = null, utils: any) => {
    dispatch(setContractEdited(false));
    // console.log(companyId, name, replacement_tags, status, template, has_signature, contractFormId);
    const response = await handleApiRequest(
      dispatch,
      utils.Api.put(`/contract-forms/${contractFormId}`, {
        company_id: companyId,
        name,
        replacement_tags,
        status,
        template,
        has_signature,
      })
    );
    // console.log(response);
    if (response?.data) {
      dispatch(setContractEdited(true));
      dispatch(setToaster('Contract Edited', true));
    }
  };

export const deleteCompanyContracts =
  (contractId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.delete(`/contract-forms/${contractId}`, {
        params: {},
      })
    );

    // console.log(response);
    if (response) {
      dispatch(setToaster('Cannot delete contract', false));
    } else {
      dispatch({
        type: DELETE_CONTRACTS,
        payload: contractId,
      });
      dispatch(setToaster('Contract Deleted', true));
    }
  };

export const setFetchingContracts = (value: boolean) => (dispatch) => {
  dispatch({
    type: FETCHING_CONTRACTS,
    payload: value,
  });
};

export const setIdToDelete = (value: number) => (dispatch) => {
  dispatch({
    type: SET_CONTRACTS_DELETE_ID,
    payload: value,
  });
};

export const setIdToEdit = (value: number) => (dispatch) => {
  dispatch({
    type: SET_CONTRACTS_EDIT_ID,
    payload: value,
  });
};
