import React, { memo } from 'react';

import { Modal } from 'Components/Modal';

import { areEqual } from 'Utils/equalityChecks';
import { PurpleButton } from 'Components/Button';
import { ScopeOfWorkList } from 'Containers/RocketScan';

import classes from './scopeOfWorkAddModal.module.css';

interface Props {
  isOpen: boolean;
  onSaveClick: (e: any) => void;
  onCancelClick: (e: any) => void;
}

const ScopeOfWorkAddModal = ({ isOpen, onSaveClick, onCancelClick }: Props) => (
  <Modal
    id="damaged-materials-modal"
    classes={classes}
    title="Scope Sheet"
    isOpen={isOpen}
    modalHeader
    closeButtonText="Cancel"
    dataBsBackdrop="static"
    dataBsKeyboard="false"
    modalCloseClick={onCancelClick}
  >
    <ScopeOfWorkList />
    <div className={classes.buttonRow}>
      <PurpleButton className={`${classes.button} ${classes.save}`} onClick={onSaveClick}>
        Save
      </PurpleButton>
      <PurpleButton className={`${classes.button} ${classes.cancel}`} onClick={onCancelClick}>
        Cancel
      </PurpleButton>
    </div>
  </Modal>
);

const ScopeOfWorkAddModalMemo = memo(ScopeOfWorkAddModal, areEqual);

export { ScopeOfWorkAddModalMemo as ScopeOfWorkAddModal };
