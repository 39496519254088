import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import classes from './errorMessage.module.css';

interface Props {
  message: string;
}

function ErrorMessage({ message }: Props) {
  return <div className={`${classes.errorMessage} ${message ? classes.visible : ''}`}>{message}</div>;
}

const ErrorMessageMemo = memo(ErrorMessage, areEqual);
export { ErrorMessageMemo as ErrorMessage };
