import React, { memo } from 'react';

import { Spinner } from 'Components/Spinner';
import { PurpleButton } from 'Components/Button/PurpleButton';
import { areEqual } from 'Utils/equalityChecks';

import classes from './contractform.module.css';
import { FormPlaceholder } from './FormPlaceholder';
import { ContractFormList } from './ContractFormList';

interface Props {
  contractforms: any;
  totalForms: number;
  canDelete: boolean;
  fetching: boolean;
  onButtonClick: (e: any) => void;
  onClickRow: (e: any) => void;
  onDeleteClick: (e: any) => void;
}

function ContractForm({
  contractforms,
  totalForms,
  canDelete,
  fetching,
  onButtonClick,
  onClickRow,
  onDeleteClick,
}: Props) {
  return (
    <div className={classes.employeesContent}>
      <div className={`d-flex justify-content-start align-items-center ${classes.contentHeader}`}>
        <h2>Form Templates</h2>
        <PurpleButton className={classes.inviteButton} onClick={onButtonClick}>
          Add +
        </PurpleButton>
      </div>
      {fetching && <Spinner loading />}
      {totalForms === 0 && !fetching && <FormPlaceholder onClickPlaceholderButton={onButtonClick} />}
      {totalForms > 0 && (
        <ContractFormList
          contractforms={contractforms}
          canDelete={canDelete}
          onClickRow={onClickRow}
          onDeleteClick={onDeleteClick}
        />
      )}
    </div>
  );
}

const ContractFormMemo = memo(ContractForm, areEqual);

export { ContractFormMemo as ContractForm };
