import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { CreateNoteForm, NoteItems } from 'Components/Notes';

import classes from './clockedInTimeCardNotes.module.css';

interface Props {
  projectClockedIn: boolean;
  notesOpen: boolean;
  newNoteText: string;
  timeCardNotes: any[];
  notePlaceholder: string;
  postNoteButtonDisabled: boolean;
  noteErrors: any;
  onNotesSectionClick: (e: any) => void;
  onChangeNoteText: (e: any) => void;
  onPostNoteButtonClick: (e: any) => void;
  onClickDeleteIcon: (e: any) => void;
}

const ClockedInTimeCardNotes = ({
  projectClockedIn,
  notesOpen,
  newNoteText,
  timeCardNotes,
  notePlaceholder,
  postNoteButtonDisabled,
  noteErrors,
  onNotesSectionClick,
  onChangeNoteText,
  onPostNoteButtonClick,
  onClickDeleteIcon,
}: Props) => {
  if (notesOpen) {
    return (
      <div className={classes.sectionText}>
        <div className={classes.notesContainer}>
          <NoteItems notes={timeCardNotes} isReadOnly markable={false} onClickDeleteIcon={onClickDeleteIcon} />
        </div>
        <CreateNoteForm
          errors={noteErrors}
          noteText={newNoteText}
          disabled={postNoteButtonDisabled}
          placeholderText="Add your note here..."
          onNoteChange={onChangeNoteText}
          onPostButtonClick={onPostNoteButtonClick}
        />
      </div>
    );
  }
  return projectClockedIn ? (
    <div
      className={classes.sectionText}
      role="button"
      onClick={onNotesSectionClick}
      onKeyUp={onNotesSectionClick}
      tabIndex={0}
    >
      <div>{notePlaceholder}</div>
      <div className={classes.hintText}>Click to add notes.</div>
    </div>
  ) : (
    <div className={`${classes.sectionText} ${classes.hintText}`}>Clock in to add notes to the timecard.</div>
  );
};

const ClockedInTimeCardNotesMemo = memo(ClockedInTimeCardNotes, areEqual);

export { ClockedInTimeCardNotesMemo as ClockedInTimeCardNotes };
