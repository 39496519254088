import React, { memo } from 'react';

import { Modal } from 'Components/Modal';

import { areEqual } from 'Utils/equalityChecks';
import { Button } from 'Components/Button';

import { TimeTrackingCardTimesheet } from 'Containers/TimeTracking/types';
import { formatDate } from 'Utils/helpers';

import classes from './deleteTimeCardModal.module.css';

interface Props {
  id: number;
  card: TimeTrackingCardTimesheet;
  isOpen: boolean;
  modalCloseClick: (e: any) => void;
  onDeleteButtonClick: (e: any) => void;
}
const DeleteTimeCardModal = ({ id, card, isOpen, modalCloseClick, onDeleteButtonClick }: Props) => (
  <Modal
    id={id && id.toString()}
    classes={classes}
    title="Delete Time Card?"
    isOpen={isOpen}
    modalHeader
    dataBsBackdrop="static"
    dataBsKeyboard="false"
    modalCloseClick={modalCloseClick}
  >
    <div className={classes.deleteModalCopy}>
      {card && (
        <p className={classes.timeCardInfo}>
          {card.user} -{card.address ?? '[deleted project]'} -{formatDate(card.timeIn, 'PP')}
        </p>
      )}
      <p>This action cannot be undone.</p>
    </div>

    {/* TODO::Please create a red outline button! */}
    <div className={classes.buttonRow}>
      <Button className={`${classes.modalButtons} ${classes.delete}`} onClick={onDeleteButtonClick}>
        Delete
      </Button>
      <Button className={`${classes.modalButtons} ${classes.closeButton}`} onClick={modalCloseClick}>
        Cancel
      </Button>
    </div>
  </Modal>
);

const DeleteTimeCardModalMemo = memo(DeleteTimeCardModal, areEqual);

export { DeleteTimeCardModalMemo as DeleteTimeCardModal };
