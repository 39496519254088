import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { ClaimsDataView } from 'Components/ProjectData';
import { useClaimsDataFunctions } from 'Context/ClaimsData';
import { getClaims } from '../actions';

const ClaimsDataViewContainer = () => {
  const dispatch = useDispatch();
  const { project, claims }: any = useClaimsDataFunctions();

  const [policyHolder, setPolicyHolder] = useState('');
  const [representative, setRepresentative] = useState('');
  const [provider, setProvider] = useState('');
  const [policyNumber, setPolicyNumber] = useState('');
  const [claimNumber, setClaimNumber] = useState('');
  const [ownershipStatus, setOwnershipStatus] = useState('');
  const [policyHolderPhone, setPolicyHolderPhone] = useState('');
  const [policyHolderEmail, setPolicyHolderEmail] = useState('');
  const [insuranceDeductible, setInsuranceDeductible] = useState('');
  const [adjuster, setAdjuster] = useState('');
  const [adjusterEmail, setAdjusterEmail] = useState('');
  const [adjusterPhone, setAdjusterPhone] = useState('');

  useEffect(() => {
    if (project?.id) {
      const { id } = project;
      dispatch(getClaims(id));
    }
  }, [project]);

  useEffect(() => {
    if (claims.length > 0) {
      const [claim] = claims;
      const {
        policy_holder: policyHolder,
        ownership_status: ownershipStatus,
        policy_holder_phone: policyHolderPhone,
        policy_holder_email: policyHolderEmail,
        representative,
        provider,
        insurance_deductible: insuranceDeductible,
        policy_number: policyNumber,
        claim_number: claimNumber,
        adjuster: adjuster,
        adjuster_phone: adjusterPhone,
        adjuster_email: adjusterEmail,
      } = claim;
      setPolicyHolder(policyHolder);
      setRepresentative(representative);
      setProvider(provider);
      setPolicyNumber(policyNumber);
      setClaimNumber(claimNumber);
      setOwnershipStatus(ownershipStatus);
      setPolicyHolderPhone(policyHolderPhone);
      setPolicyHolderEmail(policyHolderEmail);
      setInsuranceDeductible(insuranceDeductible);
      setAdjuster(adjuster);
      setAdjusterPhone(adjusterPhone);
      setAdjusterEmail(adjusterEmail);
    }
  }, [claims]);

  return (
    <ClaimsDataView
      policyHolder={policyHolder}
      ownershipStatus={ownershipStatus}
      policyHolderPhone={policyHolderPhone}
      policyHolderEmail={policyHolderEmail}
      representative={representative}
      provider={provider}
      insuranceDeductible={insuranceDeductible}
      policyNumber={policyNumber}
      claimNumber={claimNumber}
      adjuster={adjuster}
      adjusterPhone={adjusterPhone}
      adjusterEmail={adjusterEmail}
    />
  );
};

const ClaimsDataViewContainerMemo = memo(ClaimsDataViewContainer, areEqual);

export { ClaimsDataViewContainerMemo as ClaimsDataView };
