import { createContext, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { projectSelector, propertySelector } from 'Containers/RocketScan/selectors';

export const RocketDryContext = createContext({});

export const RocketDryFunctions = () => {
  const project = useSelector(projectSelector, areEqual);
  const property = useSelector(propertySelector, areEqual);

  const [isEquipmentFolder, setIsEquipmentFolder] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isViewAtmosphericLogModalOpen, setIsViewAtmosphericLogModalOpen] = useState(false);
  const [selectedAtmosphericLog, setSelectedAtmosphericLog] = useState<any>({});
  const [selectedAtmosphericLogType, setSelectedAtmosphericLogType] = useState(undefined);
  const [isViewMoistureLogModalOpen, setIsViewMoistureLogModalOpen] = useState(false);
  const [selectedMoistureLog, setSelectedMoistureLog] = useState<any>({});

  return {
    project,
    property,
    isEquipmentFolder,
    isInfoModalOpen,
    isViewAtmosphericLogModalOpen,
    selectedAtmosphericLog,
    selectedAtmosphericLogType,
    isViewMoistureLogModalOpen,
    selectedMoistureLog,
    setIsEquipmentFolder,
    setIsInfoModalOpen,
    setIsViewAtmosphericLogModalOpen,
    setSelectedAtmosphericLog,
    setSelectedAtmosphericLogType,
    setIsViewMoistureLogModalOpen,
    setSelectedMoistureLog,
  };
};

export const useRocketDryFunctions = () => useContext(RocketDryContext);
