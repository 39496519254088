import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Rooms } from './Rooms';
import classes from './CustomReportEditor.module.css';

interface Props {
  unit: any;
  onPhotoClick: (e: any) => void;
}

const Units = ({ unit, onPhotoClick }: Props) => (
  <>
    <div className="">
      <ul className={classes.marginAuto}>
        <li className={`${classes.unitHead} ${classes.marginAuto}`}>{unit.unit}</li>
      </ul>
      {unit.rooms.length
        ? unit.rooms.map((room: any, index: number) => (
            <Rooms key={`${index + 1}`} room={room} onPhotoClick={onPhotoClick} />
          ))
        : null}{' '}
    </div>
  </>
);

const UnitsMemo = memo(Units, areEqual);

export { UnitsMemo as Units };
