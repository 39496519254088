import {
  GET_ESIGNATURE_DATA,
  EsignatureActionTypes,
  ESIGN_CREATED,
  ESIGN_PROPERTY,
  ESIGN_ERRORS,
  ESIGN_DELETED,
  ESIGN_SHARED,
  ESIGN_SHARING,
  PROJECT_ESIGNS,
} from 'Containers/Esignature/action';

const initialState = {
  selectedeSignLocations: [],
  eSignCreated: false,
  eSignDeleted: false,
  eSignSharing: false,
  eSignShared: undefined,
  eSignProperty: [],
  eSigns: undefined,
  errors: undefined,
  eSignatureData: [],
};

export const eSignsReducer = (state = initialState, action: EsignatureActionTypes) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ESIGNATURE_DATA:
      return {
        ...state,
        eSignatureData: payload,
      };
    case ESIGN_CREATED:
      return {
        ...state,
        eSignCreated: payload,
      };
    case ESIGN_DELETED:
      return {
        ...state,
        eSignDeleted: payload,
      };
    case ESIGN_PROPERTY:
      return {
        ...state,
        eSignProperty: payload,
      };
    case ESIGN_SHARED:
      return {
        ...state,
        eSignShared: payload,
      };
    case ESIGN_SHARING:
      return {
        ...state,
        eSignSharing: payload,
      };
    case PROJECT_ESIGNS: {
      return {
        ...state,
        eSigns: payload,
      };
    }
    case ESIGN_ERRORS:
      return {
        ...state,
        errors: payload,
      };

    default:
      return state;
  }
};
