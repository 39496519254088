import React, { memo, useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';
import { Modal } from 'Components/Modal';
import { Span } from 'Components/Span';
import { PurpleButton } from 'Components/Button';
import { listCompanyContracts } from 'Containers/ContractForm/actions';
import { createEsignature, getPropertyForEsignature } from 'Containers/Esignature/action';
import { contractsInitialsSelector } from 'Containers/ContractForm/selectors';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { projectSelector } from 'Containers/RocketScan/selectors';
import { EsignatureItem } from 'Components/Esignature/EsignatureItem';
import { SearchBox } from 'Components/SearchBox';
import { populateCustomReportData } from 'Components/ReportsAndDocuments/Reports/GenerateReport/actions';
import classes from './ContractFormModal.module.css';

interface Props {
  isOpen: boolean;
  setContractFormModal: (e: any) => void;
  modalCloseClick: (e: any) => void;
}

const ContractFormModal = ({ isOpen, modalCloseClick, setContractFormModal }: Props) => {
  const dispatch = useDispatch();
  const textBoxRef = useRef(null);
  const searchBoxRef = useRef(null);
  const mounted = useRef(true);
  const checkList = useRef([]);

  const [searchValue, setSearchValue] = useState('');
  const [checkListIds, setCheckListIds] = useState([]);
  const [hasChecked, setHasChecked] = useState(false);
  const [loading, setLoading] = useState(true);

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);
  const contractFormInitials = useSelector(contractsInitialsSelector, areEqual);
  const project = useSelector(projectSelector, areEqual);

  const [contractList, setContractList] = useState([]);

  const selectContractForm = useCallback((formId: number) => {
    const index = checkList.current.indexOf(formId);
    const checkListRaw = checkList.current;
    if (index > -1) {
      checkListRaw.splice(index, 1);
      setLoading(true);
    } else {
      setLoading(true);
      checkListRaw.push(formId);
    }

    if (checkListRaw.length > 0) {
      setHasChecked(true);
      setLoading(false);
    } else {
      setHasChecked(false);
      setLoading(false);
    }
    checkList.current = checkListRaw;
    setCheckListIds(() => [...checkListRaw]);
  }, []);

  const getCompanyContracts = useCallback(async () => {
    await dispatch(listCompanyContracts(firstCompanyId));
  }, [firstCompanyId]);

  useEffect(() => {
    if (project?.id) {
      const { id } = project;
      dispatch(populateCustomReportData(id));
      dispatch(getPropertyForEsignature(id));
    }
  }, [project]);

  useEffect(() => {
    mounted.current = true;
    (async function fetchData() {
      await getCompanyContracts();
      setLoading(false);
    })();
    return () => {
      if (mounted) {
        mounted.current = false;
      }
    };
  }, [firstCompanyId]);

  useEffect(() => {
    if (loading === false) {
      setContractList(contractFormInitials);
    }
  }, [loading]);

  const onChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
    if (!(searchBoxRef.current == null)) {
      clearTimeout(searchBoxRef.current);
    }

    searchBoxRef.current = setTimeout(() => {
      if (e.target.value.length > 0) {
        const newContractList = contractFormInitials.filter(
          (contract) => contract.name.toLowerCase().indexOf(e.target.value) > -1
        );
        setContractList(newContractList);
      } else {
        setContractList(contractFormInitials);
      }
    }, 500);
  };

  const onClickClearButton = () => {
    setContractList(contractFormInitials);
    setSearchValue('');
    textBoxRef.current.value = '';
  };

  const onClickHandler = async () => {
    let isDirty = false;
    const { id: projectId } = project;
    for (let i = 0; i < contractFormInitials.length; i += 1) {
      const row = contractFormInitials[i];
      // if (checkList.includes(row.id)) {
      if (checkList.current.includes(row.id)) {
        isDirty = true;
        dispatch(createEsignature(firstCompanyId, projectId, row.id, row.name, row.replacementTags, 'not signed'));
      }
    }
    if (isDirty) {
      setContractFormModal(false);
      setHasChecked(false);
      checkList.current = [];
      setCheckListIds(() => []);
      await getCompanyContracts();
    }
  };

  React.useEffect(
    () => () => {
      clearTimeout(searchBoxRef.current);
    },
    []
  );

  return (
    <Modal
      title="Create Document to Sign"
      leftHeaderIcon="projects"
      isOpen={isOpen}
      classes={classes}
      modalHeader
      dataBsBackdrop="static"
      dataBsKeyboard="false"
      modalCloseClick={modalCloseClick}
    >
      <SearchBox
        ref={textBoxRef}
        id="contract-form-search"
        name="search"
        ariaLabel="Search a contract form"
        value={searchValue}
        onChangeValue={onChangeSearchValue}
        onClickClearButton={onClickClearButton}
      />
      <Span className={classes.FormTitle}>Form List</Span>
      {!loading &&
        contractList.map(({ id, name }) => (
          <React.Fragment key={id}>
            <EsignatureItem formId={id} title={name} checkList={checkListIds} selectContractForm={selectContractForm} />
          </React.Fragment>
        ))}
      <PurpleButton className={classes.buttonAdd} onClick={onClickHandler} disabled={!hasChecked}>
        Create Document to Sign
      </PurpleButton>
    </Modal>
  );
};

const ContractFormModalMemo = memo(ContractFormModal, areEqual);

export { ContractFormModalMemo as ContractFormModal };
