import React, { memo, useEffect, useState, useCallback } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { InternalAtmosphericTable } from 'Components/RocketDry';

import { InternalAtmosphericLog, InternalAtmosphericLogGroup, atmosphericLogType } from 'Containers/RocketDry/types';
import { useRocketDryFunctions } from 'Context/RocketDry';

interface Props {
  headers: any[];
  logs: any;
  roomAreaName: string;
}

const compareLogGroups = (group1: InternalAtmosphericLogGroup, group2: InternalAtmosphericLogGroup) => {
  if (group1.inlet.createdAt < group2.inlet.createdAt) {
    return -1;
  }

  if (group1.inlet.createdAt > group2.inlet.createdAt) {
    return 1;
  }

  return 0;
};

const GroupRoomAtmosphericLogsByOutlet = (areaGroupedLogs): InternalAtmosphericLogGroup[] =>
  areaGroupedLogs
    .reduce((logsTemp: InternalAtmosphericLogGroup[], logEntry, _, array) => {
      // assumption here is that inlet and outlet both have the same room area
      // -> so there shouldn't be unpaired outlet entries

      // get current log id
      const logId = logEntry.id;

      // is it an outlet entry? skip it, will get added alongside the corresponding inlet entry
      const isOutlet: boolean = logEntry.inlet_id ?? false;

      if (!isOutlet) {
        // create log for inlet
        const inletLogFinal: InternalAtmosphericLog = {
          id: logId,
          createdAt: logEntry.created_at,
          relativeHumidity: logEntry.relative_humidity,
          temperature: logEntry.temperature,
          dewPoint: logEntry.dew_point,
          gpp: logEntry.gpp,
          emptyEntry: !(logEntry.relative_humidity || logEntry.temperature || logEntry.dew_point || logEntry.gpp),
          photo: logEntry.photo,
          creator: { id: logEntry.creator.id, name: logEntry.creator.full_name },
        };
        if (logEntry.updated_at !== logEntry.created_at) {
          inletLogFinal.updatedAt = logEntry.updated_at;
        }

        // does any log have it as an inlet? if so, make an inlet-outlet pair
        const outletLog = array.find((log) => log.inlet_id === logId);

        let newEntry: InternalAtmosphericLogGroup;
        if (outletLog) {
          const outletLogFinal: InternalAtmosphericLog = {
            id: outletLog.id,
            createdAt: outletLog.created_at,
            relativeHumidity: outletLog.relative_humidity,
            temperature: outletLog.temperature,
            dewPoint: outletLog.dew_point,
            gpp: outletLog.gpp,
            photo: logEntry.photo,
            creator: { id: logEntry.creator.id, name: logEntry.creator.full_name },
          };
          if (outletLog.updated_at !== outletLog.created_at) {
            outletLogFinal.updatedAt = outletLog.updated_at;
          }

          newEntry = {
            inlet: inletLogFinal,
            outlet: outletLogFinal,
          };

          // add newly created log
          logsTemp.push(newEntry);
        } else {
          // otherwise it's just inlet
          newEntry = {
            inlet: inletLogFinal,
          };

          // add newly created log
          logsTemp.push(newEntry);
        }
      }

      return logsTemp;
    }, [])
    .sort(compareLogGroups);

function InternalAtmosphericTableContainer({ headers, logs, roomAreaName }: Props) {
  const { setSelectedAtmosphericLog, setSelectedAtmosphericLogType, setIsViewAtmosphericLogModalOpen }: any =
    useRocketDryFunctions();

  const handleViewPicture = useCallback((log: any) => {
    setSelectedAtmosphericLog(log);
    setSelectedAtmosphericLogType(atmosphericLogType.Internal);
    setIsViewAtmosphericLogModalOpen(true);
  }, []);

  const [groupedLogs, setGroupedLogs] = useState([]);

  useEffect(() => {
    if (logs?.length > 0) {
      setGroupedLogs(GroupRoomAtmosphericLogsByOutlet(logs));
    }
  }, [logs]);

  return (
    <InternalAtmosphericTable
      headers={headers}
      logs={groupedLogs}
      roomAreaName={roomAreaName}
      handleViewPicture={handleViewPicture}
    />
  );
}

const InternalAtmosphericTableContainerMemo = memo(InternalAtmosphericTableContainer, areEqual);

export { InternalAtmosphericTableContainerMemo as InternalAtmosphericTable };
