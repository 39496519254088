import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { TabContentHeader } from 'Components/TabContentHeader';
import { useSelector } from 'react-redux';
import { selectHeaderIcon } from 'Utils/projectHelpers';
import { projectAddressSelector, projectSelector, propertySelector } from 'Containers/RocketScan/selectors';

function NotesHeaderContainer() {
  const project = useSelector(projectSelector, areEqual);
  const projectAddress = useSelector(projectAddressSelector, areEqual);
  const property = useSelector(propertySelector, areEqual);

  return (
    projectAddress && (
      <TabContentHeader
        icon={selectHeaderIcon(property?.name)}
        name={projectAddress}
        isCommercial={false}
        isInaccessible={false}
        jobNumber={project.uid}
        hasDivider
      />
    )
  );
}

const NotesHeaderContainerMemo = memo(NotesHeaderContainer, areEqual);

export { NotesHeaderContainerMemo as NotesHeaderContainer };
