import React, { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Modal } from 'Components/Modal';
import { Spinner } from 'Components/Spinner';
import { TabContent } from 'Components/Tabs';

import { RocketPayCompanyModel } from 'Containers/User/Models/RocketPayModel/RocketPayCompanyModel';

import { PurpleButton } from 'Components/Button';
import classes from './rocketPayPayments.module.css';

// eslint-disable-next-line
import '@justifi/webcomponents/dist/module/justifi-payments-list.js';
// eslint-disable-next-line
import '@justifi/webcomponents/dist/module/justifi-payment-details.js';
// eslint-disable-next-line
import '@justifi/webcomponents/dist/webcomponents/webcomponents.css';

interface Props {
  companyInfo: RocketPayCompanyModel;
  authToken: string;
}

function RocketPayPayments({ companyInfo, authToken }: Props) {
  const formRefPayment = useRef(null);
  const [isFormReady, setIsFormReady] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paymentId, setPaymentId] = useState('');

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (formRefPayment.current) {
        setIsFormReady(true);
        observer.disconnect();
      }
    });

    if (formRefPayment.current) {
      setIsFormReady(true);
    } else {
      observer.observe(document.body, { childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, []);

  useLayoutEffect(() => {
    if (isFormReady) {
      const form = formRefPayment.current;
      if (form) {
        const paymentRowClicked = (event) => {
          setPaymentId(event.detail.id);
          setIsModalVisible(true);
        };
        form.addEventListener('payment-row-clicked', paymentRowClicked);

        // Cleanup
        return () => form.removeEventListener('payment-row-clicked', paymentRowClicked);
      }
    }
    return () => {};
  }, [isFormReady]);

  return (
    <TabContent key="tab-content-rocketpay-payments" id="rocketpaypayments" className="show active position-relative">
      <style>
        {`justifi-payments-list::part(table-head-cell) {

          }`}
      </style>
      <div className={classes.sectionContainer}>
        {companyInfo && authToken ? (
          <justifi-payments-list ref={formRefPayment} account-id={companyInfo.sub_account_id} auth-token={authToken} />
        ) : (
          <Spinner loading />
        )}
      </div>
      <Modal
        id="paymentDetailsModal"
        title="Payment Details"
        classes={{ modalDialog: classes.modalOverride }}
        isOpen={isModalVisible}
        modalHeader
        modalCloseClick={() => setIsModalVisible(false)}
      >
        {paymentId && authToken ? (
          <justifi-payment-details payment-id={paymentId} auth-token={authToken} />
        ) : (
          <Spinner loading />
        )}
        <div className="d-flex justify-content-end">
          <PurpleButton className={classes.closeButton} onClick={() => setIsModalVisible(false)}>
            Close
          </PurpleButton>
        </div>
      </Modal>
    </TabContent>
  );
}

const RocketPayPaymentsMemo = memo(RocketPayPayments, areEqual);
export { RocketPayPaymentsMemo as RocketPayPayments };
