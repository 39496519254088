import React, { memo, useEffect, useState } from 'react';

import { areEqual } from 'Utils/equalityChecks';

import { TabContent } from 'Components/Tabs';
import { Table, TableBody, TableHeader, TableRow, Th } from 'Components/Table';
import { handleApiRequest } from 'Utils/handleApiRequest';
import { Api } from 'Utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { RocketCallRecordingModel } from 'Containers/User/Models/RocketCallModel';
import classes from './rocketCallRecordingsTab.module.css';

function RocketCallRecordingsTabContainer() {
  const dispatch = useDispatch();
  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);
  const [rocketCallRecordings, setRocketCallRecordings] = useState<RocketCallRecordingModel[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await handleApiRequest(
        dispatch,
        Api.get(`companies/${firstCompanyId}/rocket-call-recordings`, {
          page: 1, // TODO: Add pagination
        })
      );
      if (response.data) {
        setRocketCallRecordings(response.data);
      }
    };

    if (rocketCallRecordings.length === 0) {
      fetchData();
    }
  }, [rocketCallRecordings]);

  return (
    <TabContent key="tab-content-rocketcall-recordinngs" id="rocketcallrecordings" className="position-relative">
      <div className={classes.sectionContainer}>
        <Table className={`table ${classes.tableWrapper}`}>
          <TableHeader>
            <TableRow>
              <Th>Contact Name</Th>
              <Th>Contact Number</Th>
              <Th>Date</Th>
              <Th>Duration</Th>
              <Th>Recording</Th>
            </TableRow>
          </TableHeader>
          <TableBody>
            {rocketCallRecordings.map((recording) => (
              <TableRow key={recording.id}>
                <td>
                  {recording.first_name} {recording.last_name}
                </td>
                <td>{recording.from_number}</td>
                <td>{recording.recorded_at}</td>
                <td>{recording.duration}</td>
                <td>
                  <a href={recording.call_recording_url} target="_blank" rel="noreferrer" aria-label="Play recording">
                    <i className="fa fa-play" />
                  </a>
                </td>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </TabContent>
  );
}

const RocketCallRecordingsTabContainerMemo = memo(RocketCallRecordingsTabContainer, areEqual);

export { RocketCallRecordingsTabContainerMemo as RocketCallRecordingsTabContainer };
